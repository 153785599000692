import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { C } from "@fullcalendar/core/internal-common";
import { empty, isEmpty } from "rxjs";
import { MENU_BUTTON_CODE, MENU_BUTTON_STYLE, c1 } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { MenuButton, Tab, colorSchema } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { BuilderService } from "src/app/sharedservices/builder.service";



@Component({
  selector: "app-button-submit",
  templateUrl: "./button-submit.component.html",
  styleUrls: ["./button-submit.component.scss"],
})
export class ButtonSubmitComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    public builderService: BuilderService,
    private ref: ChangeDetectorRef
  ) {}



  @Input() button: MenuButton;
  @Input() row_id: string;
  @Input() fontSmaller = false;

  @Input() mode: string;
  @Input() fontResize = false;
  @Input() data: Tab;
  @Input() colors:any;
  @Input() no_buttons = 1;

//////////////////// what have been used //////////////
currentMenuSubscription: any;
updateBuilderComponentSubscription: any;
offCanvasSubscription: any;
colorsComponentSubscription: any;

helper:string    // = 'Helper Text';
error: string    //'error';  error messages
helper_color: string //='#8e8e93'
error_color: string  //= '#FF0000' ;
leading_icon: string = null// 'fas fa-heart' ;
trailing_icon: string = null;   //= 'fas fa-check-circle';

error_icon: string = 'fas fa-exclamation-circle';
leading_icon_color: string
trailing_icon_color: string

suffix: string;
suffix_color: string
prefix : string;
prefix_color : string;

border_color : string;
border_color_ios: string;

bg_image: string = null;
bg_color: string ;
start_color: string =null;
end_color: string = null;
image: string;
album_button: any;
albums: any;
emptyImage: string;
emptyImageP0: string;
emptyImageP1: string;
widthP0: string;
label: string;
label_color: string;
settings: any;
primary_color: string;
pStyle: number;
cellHight: string;
labellPadding: string;
padding: string;
labelSetting: any
isAndroid : boolean;
current_button: any;
isActive: boolean;
outline: string;

/// colors to be common into the tab section///
light: boolean = true;
color_schema: any;
color_schema_ios: any;

ngOnInit() {
  this.isAndroid = localStorage.getItem("mode")[0]=== c1.ANDROID? true: false;
  let style = this.isAndroid? this.button.button_style: this.button.button_style_ios;
  this.initialize(style,this.no_buttons)

  this.currentMenuSubscription = this.mdw.currentMenuContainer$.subscribe((data) => {
    if (data && !data.fromButton){
      if (data["sortedButtonList"][this.row_id]){
        this.no_buttons = data["sortedButtonList"][this.row_id].length >0 ? data["sortedButtonList"][this.row_id].length: 1
        this.current_button =data["currentButton"];
        this.isActive =this.current_button && (this.current_button.button_id==this.button.button_id)? true: false

      if (data['colors']){
        this.colors = data["colors"];
        this.light = data["light"];
        this.color_schema = this.light? this.colors.color_schema.schemes.light:this.colors.color_schema.schemes.dark ;
        this.color_schema_ios = this.light? this.colors.color_schema_ios.schemes.light:  this.colors.color_schema_ios.schemes.dark
      }
      }
   this.isAndroid = localStorage.getItem("mode")[0]=== c1.ANDROID? true: false;
  let style = this.isAndroid? this.button.button_style: this.button.button_style_ios;
  // console.log('currentMenu style',style);

  this.initialize(style,this.no_buttons)
      }
  })
  this.colorsComponentSubscription = this.mdw.colorsComponentContainer$.subscribe(
    (data) => {
      if (data  && data.color_schema  && data.color_schema_ios && data.light !=null) {
        this.colors = data;
        this.light = data.light
        this.isAndroid = localStorage.getItem("mode")[0]=== c1.ANDROID? true: false;
        let style = this.isAndroid? this.button.button_style: this.button.button_style_ios;
        // console.log('colorsCompo style',style);

        this.initialize(style,this.no_buttons)
      }
    })

  this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
    (data) => {
      // only for getting default button to change style for active or none active button style
       if (data && data.button){
        this.current_button =data.button;
        this.isActive =this.current_button && (this.current_button.button_id==this.button.button_id)? true: false
        this.isAndroid = localStorage.getItem("mode")[0]=== c1.ANDROID? true: false;
        let style = this.isAndroid? this.button.button_style: this.button.button_style_ios;
        this.initialize(style,this.no_buttons)
         }
    }
  );
  this.updateBuilderComponentSubscription =
  this.mdw.updateBuilderComponentContainer$.subscribe((data) => {
      if (data && data.tab) {

        // only to get different views for button for Android and iOS. if both same view we do not need this subscription.
        this.isAndroid = localStorage.getItem("mode")[0]=== c1.ANDROID? true: false;

        let style = this.isAndroid? this.button.button_style: this.button.button_style_ios;

        this.initialize(style,this.no_buttons)
      }
  });
}

  initialize(style, no_button) {

   if (this.colors && this.colors.color_schema && this.button){
      this.color_schema = this.light? this.colors.color_schema.schemes.light:this.colors.color_schema.schemes.dark ;
      this.color_schema_ios = this.light? this.colors.color_schema_ios.schemes.light:  this.colors.color_schema_ios.schemes.dark
      // Button Content


         this.label = this.button.button_label
         this.leading_icon = this.isAndroid? this.button.button_icon: this.button.button_icon_ios
         this.trailing_icon = this.error ? this.error_icon : this.isAndroid? this.button.button_trailing_icon: this.button.button_trailing_icon_ios
         this.helper = this.error ? this.button.button_error: this.button.button_helper;
         this.bg_image= null;


        // Theme Settings
         let index = this.getStyle(style);
         this.album_button = album_input_map[index];
         this.settings = this.isAndroid ? this.album_button.settings : this.album_button.settings_ios
         this.labelSetting = this.isAndroid ? this.album_button.label : this.album_button.label_ios;

         this.cellHight = this.fontSmaller ? this.settings.cellHight_s : this.settings.cellHight
         this.padding = this.fontSmaller ? this.settings.padding_s : this.no_buttons === 3 ? this.settings.padding['3'] : this.settings.padding['default']
         this.labellPadding = this.fontSmaller ? this.labelSetting.padding_s : this.no_buttons === 3 ? this.labelSetting.padding['3'] : this.labelSetting.padding['default']

        // Colors
          this.themeColors(style)
          this.helper_color = this.isAndroid ? this.color_schema.onSurfaceVariant : this.color_schema_ios.onSurfaceVariant
          this.error_color = this.isAndroid ? this.color_schema.error : this.color_schema_ios.error

          this.trailing_icon_color = this.error ? this.error_color : this.trailing_icon_color;

          this.border_color = this.error ? this.error_color : this.isActive ? this.color_schema.primary :  this.color_schema.outline;   // Android
          this.border_color_ios = this.error ? this.error_color : this.color_schema_ios.outline;   // iOS
    }

  }

themeColors (style){
  switch (style){

    // elevated button
    case MENU_BUTTON_STYLE.STYLE_04:
    if (this.isAndroid=== true){
        // elevated button
        this.bg_color = this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'bg', 'surfaceContainerLow', this.light)
        this.label_color = this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'label', 'primary', this.light)
        this.leading_icon_color = this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'icon', 'primary', this.light)
        this.trailing_icon_color = this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'trailing_icon', 'primary', this.light)
        this.border_color = null;
      } else if (this.isAndroid=== false) {
        // grey button
        this.bg_color = this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'bg', 'surfaceContainerHigh', this.light)
        this.label_color = this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'label', 'primary', this.light)
        this.leading_icon_color = this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'icon', 'primary', this.light)
        this.trailing_icon_color = this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'trailing_icon', 'primary', this.light)
        this.border_color = null;
      }
     break;
    //filled
    case MENU_BUTTON_STYLE.STYLE_01:
      if (this.isAndroid=== true){
        this.bg_color = this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'bg', 'primary', this.light)
        this.label_color = this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'label', 'onPrimary', this.light)
        this.leading_icon_color = this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'icon', 'onPrimary', this.light)
        this.trailing_icon_color = this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'trailing_icon', 'onPrimary', this.light)
        this.border_color = null;
      } else if (this.isAndroid=== false) {
        // filled
        this.bg_color = this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'bg', 'primary', this.light)
        this.label_color = this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'label', 'onPrimary', this.light)
        this.leading_icon_color = this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'icon', 'onPrimary', this.light)
        this.trailing_icon_color = this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'trailing_icon', 'onPrimary', this.light)
        this.border_color = null;
      }
      break;
      //Tonal
      case MENU_BUTTON_STYLE.STYLE_03:
      if (this.isAndroid===true){
        this.bg_color = this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'bg', 'secondaryContainer', this.light)
        this.label_color = this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'label', 'onSecondaryContainer', this.light)
        this.leading_icon_color = this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'icon', 'onSecondaryContainer', this.light)
        this.trailing_icon_color = this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'trailing_icon', 'onSecondaryContainer', this.light)
        this.border_color = null;
      }else if (this.isAndroid=== false) {
        //Tinited
        this.bg_color = this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'bg', 'primaryContainer', this.light)
        this.label_color = this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'label', 'primary', this.light)
        this.leading_icon_color = this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'icon', 'primary', this.light)
        this.trailing_icon_color = this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'trailing_icon', 'primary', this.light)
        this.border_color = null;
      }
      break;
      //outlined
    case MENU_BUTTON_STYLE.STYLE_02:
      if(this.isAndroid===true){
        this.bg_color = null;
        this.label_color = this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'label', 'primary', this.light)
        this.leading_icon_color = this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'icon', 'primary', this.light)
        this.trailing_icon_color = this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'trailing_icon', 'primary', this.light)
        this.border_color = this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'outline', 'outline', this.light)
      }else if (this.isAndroid=== false){
        //outlined
        this.bg_color = null;
        this.label_color = this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'label', 'primary', this.light)
        this.leading_icon_color = this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'icon', 'primary', this.light)
        this.trailing_icon_color = this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'trailing_icon', 'primary', this.light)
        this.border_color = this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'outline', 'outline', this.light)
      }
      break;

      // text
      case MENU_BUTTON_STYLE.STYLE_05:
        if (this.isAndroid===true){
          this.bg_color = null;
          this.label_color = this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'label', 'primary', this.light)
          this.leading_icon_color = this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'icon', 'primary', this.light)
          this.trailing_icon_color = this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'trailing_icon', 'primary', this.light)
          this.border_color = null;
        }else if (this.isAndroid=== false){
          //text
        this.bg_color = null;
        this.label_color = this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'label', 'primary', this.light)
        this.leading_icon_color = this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'icon', 'primary', this.light)
        this.trailing_icon_color = this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'trailing_icon', 'primary', this.light)
        this.border_color = null;
        }
        break;
   }

  }
  get setButtonBg() {
    if (this.bg_image) {
      return {
        "background-image": `url(${this.bg_image})`,
        "background-position": "center",
        "background-repeat": "no-repeat",
        "background-size": "cover",
      };
    }
    else if (this.start_color && this.end_color) {
      return {
        "background-image": `linear-gradient(180deg, ${this.start_color}, ${this.end_color})`,
      };
    } else if (this.bg_color) {
      return {
        'background': this.bg_color,  };
    }else {
      return { background: "unset" };
    }
  }
get labelColor() {
  return {
    "color": this.label_color,
    'font-size': this.fontSmaller ? '4px' : this.no_buttons==2? '12px': this.no_buttons==3?'10px': (this.labelSetting && this.labelSetting.titleSize)? this.labelSetting.titleSize: '12px',
  };
}

get helperColor() {
  return {
  "color": this.helper_color? this.helper_color: '#EBEBEB',
  'font-size': this.fontSmaller ? '2px' : this.no_buttons==3?'8px':'10px',
};
}

get errorColor() {
  return {
  "color": this.error_color? this.error_color: '#FF0000',
  'font-size': this.fontSmaller ? '2px' : this.no_buttons==3?'10px':'12px',
};
}

get leadingIconColor() {
  return {
  "color": this.leading_icon_color? this.leading_icon_color: '#676767',
  'font-size': this.fontSmaller ? '6px' :  this.no_buttons==2?'16px': this.no_buttons==3?'16px':'18px',
};
}

get traillingIconColor() {
  return {
  "color": this.trailing_icon_color? this.trailing_icon_color: '#676767',
  'font-size': this.fontSmaller ? '4px' : this.no_buttons==3?'14px':'14px',
};
}

get borderColor() {
    return {

      "border": "solid 1px " + this.border_color,
      "height": "40px"
    };
  }


getStyle(style){
  // if (this.isAndroid){
  //   let active = this.isActive? '_A': null;
  //   if (active){
  //     return  style + active
  //   }else return style
  // }else
  return style
}

ngOnDestroy(): void {
  if (this.updateBuilderComponentSubscription) {
    this.updateBuilderComponentSubscription.unsubscribe();
  }
  if (this.currentMenuSubscription) {
    this.currentMenuSubscription.unsubscribe();
  }

  if (this.colorsComponentSubscription) {
    this.colorsComponentSubscription.unsubscribe();
  }

  if (this.offCanvasSubscription) {
    this.offCanvasSubscription.unsubscribe();
  }
}


}


export const album_input_map = {
  [MENU_BUTTON_STYLE?.STYLE_04]: {
    settings:{cellHight:'36px',  cellHight_s:'18px',  padding:{default:'shadow-sm p-0 rounded-pill', 3:'shadow-sm p-0 rounded-pill'}, padding_s:'shadow-sm p-0 rounded-pill',},
    label:{ padding:{default:'fw-bolder py-0 ps-2', 3:'fw-bolder py-0 ps-0'}, padding_s:'fw-bolder p-0', titleSize:'12px',  titleSize_s:'4px'},
    settings_ios:{cellHight:'36px',  cellHight_s:'18px',  padding:{default:'p-0 rounded-3', 3:'p-0 rounded-3'}, padding_s:'p-0 rounded-2',},
    label_ios:{ padding:{default:'py-0 ps-2', 3:'py-0 ps-0'}, padding_s:'p-0', titleSize:'12px',  titleSize_s:'4px'},
  },
  [MENU_BUTTON_STYLE?.STYLE_01]: {
    settings:{cellHight:'36px',  cellHight_s:'18px',  padding:{default:'p-0 rounded-pill', 3:'p-0 rounded-pill'}, padding_s:'p-0 rounded-pill',},
    label:{ padding:{default:'py-0 ps-2', 3:'py-0 ps-0'}, padding_s:'p-0', titleSize:'12px',  titleSize_s:'4px'},
    settings_ios:{cellHight:'36px',  cellHight_s:'18px',  padding:{default:'p-0 rounded-3', 3:'p-0 rounded-3'}, padding_s:'p-0 rounded-2',},
    label_ios:{ padding:{default:'py-0 ps-2', 3:'py-0 ps-0'}, padding_s:'p-0', titleSize:'12px',  titleSize_s:'4px'},
  },
  [MENU_BUTTON_STYLE?.STYLE_03]: {
    settings:{cellHight:'36px',  cellHight_s:'18px',  padding:{default:'p-0 rounded-pill', 3:'p-0 rounded-pill'}, padding_s:'p-0 rounded-pill',},
    label:{ padding:{default:'fw-bolder py-0 ps-2', 3:'fw-bolder py-0 ps-0'}, padding_s:'fw-bolder p-0', titleSize:'12px',  titleSize_s:'4px'},
    settings_ios:{cellHight:'36px',  cellHight_s:'18px',  padding:{default:'p-0 rounded-3', 3:'p-0 rounded-3'}, padding_s:'p-0 rounded-2',},
    label_ios:{ padding:{default:'py-0 ps-2', 3:'py-0 ps-0'}, padding_s:'p-0', titleSize:'12px',  titleSize_s:'4px'},
  },
  [MENU_BUTTON_STYLE?.STYLE_02]: {
    settings:{cellHight:'36px',  cellHight_s:'18px',  padding:{default:'p-0 border border-1 rounded-pill', 3:'p-0 border border-1 rounded-pill'}, padding_s:'p-0 border border-1 rounded-pill',},
    label:{ padding:{default:'py-0 ps-2', 3:'py-0 ps-0'}, padding_s:'p-0', titleSize:'12px',  titleSize_s:'4px'},
    settings_ios:{cellHight:'36px',  cellHight_s:'18px',  padding:{default:'p-0 border border-1 rounded-3', 3:'p-0 border border-1 rounded-3'}, padding_s:'p-0 border border-1 rounded-2',},
    label_ios:{ padding:{default:'py-0 ps-2', 3:'py-0 ps-0'}, padding_s:'p-0', titleSize:'12px',  titleSize_s:'4px'},
  },
  [MENU_BUTTON_STYLE?.STYLE_05]: {
    settings:{cellHight:'36px',  cellHight_s:'18px',  padding:{default:'p-0 rounded-pill', 3:'p-0 rounded-pill'}, padding_s:'p-0 rounded-pill',},
    label:{ padding:{default:'fw-bolder py-0 ps-2', 3:'fw-bolder py-0 ps-0'}, padding_s:'fw-bolder p-0', titleSize:'12px',  titleSize_s:'4px'},
    settings_ios:{cellHight:'36px',  cellHight_s:'18px',  padding:{default:'p-0 rounded-3', 3:'p-0 rounded-3'}, padding_s:'p-0 rounded-2',},
    label_ios:{ padding:{default:'py-0 ps-2', 3:'py-0 ps-0'}, padding_s:'p-0', titleSize:'12px',  titleSize_s:'4px'},
  },
}



