import { Input } from '@angular/core';
import { Tab } from 'src/app/core/services/interface';
import { androidColors } from "./interface";

export enum wireframeConst{
 DX = 2*260,
 DY = 1.1* 533,
 HXINIT= 260,
 HYINIT= 533,
}

export enum ValidateItemKey{
  NOT ="not",
  DOESNOTCONTAIN ="doesNotContain",
  CONTAIN ="contain",
  LOGO ="logo",
  LENGTH = "length",
  WEBVIEW = "web_view",
  NOTEMPTY = "notEmpty",
  NEXT = "next",
  URL ="url",
  STATUS ="status",
  OBJECTLENGTH ="objectLength",
  COMPANY="companyDetail"
}
export enum AppItems{
  APP_INFO ="app_info",
  SPLASH ="splash",
  LOGIN_EMAIL ="email",
  LOGIN_MSISDN ="msisdn",
  VERSION = "version",
  STRPIE = "stripe",
  PAYPAL = "paypal",
  AMAZON = "amazon",
  GOOGLE_ADS = "google_ads",
  COMPANY = 'company_detail'
  }

  export enum AppKeys{
PRIVACY="privacy_url",
TERMS="tbc_link",
LOGOCOLOR="logo_color",
CHANNELNAME="channel_name",
COMPANY="company_detail",
APPINFO="app_info",

  }

export enum TabKeys{
  WEBVIEW ="web_view",
  IMAGE = "image_url",
  CHATID = "chat_id"
  }
export const  tab_check_list = {
  [TabKeys.WEBVIEW]:
  {id: TabKeys.WEBVIEW, validators:[{id: ValidateItemKey.WEBVIEW}], name:"Webview URL" ,icon:null, type:{apk: "error", default: "error"}, level:"info", error:"Update Webview Component's URL", route: null, setting: true, target:{apk:true, apn:true, bundle: true, publish: true, ai: true}, },
  [TabKeys.IMAGE]:
  {id: TabKeys.IMAGE, validators:[{id: ValidateItemKey.DOESNOTCONTAIN, value:"nandbox.com"}], name:"Component's Image", icon:null, type:{apk: "error", default: "error"},level:"error", error:"Change Template Image", route: null, setting: true, target:{apk:false, apn:true, bundle: true, publish: true, ai:true}, },


  [TabKeys.CHATID]:
  {id: TabKeys.CHATID, validators:[{id: ValidateItemKey.DOESNOTCONTAIN, value:"nandbox.com"}], name:"Component's Image", icon:null, type:{apk: "error", default: "error"},level:"info", error:"Change Template Image", route: null, setting: true, target:{apk:true, apn:true, bundle: true, publish: true, ai:true}, },
}

export enum ButtonKeys{
  IMAGE = "button_img_url",
  BGIMAGE = "button_bgimage",
  IMAGEDARK = "button_img_url_dark",
  BGIMAGEDARAK="button_bgimage_dark",
  IMAGEIOS = "button_img_url_ios",
  BGIMAGEIOS = "button_bgimage_ios",
  IMAGEDARKIOS = "button_img_url_dark_ios",
  BGIMAGEDARAKIOS="button_bgimage_dark_ios",
  }
export const  button_check_list = {
  [ButtonKeys.IMAGE]:
  {id: ButtonKeys.IMAGE, validators:[{id: ValidateItemKey.DOESNOTCONTAIN, value:"nandbox.com"}], name:"Cell's Image",icon:"indeterminate_check_box", type:{apk: "error", default: "error"}, level:"error", error:"Use Your own Images", route: null, setting: true, target:{apk:false, apn:true, bundle: true, publish: true, ai:true}, },
  [ButtonKeys.BGIMAGE]:
  {id: ButtonKeys.BGIMAGE, validators:[{id: ValidateItemKey.DOESNOTCONTAIN, value:"nandbox.com"}], name:"Cell's Image", icon:"indeterminate_check_box",type:{apk: "error", default: "error"}, level:"error", error:"Use Your own Images", route: null, setting: true, target:{apk:false, apn:true, bundle: true, publish: true, ai:true}, },
  [ButtonKeys.IMAGEDARK]:
  {id: ButtonKeys.IMAGEDARK, validators:[{id: ValidateItemKey.DOESNOTCONTAIN, value:"nandbox.com"}], name:"Cell's Image", icon:"indeterminate_check_box",type:{apk: "error", default: "error"},level:"error", error:"Use Your own Images", route: null, setting: true, target:{apk:false, apn:true, bundle: true, publish: true, ai:true}, },
  [ButtonKeys.BGIMAGEDARAK]:
  {id: ButtonKeys.BGIMAGEDARAK, validators:[{id: ValidateItemKey.DOESNOTCONTAIN, value:"nandbox.com"}], name:"Cell's Image",icon:"indeterminate_check_box", type:{apk: "error", default: "error"},level:"error", error:"Use Your own Images", route: null, setting: true, target:{apk:false, apn:true, bundle: true, publish: true, ai:true}, },
  [ButtonKeys.IMAGEIOS]:
  {id: ButtonKeys.IMAGEIOS, validators:[{id:ValidateItemKey.DOESNOTCONTAIN, value:"nandbox.com"}], name:"Cell's Image",icon:"indeterminate_check_box", type:{apk: "error", default: "error"}, level:"error",error:"Use Your own Images", route: null, setting: true, target:{apk:false, apn:true, bundle: true, publish: true, ai:true}, },
  [ButtonKeys.BGIMAGEIOS]:
  {id: ButtonKeys.BGIMAGEIOS, validators:[{id: ValidateItemKey.DOESNOTCONTAIN, value:"nandbox.com"}], name:"Cell's Image", icon:"indeterminate_check_box",type:{apk: "error", default: "error"},level:"error", error:"Use Your own Images", route: null, setting: true, target:{apk:false, apn:true, bundle: true, publish: true, ai:true}, },
  [ButtonKeys.IMAGEDARKIOS]:
  {id: ButtonKeys.IMAGEDARKIOS, validators:[{id:ValidateItemKey.DOESNOTCONTAIN, value:"nandbox.com"}], name:"Cell's Image",  icon:"indeterminate_check_box", type:{apk: "error", default: "error"},level:"error", error:"Use Your own Images", route: null, setting: true, target:{apk:false, apn:true, bundle: true, publish: true, ai:true}, },
  [ButtonKeys.BGIMAGEDARAKIOS]:
  {id: ButtonKeys.BGIMAGEDARAKIOS, validators:[{id:ValidateItemKey.DOESNOTCONTAIN, value:"nandbox.com"}], name:"Cell's Image",  icon:"indeterminate_check_box", type:{apk: "error", default: "error"},level:"error", error:"Use Your own Images", route: null, setting: true, target:{apk:false, apn:true, bundle: true, publish: true, ai:true}, },

}
export const  app_check_list = {
[AppItems.APP_INFO]: [
  {id:AppKeys.CHANNELNAME , validators:[{id: ValidateItemKey.NOT, value:"newApp"}, ], name:"App Name" ,icon:"title", type:{apk: "warning", default: "error"}, level:"info", error:"Change App Name", route:"/appbuilder/branding", setting: false, target:{apk:true, apn:true, bundle: true, publish: true, ai:true}, },
  {id:AppKeys.LOGOCOLOR, validators:[{id:ValidateItemKey.OBJECTLENGTH, value:26}],name: "App Logo" ,icon:'business', type:{apk: "error", default: "error"},level:"info", error: "Upload logo", route:"/appbuilder/branding", setting: false, target:{apk:false, apn:true, bundle: true, publish: true, ai:true}},
],

[AppItems.COMPANY]: [
    {id:AppKeys.APPINFO, validators:[{id:ValidateItemKey.COMPANY}],name: "Company Details" ,icon:'store', type:{apk: "error", default: "error"},level:"info", error: "Complete Your Company Detail", route:"/appbuilder/build/company-details", setting: false, target:{apk:true, apn:true, bundle: true, publish: true, ai:true}},
 ],

[AppItems.SPLASH]:[
  {id: AppKeys.TERMS, check: {validate:{id:ValidateItemKey.URL},  name:"Term and Conditions" ,icon:"title", type:{apk: "error", default: "error"}, level:"info",error: "Terms and Condition URL is not valid", route: "/appbuilder/branding/splash/splash", setting: true, target:{apk:false, apn:true, bundle: true, publish: true, ai: true}}},
  {id: AppKeys.PRIVACY, check: {validate:{id:ValidateItemKey.URL},  name:"Privacy Policy Name" ,icon:"title", type:{apk: "error", default: "error"}, level:"info", error: "Privacy Policy URL is not valid", route: "/appbuilder/branding/splash/splash", setting: false,target:{apk:false, apn:true, bundle: true, publish: true, ai: true}}},
],

[AppItems.LOGIN_EMAIL]: [
  {id: AppKeys.PRIVACY, check: {validate:{id:ValidateItemKey.URL},  name:"Privacy Policy Name" ,icon:"title", type:{apk: "error", default: "error"}, level:"info", error: "Privacy Policy URL is not valid", route: "/appbuilder/branding/splash/splash", setting: false,target:{apk:false, apn:true, bundle: true, publish: true, ai: true}}},
],
[AppItems.LOGIN_MSISDN]: [
  {id: AppKeys.PRIVACY, check: {validate:{id:ValidateItemKey.URL},  name:"Privacy Policy Name" ,icon:"title", type:{apk: "error", default: "error"}, level:"info", error: "Privacy Policy URL is not valid", route: "/appbuilder/branding/splash/splash", setting: false,target:{apk:false, apn:true, bundle: true, publish: true, ai: true}}},
]

  }


  export enum TabNames {
    // Existing entries
    FEED = "feed",
    WEBVIEW = "web_view",
    CHANNEL = "channel",
    OPENBOT = "open_bot",
    CHAT = "chat",
    CONTACT = "contact",
    CHANNELLIST = "channel_list",
    GROUPLIST = "group_list",
    VIDEOAUDIO = "VIDEOAUDIO",
    SETTINGS = "setting",
    CALENDAR = "event",
    INVITATION = "invitation",
    OPENCHAT = "open_chat",
    OPENGROUP = "open_group",
    MYLIST = "mylist",
    QR = "qr",
    MAP = "map",
    PAGE = "page",
    SEARCH = "search",
    GROUP = "group",
    BOOKINGLIST = "booking_list",
    MARKET = "market",
    CALL_LOG = "call_log",
    BUNDLE = "bundle",
    VIDEO = "video",
    PACKAGE = "package",
    QR_PAGE = "qr_page",
    VALIDATE_TICKET = "validate_ticket",
    REDEEM_TICKET = "redeem_ticket",
    MAP_SEARCH = "map_search",
    MENU = "menu",
    STORE = "store",
    POLL = "poll",
    ONLINECHANNEL = "online_channels",
    ONLINEGROUP = "online_groups",
    EMPTY = "empty",
    SPEEDDIALER = "dialer",
    ORDERLIST = "orderlist",
    BOOKING = "booking",
    QUEUINGLIST = "queuinglist",
    EVENT = "myevent",
    EVENTLIST = "eventlist",
    SPLASH = "splash",
    PALETTE = "palette",
    ONBOARDING = "onboarding",
    QUERY = "query",
    MYORDERS = "my_orders",
    PAYMENT_VIEW = "payment_view",
    LOGIN_VIEW = "login_view",
    PRODUCT ="product",
    COLLECTION="collection",
    CATEGORY="category",
    BOOKINGPRODUCT="booking_product",
    EVENTPRODUCT="event_product",
    CART="cart",
    LISTVIEW="list_view",

    // Added entries from links
    SUB_MESSENGER_VOICE_MESSAGE_1 = "messenger_voice_message_1",
    SUB_MESSENGER_VOICE_MESSAGE_2 = "messenger_voice_message_2",
    SUB_MESSENGER_VOICE_MESSAGE_3 = "messenger_voice_message_3",
    SUB_MESSENGER_VOICE_MESSAGE_4 = "messenger_voice_message_4",
    SUB_MESSENGER_VIDEO_CALL = "messenger_video_call",
    SUB_MESSENGER_USER_PROFILE = "messenger_user_profile",
    SUB_MESSENGER_SEND_VIDEO = "messenger_send_video",
    SUB_MESSENGER_SEND_PHOTO = "messenger_send_photo",
    SUB_MESSENGER_SEND_FILE = "messenger_send_file",
    SUB_MESSENGER_SEARCH = "messenger_search",
    SUB_MESSENGER_REPLY_MESSAGE = "messenger_reply_message",
    SUB_MESSENGER_MULTI_PROFILE = "messenger_multi_profile",
    SUB_MESSENGER_MEDIA_FILE = "messenger_media_file",
    SUB_MESSENGER_SEND_LOCATION = "messenger_group_send_location",
    SUB_MESSENGER_SELECT_MESSAGE = "messenger_group_select_message",
    SUB_MESSENGER_GALLERY = "messenger_gallery",
    SUB_MESSENGER_DELETE_MESSAGE = "messenger_delete_message",
    SUB_MESSENGER_CONTACTS = "messenger_contacts",
    SUB_MESSENGER_CHAT_MENU = "messenger_chat_menu",
    SUB_MESSENGER_CHAT_TEXTM = "messenger_chat_textm",
    SUB_MESSENGER_CHAT = "messenger_chat",
    SUB_MESSENGER_AUDIO_FILE = "messenger_audio_file",
    SUB_MESSENGER_AUDIO_CALL = "messenger_audio_call",
    SUB_MESSENGER_ATTACH = "messenger_attach",
    SUB_EMOJI = "emoji",
    SUB_COLORED_MESSAGE_CHAT = "colored_message_chat",
    SUB_CHANNELS_FORWARD_MESSAGE = "channels_forward_message",
    SUB_MESSENGER_CHAT_GROUP = "messenger_chat_group",
    SUB_CREATE_TYPE_CHAT_GROUP_INFO = "create_type_chat_group_info",
    SUB_CREATE_CHAT_GROUP_INFO = "create_chat_group_info",
    SUB_CREATE_CHAT_GROUP = "create_chat_group",
    SUB_CHAT_GROUP_TEXT_MESSAGE_BACKGROUND = "chat_group_text_message_background",
    SUB_CHAT_GROUP_TEXT_MESSAGE = "chat_group_text_message",
    SUB_CHAT_GROUP_SEND_PHOTO = "chat_group_send_photo",
    SUB_CHAT_GROUP_SELECT_MESSAGE = "chat_group_select_message",
    SUB_CHAT_GROUP_NOTIFICATION = "chat_group_notification",
    SUB_CHAT_GROUP_INFO = "chat_group_info",
    SUB_CHAT_GROUP_EMOJE = "chat_group_emoje",
    SUB_CHAT_GROUP_VOICE_MESSAGE_CHECK = "chat_group_voice_message_check",
    SUB_CHAT_GROUP_VOICE_MESSAGE = "chat_group_voice_message",
    SUB_CHAT_GROUP_ATTACH = "chat_group_attach",
    SUB_CHAT_GROUP_USER_PERMISSIONS = "chat_group_user_permissions",
    SUB_CHAT_GROUP_SHARE = "chat_group_share",
    SUB_CHAT_GROUP_SEND_VOICE_MESSAGE = "chat_group_send_voice_message",
    SUB_CHAT_GROUP_REPLIES = "chat_group_replies",
    SUB_CHAT_GROUP_QR = "chat_group_qr",
    SUB_CHAT_GROUP_LOCK_VOICE_MESSAGE = "chat_group_lock_voice_message",
    SUB_CHAT_GROUP_INVITE_ADMIN_PARTICIPANTS = "chat_group_invite_admin_participants",

    SUB_MAIN = "sub_main"


  }

export enum CHAT_TYPE {
  CHANNEL = "Channel",
  GROUP = "Group",
  REPLY = "Reply",
  LEVEL1 = "Level1"
}
export enum ASSIGN_STORE {
  DEFAULT = 0,
  EXISTING = 1,
  NEW = 2,
}
export enum MENU_CELL_STYLE {
  STYLE_0 = 0,
  STYLE_1 = 1,
  STYLE_2 = 2,
  STYLE_3 = 3,
  STYLE_4 = 4,
  STYLE_5 = 5,
  STYLE_6 = 6,
  STYLE_7 = 7,
  STYLE_8 = 8,
  STYLE_9 = 9,
}

export enum CELLNAMES {
  HEADLINE = "Headline",
  CARDS = "Cards",
  TEXTFIELD = "Text Fields",
  BUTTONS = "Buttons",
  OUTPUTS = "Outputs",
  RADIOBUTTONS = "Radio Buttons",
  CHECKBOX = "Checkbox",
  TOGGLE = "Toggle Buttons",
  DROPDOWN = "Dropdown Menu",
}
export enum MENU_BUTTON_STYLE {
  STYLE_1 = "1",
  STYLE_2 = "2",
  STYLE_3 = "3",
  STYLE_4 = "4",
  STYLE_0 = "0",
  STYLE_01 = "01",
  STYLE_02 = "02",
  STYLE_03 = "03",
  STYLE_04 = "04",
  STYLE_05 = "05",
  STYLE_06 = "06",
  STYLE_07 = "07",
  STYLE_08 = "08",
  STYLE_1000 = "1000",
  STYLE_2000 = "2000",
  STYLE_3000 = "3000",
  STYLE_4000 = "4000",
  STYLE_5000 = "5000",
  STYLE_6000 = "6000",
  STYLE_7000 = "7000",
  STYLE_8000 = "8000",
  STYLE_9000 = "9000",
  STYLE_01_A = "01_A",
  STYLE_02_A = "02_A",
  STYLE_03_A = "03_A",
  STYLE_04_A = "04_A",
  STYLE_05_A = "05_A",
  STYLE_06_A = "06_A",
  STYLE_07_A = "07_A",
  STYLE_08_A = "08_A",
  STYLE_0603 = "0603",
  STYLE_0303 = "0303",
  STYLE_0503 = "0503",
  STYLE_0403 = "0403",
  STYLE_0703 = "0703",
  STYLE_0803 = "0803",
  STYLE_1004 = "1004",
  STYLE_0103="0103",
  STYLE_0204="0204",
  STYLE_0101 ="0101"
}
export enum MENU_BUTTON_FORM {
  SEPARATOR = "bar",
  BUTTON = "button",
  INPUT = "inout",
  SEARCHBAR = "searchbar",
  OUTPUT = "out",
  SUBMIT = "submit",
  CARD = "card",
  SINGLECHOICE = "singlechoice",
  DROPDOWN = "dropdown",
  MULTICHOICE = "multiplechoice",
  MULTICHOICE_SEGMENTED = "multi_segmented",
  SINGLECHOICE_SEGMENTED = "single_segmented",
  MULTI_CHIPSET = "multi_chipset",
  SINGLE_CHIPSET = "single_chipset",
  ASSIST_CHIPSET = "assist_chipset",
  DROPDOWN_CHIP = "dropdown_chip",
  TOGGLE = "toggle",
  PRODUCT = "product",
  COLLECTION = "collection",
  PLACEHOLDER = "placeholder",
  PAGESLIDER = "pageslider",
  PAGEGALLERY = "pagegallery",
  PAGESINGLEIMAGE = "pagesingleimage",
  PAGEFEATURE = "pagefeature",
  PAGETEXT = "pagetext",
  PAGEHEADER = "pageheader",
  PAGEDIVIDER = "pagedivider",
  PAGEVIDEO = "pagevideo",
  PAGEQR = "pageqr",
  PAGELOCATION = "pagelocation",
  PAGEPDFVIEWER = "pagepdfviewer",
  PAGESOCIALBAR = "pagesocialbar",
  PAGESLIDERLABEL = "pagesliderlabel",
  SPLASH = "splash",
  FILLER ="filler"
}
export enum MENU_BUTTON_CODE {
  SEPARATOR_01 = "bar_01",
  BUTTON_01 = "button_01",
  BUTTON_02 = "button_02",
  BUTTON_03 = "button_03",
  BUTTON_04 = "button_04",
  BUTTON_05 = "button_05",
  BUTTON_06 = "button_06",
  BUTTON_07 = "button_07",
  BUTTON_08 = "button_08",
  CARD_01 = "card_01",
  CARD_03 = "card_03",
  CARD_04 = "card_04",
  CARD_05 = "card_05",
  CARD_06 = "card_06",
  CARD_07 = "card_07",
  CARD_08 = "card_08",
  INPUT_01 = "inout_01",
  INPUT_02 = "inout_02",
  INPUT_03 = "inout_03",
  OUTPUT_01 = "out_01",
  OUTPUT_02 = "out_02",
  OUTPUT_03 = "out_03",
  SUBMIT_01 = "submit_01",
  SUBMIT_02 = "submit_02",
  SUBMIT_03 = "submit_03",
  SUBMIT_04 = "submit_04",
  SUBMIT_05 = "submit_05",
  SINGLECHOICE_1000 = "singlechoice_1000",
  SINGLECHOICE_2000 = "singlechoice_2000",
  SINGLECHOICE_3000 = "singlechoice_3000",
  MULTICHOICE_1000 = "multiplechoice_1000",
  MULTICHOICE_2000 = "multiplechoice_2000",
  MULTICHOICE_3000 = "multiplechoice_3000",
  MULTICHOICE_5000 = "multiplechoice_5000",
  MULTICHOICE_6000 = "multiplechoice_6000",
  MULTICHOICE_SEGMENTED_1000 = "multichoice_segmented_1000",
  SINGLECHOICE_SEGMENTED_1000 = "singlechoice_segmented_1000",
  MUTLI_CHIPSET_0100 = "mutli_chipset_0100",
  MUTLI_CHIPSET_0102 = "mutli_chipset_0102",
  MUTLI_CHIPSET_0200 = "mutli_chipset_0200",
  MUTLI_CHIPSET_0202 = "mutli_chipset_0202",
  SINGLE_CHIPSET_0100 = "single_chipset_0100",
  SINGLE_CHIPSET_0102 = "single_chipset_0102",
  SINGLE_CHIPSET_0200 = "single_chipset_0200",
  SINGLE_CHIPSET_0202 = "single_chipset_0202",
  ASSIST_CHIPSET_0100 = "assist_chipset_0100",
  ASSIST_CHIPSET_0102 = "assist_chipset_0102",
  DROPDOWN_CHIP_01 = "dropdown_chip_01",
  DROPDOWN_CHIP_02 = "dropdown_chip_02",
  INPUT_SEARCH_01 = "input_search_01",
  SEARCHBAR_01 = "searchbar_01",
  INPUT_SEARCH_03 = "input_search_03",
  INPUT_SEARCH_04 = "input_search_04",
  TOGGLE_1000 = "toggle_1000",
  TOGGLE_2000 = "toggle_2000",
  TOGGLE_3000 = "toggle_3000",
  DROPDOWN_01 = "dropdown_01",
  DROPDOWN_02 = "dropdown_02",
  DROPDOWN_03 = "dropdown_03",
  TOGGLECHOICE = "togglechoice",
  PRODUCT = "product",
  COLLECTION = "collection",
  COLLECTION_01 = "collection_01",
  COLLECTION_02 = "collection_02",
  COLLECTION_03 = "collection_03",
  COLLECTION_04 = "collection_04",
  COLLECTION_05 = "collection_05",
  COLLECTION_06 = "collection_06",
  COLLECTION_07 = "collection_07",
  COLLECTION_08 = "collection_08",
  COLLECTION_09 = "collection_09",
  COLLECTION_10 = "collection_10",
  COLLECTION_11 = "collection_11",
  COLLECTION_12 = "collection_12",
  COLLECTION_13 = "collection_13",
  COLLECTION_14 = "collection_14",
  COLLECTION_15 = "collection_15",
  COLLECTION_16 = "collection_16",
  PAGETEXT = "pagetext",
  PAGESLIDER = "pageslider",
  PAGEGALLERY = "pagegallery",
  PAGESINGLEIMAGE = "pagesingleimage",
  PAGEFEATURE = "pagefeature",
  PLACEHOLDER = "placeholder",
  PAGEHEADER = "pageheader",
  PAGEDIVIDER = "pagedivider",
  PAGEVIDEO = "pagevideo",
  PAGEQR = "pageqr",
  PAGELOCATION = "pagelocation",
  PAGEPDFVIEWER = "pagepdfviewer",
  PAGESOCIALBAR = "pagesocialbar",
  PAGESLIDERLABEL = "pagesliderlabel",
  SPLASH_01 = "splash_01",
  SPLASH_02 = "splash_02",
  SPLASH_03 = "splash_03",
  SPLASH_04 = "splash_04",
  SPLASH_05 = "splash_05",
  SPLASH_06 = "splash_06",
  SPLASH_07 = "splash_07",
}

export enum MENU_CELLS_TYPE {
  BUTTON = "in",
  INPUT = "inout",
  OUTPUT = "out",
  SUBMIT = "submit-all",
  BAR = "bar",
  CHOICE = "choice",
}

export enum MessageTypes {
  PHOTO = "photo",
  TEXT = "text",
  TEXT_FILE = "text_file",
  VIDEO = "video",
  AUDIO = "audio",
  VOICE = "voice",
  LOCATION = "location",
  STICKER = "sticker",
  CONTACT = "contact",
  GIF = "gif",
  DOCUMENT = "document",
  ARTICLE = "article",
  CALENDAR = "calendar",
}

export const offcanvasColors = {
  activeColor: "#50a5f1",
  switchColor: "#ffffff",
  bgColor: "#585E72",
  bgColorSecondary: "#151B2C",
  borderColor: "#3f4454",
  activeTextColor: "#ffffff",
  offTextColor: "#a6b0cf",
  disabledBgColor: "#f8f8fb",
  disabledTextColor: "#343a40",
};

export enum MarkerTypes {
  Group = "group",
  mStore = "mstore",
  Booking = "booking",
  Event = "event",
  Channel = "channel",
  Vapp = "vapp",
}

export enum RowTypes {
  TEXT = "text",
  PAGEGALLERY = "pagegallery",
  PAGESINGLEIMAGE = "pagesingleimage",
  PAGEFEATURE = "pagefeature",
  PAGESLIDER = "pageslider",
  YOUTUBECHANNEL = "youtubeChannel",
}
export const submitType: string[] = [
  "submit-cell",
  "submit-screen",
  "submit-all",
];

export enum BubbleStatus {
  nandbox_bubble = "nandbox",
  whatsapp_bubble = "whatsapp",
  viber_bubble = "viber",
  darktheme_bubble = "darktheme",
  wechat_bubble = "wechat",
  telegram_bubble = "telegram",
}

export enum PagesSocialBar {
  facebook = "facebook",
  twitter = "twitter",
  google = "google",
  instagram = "instagram",
  linkedin = "linkedin",
  pinterest = "pinterest",
  youtube = "youtube",
  slack = "slack",
  reddit = "reddit",
  whatsapp = "whatsapp",
}

export enum RELEASEAPP_STATUS {
  Pending = "Pending",
  Complete = "Completed",
  Download = "Download",
  Successful = "Successful",
  Uploading = "Uploading",
  Uploaded = "Uploaded",
  Publishing = "Publishing",
  Published = "Published",
  Failed = "Failed",
  CreatingApp = "Creating App",
  Created = "App Created",
  Building = "Building",
}

export enum AppStoreStatus {
  PENDING = "pending_invitation",
  VERIFYING = "verifying",
  SETTING = "setting",
  RENEWING = "Renewing ",
  CERTIFICATE = "Certificate ",
  FINISHED = "finished",
  FAILED = "failed",
}

export enum c1 {
  ID = "c1",
  ANDROID = "a",
  IOS = "i",
}

export enum c2 {
  ID = "c2",
  FULL = "f",
  HOME = "h",
  SIDE = "s",
  COMPONENT = "c",
}

export enum c3 {
  ID = "c3",
  SINGLE = "s",
  NOTAB = "n",
  MANYTAB = "m",
}

export enum c4 {
  ID = "c4",
  TOP = "t",
  BOTTOM = "b",
}
export enum c5 {
  ID = "c5",
  WIDE = "w",
  STANDARD = "s",
}

export enum c6 {
  ID = "c6",
  TOP = "t",
  BOTTOM = "b",
}

export enum c7 {
  ID = "c7",
  LARGE = "l",
  STANDARD = "s",
}

export enum c8 {
  ID = "c8",
  SIDE = "t",
  NOSIDE = "f",
}

export enum c9 {
  ID = "c9",
  SIDE = "t",
  NOSIDE = "f",
}

export enum c10 {
  ID = "c10",
  WIDE = "w",
  STANDARD = "s",
}

export enum c11{
  ID = "c11",
  M2 = "2",
  M3 = "3",
}

export enum c12 {
  ID = "c12",
  ALL = "a",
  HOME = "h",
  SIDE = "s"
}

export enum c13 {
  ID = "c13",
  M2 = "2",
  M3 = "3",
}

export enum c14 {
  ID = "c14",
  PRIMARY = "p",
  SECONDARY = "s",
  TERTIARY = "t",
}

export enum c15 {
  ID = "c15",
  LOWEST = "0",
  LOW = "1",
  HIGH = "2",
}

export enum c16 {
  ID = "c16",
  LIGHT = "l",
  DARK = "d",
}

export enum c17 {
  ID = "c17",
  PRIMARY = "p",
  SECONDARY = "s",
  TERTIARY = "t",
}

export enum c18 {
  ID = "c18",
  SIDEVIEW = "s",
  TABVIEW = "t",
}
export enum MobileView {
  a_full = "a_full",
  a_main_tab = "a_main_tab",
  a_main_notab = "a_main_notab",
  a_main_many_top_wide = "a_main_many_top_wide",
  a_main_many_bottom_wide = "a_main_many_bottom_wide",
  a_main_many_top_std = "a_main_many_top_std",
  a_main_many_bottom_std = "a_main_many_bottom_std",
  a_component = "a_component",
  i_full = "i_full",
  i_main_tab = "i_main_tab",
  i_main_notab = "a_main_notab",
  i_main_noside = "i_main_noside",
  i_main_top_large = "i_main_top_large",
  i_main_top_small = "i_main_top_small",
  i_main_bottom_large = "i_main_bottom_large",
  i_main_bottom_small = "i_main_bottom_small",
  i_side = "i_side",
  i_component = "i_component",
}

export enum SPLASH_STYLE {
  STYLE_01 = "01",
  STYLE_02 = "02",
  STYLE_03 = "03",
  STYLE_04 = "04",
  STYLE_05 = "05",
  STYLE_06 = "06",
  STYLE_07 = "07",
}

//////////////

export const errorMap = {
  12000: {
    title: "Failed",
    html: "Profile Not Saved",
    icon: "error",
  },
  12003: {
    title: "Failed",
    html: "Domain Not Saved",
    icon: "error",
  },
  12009: {
    title: "Failed",
    html: "Order Not Created",
    icon: "error",
  },
  12010: {
    title: "Failed",
    html: "Order Not Approved",
    icon: "error",
  },
  12011: {
    title: "Failed",
    html: "Order Not Approved",
    icon: "error",
  },
  12026: {
    title: "Failed",
    html: "Domain Already Exists",
    icon: "error",
  },
  12027: {
    title: "Failed",
    html: "Domain Edit Not Saved",
    icon: "error",
  },
  100: {
    title: "Failed",
    html: "Media upload failed - the file might be corrupted",
    icon: "error",
  },
  101: {
    title: "Failed",
    html: "The file you are trying to upload is Empty",
    icon: "error",
  },
  102: {
    title: "Failed",
    html: "The file you are trying to upload is not Supported",
    icon: "error",
  },
  100012: {
    title: "Failed",
    html: "Cannot delete while having more than one admin",
    icon: "error",
  },
};

export const plans_map = [
  {
    period: "Week",
    period_server: "WEEK",
    period_number: 1,
  },
  {
    period: "Month",
    period_server: "MONTH",
    period_number: 1,
  },
  {
    period: "Month",
    period_server: "MONTH",
    period_number: 3,
  },
  {
    period: "Month",
    period_server: "MONTH",
    period_number: 6,
  },
  {
    period: "Year",
    period_server: "YEAR",
    period_number: 1,
  },
];

export const user_status_map = {
  A: "GENERALS.ACTIVE",
  N: "GENERALS.NOTACTIVE",
  B: "GENERALS.BLOCKED",
};

export const user_status_color_map = {
  A: "success-badge",
  N: "danger-badge",
  B: "danger-badge"
};

export const payment_status_color_map = {
  PENDING: 'warning-badge',
  POSTED: 'success-badge',
  DECLINED:'danger-badge',
  REFUNDED: 'primary-container-badge',
  CANCELLED: 'danger-badge',
};

export const bot_status_color_map = {
  0: "danger-badge",
  1: "success-badge",
};

export const bot_status_map = {
  0: "GENERALS.PRIVATE",
  1: "GENERALS.PUBLIC",
};

// export const bot_status_map = {
//   A: "GENERALS.ACTIVE",
//   N: "GENERALS.NOTACTIVE",
//   B: "GENERALS.BLOCKED",
// };

export const published_status_map = {
  0: "Offline",
  1: "Online",
};

export const payment_enabled_map = {
  0: "Free",
  1: "Paid",
};

export const payment_enabled_color_map = {
  0: "success-badge",
  1: "warning-badge"
};

export const service_profile_map = [
  { name: "Subscription", id: 2, key: "SUBSCRIPTION" },
  { name: "Booking", id: 3, key: "BOOKING" },
  { name: "Inventory", id: 4, key: "INVENTORY" },
  { name: "Tag", id: 7, key: "TAG" },
  { name: "Negativeinventory", id: 9, key: "NEGATIVEINVENTORY" },
  { name: "Bot_Subscription", id: 14, key: "BOT_SUBSCRIPTION" },
];

export const user_search_placeholder_map = {
  name: "GENERALS.NAMEPLACEHOLDER",
  member_id: "USERS.USERIDPLACEHOLDER",
  msisdn: "USERS.LOGINIDPLACEHOLDER",
  channel: "CHANNELS.CHANNELIDPLACEHOLDER",
  group: "GROUPS.GROUPIDPLACEHOLDER",
  booking: "BOOKINGS.BOOKINGIDPLACEHOLDER",
  calendar: "CALENDARS.CALENDARIDPLACEHOLDER",
  event: "EVENTS.EVENTIDPLACEHOLDER",
  coupon_id: "COUPONS.COUPONIDPLACEHOLDER",
  campaign_id: "CAMPAIGNS.CAMPAIGNIDPLACEHOLDER",
  menu_id: "POLLS.POLLIDPLACEHOLDER",
  product: "PRODUCTS.PRODUCTIDPLACEHOLDER",
  bot_id: "APIS.BOTIDPLACEHOLDER",
  collection: "COLLECTIONS.COLLECTIONIDPLACEHOLDER",
  store: "STORES.STOREIDPLACEHOLDER",
  market: "Enter Marketplace ID",
  category: "Enter Category ID",
  oid: "Enter Order ID",
  receiverId: "Enter User ID",
};

export const user_role_map = {
  "0": "USERS.MEMBER",
  "1": "USERS.OWNER",
  "2": "USERS.SUPERADMIN",
  "3": "USERS.MODERATOR",
  "4": "USERS.ADVERTISER",
  "5": "USERS.ANALYST",
  "6": "USERS.EDITOR",
  "7": "USERS.ADMIN",
};

export const user_roles_map = [
  { id: 0, name: "Member" },
  { id: 2, name: "Super Admin" },
  { id: 7, name: "Admin" },
  { id: 3, name: "Moderator" },
  { id: 4, name: "Advertiser" },
  { id: 5, name: "Analyst" },
  { id: 6, name: "Editor" },
];

export const user_role_color_map = {
  "0": "secondary-container-badge",
  "1": "success-badge",
  "2": "primary-container-badge",
  "3": "primary-container-badge",
  "4": "primary-container-badge",
  "5": "primary-container-badge",
  "6": "primary-container-badge",
  "7": "primary-container-badge",
};

export const tags_color_map = {
  "0": "success-badge",
  "1": "secondary-container-badge",
};

export const tags_map = {
  "0": "GENERALS.PUBLIC",
  "1": "GENERALS.PRIVATE",
};

export const store_color_map = {
  "0": "secondary-container-badge",
  "1": "success-badge",
};
export const store_role_map = {
  "0": "GENERALS.NO",
  "1": "GENERALS.YES",
};

export const month_names_map = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

export const chat_categories_map = [
  {
    key: "S",
    value: "CHANNELS.SOCIAL",
  },
  {
    key: "E",
    value: "CHANNELS.ENTERTAINMENT",
  },
  {
    key: "H",
    value: "CHANNELS.HEALTHANDFITNESS",
  },
  {
    key: "L",
    value: "CHANNELS.LIFESTYLE",
  },
  {
    key: "M",
    value: "CHANNELS.MEDIA",
  },
  {
    key: "N",
    value: "CHANNELS.NEWSANDMAGAZINES",
  },
  {
    key: "T",
    value: "CHANNELS.SPORTS",
  },
  {
    key: "D",
    value: "CHANNELS.EDUCATION",
  },
  {
    key: "C",
    value: "CHANNELS.CORPORATE",
  },
  {
    key: "G",
    value: "CHANNELS.GOVERNMENT",
  },
  {
    key: "P",
    value: "CHANNELS.PROFESSIONAL",
  },
  {
    key: "O",
    value: "CHANNELS.STORESANDRETAIL",
  },
  {
    key: "R",
    value: "CHANNELS.RELIGION",
  },
];

export const product_types = [
  {
    key: 'product',
    value: "Product",
  },
  {
    key: "digital",
    value: "Digital",
  },
  {
    key: "event",
    value: "Event",
  },
  {
    key: "booking",
    value: "Booking",
  },
];

export const message_media_status_map = {
  INIT: "INIT",
  DOWNLOADING: "DOWNLOADING",
  DOWNLOAD_FAILED: "DOWNLOAD_FAILED",
  UPLOADING: "UPLOADING",
  UPLOAD_FAILED: "UPLOAD_FAILED",
  DOWNLOADED: "DOWNLOADED",
  UPLOADED: "UPLOADED",
};

export const message_status_map = {
  DELETED: "deleted",
  UPDATED: "updated",
};

export const device_os_map = {
  ANDROID: "mdi mdi-android text-success",
  IOS: "mdi mdi-apple",
  0: "mdi mdi-android text-success",
  1: "mdi mdi-apple",
};

export const coupon_share_icon_map = {
  0: "mdi mdi-share-off",
  1: "mdi mdi-share",
};

export const splash_config_map = {
  [SPLASH_STYLE.STYLE_01]: {
    tab_part: {
      style: "01",
      title: "",
      button_title: "ACCEPT AND CONTINUE",

      btn_bg: "tertiary",
      btn_text: "onTertiary",
      tnc_text: "onSurface",
      tnc_link: "hint",
      tnc_bg: "surface",

      btn_bg_ios: "tertiary",
      btn_text_ios: "onTertiary",
      tnc_text_ios: "onSurface",
      tnc_link_ios: "hint",
      tnc_bg_ios: "surface",
    },
    button_part: {
      title: "", //this.button.button_label
      desc: "", //this.button.button_sublabel;
      ovalImage: "",
      images: {
        "01": {
          url: "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_Bac684PM6K_s_xxxhdpi.jpg?temp_url_sig=015541611a83b3cb6efff805b350f718b02f47f1&temp_url_expires=2029425688",
          width: 540,
          hight: 897,
          aspect: "360x598",
          type: null,
          imageSet: {
            hdpi: "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_Bac684PM6K_s_hdpi.jpg?temp_url_sig=38c218e7554e592beab71fa7b2488f57e749efb8&temp_url_expires=2029425680",
            ios1x:
              "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_Bac684PM6K_s_1x.jpg?temp_url_sig=59234fb47b3127fb420078a6ab5f23d2205a61aa&temp_url_expires=2029425690",
            ios2x:
              "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_Bac684PM6K_s_2x.jpg?temp_url_sig=d614c6cdc80b76ce06815ab7e251d4b23389484a&temp_url_expires=2029425693",
            ios3x:
              "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_Bac684PM6K_s_3x.jpg?temp_url_sig=a23c901a689c967870579ae73422569d1372e814&temp_url_expires=2029425696",
            mdpi: "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_Bac684PM6K_s_mdpi.jpg?temp_url_sig=3c2090d18487060a395b700a75e378593c834061&temp_url_expires=2029425682",
            xhdpi:
              "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_Bac684PM6K_s_xhdpi.jpg?temp_url_sig=852a0c4e46da7e504f64fbbc4c08ccdcf9cd13c4&temp_url_expires=2029425684",
            xxhdpi:
              "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_Bac684PM6K_s_xxhdpi.jpg?temp_url_sig=3575b13ff913aa291bf670a294af18ccad5e2270&temp_url_expires=2029425686",
            xxxhdpi:
              "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_Bac684PM6K_s_xxxhdpi.jpg?temp_url_sig=015541611a83b3cb6efff805b350f718b02f47f1&temp_url_expires=2029425688",
          },
        },
      },
      // image:"https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684293000559_6323a65cbe6461e1f8aff1521f8e593638a5565b93fcd239eb0017cab677afd6.jpg?temp_url_sig=d73ac5a2aba2cbf466059477ab838322e33814ac&temp_url_expires=2028055587",        //button.button_img_url
      bg_image: "", // this.button.button_bgimage;
      bgstart: "surfaceContainerLowest", //this.button.button_bgstart;
      bgend: "surfaceContainerLowest", // this.button.button_bgend;
      info: { width: 360, hight: 598, ratio: "360x598", imageType: null },
    },
  },
  [SPLASH_STYLE.STYLE_02]: {
    tab_part: {
      style: "02",
      title: "",
      button_title: "ACCEPT AND CONTINUE",
      btn_bg: "onSurface",
      btn_text: "surface",
      tnc_text: "surface",
      tnc_link: "hint",
      tnc_bg: null,

      btn_bg_ios: "onSurface",
      btn_text_ios: "surface",
      tnc_text_ios: "surface",
      tnc_link_ios: "hint",
      tnc_bg_ios: null,
    },
    button_part: {
      title:
        "<h3><strong>Flowers Store</strong></h3> <p>30 years of market expertise and a full-fledged team.</p>", //this.button.button_label
      desc: "", //this.button.button_sublabel;
      ovalImage: "",
      images: {
        "02": {
          url: "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0ytfClGNUx_s_xxxhdpi.png?temp_url_sig=67d62b05aeea186a84aef6a36b60a17c276dca96&temp_url_expires=2029422073",
          width: 260,
          hight: 260,
          aspect: null,
          type: "image/png,",
          imageSet: {
            hdpi: "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0ytfClGNUx_s_hdpi.png?temp_url_sig=4e14bda3c06e8f9dce9db0e71ce038d14efc1369&temp_url_expires=2029422062",
            ios1x:
              "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0ytfClGNUx_s_1x.png?temp_url_sig=44f44c731fbd7d7179216e137f8609d35e95b6c1&temp_url_expires=2029422079",
            ios2x:
              "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0ytfClGNUx_s_2x.png?temp_url_sig=896b75c4afc29a856ecdb6b1a077c49eb55756a3&temp_url_expires=2029422080",
            ios3x:
              "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0ytfClGNUx_s_3x.png?temp_url_sig=f622588b4a841b764152f5165538fae4765d9246&temp_url_expires=2029422083",
            mdpi: "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0ytfClGNUx_s_mdpi.png?temp_url_sig=ee29be9fee7b770b800a968329b363b2c0eddd65&temp_url_expires=2029422064",
            xhdpi:
              "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0ytfClGNUx_s_xhdpi.png?temp_url_sig=493db2153fee482d0b9757b432744f5396efc1ba&temp_url_expires=2029422066",
            xxhdpi:
              "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0ytfClGNUx_s_xxhdpi.png?temp_url_sig=ea1570d96091bb691950387ba74bb1805ae6d85c&temp_url_expires=2029422067",
            xxxhdpi:
              "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0ytfClGNUx_s_xxxhdpi.png?temp_url_sig=67d62b05aeea186a84aef6a36b60a17c276dca96&temp_url_expires=2029422073",
          },
        },
      },
      bg_image: "", // this.button.button_bgimage;
      bgstart: 'secondaryContainer',  // "#fad961", //this.button.button_bgstart;
      bgend:  'tertiaryContainer'//"#e47a3e", // this.button.button_bgend;
    },
  },
  [SPLASH_STYLE.STYLE_03]: {
    tab_part: {
      style: "03",
      title:
        "<h3><strong>Flowers Store</strong></h3> <p>30 years of market expertise and a full-fledged team.</p>",
      button_title: "ACCEPT AND CONTINUE",
      btn_bg: "onSurface",
      btn_text: "surface",
      tnc_text: "surfaceContainerLowest",
      tnc_link: "hint",
      tnc_bg: null,

      btn_bg_ios: "onSurface",
      btn_text_ios: "surface",
      tnc_text_ios: "surfaceContainerLowest",
      tnc_link_ios: "hint",
      tnc_bg_ios: null,
    },
    button_part: {
      title: "", //this.button.button_label
      desc: "", //this.button.button_sublabel;
      ovalImage: "",
      images: { "03": { url: "", width: 540, hight: 1107, aspect: "360x738" } },
      bg_image:
        "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_EkvKy0qtXz_s_xxxhdpi.jpg?temp_url_sig=0d0cfdb8136554d2c9b711c7a5413865ba1ebfe0&temp_url_expires=2029430455", // this.button.button_bgimage;
      bg_imageSet: {
        hdpi: "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_EkvKy0qtXz_s_hdpi.jpg?temp_url_sig=e3a0940c8f9a5f04034dcad3d7aaa7138b549efa&temp_url_expires=2029430445",
        ios1x:
          "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_EkvKy0qtXz_s_1x.jpg?temp_url_sig=70c0fb410bd5add69d256298ce3b8d8d3d443ec5&temp_url_expires=2029430457",
        ios2x:
          "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_EkvKy0qtXz_s_2x.jpg?temp_url_sig=aee737323f64ad9975871ccc58f4491ce027869d&temp_url_expires=2029430460",
        ios3x:
          "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_EkvKy0qtXz_s_3x.jpg?temp_url_sig=9ad573b3368f69bb5c4406abf3ae9d4ca26c7586&temp_url_expires=2029430465",
        mdpi: "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_EkvKy0qtXz_s_mdpi.jpg?temp_url_sig=f1c0d5a582713da9a932615f39b209b3e4231a07&temp_url_expires=2029430447",
        xhdpi:
          "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_EkvKy0qtXz_s_xhdpi.jpg?temp_url_sig=f18a66138829a6747ef6e7384f330941f018aed1&temp_url_expires=2029430450",
        xxhdpi:
          "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_EkvKy0qtXz_s_xxhdpi.jpg?temp_url_sig=c73b97fc0be16e139ea03a6c6d9c9d376bd3c514&temp_url_expires=2029430452",
        xxxhdpi:
          "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_EkvKy0qtXz_s_xxxhdpi.jpg?temp_url_sig=0d0cfdb8136554d2c9b711c7a5413865ba1ebfe0&temp_url_expires=2029430455",
      },
      bgstart: null,
      bgend:  null
    },
  },
  [SPLASH_STYLE.STYLE_04]: {
    tab_part: {
      style: "04",
      title: null,
      button_title: "ACCEPT AND CONTINUE",
      btn_bg: "secondary",
      btn_text: "onSecondary",
      tnc_text: "onSurface",
      tnc_link: "hint",
      tnc_bg: "surfaceContainerLowest",

      btn_bg_ios: "secondary",
      btn_text_ios: "onSecondary",
      tnc_text_ios: "onSurface",
      tnc_link_ios: "hint",
      tnc_bg_ios: "surfaceContainerLowest",
    },
    button_part: {
      title:
        "<h4><strong>Free Messaging</strong></h4> <p>Chats with seamless media sharing options.</p>", //this.button.button_label
      desc: "", //this.button.button_sublabel;
      ovalImage: "./assets/images/small/oval260.png",
      images: {
        "04": {
          url: "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0FbcTPAptb_s_xxxhdpi.png?temp_url_sig=11ab0a15e94c652b6b714a159d4a77910645a80f&temp_url_expires=2029422498",
          width: 260,
          hight: 260,
          aspect: null,
          type: "image/png,",
          imageSet: {
            hdpi: "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0FbcTPAptb_s_hdpi.png?temp_url_sig=2351bc984524b86cfff487b2f6736a569bdf92f9&temp_url_expires=2029422489",
            ios1x:
              "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0FbcTPAptb_s_1x.png?temp_url_sig=7b77e200bb6e54126ad068276a53e73d518f5edb&temp_url_expires=2029422500",
            ios2x:
              "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0FbcTPAptb_s_2x.png?temp_url_sig=cef5d78dffdef9bdc2ebbb70d126655aa3aede57&temp_url_expires=2029422501",
            ios3x:
              "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0FbcTPAptb_s_3x.png?temp_url_sig=500fb231f1d6cedf6df0e37739aa59f6a78df96b&temp_url_expires=2029422504",
            mdpi: "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0FbcTPAptb_s_mdpi.png?temp_url_sig=6cdbeaed82abb0c87ad590421bd21b6b7f5e1cc0&temp_url_expires=2029422492",
            xhdpi:
              "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0FbcTPAptb_s_xhdpi.png?temp_url_sig=a7e1b5778de40c498ab11f8274111c5f13f8ec8e&temp_url_expires=2029422493",
            xxhdpi:
              "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0FbcTPAptb_s_xxhdpi.png?temp_url_sig=c091a289be8e5c94cb8a271f469adc0a5bde48a3&temp_url_expires=2029422497",
            xxxhdpi:
              "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0FbcTPAptb_s_xxxhdpi.png?temp_url_sig=11ab0a15e94c652b6b714a159d4a77910645a80f&temp_url_expires=2029422498",
          },
        },
      },
      // image:"./assets/images/small/imageSplash4.png",
      // 100x 202 aspect
      bg_image: "", // this.button.button_bgimage;
      bgstart: 'secondary',  // "#fad961", //this.button.button_bgstart;
      bgend:  'secondary'//"#e47a3e", // this.button.button_bgend;

    },
  },

  [SPLASH_STYLE.STYLE_05]: {
    tab_part: {
      style: "05",
      title: null,
      button_title: "ACCEPT AND CONTINUE",

      btn_bg: "primary",
      btn_text: "onPrimary",
      tnc_text: "onSurface",
      tnc_link: "hint",
      tnc_bg: "surfaceContainerLowest",

      btn_bg_ios: "primary",
      btn_text_ios: "onPrimary",
      tnc_text_ios: "onSurface",
      tnc_link_ios: "hint",
      tnc_bg_ios: "surfaceContainerLowest",
    },
    button_part: {
      title:
        "<h4><strong>Free Messaging</strong></h4> <p>Chats with seamless media sharing options.</p>",
      desc: "", //this.button.button_sublabel;
      ovalImage: "./assets/images/small/oval260.png",
      images: {
        "05": {
          url: "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_1nuyLYRclD_s_xxxhdpi.png?temp_url_sig=412969a90811990b3a4088614db1c6c33f754549&temp_url_expires=2029422714",
          width: 260,
          hight: 260,
          aspect: null,
          type: "image/png,",
          imageSet: {
            hdpi: "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_1nuyLYRclD_s_hdpi.png?temp_url_sig=a6e2a8c50d83181753bce18eb7a297651421192e&temp_url_expires=2029422709",
            ios1x:
              "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_1nuyLYRclD_s_1x.png?temp_url_sig=1fcb01c466ad3648d982fb37f66909f2d9c57d82&temp_url_expires=2029422716",
            ios2x:
              "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_1nuyLYRclD_s_2x.png?temp_url_sig=deab9c10f1bc09780e20c7816f3939bf5173cb4c&temp_url_expires=2029422717",
            ios3x:
              "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_1nuyLYRclD_s_3x.png?temp_url_sig=3a71eee768113e645023d0a875a039a39cfb51f3&temp_url_expires=2029422719",
            mdpi: "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_1nuyLYRclD_s_mdpi.png?temp_url_sig=d937e3636c76d61052c76a6d781d08db2d442e4e&temp_url_expires=2029422710",
            xhdpi:
              "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_1nuyLYRclD_s_xhdpi.png?temp_url_sig=0858505b0fcf60f346fe6f2a8e87b3051a1f0057&temp_url_expires=2029422711",
            xxhdpi:
              "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_1nuyLYRclD_s_xxhdpi.png?temp_url_sig=3acaa76cd1b303763e60eb951b7d38157e8a73fa&temp_url_expires=2029422712",
            xxxhdpi:
              "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_1nuyLYRclD_s_xxxhdpi.png?temp_url_sig=412969a90811990b3a4088614db1c6c33f754549&temp_url_expires=2029422714",
          },
        },
      },
      // image:"./assets/images/small/imageSplash5.png",
      bg_image: "",

      bgstart: 'primary',  // "#fad961", //this.button.button_bgstart;
      bgend:  'primary'//"#e47a3e", // this.button.button_bgend;
    },
  },

  [SPLASH_STYLE.STYLE_06]: {
    tab_part: {
      style: "06",
      title: null,
      button_title: "ACCEPT AND CONTINUE",
      btn_bg: "secondary",
      btn_text: "onSecondary",
      tnc_text: "onSurface",
      tnc_link: "hint",
      tnc_bg: null,

      btn_bg_ios: "secondary",
      btn_text_ios: "onSecondary",
      tnc_text_ios: "onSurface",
      tnc_link_ios: "hint",
      tnc_bg_ios:null,

    },
    button_part: {
      title:
        "<h4><strong>Interactive Channels</strong></h4> <p>Enjoy unlimited Channels</p>",
      desc: "", //this.button.button_sublabel;
      ovalImage: "./assets/images/small/ovals200.png",
      images: {
        "06": {
          url: "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_97WsUq1pdC_s_xxxhdpi.png?temp_url_sig=85a2393c57aa14d006f05fcd8daa91af2d8a07de&temp_url_expires=2029423833",
          width: 260,
          hight: 260,
          aspect: null,

          type: "image/png,",
          imageSet: {
            hdpi: "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_97WsUq1pdC_s_hdpi.png?temp_url_sig=70af0e76b1f7b501d22de7a8f905c1b40bce6371&temp_url_expires=2029423824",
            ios1x:
              "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_97WsUq1pdC_s_1x.png?temp_url_sig=5a9eb3c6fac1956f5b968af738c6e87c704ddaa8&temp_url_expires=2029423834",
            ios2x:
              "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_97WsUq1pdC_s_2x.png?temp_url_sig=88fd7c326d0ea46eca4d63bae8a0f88169b312ca&temp_url_expires=2029423836",
            ios3x:
              "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_97WsUq1pdC_s_3x.png?temp_url_sig=c3dda49f2092d6da5a05c3f817f16adb61a5c9fc&temp_url_expires=2029423837",
            mdpi: "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_97WsUq1pdC_s_mdpi.png?temp_url_sig=e60611011847fbf8bb95d4390f13c6f3bb9eb28c&temp_url_expires=2029423826",
            xhdpi:
              "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_97WsUq1pdC_s_xhdpi.png?temp_url_sig=d92cd07d7b4a23b687d6f4b2f42412894581b651&temp_url_expires=2029423829",
            xxhdpi:
              "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_97WsUq1pdC_s_xxhdpi.png?temp_url_sig=24d08bc8033daaba80ff42db30623e858ef7cf81&temp_url_expires=2029423831",
            xxxhdpi:
              "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_97WsUq1pdC_s_xxxhdpi.png?temp_url_sig=85a2393c57aa14d006f05fcd8daa91af2d8a07de&temp_url_expires=2029423833",
          },
        },
      },
      // image:"./assets/images/small/imageSplash6.png",
      // 100x 202 aspect
      bg_image: "", // this.button.button_bgimage;
      bgstart: 'surface',  // "#fad961", //this.button.button_bgstart;
      bgend: 'surface'//"#e47a3e", // this.button.button_bgend;
    },
  },
  [SPLASH_STYLE.STYLE_07]: {
    tab_part: {
      style: "07",
      title: "",
      button_title: "ACCEPT AND CONTINUE",
      btn_bg: "tertiary",
      btn_text: "onTertiary",
      tnc_text: "onSurface",
      tnc_link: "hint",
      tnc_bg: null,

      btn_bg_ios: "tertiary",
      btn_text_ios: "onTertiary",
      tnc_text_ios: "onSurface",
      tnc_link_ios: "hint",
      tnc_bg_ios: null,
    },
    button_part: {
      title:
        "<h4><strong>Bigger Groups</strong></h4> <p>Chat with up to 50,000 group memebers.</p>",
      desc: "", //this.button.button_sublabel;
      ovalImage: "./assets/images/small/oval180.png",
      images: {
        "07": {
          url: "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_MYVfAJl6lq_s_xxxhdpi.png?temp_url_sig=f1615d81dc44c10094ee6cc84b8f6ca157c0ec5c&temp_url_expires=2029424078",
          width: 260,
          hight: 260,
          aspect: null,

          type: "image/png,",
          imageSet: {
            hdpi: "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_MYVfAJl6lq_s_hdpi.png?temp_url_sig=b70f25aa2ae025f5703c8e70d3edb1b9cbb4fd21&temp_url_expires=2029424073",
            ios1x:
              "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_MYVfAJl6lq_s_1x.png?temp_url_sig=1142c67ac4f61913526e218599f90b0bddc5374b&temp_url_expires=2029424080",
            ios2x:
              "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_MYVfAJl6lq_s_2x.png?temp_url_sig=f4500e300b7e644e1009b670aefd799088f7112b&temp_url_expires=2029424082",
            ios3x:
              "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_MYVfAJl6lq_s_3x.png?temp_url_sig=caab83be3afd33c3064162b9ad3abab548a5deb7&temp_url_expires=2029424085",
            mdpi: "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_MYVfAJl6lq_s_mdpi.png?temp_url_sig=ee4b1fd3088fb15b591907339979da1ab42a990b&temp_url_expires=2029424074",
            xhdpi:
              "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_MYVfAJl6lq_s_xhdpi.png?temp_url_sig=809ac57010906b7a7276d601bc547decb7a4ab32&temp_url_expires=2029424075",
            xxhdpi:
              "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_MYVfAJl6lq_s_xxhdpi.png?temp_url_sig=f1fc71bf16f958486c0e7aca0a7651a0968868c2&temp_url_expires=2029424077",
            xxxhdpi:
              "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_MYVfAJl6lq_s_xxxhdpi.png?temp_url_sig=f1615d81dc44c10094ee6cc84b8f6ca157c0ec5c&temp_url_expires=2029424078",
          },
        },
      },
      // image:"./assets/images/small/imageSplash7.png",
      // 100x 202 aspect
      bg_image: "",

      bgstart: 'surfaceContainerLowest',  // "#fad961", //this.button.button_bgstart;
      bgend:  'surfaceContainerLowest'//"#e47a3e", // this.button.button_bgend;
    },
  },
};

export const common_button_migration_map = {
  // we need to take the button code from "button_form + style"  Hazem A.

  [MENU_BUTTON_FORM.SEPARATOR]: {
    button_code: MENU_BUTTON_CODE.SEPARATOR_01,
  },

  [MENU_BUTTON_FORM.BUTTON]: {
    button_code: MENU_BUTTON_CODE.CARD_06,
    button_form: MENU_BUTTON_FORM.CARD,
    button_headline: "Headline",
    button_subhead: "Subhead",
  },

  [MENU_BUTTON_FORM.INPUT]: {
    button_code: MENU_BUTTON_CODE.INPUT_01,
  },
  [MENU_BUTTON_FORM.OUTPUT]: {
    button_code: MENU_BUTTON_CODE.OUTPUT_01,
  },

  [MENU_BUTTON_FORM.SUBMIT]: {
    button_code: MENU_BUTTON_CODE.BUTTON_01,
    button_form: MENU_BUTTON_FORM.BUTTON,
  },

  [MENU_BUTTON_FORM.SINGLECHOICE]: {
    button_code: MENU_BUTTON_CODE.SINGLECHOICE_1000,
  },

  [MENU_BUTTON_FORM.MULTICHOICE]: {
    button_code: MENU_BUTTON_CODE.MULTICHOICE_1000,
  },

  [MENU_BUTTON_FORM.DROPDOWN]: {
    button_code: MENU_BUTTON_CODE.DROPDOWN_01,
  },

  [MENU_BUTTON_FORM.TOGGLE]: {
    button_code: MENU_BUTTON_CODE.TOGGLE_1000,
  },

  [MENU_BUTTON_FORM.PRODUCT]: {
    button_code: MENU_BUTTON_CODE.PRODUCT,
  },

  [MENU_BUTTON_FORM.COLLECTION]: {
    button_code: MENU_BUTTON_CODE.COLLECTION_04,
  },

  [MENU_BUTTON_FORM.PAGESLIDER]: {
    button_code: MENU_BUTTON_CODE.PAGESLIDER,
  },
  [MENU_BUTTON_FORM.PAGESLIDERLABEL]: {
    button_code: MENU_BUTTON_CODE.PAGESLIDERLABEL,
  },
  [MENU_BUTTON_FORM.PAGEGALLERY]: {
    button_code: MENU_BUTTON_CODE.PAGEGALLERY,
  },

  [MENU_BUTTON_FORM.PAGEFEATURE]: {
    button_code: MENU_BUTTON_CODE.PAGEFEATURE,
  },
  [MENU_BUTTON_FORM.PLACEHOLDER]: {
    button_code: MENU_BUTTON_CODE.PLACEHOLDER,
  },
};

export const   collection_composed_images = {
  ["collection-7-8"]:  {image:  "./assets/images/temp/collection-7-8.png",   },
  ["collection-9-10"]: {image:  "./assets/images/temp/collection-9-10.png",  },
  ["collection-11-12"]:{image:  "./assets/images/temp/collection-11-12.png", },
  ["collection-13-16"]:{image:  "./assets/images/temp/collection-13-16.png", },
}
export const   menu_button_map = {
  [MENU_BUTTON_CODE.SEPARATOR_01]: {
    button_code: "bar_01",
    button_form: "bar",
    button_style: "1004",
    button_style_ios: "1004",
    button_type: "bar",
    button_headline: "Headline",
    button_subhead: "Subhead",
    button_divider: 1,
    button_text_fontsize: {headline:"md", subhead:"md"},
    button_text_fontsize_ios: {headline:"md", subhead:"md"},
    button_text_align:{headline:"left", subhead:"left"},
    button_text_align_ios:{headline:"left", subhead:"left"},
    info: {onClick: false, min_grid: 30, image: "./assets/images/workflow/headline.webp", checkList:{}}
     },
   [MENU_BUTTON_CODE.CARD_03]: {
    button_code: "card_03",
    button_form: "card",
    button_style: "0303",
    button_style_ios: "0303",
    button_type: "card",
    button_headline: "Headline",
    button_subhead: "Subhead",
    button_keyboard: "text",
    button_img_url:
      "https://nandbox.com/wp-content/uploads/2022/12/rectangle-sky.webp",
    button_img_url_ios:
      "https://nandbox.com/wp-content/uploads/2022/12/rectangle-sky.webp",
    button_text_fontsize: {headline:"md", subhead:"md"},
    button_text_fontsize_ios: {headline:"md", subhead:"md"},
    button_text_align:{headline:"center", subhead:"center"},
    button_text_align_ios:{headline:"center", subhead:"center"},

    info: {
      onClick: true,
      image: "https://nandbox.com/wp-content/uploads/2024/10/image-headline-subhead.webp",
      min_grid: 20,
      hight: 120,
      ratio: {
        "1": "456x120",
        "2": "228x120",
        "3": "144x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
      validate:[
        button_check_list[ButtonKeys.IMAGE],
        button_check_list[ButtonKeys.IMAGEDARK],
        button_check_list[ButtonKeys.IMAGEIOS],
       ]
    },
  },

  [MENU_BUTTON_CODE.CARD_04]: {
    button_code: "card_04",
    button_form: "card",
    button_style: "0403",
    button_style_ios: "0403",
    button_type: "card",
    button_headline: "Headline",
    button_subhead: "Subhead",
    button_keyboard: "text",
    button_text_fontsize: {headline:"md", subhead:"md"},
    button_text_fontsize_ios: {headline:"md", subhead:"md"},
    button_text_align:{headline:"center", subhead:"center"},
    button_text_align_ios:{headline:"center", subhead:"center"},
    info: {
      onClick: true,
      image: "https://nandbox.com/wp-content/uploads/2024/10/card-colorbackground-headline-subhead-1.webp",
      min_grid: 20,
      hight: 120,
      ratio: {
        "1": "360x120",
        "2": "180x120",
        "3": "120x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },

  [MENU_BUTTON_CODE.CARD_05]: {
    button_code: "card_05",
    button_form: "card",
    button_style: "0503",
    button_style_ios: "0503",
    button_type: "card",
    button_headline: "Headline",
    button_subhead: "Subhead",
    button_keyboard: "text",
    button_text_fontsize: {headline:"md", subhead:"md"},
    button_text_fontsize_ios: {headline:"md", subhead:"md"},
    button_text_align:{headline:"center", subhead:"center"},
    button_text_align_ios:{headline:"center", subhead:"center"},
    button_bgimage: "https://nandbox.com/wp-content/uploads/2022/12/button-bg.webp",
    button_bgimage_ios: "https://nandbox.com/wp-content/uploads/2022/12/button-bg.webp",
    info: {
      onClick: true,
      image: "https://nandbox.com/wp-content/uploads/2024/10/card-background-headline-subhead.webp",
      min_grid: 20,
      hight: 120,
      ratio: {
        "1": "456x240",
        "2": "240x240",
        "3": "150x240",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },
  [MENU_BUTTON_CODE.CARD_06]: {
    button_code: "card_06",
    button_form: "card",
    button_style: "0603",
    button_name:"CARD_06",
    button_style_ios: "0603",
    button_type: "card",
    button_headline: "Headline",
    button_subhead: "Subhead",
    button_img_url: "https://nandbox.com/wp-content/uploads/2022/12/yoga.webp",
    button_img_url_ios: "https://nandbox.com/wp-content/uploads/2022/12/yoga.webp",
    button_keyboard: "text",
    button_text_fontsize: {headline:"md", subhead:"md"},
    button_text_fontsize_ios: {headline:"md", subhead:"md"},
    button_text_align:{headline:"center", subhead:"center"},
    button_text_align_ios:{headline:"center", subhead:"center"},
    // button_newpage: false,
    info: {
      onClick: true,
      image: "https://nandbox.com/wp-content/uploads/2024/10/card-middle-image-subhead.webp",
      min_grid: 20, // 60/20= 3cols
      hight: 40,
      ratio: {
        "1": "240x240",
        "2": "180x180",
        "3": "120x120",
        "4": "90x90",
        "5": "72x72",
        "6": "60x60",
      },
    },
  },
  [MENU_BUTTON_CODE.CARD_07]: {
    button_code: "card_07",
    button_form: "card",
    button_style: "0703",
    button_style_ios: "0703",
    button_type: "card",
    button_headline: "Headline",
    button_subhead: "Subhead",
    button_keyboard: "text",
    button_img_url: "https://nandbox.com/wp-content/uploads/2022/12/yoga-2.webp",
    button_img_url_ios:  "https://nandbox.com/wp-content/uploads/2022/12/yoga-2.webp",
    button_text_fontsize: {headline:"md", subhead:"md"},
    button_text_fontsize_ios: {headline:"md", subhead:"md"},
    button_text_align:{headline:"left", subhead:"left"},
    button_text_align_ios:{headline:"left", subhead:"left"},
     // button_newpage: false,
     info: {
      onClick: true,
      image: "https://nandbox.com/wp-content/uploads/2024/10/small-card-headline-subhead.webp",
      min_grid: 60, // 60/60= 1col
      hight: 100,
      ratio: {
        "1": "240x240",
        "2": "180x100",
        "3": "120x100",
        "4": "90x100",
        "5": "72x100",
        "6": "60x100",
      },
    },
  },

  [MENU_BUTTON_CODE.CARD_08]: {
    button_code: "card_08",
    button_form: "card",
    button_style: "0803",
    button_style_ios: "0803",
    button_type: "card",
    button_headline: "Headline",
    button_subhead: "Subhead",
    button_keyboard: "text",
    button_img_url:       "https://nandbox.com/wp-content/uploads/2022/12/fitness.webp",
    button_img_url_ios:     "https://nandbox.com/wp-content/uploads/2022/12/fitness.webp",
      button_text_fontsize: {headline:"md", subhead:"md"},
      button_text_fontsize_ios: {headline:"md", subhead:"md"},
      button_text_align:{headline:"left", subhead:"left"},
      button_text_align_ios:{headline:"left", subhead:"left"},

    // button_newpage: false,
    info: {
      onClick: true,
      image: "https://nandbox.com/wp-content/uploads/2024/10/card-image-headline-subhead.webp",
      min_grid: 60, // 60/60= 1col
      hight: 477,
      ratio: {
        "1": "240x240",
        "2": "180x80",
        "3": "120x80",
        "4": "90x80",
        "5": "72x80",
        "6": "60x80",
      },
    },
  },

  [MENU_BUTTON_CODE.COLLECTION_01]: {
    button_code: "collection_01",
    button_form: "collection",
    button_style: "0103",
    button_style_ios: "0103",
    button_type: "collection",
    button_headline: "Headline",
    button_text_fontsize: {headline:"md"},
    button_text_fontsize_ios: {headline:"md"},
    button_text_align:{headline:"center"},
    button_text_align_ios:{headline:"center",},
    button_img_url:    "https://nandbox.com/wp-content/uploads/2024/10/collection-1.png",
    button_img_url_ios:"https://nandbox.com/wp-content/uploads/2024/10/collection-1.png",
    info: {
      onClick: true,
      image:  "https://nandbox.com/wp-content/uploads/2024/10/collection-1.png",
      min_grid: 30,
      hight: 120,
      ratio: {
        "1": "360x120",
        "2": "180x120",
        "3": "120x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },

  [MENU_BUTTON_CODE.COLLECTION_02]: {
    button_code: "collection_02",
    button_form: "collection",
    button_style: "0203",
    button_style_ios: "0203",
    button_type: "collection",
    button_headline: "Headline",
    button_text_fontsize: {headline:"sm"},
    button_text_fontsize_ios: {headline:"sm"},
    button_text_align:{headline:"center"},
    button_text_align_ios:{headline:"center",},
    button_img_url:   "https://nandbox.com/wp-content/uploads/2024/10/collection-9.png",
    button_img_url_ios:    "https://nandbox.com/wp-content/uploads/2024/10/collection-9.png",
    info: {
      onClick: true,
      image:  "https://nandbox.com/wp-content/uploads/2024/10/collection-9.png",
      min_grid: 15,
      hight: 120,
      ratio: {
        "1": "360x120",
        "2": "180x120",
        "3": "120x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },

  [MENU_BUTTON_CODE.COLLECTION_04]: {
    button_code: "collection_04",
    button_form: "collection",
    button_style: "0403",
    button_style_ios: "0403",
    button_type: "collection",
    button_img_url:"https://nandbox.com/wp-content/uploads/2024/10/collection-7.png",
    button_img_url_ios: "https://nandbox.com/wp-content/uploads/2024/10/collection-7.png",
    info: {
      onClick: true,
      image:  "https://nandbox.com/wp-content/uploads/2024/10/collection-7.png",
      min_grid: 60,
      hight: 120,
      ratio: {
        "1": "528x240",
        "2": "262x240",
        "3": "176x240",
        "4": "132x240",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },
  [MENU_BUTTON_CODE.COLLECTION_05]: {
    button_code: "collection_05",
    button_form: "collection",
    button_style: "0503",
    button_style_ios: "0503",
    button_type: "collection",
    button_headline: "Headline",
    button_text_fontsize: {headline:"md"},
    button_text_fontsize_ios: {headline:"md"},
    button_text_align:{headline:"left"},
    button_text_align_ios:{headline:"left",},
    button_img_url: "https://nandbox.com/wp-content/uploads/2024/10/collection-8.png",
    button_img_url_ios:"https://nandbox.com/wp-content/uploads/2024/10/collection-8.png",
    info: {
      onClick: true,
      image:  "https://nandbox.com/wp-content/uploads/2024/10/collection-8.png",
      min_grid: 60,
      hight: 120,
      ratio: {
        "1": "528x240",
        "2": "262x240",
        "3": "176x240",
        "4": "132x240",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },
  [MENU_BUTTON_CODE.COLLECTION_07]: {
    // same as style 0503
    button_code: "collection_07",
    button_form: "collection",
    button_style: "0103",
    button_style_ios: "0103",
    button_type: "collection",
    button_headline: "Headline",
    button_text_fontsize: {headline:"md"},
    button_text_fontsize_ios: {headline:"md"},
    button_text_align:{headline:"center"},
    button_text_align_ios:{headline:"center",},
    button_img_url:
      "https://nandbox.com/wp-content/uploads/2024/10/collection-1.png",
    button_img_url_ios:
      "https://nandbox.com/wp-content/uploads/2024/10/collection-1.png",
    info: {
      onClick: true,
      image:  "https://nandbox.com/wp-content/uploads/2024/10/collection-1.png",
      min_grid: 20,
      hight: 120,
      ratio: {
        "1": "576x240",
        "2": "288x240",
        "3": "192x240",
        "4": "144x240",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },
  [MENU_BUTTON_CODE.COLLECTION_08]: {
    // same as style 0503
    button_code: "collection_08",
    button_form: "collection",
    button_style: "0103",
    button_style_ios: "0103",
    button_type: "collection",
    button_headline: "Headline",
    button_text_fontsize: {headline:"md"},
    button_text_fontsize_ios: {headline:"md"},
    button_text_align:{headline:"center"},
    button_text_align_ios:{headline:"center",},
    button_img_url:
      "https://nandbox.com/wp-content/uploads/2024/10/collection-2.png",
    button_img_url_ios:
      "https://nandbox.com/wp-content/uploads/2024/10/collection-2.png",
    info: {
      onClick: true,
      image: "https://nandbox.com/wp-content/uploads/2024/10/collection-2.png",
      min_grid: 20,
      hight: 120,
      ratio: {
        "1": "576x240",
        "2": "288x240",
        "3": "192x240",
        "4": "144x240",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },
  [MENU_BUTTON_CODE.COLLECTION_09]: {
    button_code: "collection_09",
    button_form: "collection",
    button_style: "0403",
    button_style_ios: "0403",
    button_type: "collection",
    button_img_url:
      "https://nandbox.com/wp-content/uploads/2024/10/collection-3.png",
    button_img_url_ios:
      "https://nandbox.com/wp-content/uploads/2024/10/collection-3.png",
    info: {
      onClick: true,
      image:  "https://nandbox.com/wp-content/uploads/2024/10/collection-3.png",
      min_grid: 60,
      hight: 120,
      ratio: {
        "1": "576x240",
        "2": "288x240",
        "3": "192x240",
        "4": "144x240",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },
  [MENU_BUTTON_CODE.COLLECTION_10]: {
    button_code: "collection_10",
    button_form: "collection",
    button_style: "0403",
    button_style_ios: "0403",
    button_type: "collection",
    button_img_url:
      "https://nandbox.com/wp-content/uploads/2024/10/collection-4.png",
    button_img_url_ios:
      "https://nandbox.com/wp-content/uploads/2024/10/collection-4.png",
    info: {
      onClick: true,
      image:  "https://nandbox.com/wp-content/uploads/2024/10/collection-4.png",
      min_grid: 60,
      hight: 120,
      ratio: {
        "1": "576x240",
        "2": "288x240",
        "3": "192x240",
        "4": "144x240",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },
  [MENU_BUTTON_CODE.COLLECTION_11]: {
    // same as style 0503
    button_code: "collection_11",
    button_form: "collection",
    button_style: "0503",
    button_style_ios: "0503",
    button_type: "collection",
    button_headline: "Headline",
    button_text_fontsize: {headline:"md"},
    button_text_fontsize_ios: {headline:"md"},
    button_text_align:{headline:"center"},
    button_text_align_ios:{headline:"center",},
    button_img_url:
      "https://nandbox.com/wp-content/uploads/2024/10/collection-5.png",
    button_img_url_ios:
      "https://nandbox.com/wp-content/uploads/2024/10/collection-5.png",
    info: {
      onClick: true,
      image:  "https://nandbox.com/wp-content/uploads/2024/10/collection-5.png",
      min_grid: 30,
      hight: 120,
      ratio: {
        "1": "576x240",
        "2": "288x240",
        "3": "192x240",
        "4": "144x240",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },
  [MENU_BUTTON_CODE.COLLECTION_12]: {
    // same as style 0503
    button_code: "collection_12",
    button_form: "collection",
    button_style: "0503",
    button_style_ios: "0503",
    button_type: "collection",
    button_headline: "Headline",
    button_text_fontsize: {headline:"md"},
    button_text_fontsize_ios: {headline:"md"},
    button_text_align:{headline:"center"},
    button_text_align_ios:{headline:"center",},
    button_img_url:
      "https://nandbox.com/wp-content/uploads/2024/10/collection-6.png",
    button_img_url_ios:
      "https://nandbox.com/wp-content/uploads/2024/10/collection-6.png",
    info: {
      onClick: true,
      image: "https://nandbox.com/wp-content/uploads/2024/10/collection-6.png",
      min_grid: 30,
      hight: 120,
      ratio: {
        "1": "576x240",
        "2": "288x240",
        "3": "192x240",
        "4": "144x240",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },

  [MENU_BUTTON_CODE.COLLECTION_13]: {
    // same as style 0503
    button_code: "collection_13",
    button_form: "collection",
    button_style: "0204",
    button_style_ios: "0204",
    button_type: "collection",
    button_headline: "Headline",
    button_text_fontsize: {headline:"md"},
    button_text_fontsize_ios: {headline:"md"},
    button_text_align:{headline:"center"},
    button_text_align_ios:{headline:"center",},
    button_img_url:
      "https://nandbox.com/wp-content/uploads/2024/10/collection-9.png",
    button_img_url_ios:
      "https://nandbox.com/wp-content/uploads/2024/10/collection-9.png",
    info: {
      onClick: true,
      image: "https://nandbox.com/wp-content/uploads/2024/10/collection-9.png",
      min_grid: 15,
      hight: 120,
      ratio: {
        "1": "360x360",
        "2": "180x180",
        "3": "120x120",
        "4": "90x90",
        "5": "72x72",
        "6": "60x60",
      },
    },
  },

  [MENU_BUTTON_CODE.COLLECTION_14]: {
    // same as style 0503
    button_code: "collection_14",
    button_form: "collection",
    button_style: "0204",
    button_style_ios: "0204",
    button_type: "collection",
    button_headline: "Headline",
    button_text_fontsize: {headline:"md"},
    button_text_fontsize_ios: {headline:"md"},
    button_text_align:{headline:"center"},
    button_text_align_ios:{headline:"center",},
    button_img_url:
      "https://nandbox.com/wp-content/uploads/2024/10/collection-10.png",
    button_img_url_ios:
      "https://nandbox.com/wp-content/uploads/2024/10/collection-10.png",
    info: {
      onClick: true,
      image: "https://nandbox.com/wp-content/uploads/2024/10/collection-10.png",
      min_grid: 15,
      hight: 120,
      ratio: {
        "1": "360x360",
        "2": "180x180",
        "3": "120x120",
        "4": "90x90",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },


  [MENU_BUTTON_CODE.COLLECTION_15]: {
    // same as style 0503
    button_code: "collection_15",
    button_form: "collection",
    button_style: "0204",
    button_style_ios: "0204",
    button_type: "collection",
    button_headline: "Headline",
    button_text_fontsize: {headline:"md"},
    button_text_fontsize_ios: {headline:"md"},
    button_text_align:{headline:"center"},
    button_text_align_ios:{headline:"center",},
    button_img_url:
      "https://nandbox.com/wp-content/uploads/2024/10/collection-11.png",
    button_img_url_ios:
      "https://nandbox.com/wp-content/uploads/2024/10/collection-11.png",
    info: {
      onClick: true,
      image: "https://nandbox.com/wp-content/uploads/2024/10/collection-11.png",
      min_grid: 15,
      hight: 120,
      ratio: {
        "1": "360x360",
        "2": "180x180",
        "3": "120x120",
        "4": "90x90",
        "5": "72x72",
        "6": "60x60",
      },
    },
  },

  [MENU_BUTTON_CODE.COLLECTION_16]: {
    // same as style 0503
    button_code: "collection_16",
    button_form: "collection",
    button_style: "0204",
    button_style_ios: "0204",
    button_type: "collection",
    button_headline: "Headline",
    button_text_fontsize: {headline:"md"},
    button_text_fontsize_ios: {headline:"md"},
    button_text_align:{headline:"center"},
    button_text_align_ios:{headline:"center",},
    button_img_url:
      "https://nandbox.com/wp-content/uploads/2024/10/collection-12.png",
    button_img_url_ios:
      "https://nandbox.com/wp-content/uploads/2024/10/collection-12.png",
    info: {
      onClick: true,
      image: "https://nandbox.com/wp-content/uploads/2024/10/collection-12.png",
      min_grid: 15,
      hight: 120,
      ratio: {
        "1": "360x360",
        "2": "180x180",
        "3": "120x120",
        "4": "90x90",
        "5": "72x72",
        "6": "60x60",
      },
    },
  },

  [MENU_BUTTON_CODE.INPUT_01]: {
    button_code: "inout_01",
    button_form: "inout",
    button_style: "01",
    button_style_ios: "01",
    button_type: "inout",
    button_keyboard: "text",
    button_label: "Title",
    button_input: "Input Text",
    button_placeholder: null,

    button_newpage: false,
    info: {
      onClick: false,
      image: "https://nandbox.com/wp-content/uploads/2024/10/label-input-text.webp",
      min_grid: 20,
      hight: 40,
      ratio: {
        "1": "360x40",
        "2": "180x40",
        "3": "120x40",
        "4": "90x40",
        "5": "72x40",
        "6": "60x40",
      },
    },
  },

  [MENU_BUTTON_CODE.INPUT_03]: {
    button_code: "inout_03",
    button_form: "inout",
    button_style: "03",
    button_style_ios: "03",
    button_type: "inout",
    button_keyboard: "text",
    button_label: "Title",
    button_input: "Input Text",
    button_placeholder: null,
    button_newpage: false,
    info: {
      onClick: false,
      image: "https://nandbox.com/wp-content/uploads/2024/10/label-input-text-white.webp",
      min_grid: 20,
      hight: 40,
      ratio: {
        "1": "360x40",
        "2": "180x40",
        "3": "120x40",
        "4": "90x40",
        "5": "72x40",
        "6": "60x40",
      },
    },
  },

  [MENU_BUTTON_CODE.INPUT_02]: {
    button_code: "inout_02",
    button_form: "inout",
    button_style: "02",
    button_style_ios: "02",
    button_type: "inout",
    button_keyboard: "text",
    button_label: "Title",
    button_input: "Input Text",
    button_placeholder: null,
    button_newpage: false,
    info: {
      onClick: false,
      image: "https://nandbox.com/wp-content/uploads/2024/10/input-text.webp",
      min_grid: 20,
      hight: 40,
      ratio: {
        "1": "360x40",
        "2": "180x40",
        "3": "120x40",
        "4": "90x40",
        "5": "72x40",
        "6": "60x40",
      },
    },
  },
  [MENU_BUTTON_CODE.DROPDOWN_01]: {
    button_code: "dropdown_01",
    button_form: "dropdown",
    button_style: "01",
    button_style_ios: "01",
    button_type: "inout",
    button_label: "Title",
    button_keyboard: "text",
    button_helper:"Supporting Text",
    button_option: [
      {
        id: "o_0",
        label: "Select Option",
        value: "select-option",
      },
      {
        id: "o_2",
        label: "Option 2",
        value: "option-2",
      },
      {
        id: "o_3",
        label: "Option 3",
        value: "option-3",
      },
    ],
    button_value: [
      {
        id: "o_0",
        label: "Select Option",
        value: "select-option",
      },
    ],
    button_newpage: false,
    info: {
      onClick: false,
      image: "./assets/images/workflow/outlined_drop.webp",
      min_grid: 20,
      hight: 40,
      ratio: {
        "1": "360x40",
        "2": "180x40",
        "3": "120x40",
        "4": "90x40",
        "5": "72x40",
        "6": "60x40",
      },
    },
  },
  [MENU_BUTTON_CODE.DROPDOWN_02]: {
    button_code: "dropdown_02",
    button_form: "dropdown",
    button_style: "02",
    button_style_ios: "02",
    button_option_color: null,
    button_url: null,
    button_type: "inout",
    button_keyboard: "text",
    button_label: "Title",
    button_sublabel: "Select Option",
    button_helper:"Supporting Text",

    button_option: [
      {
        id: "o_0",
        label: "Select Option",
        value: "select-option",
      },
      {
        id: "o_2",
        label: "Option 2",
        value: "option-2",
      },
      {
        id: "o_3",
        label: "Option 3",
        value: "option-3",
      },
    ],

    button_value: [
      {
        id: "o_0",
        label: "Select Option",
        value: "select-option",
      },
    ],
    button_newpage: false,
    info: {
      onClick: false,
      image: "./assets/images/workflow/elevated_drop.webp",
      min_grid: 20,
      hight: 40,
      ratio: {
        "1": "360x40",
        "2": "180x40",
        "3": "120x40",
        "4": "90x40",
        "5": "72x40",
        "6": "60x40",
      },
    },
  },

  [MENU_BUTTON_CODE.DROPDOWN_03]: {
    button_code: "dropdown_03",
    button_form: "dropdown",
    button_style: "03",
    button_style_ios: "03",
    button_type: "inout",
    button_keyboard: "text",
    button_label: "Title",
    button_sublabel: "Select Option",
    button_helper:"Supporting Text",
    button_option: [
      {
        id: "o_0",
        label: "Select Option",
        value: "select-option",
      },
      {
        id: "o_2",
        label: "Option 2",
        value: "option-2",
      },
      {
        id: "o_3",
        label: "Option 3",
        value: "option-3",
      },
    ],

    button_value: [
      {
        id: "o_0",
        label: "Select Option",
        value: "select-option",
      },
    ],

    button_newpage: false,
    info: {
      onClick: false,
      image: "./assets/images/workflow/filled_drop.webp",
      min_grid: 20,
      hight: 40,
      ratio: {
        "1": "360x40",
        "2": "180x40",
        "3": "120x40",
        "4": "90x40",
        "5": "72x40",
        "6": "60x40",
      },
    },
  },

  [MENU_BUTTON_CODE.OUTPUT_01]: {
    button_code: "out_01",
    button_form: "out",
    button_style: "0103",
    button_style_ios: "0103",
    button_type: "inout",
    button_keyboard: "text",
    button_headline: "Headline",
    button_subhead: "Subhead",
    button_text_fontsize: {headline:"md", subhead:"md"},
    button_text_fontsize_ios: {headline:"md", subhead:"md"},
    button_text_align:{headline:"center", subhead:"center"},
    button_text_align_ios:{headline:"center", subhead:"center"},
    button_newpage: false,
    info: {
      onClick: false,
      image: "https://nandbox.com/wp-content/uploads/2024/10/L-headline-subhead-card.webp",
      min_grid: 20,
      hight: 100,
      ratio: {
        "1": "360x100",
        "2": "180x100",
        "3": "120x100",
        "4": "90x100",
        "5": "72x100",
        "6": "60x100",
      },
    },
  },
  [MENU_BUTTON_CODE.OUTPUT_02]: {
    button_code: "out_02",
    button_form: "out",
    button_style: "0203",
    button_style_ios: "0203",
    button_type: "out",
    button_keyboard: "text",
    button_headline: "Headline",
    button_subhead: "Subhead",
    button_text_fontsize: {headline:"md", subhead:"md"},
    button_text_fontsize_ios: {headline:"md", subhead:"md"},
    button_text_align:{headline:"center", subhead:"center"},
    button_text_align_ios:{headline:"center", subhead:"center"},
    button_newpage: false,
    info: {
      onClick: false,
      image: "https://nandbox.com/wp-content/uploads/2024/10/headline-subhead-card.webp",
      min_grid: 20,
      hight: 40,
      ratio: {
        "1": "360x40",
        "2": "180x40",
        "3": "120x40",
        "4": "90x40",
        "5": "72x40",
        "6": "60x40",
      },
    },
  },
  [MENU_BUTTON_CODE.OUTPUT_03]: {
    button_code: "out_03",
    button_form: "out",
    button_style: "0303",
    button_style_ios: "0303",
    button_type: "out",
    button_keyboard: "text",
    button_headline: "Headline",
    button_subhead: "Subhead",
    button_body: "Value Text",

    button_text_fontsize: {headline:"md", subhead:"md", body:"md"},
    button_text_fontsize_ios: {headline:"md", subhead:"md", body: "md"},
    button_text_align:{headline:"center", subhead:"center", body:"center"},
    button_text_align_ios:{headline:"center", subhead:"center",body:"center"},

    button_newpage: false,
    info: {
      onClick: false,
      image: "https://nandbox.com/wp-content/uploads/2024/10/headline-subhead-valueText-card.webp",
      min_grid: 20,
      hight: 60,
      ratio: {
        "1": "360x120",
        "2": "180x120",
        "3": "120x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },

  [MENU_BUTTON_CODE.SINGLECHOICE_3000]: {
    button_code: "singlechoice_3000",
    button_form: "singlechoice",
    button_style: "3000",
    button_style_ios: "3000",
    button_type: "inout",
    button_headline: "Headline",
    button_subhead: "Subhead",
     button_helper:"Supporting Text",
    button_text_fontsize: {headline:"md", subhead:"md"},
    button_text_fontsize_ios: {headline:"md", subhead:"md"},
    button_text_align:{headline:"left", subhead:"left"},
    button_text_align_ios:{headline:"left", subhead:"left"},
    button_option: [
      {
        id: "o_1",
        label: "Option 1",
        value: "option-1",
      },
      {
        id: "o_2",
        label: "Option 2",
        value: "option-2",
      },
    ],

    button_value: [
      {
        id: "o_1",
        label: "Option 1",
        value: "option-1",
      }
    ],
    button_newpage: false,
    info: {
      image: "https://nandbox.com/wp-content/uploads/2024/10/radio-Button-card.webp",
      min_grid: 60,
      ratio: {
        "1": "360x120",
        "2": "180x120",
        "3": "120x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },
  [MENU_BUTTON_CODE.SINGLECHOICE_2000]: {
    button_code: "singlechoice_2000",
    button_form: "singlechoice",
    button_style: "2000",
    button_style_ios: "2000",
    button_type: "inout",
    button_headline: "Headline",
    button_subhead: "Subhead",
     button_helper:"Supporting Text",
    button_text_fontsize: {headline:"md", subhead:"md"},
    button_text_fontsize_ios: {headline:"md", subhead:"md"},
    button_text_align:{headline:"left", subhead:"left"},
    button_text_align_ios:{headline:"left", subhead:"left"},
    button_option: [
      {
        id: "o_1",
        label: "Option 1",
        image: "./assets/images/workflow/home.png",
        image_ios: "./assets/images/workflow/home.png",
        value: "option-1",
      },
      {
        id: "o_2",
        label: "Option 2",
        image: "./assets/images/workflow/work.png",
        image_ios: "./assets/images/workflow/work.png",
        value: "option-2",
      },
    ],
    button_value:[
      {
        id: "o_1",
        label: "Option 1",
        image: "./assets/images/workflow/home.png",
        image_ios: "./assets/images/workflow/home.png",
        value: "option-1",
      }
    ],

    button_newpage: false,
    info: {
      image: "./assets/images/workflow/radio_2000.jpg",
      min_grid: 60,
      ratio: {
        "1": "360x120",
        "2": "180x120",
        "3": "120x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },
  [MENU_BUTTON_CODE.SINGLECHOICE_1000]: {
    button_code: "singlechoice_1000",
    button_form: "singlechoice",
    button_style: "1000",
    button_style_ios: "1000",
    button_type: "inout",
    button_headline: "Headline",
    button_subhead: "Subhead",
     button_helper:"Supporting Text",
    button_text_fontsize: {headline:"md", subhead:"md"},
    button_text_fontsize_ios: {headline:"md", subhead:"md"},
    button_text_align:{headline:"left", subhead:"left"},
    button_text_align_ios:{headline:"left", subhead:"left"},
    button_option: [
      {
        id: "o_1",
        label: "Option 1",
        icon: "mir_home",
        icon_ios: "mir_home",
        value: "option-1",
      },
      {
        id: "o_2",
        label: "Option 2",
        icon: "mir_bookmarks",
        icon_ios: "mir_bookmarks",
        value: "option-2",

      },
    ],

    button_value: [
      {
        id: "o_1",
        label: "Option 1",
        icon: "mir_home",
        icon_ios: "mir_home",
        value: "option-1",
      },
    ],
    button_newpage: false,
    info: {
      image: "https://nandbox.com/wp-content/uploads/2024/10/radio-Button-card-icon.webp",

      min_grid: 60,
      hight: 60,
      ratio: {
        "1": "360x120",
        "2": "180x120",
        "3": "120x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },

  [MENU_BUTTON_CODE.MULTICHOICE_1000]: {
    button_code: "multiplechoice_1000",
    button_form: "multiplechoice",
    button_style: "1000",
    button_style_ios: "1000",
    button_type: "inout",
    button_headline: "Headline",
    button_subhead: "Subhead",
     button_helper:"Supporting Text",
    button_text_fontsize: {headline:"md", subhead:"md"},
    button_text_fontsize_ios: {headline:"md", subhead:"md"},
    button_text_align:{headline:"left", subhead:"left"},
    button_text_align_ios:{headline:"left", subhead:"left"},
    button_option: [
      {
        id: "o_1",
        label: "Option 1",
        icon: "mir_home",
        icon_ios: "mir_home",
        value: "option-1",
      },
      {
        id: "o_2",
        label: "Option 2",
        icon: "mir_bookmarks",
        icon_ios: "mir_bookmarks",
        value: "option-2",
      },
    ],

    button_value: [
      {
        id: "o_1",
        label: "Option 1",
        icon: "mir_home",
        icon_ios: "mir_home",
        value: "option-1",
      },
    ],

    button_newpage: false,
    info: {
      image: "https://nandbox.com/wp-content/uploads/2024/10/check-card-icon.webp",
      min_grid: 60,
      ratio: {
        "1": "360x120",
        "2": "180x120",
        "3": "120x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },

  [MENU_BUTTON_CODE.MULTICHOICE_2000]: {
    button_code: "multiplechoice_2000",
    button_form: "multiplechoice",
    button_style: "2000",
    button_style_ios: "2000",
    button_type: "inout",
    button_headline: "Headline",
    button_subhead: "Subhead",
     button_helper:"Supporting Text",
    button_text_fontsize: {headline:"md", subhead:"md"},
    button_text_fontsize_ios: {headline:"md", subhead:"md"},
    button_text_align:{headline:"left", subhead:"left"},
    button_text_align_ios:{headline:"left", subhead:"left"},
    button_option:     [
      {
        id: "o_1",
        label: "Option 1",
        image: "./assets/images/workflow/home.png",
        image_ios: "./assets/images/workflow/home.png",
        value: "option-1",
      },
      {
        id: "o_2",
        label: "Option 2",
        image: "./assets/images/workflow/work.png",
        image_ios: "./assets/images/workflow/work.png",
        value: "option-2",
      },
    ],
    button_value: [
      {
        id: "o_1",
        label: "Option 1",
        image: "./assets/images/workflow/home.png",
        image_ios: "./assets/images/workflow/home.png",
        value: "option-1",
      },
    ],


    button_newpage: false,
    info: {
      image: "https://nandbox.com/wp-content/uploads/2024/10/check-card-image.webp",
      min_grid: 60,
      ratio: {
        "1": "360x120",
        "2": "180x120",
        "3": "120x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },
  [MENU_BUTTON_CODE.MULTICHOICE_3000]: {
    button_code: "multiplechoice_3000",
    button_form: "multiplechoice",
    button_style: "3000",
    button_style_ios: "3000",
    button_type: "inout",
    button_headline: "Headline",
    button_subhead: "Subhead",
     button_helper:"Supporting Text",
    button_text_fontsize: {headline:"md", subhead:"md"},
    button_text_fontsize_ios: {headline:"md", subhead:"md"},
    button_text_align:{headline:"left", subhead:"left"},
    button_text_align_ios:{headline:"left", subhead:"left"},
    button_option: [
      {
        id: "o_1",
        label: "Option 1",
        value: "option-1",
      },
      {
        id: "o_2",
        label: "Option 2",
        value: "option-2",
      },
    ],
    button_value: [
      {
        id: "o_1",
        label: "Option 1",
        value: "option-1",
      },
    ],

    button_keyboard: "text",
    button_newpage: false,
    info: {
      image: "https://nandbox.com/wp-content/uploads/2024/10/check-card.webp",
      min_grid: 60,
      ratio: {
        "1": "360x120",
        "2": "180x120",
        "3": "120x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },

  [MENU_BUTTON_CODE.MULTICHOICE_SEGMENTED_1000]: {
    button_code: "multichoice_segmented_1000",
    button_form: "multi_segmented",
    button_style: "1000",
    button_style_ios: "1000",
    button_type: "inout",
    button_option:     [
      {
        id: "o_1",
        label: "Option 1",
        icon: "mir_home",
        icon_ios: "mir_home",
        value: "option-1",
      },
      {
        id: "o_2",
        label: "Option 2",
        icon: "mir_bookmarks",
        icon_ios: "mir_bookmarks",
        value: "option-2",
      },
      {
        id: "o_3",
        label: "Option 3",
        icon: "mir_bookmarks",
        icon_ios: "mir_bookmarks",
        value: "option-3",
      },
    ],
    button_value: [
      {
        id: "o_1",
        label: "Option 1",
        icon: "mir_home",
        icon_ios: "mir_home",
        value: "option-1",
      },
      {
        id: "o_2",
        label: "Option 2",
        icon: "mir_home",
        icon_ios: "mir_home",
        value: "option-2",
      },
    ],
    button_density: 0,
    info: {
      image: "https://nandbox.com/wp-content/uploads/2025/01/multi-Segmented-button.webp",
      min_grid: 60,
      ratio: {
        "1": "360x120",
        "2": "180x120",
        "3": "120x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },

  [MENU_BUTTON_CODE.SINGLECHOICE_SEGMENTED_1000]: {
    button_code: "singlechoice_segmented_1000",
    button_form: "single_segmented",
    button_style: "1000",
    button_style_ios: "1000",
    button_type: "inout",
    button_option:     [
      {
        id: "o_1",
        label: "Option 1",
        icon: "mir_home",
        icon_ios: "mir_home",
        value: "option-1",
      },
      {
        id: "o_2",
        label: "Option 2",
        icon: "mir_bookmarks",
        icon_ios: "mir_bookmarks",
        value: "option-2",
      },
    ],
    button_value: [
      {
        id: "o_1",
        label: "Option 1",
        icon: "mir_home",
        icon_ios: "mir_home",
        value: "option-1",
      },
    ],
    button_density: 0,
    info: {
      image: "https://nandbox.com/wp-content/uploads/2025/01/Segmented-button.webp",
      min_grid: 60,
      ratio: {
        "1": "360x120",
        "2": "180x120",
        "3": "120x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },

  [MENU_BUTTON_CODE.MUTLI_CHIPSET_0100]: {
    button_code: "mutli_chipset_0100",
    button_form: "multi_chipset",
    button_style: "0100",
    button_style_ios: "0100",
    button_type: "inout",
    button_option:     [
      {
        id: "o_1",
        label: "Option 1",
        icon: "mir_home",
        icon_ios: "mir_home",
        value: "option-1",
      },
      {
        id: "o_2",
        label: "Option 2",
        icon: "mir_bookmarks",
        icon_ios: "mir_bookmarks",
        value: "option-2",
      },
    ],
    button_value: [
      {
        id: "o_1",
        label: "Option 1",
        icon: "mir_home",
        icon_ios: "mir_home",
        value: "option-1",
      },
    ],
    info: {
      image: "https://nandbox.com/wp-content/uploads/2025/01/multi-Segmented-button.webp",
      min_grid: 60,
      ratio: {
        "1": "360x120",
        "2": "180x120",
        "3": "120x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },
  [MENU_BUTTON_CODE.MUTLI_CHIPSET_0102]: {
    button_code: "mutli_chipset_0102",
    button_form: "multi_chipset",
    button_style: "0102",
    button_style_ios: "0102",
    button_type: "inout",
    button_option:     [
      {
        id: "o_1",
        label: "Option 1",
        icon: "mir_home",
        icon_ios: "mir_home",
        value: "option-1",
      },
      {
        id: "o_2",
        label: "Option 2",
        icon: "mir_bookmarks",
        icon_ios: "mir_bookmarks",
        value: "option-2",
      },
    ],
    button_value: [
      {
        id: "o_1",
        label: "Option 1",
        icon: "mir_home",
        icon_ios: "mir_home",
        value: "option-1",
      },
    ],
    info: {
      image: "https://nandbox.com/wp-content/uploads/2025/02/chips-multiple.webp",
      min_grid: 60,
      ratio: {
        "1": "360x120",
        "2": "180x120",
        "3": "120x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },
  [MENU_BUTTON_CODE.MUTLI_CHIPSET_0200]: {
    button_code: "mutli_chipset_0200",
    button_form: "multi_chipset",
    button_style: "0200",
    button_style_ios: "0200",
    button_type: "inout",
    button_option:     [
      {
        id: "o_1",
        label: "Option 1",
        icon: "mir_home",
        icon_ios: "mir_home",
        value: "option-1",
      },
      {
        id: "o_2",
        label: "Option 2",
        icon: "mir_bookmarks",
        icon_ios: "mir_bookmarks",
        value: "option-2",
      },
    ],
    button_value: [
      {
        id: "o_1",
        label: "Option 1",
        icon: "mir_home",
        icon_ios: "mir_home",
        value: "option-1",
      },
    ],
    info: {
      image: "https://nandbox.com/wp-content/uploads/2025/01/multi-Segmented-button.webp",
      min_grid: 60,
      ratio: {
        "1": "360x120",
        "2": "180x120",
        "3": "120x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },
  [MENU_BUTTON_CODE.MUTLI_CHIPSET_0202]: {
    button_code: "mutli_chipset_0202",
    button_form: "multi_chipset",
    button_style: "0202",
    button_style_ios: "0202",
    button_type: "inout",
    button_option:     [
      {
        id: "o_1",
        label: "Option 1",
        icon: "mir_home",
        icon_ios: "mir_home",
        value: "option-1",
      },
      {
        id: "o_2",
        label: "Option 2",
        icon: "mir_bookmarks",
        icon_ios: "mir_bookmarks",
        value: "option-2",
      },
    ],
    button_value: [
      {
        id: "o_1",
        label: "Option 1",
        icon: "mir_home",
        icon_ios: "mir_home",
        value: "option-1",
      },
    ],
    info: {
      image: "https://nandbox.com/wp-content/uploads/2025/01/multi-Segmented-button.webp",
      min_grid: 60,
      ratio: {
        "1": "360x120",
        "2": "180x120",
        "3": "120x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },
  [MENU_BUTTON_CODE.SINGLE_CHIPSET_0100]: {
    button_code: "single_chipset_0102",
    button_form: "single_chipset",
    button_style: "0100",
    button_style_ios: "0100",
    button_type: "inout",
    button_option:     [
      {
        id: "o_1",
        label: "Option 1",
        icon: "mir_home",
        icon_ios: "mir_home",
        value: "option-1",
      },
      {
        id: "o_2",
        label: "Option 2",
        icon: "mir_bookmarks",
        icon_ios: "mir_bookmarks",
        value: "option-2",
      },
    ],
    button_value: [
      {
        id: "o_1",
        label: "Option 1",
        icon: "mir_home",
        icon_ios: "mir_home",
        value: "option-1",
      },
    ],
    info: {
      image: "https://nandbox.com/wp-content/uploads/2025/02/chips-single.webp",
      min_grid: 60,
      ratio: {
        "1": "360x120",
        "2": "180x120",
        "3": "120x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },
  [MENU_BUTTON_CODE.SINGLE_CHIPSET_0102]: {
    button_code: "single_chipset_0102",
    button_form: "single_chipset",
    button_style: "0102",
    button_style_ios: "0102",
    button_type: "inout",
    button_option:     [
      {
        id: "o_1",
        label: "Option 1",
        icon: "mir_home",
        icon_ios: "mir_home",
        value: "option-1",
      },
      {
        id: "o_2",
        label: "Option 2",
        icon: "mir_bookmarks",
        icon_ios: "mir_bookmarks",
        value: "option-2",
      },
    ],
    button_value: [
      {
        id: "o_1",
        label: "Option 1",
        icon: "mir_home",
        icon_ios: "mir_home",
        value: "option-1",
      },
    ],
    info: {
      image: "https://nandbox.com/wp-content/uploads/2025/01/multi-Segmented-button.webp",
      min_grid: 60,
      ratio: {
        "1": "360x120",
        "2": "180x120",
        "3": "120x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },
  [MENU_BUTTON_CODE.SINGLE_CHIPSET_0200]: {
    button_code: "single_chipset_0200",
    button_form: "single_chipset",
    button_style: "0200",
    button_style_ios: "0200",
    button_type: "inout",
    button_option:     [
      {
        id: "o_1",
        label: "Option 1",
        icon: "mir_home",
        icon_ios: "mir_home",
        value: "option-1",
      },
      {
        id: "o_2",
        label: "Option 2",
        icon: "mir_bookmarks",
        icon_ios: "mir_bookmarks",
        value: "option-2",
      },
    ],
    button_value: [
      {
        id: "o_1",
        label: "Option 1",
        icon: "mir_home",
        icon_ios: "mir_home",
        value: "option-1",
      },
    ],
    info: {
      image: "https://nandbox.com/wp-content/uploads/2025/01/multi-Segmented-button.webp",
      min_grid: 60,
      ratio: {
        "1": "360x120",
        "2": "180x120",
        "3": "120x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },
  [MENU_BUTTON_CODE.SINGLE_CHIPSET_0202]: {
    button_code: "single_chipset_0202",
    button_form: "single_chipset",
    button_style: "0202",
    button_style_ios: "0202",
    button_type: "inout",
    button_option:     [
      {
        id: "o_1",
        label: "Option 1",
        icon: "mir_home",
        icon_ios: "mir_home",
        value: "option-1",
      },
      {
        id: "o_2",
        label: "Option 2",
        icon: "mir_bookmarks",
        icon_ios: "mir_bookmarks",
        value: "option-2",
      },
    ],
    button_value: [
      {
        id: "o_1",
        label: "Option 1",
        icon: "mir_home",
        icon_ios: "mir_home",
        value: "option-1",
      },
    ],
    info: {
      image: "https://nandbox.com/wp-content/uploads/2025/02/chips-filter.webp",
      min_grid: 60,
      ratio: {
        "1": "360x120",
        "2": "180x120",
        "3": "120x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },
  [MENU_BUTTON_CODE.ASSIST_CHIPSET_0100]: {
    button_code: "assist_chipset_0100",
    button_form: "assist_chipset",
    button_style: "0100",
    button_style_ios: "0100",
    button_type: "inout",
    button_option:     [
      {
        id: "o_1",
        label: "Option 1",
        icon: "mir_home",
        icon_ios: "mir_home",
        value: "option-1",
      },
      {
        id: "o_2",
        label: "Option 2",
        icon: "mir_bookmarks",
        icon_ios: "mir_bookmarks",
        value: "option-2",
      },
    ],
    // button_value: [
    //   {
    //     id: "o_1",
    //     label: "Option 1",
    //     icon: "mir_home",
    //     icon_ios: "mir_home",
    //     value: "option-1",
    //   },
    // ],
    info: {
      image: "https://nandbox.com/wp-content/uploads/2025/01/multi-Segmented-button.webp",
      min_grid: 60,
      ratio: {
        "1": "360x120",
        "2": "180x120",
        "3": "120x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },
  [MENU_BUTTON_CODE.ASSIST_CHIPSET_0102]: {
    button_code: "assist_chipset_0102",
    button_form: "assist_chipset",
    button_style: "0102",
    button_style_ios: "0102",
    button_type: "inout",
    button_option:     [
      {
        id: "o_1",
        label: "Option 1",
        icon: "mir_home",
        icon_ios: "mir_home",
        value: "option-1",
      },
      {
        id: "o_2",
        label: "Option 2",
        icon: "mir_bookmarks",
        icon_ios: "mir_bookmarks",
        value: "option-2",
      },
    ],
    button_value: [
      {
        id: "o_1",
        label: "Option 1",
        icon: "mir_home",
        icon_ios: "mir_home",
        value: "option-1",
      },
    ],
    info: {
      image: "https://nandbox.com/wp-content/uploads/2025/02/chips-assist.webp",
      min_grid: 60,
      ratio: {
        "1": "360x120",
        "2": "180x120",
        "3": "120x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },

  [MENU_BUTTON_CODE.DROPDOWN_CHIP_01]: {
    button_code: "dropdown_chip_01",
    button_form: "dropdown_chip",
    button_style: "0101",
    button_style_ios: "0101",
    button_option_color: null,
    button_url: null,
    button_type: "inout",
    button_keyboard: "text",
    button_label: "Title",
    button_sublabel: "Select Option",
    button_helper:"Supporting Text",

    button_option: [
      {
        id: "o_0",
        label: "Select Option",
        value: "select-option",
      },
      {
        id: "o_2",
        label: "Option 2",
        value: "option-2",
      },
      {
        id: "o_3",
        label: "Option 3",
        value: "option-3",
      },
    ],

    button_value: [
      {
        id: "o_0",
        label: "Select Option",
        value: "select-option",
      },
    ],
    button_newpage: false,
    info: {
      onClick: false,
      image: "./assets/images/workflow/elevated_drop.webp",
      min_grid: 20,
      hight: 40,
      ratio: {
        "1": "360x40",
        "2": "180x40",
        "3": "120x40",
        "4": "90x40",
        "5": "72x40",
        "6": "60x40",
      },
    },
  },
  [MENU_BUTTON_CODE.DROPDOWN_CHIP_02]: {
    button_code: "dropdown_chip_02",
    button_form: "dropdown_chip",
    button_style: "0102",
    button_style_ios: "0102",
    button_option_color: null,
    button_url: null,
    button_type: "inout",
    button_keyboard: "text",
    button_label: "Title",
    button_sublabel: "Select Option",
    button_helper:"Supporting Text",

    button_option: [
      {
        id: "o_0",
        label: "Select Option",
        value: "select-option",
      },
      {
        id: "o_2",
        label: "Option 2",
        value: "option-2",
      },
      {
        id: "o_3",
        label: "Option 3",
        value: "option-3",
      },
    ],

    button_value: [
      {
        id: "o_0",
        label: "Select Option",
        value: "select-option",
      },
    ],
    button_newpage: false,
    info: {
      onClick: false,
      image: "https://nandbox.com/wp-content/uploads/2025/02/chips-filter.webp",
      min_grid: 20,
      hight: 40,
      ratio: {
        "1": "360x40",
        "2": "180x40",
        "3": "120x40",
        "4": "90x40",
        "5": "72x40",
        "6": "60x40",
      },
    },
  },
  [MENU_BUTTON_CODE.SEARCHBAR_01]: {
    button_code: "searchbar_01",
    button_form: "searchbar",
    button_style: "01",
    button_style_ios: "01",
    button_type: "inout",
    button_keyboard: "text",
    button_label: "Title",
    button_input: "Input Text",
    button_avatar: 0,
    button_icon:"mir_menu",
    button_icon_ios: "mir_menu",
    button_placeholder: null,
    button_newpage: false,
    info: {
      onClick: true,
      image: "https://nandbox.com/wp-content/uploads/2025/02/search-bar.webp",
      min_grid: 20,
      hight: 40,
      ratio: {
        "1": "360x40",
        "2": "180x40",
        "3": "120x40",
        "4": "90x40",
        "5": "72x40",
        "6": "60x40",
      },
    },
  },
  [MENU_BUTTON_CODE.TOGGLE_1000]: {
    button_code: "toggle_1000",
    button_form: "toggle",
    button_style: "1000",
    button_style_ios: "1000",
    button_type: "inout",
    button_headline: "Headline",
    button_subhead: "Subhead",
     button_helper:"Supporting Text",
    button_text_fontsize: {headline:"md", subhead:"md"},
    button_text_fontsize_ios: {headline:"md", subhead:"md"},
    button_text_align:{headline:"left", subhead:"left"},
    button_text_align_ios:{headline:"left", subhead:"left"},
    button_keyboard: "text",
    button_option: [
      {
        id: "o_1",
        label: "Option 1",
        icon: "mir_home",
        icon_ios: "mir_home",
        value: "option-1",
      },
      {
        id: "o_2",
        label: "Option 2",
        icon: "mir_bookmarks",
        icon_ios: "mir_bookmarks",
        value: "option-2",
      },
    ],
    button_value: [
      {
        id: "o_1",
        label: "Option 1",
        icon: "mir_home",
        icon_ios: "mir_home",
        value: "option-1",
      }
    ],
    button_newpage: false,
    info: {
      image: "https://nandbox.com/wp-content/uploads/2024/10/Toggle-Button-card-icon.webp",

      min_grid: 60,
      ratio: {
        "1": "360x120",
        "2": "180x120",
        "3": "120x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },

  [MENU_BUTTON_CODE.TOGGLE_2000]: {
    button_code: "toggle_2000",
    button_form: "toggle",
    button_style: "2000",
    button_style_ios: "2000",
    button_type: "inout",
    button_headline: "Headline",
    button_subhead: "Subhead",
     button_helper:"Supporting Text",
    button_text_fontsize: {headline:"md", subhead:"md"},
    button_text_fontsize_ios: {headline:"md", subhead:"md"},
    button_text_align:{headline:"left", subhead:"left"},
    button_text_align_ios:{headline:"left", subhead:"left"},
    button_option: [
      {
        id: "o_1",
        label: "Option 1",
        image: "./assets/images/workflow/home.png",
        image_ios: "./assets/images/workflow/home.png",
        value: "option-1",
      },
      {
        id: "o_2",
        label: "Option 2",
        image: "./assets/images/workflow/work.png",
        image_ios: "./assets/images/workflow/work.png",
        value: "option-2",
      },
    ],
    button_value: [
      {
        id: "o_1",
        label: "Option 1",
        image: "./assets/images/workflow/home.png",
        image_ios: "./assets/images/workflow/home.png",
        value: "option-1",
      }
    ],
    button_newpage: false,
    info: {
      image: "./assets/images/workflow/toggle_2000.webp",
      min_grid: 60,
      ratio: {
        "1": "360x120",
        "2": "180x120",
        "3": "120x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },
  [MENU_BUTTON_CODE.TOGGLE_3000]: {
    button_code: "toggle_3000",
    button_form: "toggle",
    button_style: "3000",
    button_style_ios: "3000",
    button_type: "inout",
    button_headline: "Headline",
    button_subhead: "Subhead",
     button_helper:"Supporting Text",
    button_text_fontsize: {headline:"md", subhead:"md"},
    button_text_fontsize_ios: {headline:"md", subhead:"md"},
    button_text_align:{headline:"left", subhead:"left"},
    button_text_align_ios:{headline:"left", subhead:"left"},
    button_option: [
      {
        id: "o_1",
        label: "Option 1",
        value: "option-1",
      },
      {
        id: "o_2",
        label: "Option 2",
        value: "option-2",
      },
    ],
    button_value: [
      {
        id: "o_1",
        label: "Option 1",
        value: "option-1",
      }
    ],

    button_keyboard: "text",
    button_newpage: false,
    info: {
      image: "https://nandbox.com/wp-content/uploads/2024/10/Toggle-Button-card.webp",
      min_grid: 60,
      ratio: {
        "1": "360x120",
        "2": "180x120",
        "3": "120x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },

  [MENU_BUTTON_CODE.BUTTON_04]: {
    button_code: "button_04",
    button_form: "button",
    button_style: "04",
    button_style_ios: "04",
    button_type: "submit-all",
    button_label: "Elevated",
    button_icon: "mir_add",
    button_icon_ios: "mir_add",
    button_db: 1,
    button_newpage: false,
    info: {
      onClick: true,
      image: "https://nandbox.com/wp-content/uploads/2024/10/elevated@3x.webp",
      min_grid: 20,
      hight: 40,
      ratio: {
        "1": "360x20",
        "2": "180x20",
        "3": "120x20",
        "4": "90x20",
        "5": "72x20",
        "6": "60x20",
      },
    },
  },

  [MENU_BUTTON_CODE.BUTTON_01]: {
    button_code: "button_01",
    button_form: "button",
    button_style: "01",
    button_style_ios: "01",
    button_type: "submit-all",
    button_label: "Filled",
    button_keyboard: "text",
    button_db: 1,
    button_newpage: false,
    info: {
      onClick: true,
      image: "https://nandbox.com/wp-content/uploads/2024/10/filled@3x.webp",
      min_grid: 20,
      hight: 40,
      ratio: {
        "1": "360x20",
        "2": "180x20",
        "3": "120x20",
        "4": "90x20",
        "5": "72x20",
        "6": "60x20",
      },
    },
  },

  [MENU_BUTTON_CODE.BUTTON_03]: {
    button_code: "button_03",
    button_form: "button",
    button_style: "03",
    button_style_ios: "03",
    button_type: "submit-all",
    button_label: "Tonal",
    button_keyboard: "text",
    button_db: 1,
    button_newpage: false,
    info: {
      onClick: true,
      image: "https://nandbox.com/wp-content/uploads/2024/10/tonal@3x.webp",
      min_grid: 20,
      hight: 40,
      ratio: {
        "1": "360x20",
        "2": "180x20",
        "3": "120x20",
        "4": "90x20",
        "5": "72x20",
        "6": "60x20",
      },
    },
  },
  [MENU_BUTTON_CODE.BUTTON_02]: {
    button_code: "button_02",
    button_form: "button",
    button_style: "02",
    button_style_ios: "02",
    button_type: "submit-all",
    button_label: "Outlined",
    button_keyboard: "text",
    button_db: 1,
    button_newpage: false,
    info: {
      onClick: true,
      image: "https://nandbox.com/wp-content/uploads/2024/10/outlined@3x.webp",
      min_grid: 20,
      hight: 40,
      ratio: {
        "1": "360x20",
        "2": "180x20",
        "3": "120x20",
        "4": "90x20",
        "5": "72x20",
        "6": "60x20",
      },
    },
  },

  [MENU_BUTTON_CODE.BUTTON_05]: {
    button_code: "button_05",
    button_form: "button",
    button_style: "05",
    button_style_ios: "05",
    button_type: "submit-all",
    button_label: "Text",
    button_keyboard: "text",
    button_db: 1,
    button_newpage: false,
    info: {
      onClick: true,
      image: "https://nandbox.com/wp-content/uploads/2024/10/text-px@3x.webp",
      min_grid: 20,
      hight: 40,
      ratio: {
        "1": "360x20",
        "2": "180x20",
        "3": "120x20",
        "4": "90x20",
        "5": "72x20",
        "6": "60x20",
      },
    },
  },

  [MENU_BUTTON_CODE.PAGETEXT]: {
    button_code: "pagetext",
    button_form: "pagetext",
    button_style: "0",
    button_style_ios: "0",
    button_type: "pagetext",
    button_page_data: { title: "Title", body: "" },

    info: {
      min_grid: 60,
      image: "",
    },
  },
  [MENU_BUTTON_CODE.PAGESLIDER]:
{
        button_code:"pageslider",
        button_form: "pageslider",
        button_style: "1000",
        button_style_ios: "1000",
        button_label:"Page Title",
        button_sublabel: "Page Description",
        button_label_color: "#2D546B",
        button_sublabel_color: "#2D546B",
        button_type: "photo",
        button_page_data:{
           interval:0,
           animation: false
          },
        button_components:[
          { id: "0",  order:0, type: "photo", image: "https://st2.depositphotos.com/2306293/7723/i/950/depositphotos_77237624-stock-photo-happy-face-mug-on-the.jpg"}
          ,{ id: "1",  order:1,type: "photo", image: "https://st3.depositphotos.com/2306293/15341/i/1600/depositphotos_153418610-stock-photo-sign-happy-on-the-miami.jpg"}
          ,{ id: "2",  order:2, type: "photo", image: "https://st2.depositphotos.com/4215343/10981/i/950/depositphotos_109810160-stock-photo-wish-you-were-here.jpg"} ],
        info: {
            min_grid: 60,
            image: ""
           },
  },
  [MENU_BUTTON_CODE.PAGESLIDERLABEL]:
  {
          button_code:"pagesliderlabel",
          button_form: "pagesliderlabel",
          button_style: "2000",
          button_style_ios: "2000",
          button_label:"Page Title",
          button_sublabel: "Page Description",
          button_label_color: "#2D546B",
          button_sublabel_color: "#2D546B",

          button_type: "photo",
          button_page_data:{
            interval:0,
            animation: false
            },
          button_components:[
            { id: "0",  order:0, title:"Page Title",desc:"Page Description", type: "photo", image: "https://st2.depositphotos.com/2306293/7723/i/950/depositphotos_77237624-stock-photo-happy-face-mug-on-the.jpg"}
            ,{ id: "1",  order:1,title:"Page Title",desc:"Page Description", type: "photo", image: "https://st3.depositphotos.com/2306293/15341/i/1600/depositphotos_153418610-stock-photo-sign-happy-on-the-miami.jpg"}
            ,{ id: "2",  order:2,title:"Page Title",desc:"Page Description", type: "photo", image: "https://st2.depositphotos.com/4215343/10981/i/950/depositphotos_109810160-stock-photo-wish-you-were-here.jpg"} ],
          info: {
              min_grid: 60,
              image: ""
            },
  },
  [MENU_BUTTON_CODE.PAGEGALLERY]:
  {
          button_code:"pagegallery",
          button_form: "pagegallery",
          button_style: "1000",
          button_style_ios: "1000",
          button_type: "photo",
          button_components:[
              { id: "0",  order:0, type: "photo", image: "https://images.pexels.com/photos/20145987/pexels-photo-20145987/free-photo-of-a-person-riding-a-motorcycle-on-a-road.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"}
              ,{ id: "1",  order:1, type: "photo", image: "https://images.pexels.com/photos/16113289/pexels-photo-16113289/free-photo-of-close-up-of-reed.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"}
              ,{ id: "2",  order:2, type: "photo", image: "https://images.pexels.com/photos/20001995/pexels-photo-20001995/free-photo-of-a-black-and-white-photo-of-a-roller-coaster.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"}
              ,{ id: "3",  order:3, type: "photo", image: "https://images.pexels.com/photos/20084774/pexels-photo-20084774/free-photo-of-a-cloud-in-the-sky-over-some-rocks.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"}
              ,{ id: "4",  order:4, type: "photo", image: "https://images.pexels.com/photos/20103012/pexels-photo-20103012/free-photo-of-a-snowy-forest-with-trees-and-sun-shining-through.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"}
              ,{ id: "5",  order:5, type: "photo", image: "https://images.pexels.com/photos/16575290/pexels-photo-16575290/free-photo-of-view-from-car-window-on-road-in-forest-in-rain.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"}
],
          info: {
              min_grid: 20,
              image: ""
            },
  },
  [MENU_BUTTON_CODE.PAGESINGLEIMAGE]:
  {
          button_code:"pagesingleimage",
          button_form: "pagesingleimage",
          button_style: "1000",
          button_style_ios: "1000",
          button_type: "photo",
          button_label:"Page Title",
          button_sublabel: "Page Description",
          button_label_color: "#2D546B",
          button_sublabel_color: "#2D546B",
          button_components:[
            { id: "1",  order:1, type: "photo",image: "https://images.pexels.com/photos/16575290/pexels-photo-16575290/free-photo-of-view-from-car-window-on-road-in-forest-in-rain.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"}]
              ,
          info: {
              onClick: true,
              min_grid: 20,
              image: ""
            },
  },
  [MENU_BUTTON_CODE.PAGEHEADER]:
{
        button_code:"pageheader",
        button_form: "pageheader",
        button_style: "1000",
        button_style_ios: "1000",
        button_type: "photo",
        button_page_data:{
          header: "Heading"
          },
        info: {
            min_grid: 20,
            image: ""
           },
  },
  [MENU_BUTTON_CODE.PAGEDIVIDER]:
{
        button_code: MENU_BUTTON_CODE.PAGEDIVIDER,
        button_form: MENU_BUTTON_FORM.PAGEDIVIDER,
        button_style: "1000",
        button_style_ios: "1000",
        button_type: "photo",
        web_button:{},

    button_page_data: {
      border_margin_top: 1,
      border_margin_bottom: 1,
      border_margin_start: 1,
      border_margin_end: 1,
      border_size: 1,
      border_color: "#00000",
    },
    info: {
      min_grid: 20,
      image: "",
    },
  },
  [MENU_BUTTON_CODE.PAGEVIDEO]:
{
        button_code: MENU_BUTTON_CODE.PAGEVIDEO,
        button_form: MENU_BUTTON_FORM.PAGEVIDEO,
        button_style: "1000",
        button_style_ios: "1000",
        button_label:"Page Title",
        button_sublabel: "Page Description",
        button_label_color: "#2D546B",
        button_sublabel_color: "#2D546B",
        button_type: "photo",
        web_button:{},

    button_page_data: {
      video_url:
        "https://www.youtube.com/embed/Weu3iPqOX3Q?si=xMuofzCTr21_4KMa",
    },

    info: {
      min_grid: 20,
      image: "",
    },
  },
  [MENU_BUTTON_CODE.PAGEQR]:
  {
        button_code: MENU_BUTTON_CODE.PAGEQR,
        button_form: MENU_BUTTON_FORM.PAGEQR,
        button_style: "1000",
        button_style_ios: "1000",
        button_type: "photo",
        button_page_data:{
          qr_value: "QRCode:uBJcae/QYj/PctjYYsq"
          },

    info: {
      min_grid: 20,
      image: "",
    },
  },
  [MENU_BUTTON_CODE.PAGELOCATION]:
  {
        button_code: MENU_BUTTON_CODE.PAGELOCATION,
        button_form: MENU_BUTTON_FORM.PAGELOCATION,
        button_style: "1000",
        button_style_ios: "1000",
        button_type: "photo",
        web_button:{},

    button_page_data: {
      map_options: {
        center: { lat: 48.8588548, lng: 2.347035 },
        zoom: 13,
      },
      map_markers: [
        { id: 1, position: { lat: 48.8584, lng: 2.2945 } }, // Eiffel Tower
        { id: 2, position: { lat: 48.8606, lng: 2.3376 } }, // Louvre Museum
        { id: 3, position: { lat: 48.853, lng: 2.3499 } }, // Cathédrale Notre-Dame de Paris
      ],
      zoom: 13,
    },

    info: {
      min_grid: 20,
      image: "",
    },
  },
  [MENU_BUTTON_CODE.PAGEPDFVIEWER]:
  {
        button_code: MENU_BUTTON_CODE.PAGEPDFVIEWER,
        button_form: MENU_BUTTON_FORM.PAGEPDFVIEWER,
        button_style: "1000",
        button_style_ios: "1000",
        button_type: "photo",
        web_button:{},

    button_page_data: {
      pdf_url: "https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf",
    },

    info: {
      min_grid: 20,
      image: "",
    },
  },
  [MENU_BUTTON_CODE.PAGESOCIALBAR]:
{
        button_code: MENU_BUTTON_CODE.PAGESOCIALBAR,
        button_form: MENU_BUTTON_FORM.PAGESOCIALBAR,
        button_style: "1000",
        button_style_ios: "1000",
        button_type: "photo",
        web_button:{},

    button_page_data: {},
    button_components: [
      {
        id: "1",
        order: 0,
        type: "facebook",
        title: "Facebook",
        icon: "fab fa-facebook-f fa-1x",
        color: "#3b5998",
      },
      {
        id: "2",
        order: 1,
        type: "whatsapp",
        title: "Whatsapp",
        icon: "fab fa-whatsapp fa-1x",
        color: "#25d366",
      },
      {
        id: "3",
        order: 3,
        type: "instagram",
        title: "Instagram",
        icon: "fab fa-instagram",
        color: "#ac2bac",
      },
    ],
    info: {
      min_grid: 20,
      image: "",
    },
  },
  [MENU_BUTTON_CODE.PAGEFEATURE]: {
    button_code: "pagefeature",
    button_form: "pagefeature",
    button_style: "0",
    button_style_ios: "0",
    button_type: "",
    web_button: {},
    info: {
      min_grid: 20,
    },
  },
  [MENU_BUTTON_CODE.PLACEHOLDER]: {
    button_form: "placeholder",
    button_style: "0",
    button_style_ios: "0",
    button_type: "placeholder",
    info: {
      min_grid: 10,
    },
  },

  [MENU_BUTTON_CODE.SPLASH_01]: {
    button_code: "splash_01",
    button_form: "splash",
    button_style: "01",
    button_style_ios: "01",
    button_type: "splash",
    button_bgcolor: "",
    button_label: splash_config_map["01"].button_part.title,
    button_sublabel: splash_config_map["01"].button_part.desc,
    button_newpage: false,
    button_bgimage: splash_config_map["01"].button_part.bg_image,
    title: "Onboarding",
    info: {
      lock: true,
      onClick: false,
      min_grid: 60,
    },
  },
  [MENU_BUTTON_CODE.SPLASH_02]: {
    button_code: "splash_02",
    button_form: "splash",
    button_style: "02",
    button_style_ios: "02",
    button_type: "splash",
    button_bgcolor: "",
    button_label: splash_config_map["02"].button_part.title,
    button_sublabel: splash_config_map["02"].button_part.desc,
    button_newpage: false,
    button_bgimage: splash_config_map["02"].button_part.bg_image,
    title: "Onboarding",
    info: {
      lock: true,
      onClick: false,
      min_grid: 60,
    },
  },
  [MENU_BUTTON_CODE.SPLASH_03]: {
    button_code: "splash_03",
    button_form: "splash",
    button_style: "03",
    button_style_ios: "03",
    button_type: "splash",
    button_bgcolor: "",
    button_label: splash_config_map["03"].button_part.title,
    button_sublabel: splash_config_map["03"].button_part.desc,
    button_newpage: false,
    button_bgimage: splash_config_map["03"].button_part.bg_image,
    title: "Onboarding",
    info: {
      lock: true,
      onClick: false,
      min_grid: 60,
    },
  },

  [MENU_BUTTON_CODE.SPLASH_04]: {
    button_code: "splash_04",
    button_form: "splash",
    button_style: "04",
    button_style_ios: "04",
    button_type: "splash",
    button_bgcolor: "",
    button_label: splash_config_map["04"].button_part.title,
    button_sublabel: splash_config_map["04"].button_part.desc,
    button_newpage: false,
    button_bgimage: splash_config_map["04"].button_part.bg_image,
    title: "Onboarding",
    info: {
      lock: true,
      onClick: false,
      min_grid: 60,
    },
  },

  [MENU_BUTTON_CODE.SPLASH_05]: {
    button_code: "splash_05",
    button_form: "splash",
    button_style: "05",
    button_style_ios: "05",
    button_type: "splash",
    button_bgcolor: "",
    button_label: splash_config_map["05"].button_part.title,
    button_sublabel: splash_config_map["05"].button_part.desc,
    button_newpage: false,
    button_bgimage: splash_config_map["05"].button_part.bg_image,

    title: "Onboarding",
    info: {
      lock: true,
      onClick: false,
      min_grid: 60,
    },
  },

  [MENU_BUTTON_CODE.SPLASH_06]: {
    button_code: "splash_06",
    button_form: "splash",
    button_style: "06",
    button_style_ios: "06",
    button_type: "splash",
    button_bgcolor: "",
    button_label: splash_config_map["06"].button_part.title,
    button_sublabel: splash_config_map["06"].button_part.desc,
    button_newpage: false,
    button_bgimage: splash_config_map["06"].button_part.bg_image,

    title: "Onboarding",
    info: {
      lock: true,
      onClick: false,
      min_grid: 60,
    },
  },
  [MENU_BUTTON_CODE.SPLASH_07]: {
    button_code: "splash_07",
    button_form: "splash",
    button_style: "07",
    button_style_ios: "07",
    button_type: "splash",
    button_bgcolor: "",
    button_label: splash_config_map["07"].button_part.title,
    button_sublabel: splash_config_map["07"].button_part.desc,
    button_newpage: false,
    button_bgimage: splash_config_map["07"].button_part.bg_image,

    title: "Onboarding",
    info: {
      lock: true,
      onClick: false,
      min_grid: 60,
    },
  },
};

export const common_components_map = {
  [TabNames.MENU]: {
    type: TabNames.MENU,
    module: TabNames.MENU,
    icon: "mir_dashboard",
    icon_ios: "mir_dashboard",
    title: "Workflow",
    title_ios: "Workflow",
    web:{
      name: "Custom Component",
      hint: "Design and link custom native screens and forms",
      desc: "Custom Component allows you to design and link custom native screens, create seamless workflows, and integrate your API to connect your app with your backend system for dynamic and interactive mobile experiences.",
      feature: "Custom",
      category:"Custom Component"
    }

  },
  [TabNames.POLL]: {
    type: TabNames.MENU,
    module: TabNames.POLL,
    icon: "mir_shopping_card",
    icon_ios: "mir_shopping_card",
    title: "Poll",
    title_ios: "Poll",
    web:{
      name: "Customize Your Poll",
      hint: "Design Your Poll and Questionnaire",
    }

  },
  [TabNames.PAGE]: {
    type: TabNames.MENU,
    module: TabNames.PAGE,
    icon: "mir_web",
    icon_ios: "mir_web",
    title: "Page",
    title_ios: "Page",
  web:{
    android_url_l: "./assets/images/empty-imageL.webp",
    android_url_s: "./assets/images/emptyImageS.webp",
    name: "Custom Wepages",
    hint: "Full custom webpages",
  }
  },
  [TabNames.FEED]: {
    type: TabNames.FEED,
    module: TabNames.FEED,
    icon: "mir_feed",
    icon_ios: "mir_feed",
    title: "Feeds",
    title_ios: "Feeds",
    chat_label: '0',
    post: {
      column: 1,
      type: "wide",
      sticky_visible: 1,
			sticky_id: 1,
      color_schema: {},
      color_schema_ios:{},
    },
    footer: {
      status: 1,
      action: [
        {
          link: "like",
          icon: "mir_thumb_up",
          status: true,
        },
        {
          link: "like_highlight",
          icon: "mis_thumb_up",
          status: true,
        },
        {
          link: "reply",
          icon: "mir_mode_comment",
          status: true,
        },
        {
          link: "view",
          icon: "mis_check_circle",
          status: true,
        },
        {
          link: "share",
          icon: "mir_east",
          status: true,
        },
      ],
    },
    menu: [
      {
        link: "share",
        title: "Share",
        icon: "mir_east",
        status: true,
      },
      {

        link: "forward",
        title: "Forward",
        icon: "mir_call_made",
        status: true,
      },
      {
        link: "copy",
        title: "Copy",
        icon: "mir_import_contacts",
        status: true,
      },
      {

        link: "edit",
        title: "Edit",
        icon: "mir_add",
        status: true,
      },
      {
        link: "save_to_gallery",
        title: "Save to Gallery",
        icon: "mir_library_books",
        status: true,
      },
      {
        link: "save_to_music",
        title: "Save to Music",
        icon: "mir_library_music",
        status: true,
      },
      {
        link: "save_to_downloads",
        title: "Save to Downloads",
        icon: "mir_south",
        status: true,
      },
      {
        // icon: "fas fa-exclamation-circle",
        link: "abuse",
        title: "Report abuse",
        icon: "mir_report",
        status: true,
      },
      {
        link: "delete",
        title: "Delete",
        icon: "mir_warrning",
        status: true,
      },
    ],
    web:{
      name: "Feeds Center",
      hint: "Publish and display app news and updates.",
      desc: "The Feed feature lets app owners share news, updates, and messages with users.It can be added up to four times, each linked to a distinct chat label enabling tailored tabs like News, Sports, and more.",
      feature:78,
      category:"Channel Features"
    }

  },
  [TabNames.CHANNEL]: {
    type: TabNames.CHANNEL,
    module: TabNames.CHANNEL,
    icon: "mir_rss_feed",
    icon_ios:"mir_rss_feed",
    title: "Channels",
    title_ios: "Channels",
    web:{
      android_url_f: "https://nandbox.com/wp-content/uploads/2024/12/Channels-Dashboard_AN_F.webp",
      android_url_l: "https://nandbox.com/wp-content/uploads/2024/12/Channels-Dashboard_AN_L.webp",
      android_url_s: "https://nandbox.com/wp-content/uploads/2024/12/Channels-Dashboard_AN_S.webp",

      ios_url_f: "https://nandbox.com/wp-content/uploads/2024/12/Channels-Dashboard_iOS_F.webp",
      ios_url_l: "https://nandbox.com/wp-content/uploads/2024/12/Channels-Dashboard_iOS_L.webp",
      ios_url_s: "https://nandbox.com/wp-content/uploads/2024/12/Channels-Dashboard_iOS_S.webp",

      dark_android_url_f:"https://nandbox.com/wp-content/uploads/2024/12/Channels-Dashboard_Dark_AN_F.webp",
      dark_android_url_l:"https://nandbox.com/wp-content/uploads/2024/12/Channels-Dashboard_Dark_AN_L.webp",
      dark_android_url_s:"https://nandbox.com/wp-content/uploads/2024/12/Channels-Dashboard_Dark_AN_S.webp",

      dark_ios_url_f:"https://nandbox.com/wp-content/uploads/2024/12/Channels-Dashboard_Dark_iOS_F.webp",
      dark_ios_url_l:"https://nandbox.com/wp-content/uploads/2024/12/Channels-Dashboard_Dark_iOS_L.webp",
      dark_ios_url_s:"https://nandbox.com/wp-content/uploads/2024/12/Channels-Dashboard_Dark_iOS_S.webp",

      name: "Channel Suite",
      hint: "Organize, manage, and message through channels.",
      desc: "Channel Suite provides a centralized view of all channel messages, allowing users to browse updates, reply, forward, and pin channels to the top for quick access.",
      feature:72,
      category:"Channel Features",
      update:0
    }
  },
  [TabNames.CHANNELLIST]: {
    type: TabNames.CHANNELLIST,
    module: TabNames.CHANNELLIST,
    title: "Active",
    title_ios: "Active",
    icon: "mir_space_dashboard",
    icon_ios: "mir_space_dashboard",
  web:{
    android_url_f: "https://nandbox.com/wp-content/uploads/2024/12/My-Active-Channels_AN_F.webp",
    android_url_l:  "https://nandbox.com/wp-content/uploads/2024/12/My-Active-Channels_AN_L.webp",
    android_url_s:  "https://nandbox.com/wp-content/uploads/2024/12/My-Active-Channels_AN_S.webp",
    ios_url_f: "https://nandbox.com/wp-content/uploads/2024/12/My-_Active-_Channels_iOS_F.webp",
    ios_url_l: "https://nandbox.com/wp-content/uploads/2024/12/My-_Active-_Channels_iOS_L.webp",
    ios_url_s: "https://nandbox.com/wp-content/uploads/2024/12/My-_Active-_Channels_iOS_S.webp",

    dark_android_url_f:"https://nandbox.com/wp-content/uploads/2024/12/My-Active-Channels_Dark_AN_F.webp",
    dark_android_url_l:"https://nandbox.com/wp-content/uploads/2024/12/My-Active-Channels_Dark_AN_L.webp",
    dark_android_url_s:"https://nandbox.com/wp-content/uploads/2024/12/My-Active-Channels_Dark_AN_S.webp",

    dark_ios_url_f:"https://nandbox.com/wp-content/uploads/2024/12/My-Active-Channels_Dark_iOS_F.webp",
    dark_ios_url_l:"https://nandbox.com/wp-content/uploads/2024/12/My-Active-Channels_Dark_iOS_L.webp",
    dark_ios_url_s:"https://nandbox.com/wp-content/uploads/2024/12/My-Active-Channels_Dark_iOS_S.webp",

    name: "Subscribed Channels",
    hint: "View and manage channels you're subscribed to.",
    desc:"Subscribed Channels provides users with a comprehensive list of all the channels they are subscribed to, ensuring easy access and management of their favorite content.",
    feature:78,
    category:"Channel Features",
    update:0

  },
  },
  [TabNames.ONLINECHANNEL]: {
    type: TabNames.ONLINECHANNEL,
    module: TabNames.ONLINECHANNEL,
    icon: "mir_near_me",
    icon_ios: "mir_near_me",
    title: "All",
    title_ios: "All",
    web:{
      android_url_f: "https://nandbox.com/wp-content/uploads/2024/12/App-Channels-list_AN_F.webp",
      android_url_l: "https://nandbox.com/wp-content/uploads/2024/12/App-Channels-list_AN_L.webp",
      android_url_s: "https://nandbox.com/wp-content/uploads/2024/12/App-Channels-list_AN_S.webp",

      ios_url_f:  "https://nandbox.com/wp-content/uploads/2024/12/App-_Channels-_List_iOS_F.webp",
      ios_url_l:  "https://nandbox.com/wp-content/uploads/2024/12/App-_Channels-_List_iOS_L.webp",
      ios_url_s: "https://nandbox.com/wp-content/uploads/2024/12/App-_Channels-_List_iOS_S.webp",

      dark_android_url_f:"https://nandbox.com/wp-content/uploads/2024/12/App-Channels-List_Dark_AN_F.webp",
      dark_android_url_l:"https://nandbox.com/wp-content/uploads/2024/12/App-Channels-List_Dark_AN_L.webp",
      dark_android_url_s:"https://nandbox.com/wp-content/uploads/2024/12/App-Channels-List_Dark_AN_S.webp",
      dark_ios_url_f:"https://nandbox.com/wp-content/uploads/2024/12/App-Channels-List_Dark_iOS_F.webp",
      dark_ios_url_l:"https://nandbox.com/wp-content/uploads/2024/12/App-Channels-List_Dark_iOS_L.webp",
      dark_ios_url_s:"https://nandbox.com/wp-content/uploads/2024/12/App-Channels-List_Dark_iOS_S.webp",

      name: "All Channels",
      hint: "View a comprehensive list of all channels.",
      desc:"All Channels displays a complete list of available channels, allowing users to explore and join channels of interest",
      feature:78,
      category:"Channel Features",
      update:0
    }

  },
  [TabNames.CHAT]: {
    type: TabNames.CHAT,
    module: TabNames.CHAT,
    icon: "mir_rocket_launch",
    icon_ios: "mir_rocket_launch",
    title: "Chat",
    title_ios: "Chat",
    video_call: true,
    web:{
      android_url_f:"https://nandbox.com/wp-content/uploads/2024/12/Messenger__AN_F.webp",
      android_url_l:"https://nandbox.com/wp-content/uploads/2024/12/Messenger__AN_L.webp",
      android_url_s:"https://nandbox.com/wp-content/uploads/2024/12/Messenger__AN_S.webp",
      ios_url_f: "https://nandbox.com/wp-content/uploads/2024/12/Messenger.__iOS_F.webp",
      ios_url_l: "https://nandbox.com/wp-content/uploads/2024/12/Messenger.__iOS_L.webp",
      ios_url_s: "https://nandbox.com/wp-content/uploads/2024/12/Messenger.__iOS_S.webp",

      dark_android_url_f:"https://nandbox.com/wp-content/uploads/2024/12/Messenger__Dark_AN_F.webp",
      dark_android_url_l:"https://nandbox.com/wp-content/uploads/2024/12/Messenger__Dark_AN_L.webp",
      dark_android_url_s:"https://nandbox.com/wp-content/uploads/2024/12/Messenger__Dark_AN_S.webp",
      dark_ios_url_f:"https://nandbox.com/wp-content/uploads/2024/12/Messenger.__Dark_iOS_F.webp",
      dark_ios_url_l:"https://nandbox.com/wp-content/uploads/2024/12/Messenger.__Dark_iOS_L.webp",
      dark_ios_url_s:"https://nandbox.com/wp-content/uploads/2024/12/Messenger.__Dark_iOS_S.webp",

      name: "All-in-one Messenger",
      hint: "A full functioning messenger enables users to chat with contact or group and share photos, videos, voice notes, and multimedia files ordered by recent conversions. . Additionally, the messenger supports Video and Audio Calls. ",
      desc:"All-in-one messenger provides a fully native, all-in-one communication experience, supporting seamless one-on-one, group chats and video/audio calls. It offers fast, reliable, and secure messaging integrated directly into your app",
      feature:"70",
      category:"Messaging Features",
      update:0,
      module:
      {
        tabs:[
          TabNames.SUB_MESSENGER_VOICE_MESSAGE_1,
          TabNames.SUB_MESSENGER_VOICE_MESSAGE_2,
          TabNames.SUB_MESSENGER_VOICE_MESSAGE_4,
          TabNames.SUB_MESSENGER_VIDEO_CALL,
          TabNames.SUB_MESSENGER_USER_PROFILE,
          TabNames.SUB_MESSENGER_SEND_VIDEO,
          TabNames.SUB_MESSENGER_SEND_FILE,
          TabNames.SUB_MESSENGER_SEARCH,
          TabNames.SUB_MESSENGER_REPLY_MESSAGE,
          TabNames.SUB_MESSENGER_MULTI_PROFILE,
          TabNames.SUB_MESSENGER_MEDIA_FILE,
          TabNames.SUB_MESSENGER_SEND_PHOTO,
          TabNames.SUB_MESSENGER_SEND_LOCATION,
          TabNames.SUB_MESSENGER_SELECT_MESSAGE,
          TabNames.SUB_MESSENGER_GALLERY,
          TabNames.SUB_MESSENGER_DELETE_MESSAGE,
          TabNames.SUB_MESSENGER_CONTACTS,
          TabNames.SUB_MESSENGER_CHAT_MENU,
          TabNames.SUB_MESSENGER_CHAT_TEXTM,
          TabNames.SUB_MESSENGER_CHAT,
          TabNames.SUB_MESSENGER_AUDIO_FILE,
          TabNames.SUB_MESSENGER_AUDIO_CALL,
          TabNames.SUB_MESSENGER_ATTACH,
          TabNames.SUB_EMOJI,
          TabNames.SUB_COLORED_MESSAGE_CHAT,
          TabNames.SUB_CHANNELS_FORWARD_MESSAGE,
          TabNames.SUB_MESSENGER_CHAT_GROUP,
          TabNames.SUB_CHAT_GROUP_SELECT_MESSAGE,
          TabNames.SUB_CHAT_GROUP_VOICE_MESSAGE,
          TabNames.SUB_CHAT_GROUP_SEND_VOICE_MESSAGE,
          TabNames.SUB_CHAT_GROUP_TEXT_MESSAGE,
          TabNames.SUB_CHAT_GROUP_INFO,
          TabNames.SUB_CREATE_CHAT_GROUP,
          TabNames.SUB_CREATE_CHAT_GROUP_INFO,
          TabNames.SUB_CHAT_GROUP_ATTACH,
          TabNames.SUB_CHAT_GROUP_EMOJE,
          TabNames.SUB_CHAT_GROUP_NOTIFICATION,
          TabNames.SUB_CHAT_GROUP_REPLIES,
          TabNames.SUB_CHAT_GROUP_VOICE_MESSAGE_CHECK,
          TabNames.SUB_CHAT_GROUP_TEXT_MESSAGE_BACKGROUND,
          TabNames.SUB_CHAT_GROUP_QR,
          TabNames.SUB_CHAT_GROUP_INVITE_ADMIN_PARTICIPANTS,
          TabNames.SUB_CHAT_GROUP_USER_PERMISSIONS,
          TabNames.SUB_CHAT_GROUP_SEND_PHOTO

        ],
        inputIds:[TabNames.SUB_MESSENGER_CHAT,TabNames.SUB_MESSENGER_CHAT_MENU, TabNames.SUB_MESSENGER_CHAT_GROUP],
        links:[
          {outputId:TabNames.SUB_MESSENGER_CHAT,  outDir:"right",  inputId:TabNames.SUB_MESSENGER_VOICE_MESSAGE_4,   inputDir:"auto" },
          {outputId:TabNames.SUB_MESSENGER_CHAT,  outDir:"right",  inputId:TabNames.SUB_MESSENGER_ATTACH,   inputDir:"auto" },
          {outputId:TabNames.SUB_MESSENGER_CHAT,  outDir:"right",  inputId:TabNames.SUB_EMOJI,   inputDir:"auto" },
          {outputId:TabNames.SUB_MESSENGER_CHAT,  outDir:"right",  inputId:TabNames.SUB_MESSENGER_VIDEO_CALL,   inputDir:"auto" },
          {outputId:TabNames.SUB_MESSENGER_CHAT,  outDir:"right",  inputId:TabNames.SUB_MESSENGER_AUDIO_CALL,   inputDir:"auto" },
          {outputId:TabNames.SUB_MESSENGER_CHAT,  outDir:"right",  inputId:TabNames.SUB_MESSENGER_CHAT_TEXTM,   inputDir:"auto" },
          {outputId:TabNames.SUB_MESSENGER_CHAT,  outDir:"right",  inputId:TabNames.SUB_MESSENGER_USER_PROFILE,   inputDir:"auto" },
          {outputId:TabNames.SUB_MESSENGER_CHAT,  outDir:"right",  inputId:TabNames.SUB_MESSENGER_SEARCH,   inputDir:"auto" },
          {outputId:TabNames.SUB_MESSENGER_CHAT,  outDir:"right",  inputId:TabNames.SUB_MESSENGER_SELECT_MESSAGE,   inputDir:"auto" },
          {outputId:TabNames.SUB_MESSENGER_CHAT,  outDir:"right",  inputId:TabNames.SUB_CREATE_CHAT_GROUP,   inputDir:"auto" },

          {outputId:TabNames.SUB_MESSENGER_CHAT_TEXTM,  outDir:"right",  inputId:TabNames.SUB_COLORED_MESSAGE_CHAT,   inputDir:"auto" },
          {outputId:TabNames.SUB_MESSENGER_VOICE_MESSAGE_4,  outDir:"right",  inputId:TabNames.SUB_MESSENGER_VOICE_MESSAGE_2,   inputDir:"auto" },
          {outputId:TabNames.SUB_MESSENGER_VOICE_MESSAGE_2,  outDir:"right",  inputId:TabNames.SUB_MESSENGER_VOICE_MESSAGE_1,   inputDir:"auto" },
          {outputId:TabNames.SUB_MESSENGER_ATTACH,  outDir:"right",  inputId:TabNames.SUB_MESSENGER_SEND_PHOTO,   inputDir:"auto" },
          {outputId:TabNames.SUB_MESSENGER_ATTACH,  outDir:"right",  inputId:TabNames.SUB_MESSENGER_GALLERY,   inputDir:"auto" },
          {outputId:TabNames.SUB_MESSENGER_ATTACH,  outDir:"right",  inputId:TabNames.SUB_MESSENGER_SEND_VIDEO,   inputDir:"auto" },
          {outputId:TabNames.SUB_MESSENGER_ATTACH,  outDir:"right",  inputId:TabNames.SUB_MESSENGER_AUDIO_FILE,   inputDir:"auto" },
          {outputId:TabNames.SUB_MESSENGER_ATTACH,  outDir:"right",  inputId:TabNames.SUB_MESSENGER_SEND_LOCATION,   inputDir:"auto" },
          {outputId:TabNames.SUB_MESSENGER_ATTACH,  outDir:"right",  inputId:TabNames.SUB_MESSENGER_CONTACTS,   inputDir:"auto" },
          {outputId:TabNames.SUB_MESSENGER_ATTACH,  outDir:"right",  inputId:TabNames.SUB_MESSENGER_SEND_FILE,   inputDir:"auto" },
          {outputId:TabNames.SUB_MESSENGER_USER_PROFILE,  outDir:"right",  inputId:TabNames.SUB_MESSENGER_MEDIA_FILE,   inputDir:"auto" },
          {outputId:TabNames.SUB_MESSENGER_SELECT_MESSAGE,  outDir:"right",  inputId:TabNames.SUB_MESSENGER_DELETE_MESSAGE,   inputDir:"auto" },
          {outputId:TabNames.SUB_MESSENGER_SELECT_MESSAGE,  outDir:"right",  inputId:TabNames.SUB_MESSENGER_REPLY_MESSAGE,   inputDir:"auto" },
          {outputId:TabNames.SUB_MESSENGER_SELECT_MESSAGE,  outDir:"right",  inputId:TabNames.SUB_CHANNELS_FORWARD_MESSAGE,   inputDir:"auto" },


          {outputId:TabNames.SUB_MESSENGER_CHAT_GROUP,  outDir:"right",  inputId:TabNames.SUB_CHAT_GROUP_VOICE_MESSAGE,   inputDir:"auto" },
          {outputId:TabNames.SUB_MESSENGER_CHAT_GROUP,  outDir:"right",  inputId:TabNames.SUB_CHAT_GROUP_ATTACH,   inputDir:"auto" },
          {outputId:TabNames.SUB_MESSENGER_CHAT_GROUP,  outDir:"right",  inputId:TabNames.SUB_CHAT_GROUP_EMOJE,   inputDir:"auto" },
          {outputId:TabNames.SUB_MESSENGER_CHAT_GROUP,  outDir:"right",  inputId:TabNames.SUB_CHAT_GROUP_TEXT_MESSAGE,   inputDir:"auto" },
          {outputId:TabNames.SUB_MESSENGER_CHAT_GROUP,  outDir:"right",  inputId:TabNames.SUB_CHAT_GROUP_INFO,   inputDir:"auto" },
          {outputId:TabNames.SUB_MESSENGER_CHAT_GROUP,  outDir:"right",  inputId:TabNames.SUB_CHAT_GROUP_NOTIFICATION,   inputDir:"auto" },
          {outputId:TabNames.SUB_MESSENGER_CHAT_GROUP,  outDir:"right",  inputId:TabNames.SUB_CHAT_GROUP_SELECT_MESSAGE,   inputDir:"auto" },
          {outputId:TabNames.SUB_CHAT_GROUP_SELECT_MESSAGE,  outDir:"right",  inputId:TabNames.SUB_CHAT_GROUP_REPLIES,   inputDir:"auto" },
          {outputId:TabNames.SUB_CHAT_GROUP_VOICE_MESSAGE,  outDir:"right",  inputId:TabNames.SUB_CHAT_GROUP_SEND_VOICE_MESSAGE,   inputDir:"auto" },
          {outputId:TabNames.SUB_CHAT_GROUP_SEND_VOICE_MESSAGE,  outDir:"right",  inputId:TabNames.SUB_CHAT_GROUP_VOICE_MESSAGE_CHECK,   inputDir:"auto" },
          {outputId:TabNames.SUB_CHAT_GROUP_TEXT_MESSAGE,  outDir:"right",  inputId:TabNames.SUB_CHAT_GROUP_TEXT_MESSAGE_BACKGROUND,   inputDir:"auto" },
          {outputId:TabNames.SUB_MESSENGER_CHAT_GROUP,  outDir:"right",  inputId:TabNames.SUB_CHAT_GROUP_INFO,   inputDir:"auto" },
          {outputId:TabNames.SUB_CHAT_GROUP_INFO,  outDir:"right",  inputId:TabNames.SUB_MESSENGER_MEDIA_FILE,   inputDir:"auto" },
          {outputId:TabNames.SUB_CREATE_CHAT_GROUP,  outDir:"right",  inputId:TabNames.SUB_CREATE_CHAT_GROUP_INFO,   inputDir:"auto" },
          {outputId:TabNames.SUB_CREATE_CHAT_GROUP_INFO,  outDir:"right",  inputId:TabNames.SUB_CHAT_GROUP_QR,   inputDir:"auto" },
          {outputId:TabNames.SUB_CREATE_CHAT_GROUP_INFO,  outDir:"right",  inputId:TabNames.SUB_CHAT_GROUP_INVITE_ADMIN_PARTICIPANTS,   inputDir:"auto" },
          {outputId:TabNames.SUB_CREATE_CHAT_GROUP_INFO,  outDir:"right",  inputId:TabNames.SUB_CHAT_GROUP_USER_PERMISSIONS,   inputDir:"auto" },
          {outputId:TabNames.SUB_CHAT_GROUP_ATTACH,  outDir:"right",  inputId:TabNames.SUB_MESSENGER_SEND_PHOTO,   inputDir:"auto" },
          {outputId:TabNames.SUB_CHAT_GROUP_ATTACH,  outDir:"right",  inputId:TabNames.SUB_MESSENGER_GALLERY,   inputDir:"auto" },
          {outputId:TabNames.SUB_CHAT_GROUP_ATTACH,  outDir:"right",  inputId:TabNames.SUB_MESSENGER_SEND_VIDEO,   inputDir:"auto" },
          {outputId:TabNames.SUB_CHAT_GROUP_ATTACH,  outDir:"right",  inputId:TabNames.SUB_MESSENGER_AUDIO_FILE,   inputDir:"auto" },
          {outputId:TabNames.SUB_CHAT_GROUP_ATTACH,  outDir:"right",  inputId:TabNames.SUB_MESSENGER_SEND_LOCATION,   inputDir:"auto" },
          {outputId:TabNames.SUB_CHAT_GROUP_ATTACH,  outDir:"right",  inputId:TabNames.SUB_MESSENGER_CONTACTS,   inputDir:"auto" },
          {outputId:TabNames.SUB_CHAT_GROUP_ATTACH,  outDir:"right",  inputId:TabNames.SUB_MESSENGER_SEND_FILE,   inputDir:"auto" },
          {outputId:TabNames.SUB_MESSENGER_SEND_PHOTO,  outDir:"right",  inputId:TabNames.SUB_CHAT_GROUP_SEND_PHOTO,   inputDir:"auto" },
          {outputId:TabNames.SUB_MESSENGER_GALLERY,  outDir:"right",  inputId:TabNames.SUB_CHAT_GROUP_SEND_PHOTO,   inputDir:"auto" },

        ],
        ouputIds:[],
      }
    }
  },
  [TabNames.OPENBOT]: {
    type: TabNames.OPENBOT,
    module: TabNames.OPENBOT,
    title: "Bot",
    title_ios: "Bot",
    icon: "mir_account_circle",
    icon_ios: "mir_account_circle",
    web:{
      android_url_f:"https://nandbox.com/wp-content/uploads/2024/12/Bot__Chat_AN_F.webp",
      android_url_l:"https://nandbox.com/wp-content/uploads/2024/12/Bot__Chat_AN_L.webp",
      android_url_s:"https://nandbox.com/wp-content/uploads/2024/12/Bot__Chat_AN_S.webp",
      ios_url_f: "https://nandbox.com/wp-content/uploads/2024/12/Bot__Chat__iOS_F.webp",
      ios_url_l:"https://nandbox.com/wp-content/uploads/2024/12/Bot__Chat__iOS_L.webp",
      ios_url_s:"https://nandbox.com/wp-content/uploads/2024/12/Bot__Chat__iOS_S.webp",

      dark_android_url_f:"https://nandbox.com/wp-content/uploads/2024/12/Bot__Chat__Dark_AN_F.webp",
      dark_android_url_l:"https://nandbox.com/wp-content/uploads/2024/12/Bot__Chat__Dark_AN_L.webp",
      dark_android_url_s:"https://nandbox.com/wp-content/uploads/2024/12/Bot__Chat__Dark_AN_S.webp",
      dark_ios_url_f:"https://nandbox.com/wp-content/uploads/2024/12/Bot__Chat_Dark_iOS_F.webp",
      dark_ios_url_l:"https://nandbox.com/wp-content/uploads/2024/12/Bot__Chat_Dark_iOS_L.webp",
      dark_ios_url_s:"https://nandbox.com/wp-content/uploads/2024/12/Bot__Chat_Dark_iOS_S.webp",

      name: "Chatbot",
      hint: "Enable automated messaging with customizable bots.",
      desc:"The chatbot feature allows you to integrate intelligent assistants into your chats by adding multiple bots from the marketplace or creating your own, automating tasks and enhancing user interactions seamlessly.",
      feature:"50",
      category:"Messaging Features",
      update:0,
      module:
      {
        tabs:[
          ],
        inputIds:[],
        links:[
        ],
        ouputIds:[],
      }
    }
  },
  [TabNames.CONTACT]: {
    type: TabNames.CONTACT,
    module: TabNames.CONTACT,
    icon: "mir_person_add",
    icon_ios: "mir_person_add",
    title: "Contacts",
    title_ios: "Contacts",
    show_invite_friends: 1,

    web:{
      android_url_f:"https://nandbox.com/wp-content/uploads/2024/12/User__Contacts_AN_F.webp",
      android_url_l:"https://nandbox.com/wp-content/uploads/2024/12/User__Contacts_AN_L.webp",
      android_url_s:"https://nandbox.com/wp-content/uploads/2024/12/User__Contacts_AN_S.webp",
      ios_url_f: "https://nandbox.com/wp-content/uploads/2024/12/User__Contacts__iOS_F.webp",
      ios_url_l: "https://nandbox.com/wp-content/uploads/2024/12/User__Contacts__iOS_L.webp",
      ios_url_s: "https://nandbox.com/wp-content/uploads/2024/12/User__Contacts__iOS_S.webp",

      dark_android_url_f:"https://nandbox.com/wp-content/uploads/2024/12/User-Contacts__Dark_AN_F.webp",
      dark_android_url_l:"https://nandbox.com/wp-content/uploads/2024/12/User-Contacts__Dark_AN_L.webp",
      dark_android_url_s:"https://nandbox.com/wp-content/uploads/2024/12/User-Contacts__Dark_AN_S.webp",
      dark_ios_url_f:"https://nandbox.com/wp-content/uploads/2024/12/User__Contacts_Dark_iOS_F.webp",
      dark_ios_url_l:"https://nandbox.com/wp-content/uploads/2024/12/User__Contacts_Dark_iOS_L.webp",
      dark_ios_url_s:"https://nandbox.com/wp-content/uploads/2024/12/User__Contacts_Dark_iOS_S.webp",

      name: "Contacts Manager",
      hint: "Access, add, and manage user contacts.",
      desc:"The Contacts Manager provides a fully native solution to manage your contacts, enabling seamless communication. It supports features like contact syncing, searching, and user invitation for efficient and streamlined interactions.",
      feature:"51",
      category:"Messaging Features",
      update:0
    }
  },
  [TabNames.SPEEDDIALER]: {
    type: TabNames.SPEEDDIALER,
    module: TabNames.SPEEDDIALER,
    title: "Calls",
    title_ios: "Calls",
    icon: "mir_phone_in_talk",
    icon_ios: "mir_phone_in_talk",
    web:{
      android_url_f: "https://nandbox.com/wp-content/uploads/2024/12/Calls-Dialer_AN_F.webp",
      android_url_l: "https://nandbox.com/wp-content/uploads/2024/12/Calls-Dialer_AN_L.webp",
      android_url_s: "https://nandbox.com/wp-content/uploads/2024/12/Calls-Dialer_AN_S.webp",
      ios_url_f: "https://nandbox.com/wp-content/uploads/2024/12/Calls-_Dialer_iOS_F.webp",
      ios_url_l: "https://nandbox.com/wp-content/uploads/2024/12/Calls-_Dialer_iOS_L.webp",
      ios_url_s: "https://nandbox.com/wp-content/uploads/2024/12/Calls-_Dialer_iOS_S.webp",


      dark_android_url_f:"https://nandbox.com/wp-content/uploads/2024/12/Calls-Dialer_Dark_AN_F.webp",
      dark_android_url_l:"https://nandbox.com/wp-content/uploads/2024/12/Calls-Dialer_Dark_AN_L.webp",
      dark_android_url_s:"https://nandbox.com/wp-content/uploads/2024/12/Calls-Dialer_Dark_AN_S.webp",
      dark_ios_url_f:"https://nandbox.com/wp-content/uploads/2024/12/Calls-Dialer_Dark_iOS_F.webp",
      dark_ios_url_l:"https://nandbox.com/wp-content/uploads/2024/12/Calls-Dialer_Dark_iOS_L.webp",
      dark_ios_url_s:"https://nandbox.com/wp-content/uploads/2024/12/Calls-Dialer_Dark_iOS_S.webp",

      name: "Call Manager",
      hint: "Place and manage audio or video calls.",
      desc:"The Call Manager provides a fully native solution for managing audio and video calls, offering seamless connectivity with high-quality communication features directly integrated into your app.",
      feature:"23",
      category:"Messaging Features",
      update:0

    }
  },
  [TabNames.CALL_LOG]: {
    type: TabNames.CALL_LOG,
    title: "History",
    title_ios: "History",
    icon: "mir_call",
    icon_ios: "mir_call",
    web:{
      android_url_f:"https://nandbox.com/wp-content/uploads/2024/12/Call-History_AN_F.webp",
      android_url_l:"https://nandbox.com/wp-content/uploads/2024/12/Call-History_AN_L.webp",
      android_url_s:"https://nandbox.com/wp-content/uploads/2024/12/Call-History_AN_S.webp",
      ios_url_f: "https://nandbox.com/wp-content/uploads/2024/12/Call-_History_iOS_F.webp",
      ios_url_l:"https://nandbox.com/wp-content/uploads/2024/12/Call-_History_iOS_L.webp",
      ios_url_s: "https://nandbox.com/wp-content/uploads/2024/12/Call-_History_iOS_S.webp",

      dark_android_url_f:"https://nandbox.com/wp-content/uploads/2024/12/Call-History_Dark_AN_F.webp",
      dark_android_url_l:"https://nandbox.com/wp-content/uploads/2024/12/Call-History_Dark_AN_L.webp",
      dark_android_url_s:"https://nandbox.com/wp-content/uploads/2024/12/Call-History_Dark_AN_S.webp",
      dark_ios_url_f:"https://nandbox.com/wp-content/uploads/2024/12/Call-History_Dark_iOS_F.webp",
      dark_ios_url_l:"https://nandbox.com/wp-content/uploads/2024/12/Call-History_Dark_iOS_L.webp",
      dark_ios_url_s:"https://nandbox.com/wp-content/uploads/2024/12/Call-History_Dark_iOS_S.webp",

      name: "Call Logs",
      hint: "View a detailed history of user calls.",
      desc:"The Call Logs feature provides a comprehensive track record of all incoming, outgoing, and missed audio and video calls, enabling users to easily view and manage their call history directly within the app.",

      feature:"17",
      category:"Messaging Features",
      update:0
    }
  },
  [TabNames.GROUP]: {
    type: TabNames.GROUP,
    module: TabNames.GROUP,
    title: "Groups",
    title_ios: "Groups",
    icon: "mir_group_add",
    icon_ios: "mir_group_add",
    web:{
      android_url_f:"https://nandbox.com/wp-content/uploads/2024/12/Chat-Groups-list_AN_F.webp",
      android_url_l: "https://nandbox.com/wp-content/uploads/2024/12/Chat-Groups-list_AN_L.webp",
      android_url_s: "https://nandbox.com/wp-content/uploads/2024/12/Chat-Groups-list_AN_S.webp",
      ios_url_f: "https://nandbox.com/wp-content/uploads/2024/12/Chat-_Groups-_list_iOS_F.webp",
      ios_url_l:"https://nandbox.com/wp-content/uploads/2024/12/Chat-_Groups-_list_iOS_L.webp",
      ios_url_s: "https://nandbox.com/wp-content/uploads/2024/12/Chat-_Groups-_list_iOS_S.webp",
      dark_android_url_f:"https://nandbox.com/wp-content/uploads/2024/12/App-Channels-List_Dark_AN_F.webp",
      dark_android_url_l:"https://nandbox.com/wp-content/uploads/2024/12/App-Channels-List_Dark_AN_L.webp",
      dark_android_url_s:"https://nandbox.com/wp-content/uploads/2024/12/App-Channels-List_Dark_AN_S.webp",
      dark_ios_url_f:"https://nandbox.com/wp-content/uploads/2024/12/Chat-Group-list_Dark_iOS_F.webp",
      dark_ios_url_l:"https://nandbox.com/wp-content/uploads/2024/12/Chat-Group-list_Dark_iOS_L.webp",
      dark_ios_url_s:"https://nandbox.com/wp-content/uploads/2024/12/Chat-Group-list_Dark_iOS_S.webp",


      name: "Subscribed Groups",
      hint: "Allow users to access their joined group chats, send multimedia files and search throughout their conversations",
      desc:"The Subscribed Groups feature provides a list of all the groups you’ve joined, allowing you to easily view, access, and manage your group memberships directly within the app.",
      feature:"67",
      category:" Group Messaging",
      update:0
    }
  },
  [TabNames.ONLINEGROUP]: {
    type: TabNames.ONLINEGROUP,
    module: TabNames.ONLINEGROUP,
    title: "List",
    title_ios: "List",
    icon: "mir_groups",
    icon_ios: "mir_groups",
    web:{
      android_url_f: "https://nandbox.com/wp-content/uploads/2024/12/All-Groups_AN_F.webp",
      android_url_l: "https://nandbox.com/wp-content/uploads/2024/12/All-Groups_AN_L.webp",
      android_url_s: "https://nandbox.com/wp-content/uploads/2024/12/All-Groups_AN_S.webp",

      ios_url_f:"https://nandbox.com/wp-content/uploads/2024/12/All-Groups_iOS_F.webp",
      ios_url_l:"https://nandbox.com/wp-content/uploads/2024/12/All-Groups_iOS_L.webp",
      ios_url_s: "https://nandbox.com/wp-content/uploads/2024/12/All-Groups_iOS_S.webp",

      dark_android_url_f:"https://nandbox.com/wp-content/uploads/2024/12/All-Groups_Dark_AN_F.webp",
      dark_android_url_l:"https://nandbox.com/wp-content/uploads/2024/12/All-Groups_Dark_AN_L.webp",
      dark_android_url_s:"https://nandbox.com/wp-content/uploads/2024/12/All-Groups_Dark_AN_S.webp",
      dark_ios_url_f:"https://nandbox.com/wp-content/uploads/2024/12/All-Groups_Dark_iOS_F.webp",
      dark_ios_url_l:"https://nandbox.com/wp-content/uploads/2024/12/All-Groups_Dark_iOS_L.webp",
      dark_ios_url_s:"https://nandbox.com/wp-content/uploads/2024/12/All-Groups_Dark_iOS_S.webp",

      name: "All Groups",
      hint: "List and access all available groups.",
      desc:"The All Groups feature provides a comprehensive list of all available groups within the app, allowing users to explore, join, and manage group memberships effortlessly.",
      feature:"77",
      category:" Group Messaging",
      update:0
    }
  },
  [TabNames.STORE]: {
    type: TabNames.MENU,
    module: TabNames.STORE,
    title: "Store",
    title_ios: "Store",
    icon: "mir_shopping_cart",
    icon_ios: "mir_shopping_cart",
    use_default:1,
   web:{
    android_url_f:"",
    android_url_l:"",
    android_url_s:"",
    ios_url_f: "",
    ios_url_l: "",
    ios_url_s: "",
    dark_android_url_f:"",
    dark_android_url_l:"",
    dark_android_url_s:"",
    dark_ios_url_f:"",
    dark_ios_url_l:"",
    dark_ios_url_s:"",
    name: "Shopping Hub",
    hint: "Add a fully functional native shopping experience.",
    desc:"The Shopping Hub feature allows you to define and manage your store, create product collections, and showcase your items seamlessly. It provides a fully native, intuitive interface for users to browse, explore, and shop directly within the app",
    feature:"38",
    category:"Commerece",
    update:0
   }
  },
  [TabNames.MARKET]: {
    type: TabNames.MENU,
    module: TabNames.MARKET,
    title: "Booking",
    title_ios: "Booking",
    icon: "mir_shopping_cart",
    icon_ios: "mir_shopping_cart",
    web:{
      android_url_f:"",
      android_url_l:"",
      android_url_s:"",
      ios_url_f: "",
      ios_url_l: "",
      ios_url_s: "",


      dark_android_url_f:"",
      dark_android_url_l:"",
      dark_android_url_s:"",
      dark_ios_url_f:"",
      dark_ios_url_l:"",
      dark_ios_url_s:"",

      name: "Booking & Event Center",
      hint: "Browse and book events or services seamlessly.",
      desc:"A versatile feature that enables users to browse, book, and manage events or services effortlessly. It provides a centralized hub for event details, schedules, and bookings, all seamlessly integrated into your app.",
      feature:"56",
      category:"Booking & Event",
      update:0
    }
  },
  [TabNames.CALENDAR]: {
    type: TabNames.CALENDAR,
    module: TabNames.CALENDAR,
    title: "Calendar",
    title_ios: "Calendar",
    icon: "mir_calendar_month",
    icon_ios: "mir_calendar_month",
    web:{
      android_url_f:"https://nandbox.com/wp-content/uploads/2024/12/My-Calendar_AN_F.webp",
      android_url_l: "https://nandbox.com/wp-content/uploads/2024/12/My-Calendar_AN_L.webp",
      android_url_s: "https://nandbox.com/wp-content/uploads/2024/12/My-Calendar_AN_S.webp",
      ios_url_f: "https://nandbox.com/wp-content/uploads/2024/12/My-_Calendar_iOS_F.webp",
      ios_url_l: "https://nandbox.com/wp-content/uploads/2024/12/My-_Calendar_iOS_L.webp",
      ios_url_s: "https://nandbox.com/wp-content/uploads/2024/12/My-_Calendar_iOS_S.webp",


      dark_android_url_f:"https://nandbox.com/wp-content/uploads/2024/12/My-Calendar_Dark_AN_F.webp",
      dark_android_url_l:"https://nandbox.com/wp-content/uploads/2024/12/My-Calendar_Dark_AN_L.webp",
      dark_android_url_s:"https://nandbox.com/wp-content/uploads/2024/12/My-Calendar_Dark_AN_S.webp",
      dark_ios_url_f:"https://nandbox.com/wp-content/uploads/2024/12/My-Calendar_Dark_iOS_F.webp",
      dark_ios_url_l:"https://nandbox.com/wp-content/uploads/2024/12/My-Calendar_Dark_iOS_L.webp",
      dark_ios_url_s:"https://nandbox.com/wp-content/uploads/2024/12/My-Calendar_Dark_iOS_S.webp",

      name: "Smart Calendar",
      hint: "Provide scheduling tools with event management.",
      desc:"The Smart Calendar helps schedule, manage, and track events or tasks efficiently within the app. It seamlessly syncs with the user's mobile device calendar for an integrated experience.",
      feature:"5",
      category:"Essentials Features",
      update:0
    }
  },
  [TabNames.INVITATION]: {
    type: TabNames.INVITATION,
    module: TabNames.INVITATION,
    title: "Invites",
    title_ios: "Invites",
    icon: "mir_event",
    icon_ios: "mir_event",
    web:{
      android_url_f:"https://nandbox.com/wp-content/uploads/2024/12/Invitation-Dashboard_AN_F.webp",
      android_url_l: "https://nandbox.com/wp-content/uploads/2024/12/Invitation-Dashboard_AN_L.webp",
      android_url_s: "https://nandbox.com/wp-content/uploads/2024/12/Invitation-Dashboard_AN_S.webp",


      ios_url_f:"https://nandbox.com/wp-content/uploads/2024/12/Invitation-_Dashboard_iOS_F.webp",
      ios_url_l:"https://nandbox.com/wp-content/uploads/2024/12/Invitation-_Dashboard_iOS_L.webp",
      ios_url_s:"https://nandbox.com/wp-content/uploads/2024/12/Invitation-_Dashboard_iOS_S.webp",

      dark_android_url_f:"https://nandbox.com/wp-content/uploads/2024/12/Invitation-Dashboard_Dark_AN_F.webp",
      dark_android_url_l:"https://nandbox.com/wp-content/uploads/2024/12/Invitation-Dashboard_Dark_AN_L.webp",
      dark_android_url_s:"https://nandbox.com/wp-content/uploads/2024/12/Invitation-Dashboard_Dark_AN_S.webp",
      dark_ios_url_f:"https://nandbox.com/wp-content/uploads/2024/12/Invitation-Dashboard_Dark_iOS_F.webp",
      dark_ios_url_l:"https://nandbox.com/wp-content/uploads/2024/12/Invitation-Dashboard_Dark_iOS_L.webp",
      dark_ios_url_s:"https://nandbox.com/wp-content/uploads/2024/12/Invitation-Dashboard_Dark_iOS_S.webp",

      name: "Invitation Manager",
      hint: "Enable users to send, manage, and respond to invitations for channels, groups, chats, or role upgrades.",
      desc:"The Invitation Manager enables users to manage invitations by accepting or rejecting requests to join groups, channels, or become contacts, ensuring seamless control over interactions.",
      feature:"2",
      category:"Essentials Features",
      update:0
    }
  },
  [TabNames.QR]: {
    type: TabNames.QR,
    module: TabNames.QR,
    title: "QR",
    title_ios: "Qr",
    icon: "mir_qr_code_2",
    icon_ios: "mir_qr_code_2",
    generic_qr: { enable_share: 0,  enable_open_web: 0,  enable_copy: 0,  },
    web:{
      // android_url_f:"https://nandbox.com/wp-content/uploads/2024/12/QR-_Code-_AN_F.webp",
      // android_url_l:  "https://nandbox.com/wp-content/uploads/2024/12/QR-_Code-_AN_L.webp",
      // android_url_s:   "https://nandbox.com/wp-content/uploads/2024/12/QR-_Code-_AN_S.webp",
      // ios_url_f: "https://nandbox.com/wp-content/uploads/2024/12/QR-_Code-_iOS_F.webp",
      // ios_url_l:"https://nandbox.com/wp-content/uploads/2024/12/QR-_Code-_iOS_L.webp",
      // ios_url_s:"https://nandbox.com/wp-content/uploads/2024/12/QR-_Code-_iOS_S.webp",

      // dark_android_url_f:"https://nandbox.com/wp-content/uploads/2024/12/QR-_Code-Dark_AN_F.webp",
      // dark_android_url_l:"https://nandbox.com/wp-content/uploads/2024/12/QR-_Code-Dark_AN_L.webp",
      // dark_android_url_s:"https://nandbox.com/wp-content/uploads/2024/12/QR-_Code-Dark_AN_S.webp",

      // dark_ios_url_f:"https://nandbox.com/wp-content/uploads/2024/12/QR-_Code_Dark_iOS_F.webp",
      // dark_ios_url_l:"https://nandbox.com/wp-content/uploads/2024/12/QR-_Code_Dark_iOS_L.webp",
      // dark_ios_url_s:"https://nandbox.com/wp-content/uploads/2024/12/QR-_Code_Dark_iOS_S.webp",

      android_url_f:"https://nandbox.com/wp-content/uploads/2024/12/QR-Code_AN_F.webp",
      android_url_l:"https://nandbox.com/wp-content/uploads/2024/12/QR-Code_AN_L.webp",
      android_url_s:"https://nandbox.com/wp-content/uploads/2024/12/QR-Code_AN_S.webp",
      ios_url_f: "https://nandbox.com/wp-content/uploads/2024/12/QR-__Code_iOS_F.webp",
      ios_url_l: "https://nandbox.com/wp-content/uploads/2024/12/QR-__Code_iOS_L.webp",
      ios_url_s: "https://nandbox.com/wp-content/uploads/2024/12/QR-__Code_iOS_S.webp",

      dark_android_url_f:"https://nandbox.com/wp-content/uploads/2024/12/QR-Code_Dark_AN_F.webp",
      dark_android_url_l:"https://nandbox.com/wp-content/uploads/2024/12/QR-Code_Dark_AN_L.webp",
      dark_android_url_s:"https://nandbox.com/wp-content/uploads/2024/12/QR-Code_Dark_AN_S.webp",
      dark_ios_url_f:"https://nandbox.com/wp-content/uploads/2024/12/QR-Code_Dark_iOS_F.webp",
      dark_ios_url_l:"https://nandbox.com/wp-content/uploads/2024/12/QR-Code_Dark_iOS_L.webp",
      dark_ios_url_s:"https://nandbox.com/wp-content/uploads/2024/12/QR-Code_Dark_iOS_S.webp",


      opt2_android_url_l:"https://nandbox.com/wp-content/uploads/2024/01/myqr-an-L.webp",
      opt2_android_url_s:"https://nandbox.com/wp-content/uploads/2024/01/myqr-AN_S.webp",
      opt1_android_url_l: "https://nandbox.com/wp-content/uploads/2024/01/qr-link-AN_L.webp",
      opt1_android_url_s:"https://nandbox.com/wp-content/uploads/2024/01/qr-link-an-s.webp",

      opt2_ios_url_l:"https://nandbox.com/wp-content/uploads/2024/01/MyQRCode_iOS_L.webp",
      opt2_ios_url_s:"https://nandbox.com/wp-content/uploads/2024/01/MyQRCode_iOS_S.webp",
      opt1_ios_url_l: "https://nandbox.com/wp-content/uploads/2024/01/QRCodeLink_iOS_L.webp",
      opt1_ios_url_s:"https://nandbox.com/wp-content/uploads/2024/01/QRCodeLink_iOS_S.webp",

      name: "QR Scanner",
      hint: "Add native QR code scanning for quick access.",
      desc:"The QR Scanner unlocks seamless connectivity, allowing users to join the channels, and groups, or log in to the web with a simple scan. It also generates a personalized user QR code, enabling effortless contact sharing and networking.",
      feature:"4",
      category:"Essentials Features",
      update:0
    }
  },
  [TabNames.WEBVIEW]: {
    type: TabNames.WEBVIEW,
    module: TabNames.WEBVIEW,
    title: "Web",
    title_ios: "Web",
    icon: "mir_web",
    icon_ios: "mir_web",
    web_view: {wv_url:null},
    web:{
      name: "Webview",
      hint: "Allow you to redirect users to your website, blog or any external webpage of your choice to be displayed within the App",
      desc:"A feature that allows you to display web content directly within the app, providing a smooth browsing experience without the need to exit the application.",
      feature:"1",
      category:"Essentials Features",
      update:0,
      validate:[
        tab_check_list[TabKeys.WEBVIEW],
       ]
    }
  },

  [TabNames.VIDEO]: {
    type: TabNames.VIDEO,
    module: TabNames.VIDEO,
    title: "Video",
    title_ios: "Video",
    icon: "mir_video_library",
    icon_ios: "mir_video_library",
    bg_color: "#082b44",
    text_color: "#ffffff",
    desc: "play video",
    image_url: "https://nandbox.com/wp-content/uploads/2024/01/video_AN_L.webp",
    web:{
      android_url_f:"",
      android_url_l:"",
      android_url_s:"",
      ios_url_f: "",
      ios_url_l: "",
      ios_url_s: "",
      dark_android_url_f:"",
      dark_android_url_l:"",
      dark_android_url_s:"",
      dark_ios_url_f:"",
      dark_ios_url_l:"",
      dark_ios_url_s:"",
      name: "Video Page",
      hint: "Create a page with links to videos of your choice.",
      desc:"The Video Page provides a dynamic space to showcase engaging video content, offering users a visually immersive view of your app's features and offerings.",
      feature:"2",
      category:"Essentials Features",
      update:0
    }
  },
  [TabNames.MAP_SEARCH]: {
    type: TabNames.MAP_SEARCH,
    module: TabNames.MAP_SEARCH,
    title: "Map",
    title_ios: "Map",
    icon: "mir_map",
    icon_ios: "mir_map",
    web:{
      android_url_f:"https://nandbox.com/wp-content/uploads/2024/12/Map-Search_AN_F.webp",
      android_url_l:"https://nandbox.com/wp-content/uploads/2024/12/Map-Search_AN_L.webp",
      android_url_s:"https://nandbox.com/wp-content/uploads/2024/12/Map-Search_AN_S.webp",

      ios_url_f: "https://nandbox.com/wp-content/uploads/2024/12/Map-_Search_iOS_F.webp",
      ios_url_l: "https://nandbox.com/wp-content/uploads/2024/12/Map-_Search_iOS_L.webp",
      ios_url_s: "https://nandbox.com/wp-content/uploads/2024/12/Map-_Search_iOS_S.webp",

      dark_android_url_f:"https://nandbox.com/wp-content/uploads/2024/12/Map-Search_Dark_AN_F.webp",
      dark_android_url_l:"https://nandbox.com/wp-content/uploads/2024/12/Map-Search_Dark_AN_L.webp",
      dark_android_url_s:"https://nandbox.com/wp-content/uploads/2024/12/Map-Search_Dark_AN_S.webp",
      dark_ios_url_f:"https://nandbox.com/wp-content/uploads/2024/12/Map-Search_Dark_iOS_F.webp",
      dark_ios_url_l:"https://nandbox.com/wp-content/uploads/2024/12/Map-Search_Dark_iOS_L.webp",
      dark_ios_url_s:"https://nandbox.com/wp-content/uploads/2024/12/Map-Search_Dark_iOS_S.webp",
      name: "Interactive Map Search",
      hint: "Search and locate results on a map interface.",
      desc:"",
      feature:"",
      category:"",
      update:0
    }
  },
  [TabNames.SEARCH]: {
    type: TabNames.SEARCH,
    module: TabNames.SEARCH,
    icon: "mir_saved_search",
    icon_ios: "mir_saved_search",
    title: "Search",
    title_ios: "Search",
    search: {
      filter: [],
      search: [],
      search_by_time: 0,
      search_btn_icon: "mir_search",
      page_index: 1,
      search_text_hint: "What are you searching for?",
      search_by_date: 0,
       },
   web:{
    android_url_f:"",
    android_url_l:"",
    android_url_s:"",
    ios_url_f: "",
    ios_url_l: "",
    ios_url_s: "",

    dark_android_url_f:"",
    dark_android_url_l:"",
    dark_android_url_s:"",
    dark_ios_url_f:"",
    dark_ios_url_l:"",
    dark_ios_url_s:"",

    name: "Content Search",
    hint: "Enable quick searches across app content.",
    desc:"",
    feature:"",
    category:"",
    update:0,
   },

  },
  [TabNames.QUERY]: {
    type: TabNames.LISTVIEW,
    module: TabNames.QUERY,
    title: "Search",
    title_ios: "Search",
    icon: "mir_saved_search",
    icon_ios: "mir_saved_search",
    web:{
      android_url_f:"https://nandbox.com/wp-content/uploads/2024/12/New-Search-Page_AN_F.webp",
      android_url_l:"https://nandbox.com/wp-content/uploads/2024/12/New-Search-Page_AN_L.webp",
      android_url_s:"https://nandbox.com/wp-content/uploads/2024/12/New-Search-Page_AN_S.webp",
      ios_url_f: "https://nandbox.com/wp-content/uploads/2024/12/New-_Search-_Page_iOS_F.webp",
      ios_url_l: "https://nandbox.com/wp-content/uploads/2024/12/New-_Search-_Page_iOS_L.webp",
      ios_url_s: "https://nandbox.com/wp-content/uploads/2024/12/New-_Search-_Page_iOS_S.webp",
      dark_android_url_f:"https://nandbox.com/wp-content/uploads/2024/12/New-Search-Page_Dark_AN_F.webp",
      dark_android_url_l:"https://nandbox.com/wp-content/uploads/2024/12/New-Search-Page_Dark_AN_L.webp",
      dark_android_url_s:"https://nandbox.com/wp-content/uploads/2024/12/New-Search-Page_Dark_AN_S.webp",
      dark_ios_url_f:"https://nandbox.com/wp-content/uploads/2024/12/New-Search-Page_Dark_iOS_F.webp",
      dark_ios_url_l:"https://nandbox.com/wp-content/uploads/2024/12/New-Search-Page_Dark_iOS_L.webp",
      dark_ios_url_s:"https://nandbox.com/wp-content/uploads/2024/12/New-Search-Page_Dark_iOS_S.webp",
      name: "List View",
      hint: "Display and manage lists with optional search and filtering functionalities, allowing users to browse, find, and interact with structured data efficiently.",
      desc: "The Lists & Search category includes components that present structured data in a user-friendly manner, offering features like scrolling, filtering, and dynamic search. These components enhance navigation and usability by enabling seamless content discovery within the app.",
      feature:"28",
      category:"Lists & Search",
      update:0,
    }
  },
  [TabNames.SETTINGS]: {
    type: TabNames.SETTINGS,
    module: TabNames.SETTINGS,
    title: "Settings",
    title_ios: "Settings",
    icon: "mir_settings",
    icon_ios: "mir_settings",
    web:{
      android_url_f:"https://nandbox.com/wp-content/uploads/2024/12/App-Settings-_AN_F.webp",
      android_url_l: "https://nandbox.com/wp-content/uploads/2024/12/App-Settings-_AN_L.webp",
      android_url_s: "https://nandbox.com/wp-content/uploads/2024/12/App-Settings-_AN_S.webp",
      ios_url_f: "https://nandbox.com/wp-content/uploads/2024/12/App-Settings-_iOS_F.webp",
      ios_url_l: "https://nandbox.com/wp-content/uploads/2024/12/App-Settings-_iOS_L.webp",
      ios_url_s: "https://nandbox.com/wp-content/uploads/2024/12/App-Settings-_iOS_S.webp",
      dark_android_url_f:"https://nandbox.com/wp-content/uploads/2024/12/App-Settings-Dark_AN_F.webp",
      dark_android_url_l:"https://nandbox.com/wp-content/uploads/2024/12/App-Settings-Dark_AN_L.webp",
      dark_android_url_s:"https://nandbox.com/wp-content/uploads/2024/12/App-Settings-Dark_AN_S.webp",
      dark_ios_url_f:"https://nandbox.com/wp-content/uploads/2024/12/App-Settings__Dark_iOS_F.webp",
      dark_ios_url_l:"https://nandbox.com/wp-content/uploads/2024/12/App-Settings__Dark_iOS_L.webp",
      dark_ios_url_s:"https://nandbox.com/wp-content/uploads/2024/12/App-Settings__Dark_iOS_S.webp",
      name: "App Settings",
      hint: "Allow user to control thier own Downloads, Media storage, Account, Notifications, and Chats settings. This module is essential to get your App approved by Apple.",
      desc:"The App Settings section is essential for app store compliance, enabling users to manage notifications, privacy, account preferences, and storage. It ensures a secure, user-friendly, and efficient experience.",
      feature:"82",
      category:"Essentials Features",
      update:0,
    }
  },
  [TabNames.MYORDERS]: {
    type: TabNames.MYORDERS,
    module: TabNames.MYORDERS,
    title: "Orders",
    title_ios: "Orders",
    icon: "mir_receipt",
    icon_ios: "mir_receipt",
    web:{
      android_url_f:"https://nandbox.com/wp-content/uploads/2024/12/my-orders-_AN_F.webp",
      android_url_l: "https://nandbox.com/wp-content/uploads/2024/12/my-orders-_AN_L.webp",
      android_url_s: "https://nandbox.com/wp-content/uploads/2024/12/my-orders-_AN_S.webp",
      ios_url_f: "https://nandbox.com/wp-content/uploads/2024/12/my-orders-_iOS_F.webp",
      ios_url_l: "https://nandbox.com/wp-content/uploads/2024/12/my-orders-_iOS_L.webp",
      ios_url_s: "https://nandbox.com/wp-content/uploads/2024/12/my-orders-_iOS_S.webp",
      dark_android_url_f:"https://nandbox.com/wp-content/uploads/2024/12/my-orders-Dark_AN_F.webp",
      dark_android_url_l:"https://nandbox.com/wp-content/uploads/2024/12/my-orders-Dark_AN_L.webp",
      dark_android_url_s:"https://nandbox.com/wp-content/uploads/2024/12/my-orders-Dark_AN_S.webp",
      dark_ios_url_f:"https://nandbox.com/wp-content/uploads/2024/12/my-orders_Dark_iOS_F.webp",
      dark_ios_url_l:"https://nandbox.com/wp-content/uploads/2024/12/my-orders_Dark_iOS_L.webp",
      dark_ios_url_s:"https://nandbox.com/wp-content/uploads/2024/12/my-orders_Dark_iOS_S.webp",
      name: "Order Manager Spotlight",
      hint: "Track and manage all orders and purchased services in one place.",
      desc: "Allow users to monitor their active orders, review past purchases, and track service statuses effortlessly. Designed for a seamless post-purchase experience, ensuring users stay informed and in control of their orders.",
      feature:"18",
      category:"Payment Components",
    }
  },
  [TabNames.OPENGROUP]: {
    type: TabNames.OPENGROUP,
    module: TabNames.OPENGROUP,
    title: "Chat Group",
    title_ios: "Chat Group",
    icon: "mir_group",
    icon_ios: "mir_group",
    web:{
      android_url_f:"https://nandbox.com/wp-content/uploads/2024/12/Chat-Groups_AN_F.webp",
      android_url_l:"https://nandbox.com/wp-content/uploads/2024/12/Chat-Groups_AN_L.webp",
      android_url_s:"https://nandbox.com/wp-content/uploads/2024/12/Chat-Groups_AN_S.webp",
      ios_url_f: "https://nandbox.com/wp-content/uploads/2024/12/Chat-_Groups_iOS_F.webp",
      ios_url_l: "https://nandbox.com/wp-content/uploads/2024/12/Chat-_Groups_iOS_L.webp",
      ios_url_s:  "https://nandbox.com/wp-content/uploads/2024/12/Chat-_Groups_iOS_S.webp",
      dark_android_url_f:"https://nandbox.com/wp-content/uploads/2024/12/Chat_Groups_Messages_Dark_AN_F.webp",
      dark_android_url_l:"https://nandbox.com/wp-content/uploads/2024/12/Chat_Groups_Messages_Dark_AN_L.webp",
      dark_android_url_s:"https://nandbox.com/wp-content/uploads/2024/12/Chat_Groups_Messages_Dark_AN_S.webp",
      dark_ios_url_f:"https://nandbox.com/wp-content/uploads/2024/12/Chat-Groups_Dark_AN_F.webp",
      dark_ios_url_l:"https://nandbox.com/wp-content/uploads/2024/12/Chat-Groups_Dark_AN_L.webp",
      dark_ios_url_s:"https://nandbox.com/wp-content/uploads/2024/12/Chat-Groups_Dark_AN_S.webp",
      name: "Chat With A Group",
      hint: "Allow user to chat with a specific group. All group chats created on your App can include up to 10k users",
      desc:"Automatically join users to a specific group, allowing them to chat directly and engage in real-time group communication within your mobile app.",
      feature:"68",
      category:"Group Messaging",
      update:0,
      validate:[
        tab_check_list[TabKeys.CHATID],
       ]

    }
  },


  [TabNames.PRODUCT]: {
    type: TabNames.PRODUCT,
    module: TabNames.PRODUCT,
    title: "Product",
    title_ios: "Prodcut",
    icon: "mir_inventory",
    icon_ios: "mir_inventory",
    web:{
      android_url_f:"https://nandbox.com/wp-content/uploads/2025/01/Products-_AN_F.webp",
      android_url_l:"https://nandbox.com/wp-content/uploads/2025/01/Products-_AN_L.webp",
      android_url_s:"https://nandbox.com/wp-content/uploads/2025/01/Products-_AN_S.webp",
      ios_url_f: "https://nandbox.com/wp-content/uploads/2025/01/Products-_iOS_F.png",
      ios_url_l: "https://nandbox.com/wp-content/uploads/2025/01/Products-_iOS_L.png",
      ios_url_s:  "https://nandbox.com/wp-content/uploads/2025/01/Products-_iOS_S.png",
      dark_android_url_f:"https://nandbox.com/wp-content/uploads/2025/01/Dark_Products-_AN_F.webp",
      dark_android_url_l:"https://nandbox.com/wp-content/uploads/2025/01/Dark_Products-_AN_L.webp",
      dark_android_url_s:"https://nandbox.com/wp-content/uploads/2025/01/Dark_Products-_AN_S.webp",
      dark_ios_url_f:"https://nandbox.com/wp-content/uploads/2025/01/Dark_Products-_iOS_F.png",
      dark_ios_url_l:"https://nandbox.com/wp-content/uploads/2025/01/Dark_Products-_iOS_L.png",
      dark_ios_url_s:"https://nandbox.com/wp-content/uploads/2025/01/Dark_Products-_iOS_S.png",
      name: "Product Spotlight",
      hint: "Highlight a specific product for quick access and engagement.",
      desc: "Showcase a product prominently, allowing users to explore details, view images, and make purchases effortlessly. Ideal for featuring new arrivals, exclusive deals, or bestsellers.",
      feature:"38",
      category:"Commerece",
      update:0,
      validate:[ ]
    }
  },
  [TabNames.COLLECTION]: {
    type: TabNames.COLLECTION,
    module: TabNames.COLLECTION,
    title: "Collection",
    title_ios: "Collection",
    icon: "mir_photo_library",
    icon_ios: "mir_photo_library",
    web:{
      android_url_f:"https://nandbox.com/wp-content/uploads/2025/02/Collection_AN_F.webp",
      android_url_l:"https://nandbox.com/wp-content/uploads/2025/02/Collection_AN_L.webp",
      android_url_s:"https://nandbox.com/wp-content/uploads/2025/02/Collection_AN_S.webp",
      ios_url_f: "https://nandbox.com/wp-content/uploads/2025/02/Collection_iOS_F.webp",
      ios_url_l: "https://nandbox.com/wp-content/uploads/2025/02/Collection_iOS_L.webp",
      ios_url_s:  "https://nandbox.com/wp-content/uploads/2025/02/Collection_iOS_S.webp",
      dark_android_url_f:"https://nandbox.com/wp-content/uploads/2025/02/Dark_Collection_AN_F.webp",
      dark_android_url_l:"https://nandbox.com/wp-content/uploads/2025/02/Dark_Collection_AN_L.webp",
      dark_android_url_s:"https://nandbox.com/wp-content/uploads/2025/02/Dark_Collection_AN_L.webp",
      dark_ios_url_f:"https://nandbox.com/wp-content/uploads/2025/02/Dark_Collection_iOS_F.webp",
      dark_ios_url_l:"https://nandbox.com/wp-content/uploads/2025/02/Dark_Collection_iOS_L.webp",
      dark_ios_url_s:"https://nandbox.com/wp-content/uploads/2025/02/Dark_Collection_iOS_S.webp",
      name: "Collection Showcase",
      hint: "Feature a curated collection of products for easy browsing.",
      desc: "Highlight a structured collection of products, allowing users to explore and shop with ease. Ideal for organizing items by category, brand, or theme for a seamless shopping experience.",
      feature:"42",
      category:"Commerece",
      update:0,
      validate:[
       ]

    }
  },
  [TabNames.CATEGORY]: {
    type: TabNames.CATEGORY,
    module: TabNames.CATEGORY,
    title: "Category",
    title_ios: "Category",
    icon: "mir_category",
    icon_ios: "mir_category",
    web:{
      android_url_f:"https://nandbox.com/wp-content/uploads/2025/01/Category-_AN_F.webp",
      android_url_l:"https://nandbox.com/wp-content/uploads/2025/01/Category-_AN_L.webp",
      android_url_s:"https://nandbox.com/wp-content/uploads/2025/01/Category-_AN_S.webp",
      ios_url_f: "https://nandbox.com/wp-content/uploads/2025/01/Category-_iOS_F.png",
      ios_url_l: "https://nandbox.com/wp-content/uploads/2025/01/Category-_iOS_L.png",
      ios_url_s:  "https://nandbox.com/wp-content/uploads/2025/01/Category-_iOS_S.png",
      dark_android_url_f:"https://nandbox.com/wp-content/uploads/2025/01/Dark_Category-_AN_F.webp",
      dark_android_url_l:"https://nandbox.com/wp-content/uploads/2025/01/Dark_Category-_AN_L.webp",
      dark_android_url_s:"https://nandbox.com/wp-content/uploads/2025/01/Dark_Category-_AN_S.webp",
      dark_ios_url_f:"https://nandbox.com/wp-content/uploads/2025/01/Dark_Category-_iOS_F.png",
      dark_ios_url_l:"https://nandbox.com/wp-content/uploads/2025/01/Dark_Category-_iOS_L.png",
      dark_ios_url_s:"https://nandbox.com/wp-content/uploads/2025/01/Dark_Category-_iOS_S.png",
      name: "Category Showcase",
      hint: "Showcase a selection of theaters, performances, or venues for easy booking.",
      desc: "Organize and present a collection of theaters, shows, or venues, allowing users to browse and book effortlessly. Ideal for professional services, or event spaces where users can choose their preferred experience.",
      feature:"42",
      category:"Booking & Event",
      update:0,
      validate:[
       ]

    }
  },
  [TabNames.BOOKINGPRODUCT]: {
    type: TabNames.BOOKINGPRODUCT,
    module: TabNames.BOOKINGPRODUCT,
    title: "Booking Product",
    title_ios: "Booking Product",
    icon: "mir_book_online",
    icon_ios: "mir_book_online",
    web:{
      android_url_f:"https://nandbox.com/wp-content/uploads/2025/01/Booking-_AN_F.webp",
      android_url_l:"https://nandbox.com/wp-content/uploads/2025/01/Booking-_AN_L.webp",
      android_url_s:"https://nandbox.com/wp-content/uploads/2025/01/Booking-_AN_L.webp",
      ios_url_f: "https://nandbox.com/wp-content/uploads/2025/01/Booking-_iOS_F.png",
      ios_url_l: "https://nandbox.com/wp-content/uploads/2025/01/Booking-_iOS_L.png",
      ios_url_s:  "https://nandbox.com/wp-content/uploads/2025/01/Booking-_iOS_S.png",
      dark_android_url_f:"https://nandbox.com/wp-content/uploads/2025/01/Dark_Booking-_AN_F.webp",
      dark_android_url_l:"https://nandbox.com/wp-content/uploads/2025/01/Dark_Booking-_AN_L.webp",
      dark_android_url_s:"https://nandbox.com/wp-content/uploads/2025/01/Dark_Booking-_AN_S.webp",
      dark_ios_url_f:"https://nandbox.com/wp-content/uploads/2025/01/Dark_Booking-_iOS_F.png",
      dark_ios_url_l:"https://nandbox.com/wp-content/uploads/2025/01/Dark_Booking-_iOS_L.png",
      dark_ios_url_s:"https://nandbox.com/wp-content/uploads/2025/01/Dark_Booking-_iOS_S.png",
      name: "Booking Spotlight",
      hint: "Highlight the main booking service for easy access.",
      desc: "Provide a dedicated booking experience, allowing users to schedule services, appointments, or reservations effortlessly. Designed as the primary booking destination within the app for a seamless and intuitive experience.",
      feature:"38",
      category:"Booking & Event",
      update:0,
      validate:[
       ]

    }
  },
  [TabNames.EVENTPRODUCT]: {
    type: TabNames.EVENTPRODUCT,
    module: TabNames.EVENTPRODUCT,
    title: "Event",
    title_ios: "Event",
    icon: "mir_event_available",
    icon_ios: "mir_event_available",
    web:{
      android_url_f:"https://nandbox.com/wp-content/uploads/2025/01/Event-_AN_F.webp",
      android_url_l:"https://nandbox.com/wp-content/uploads/2025/01/Event-_AN_L.webp",
      android_url_s:"https://nandbox.com/wp-content/uploads/2025/01/Event-_AN_S.webp",
      ios_url_f: "https://nandbox.com/wp-content/uploads/2025/01/Event-_iOS_F.png",
      ios_url_l: "https://nandbox.com/wp-content/uploads/2025/01/Event-_iOS_L.png",
      ios_url_s:  "https://nandbox.com/wp-content/uploads/2025/01/Event-_iOS_S.png",
      dark_android_url_f:"https://nandbox.com/wp-content/uploads/2025/01/Dark_Event-_AN_F.webp",
      dark_android_url_l:"https://nandbox.com/wp-content/uploads/2025/01/Dark_Event-_AN_L.webp",
      dark_android_url_s:"https://nandbox.com/wp-content/uploads/2025/01/Dark_Event-_AN_S.webp",
      dark_ios_url_f:"https://nandbox.com/wp-content/uploads/2025/01/Dark_Event-_iOS_F.png",
      dark_ios_url_l:"https://nandbox.com/wp-content/uploads/2025/01/Dark_Event-_iOS_L.png",
      dark_ios_url_s:"https://nandbox.com/wp-content/uploads/2025/01/Dark_Event-_iOS_S.png",
      name: "Event Spotlight",
      hint: "Highlight and provide direct access to a specific event.",
      desc: "Showcase an event, allowing users to explore details, register, and participate effortlessly. Ideal for promoting conferences, concerts, webinars, or exclusive gatherings within the app.",
      feature:"38",
      category:"Booking & Event",
      update:0,
      validate:[
       ]

    }
  },
  [TabNames.CART]: {
    type: TabNames.CART,
    module: TabNames.CART,
    title: "Cart",
    title_ios: "Cart",
    icon: "mir_shopping_cart",
    icon_ios: "mir_shopping_cart",
    web:{
      android_url_f:"https://nandbox.com/wp-content/uploads/2025/01/Cart-_AN_F.webp",
      android_url_l:"https://nandbox.com/wp-content/uploads/2025/01/Cart-_AN_L.webp",
      android_url_s:"https://nandbox.com/wp-content/uploads/2025/01/Cart-_AN_S.webp",
      ios_url_f: "https://nandbox.com/wp-content/uploads/2025/01/Cart-_iOS_F.png",
      ios_url_l: "https://nandbox.com/wp-content/uploads/2025/01/Cart-_iOS_L.png",
      ios_url_s:  "https://nandbox.com/wp-content/uploads/2025/01/Cart-_iOS_S.png",
      dark_android_url_f:"https://nandbox.com/wp-content/uploads/2025/01/Dark_Cart-_AN_F.webp",
      dark_android_url_l:"https://nandbox.com/wp-content/uploads/2025/01/Dark_Cart-_AN_L.webp",
      dark_android_url_s:"https://nandbox.com/wp-content/uploads/2025/01/Dark_Cart-_AN_S.webp",
      dark_ios_url_f:"https://nandbox.com/wp-content/uploads/2025/01/Dark_Cart-_iOS_F.png",
      dark_ios_url_l:"https://nandbox.com/wp-content/uploads/2025/01/Dark_Cart-_iOS_L.png",
      dark_ios_url_s:"https://nandbox.com/wp-content/uploads/2025/01/Dark_Cart-_iOS_S.png",
      name: "Shopping Cart Spotlight",
      hint: "Provide users with a seamless cart experience for managing their purchases.",
      desc: "Enable users to view, edit, and manage their selected items before checkout. Designed for a smooth shopping experience, allowing easy access to cart details, pricing, and order summary in one place.",
      feature:"22",
      category:"Payment Components",
      update:0,
      validate:[
       ]
    },
  },



/////////////////// ******************** The following tabs only for display only in setting section  *****************//

[TabNames.PAYMENT_VIEW]: {
  type: TabNames.PAYMENT_VIEW,
  module: TabNames.PAYMENT_VIEW,
  title: "Payment",
  title_ios: "Payment",
  icon: "mir_calendar_month",
  icon_ios: "mir_calendar_month",
  web:{
    android_url_f:"https://nandbox.com/wp-content/uploads/2024/12/payment-_AN_F.webp",
    android_url_l:"https://nandbox.com/wp-content/uploads/2024/12/payment-_AN_L.webp",
    android_url_s:"https://nandbox.com/wp-content/uploads/2024/12/payment-_AN_S.webp",
    ios_url_f: "https://nandbox.com/wp-content/uploads/2024/12/payment-_iOS_F.webp",
    ios_url_l: "https://nandbox.com/wp-content/uploads/2024/12/payment-_iOS_L.webp",
    ios_url_s: "https://nandbox.com/wp-content/uploads/2024/12/payment-_iOS_S.webp",

    dark_android_url_f:"https://nandbox.com/wp-content/uploads/2024/12/payment-Dark_AN_F.webp",
    dark_android_url_l:"https://nandbox.com/wp-content/uploads/2024/12/payment-Dark_AN_L.webp",
    dark_android_url_s:"https://nandbox.com/wp-content/uploads/2024/12/payment-Dark_AN_S.webp",
    dark_ios_url_f:"https://nandbox.com/wp-content/uploads/2024/12/payment_Dark_iOS_F.webp",
    dark_ios_url_l:"https://nandbox.com/wp-content/uploads/2024/12/payment_Dark_iOS_L.webp",
    dark_ios_url_s:"https://nandbox.com/wp-content/uploads/2024/12/payment_Dark_iOS_S.webp",

    name: "Smart Calendar",
    hint: "Provide scheduling tools with event management.",
    desc:"",
    feature:"",
    category:"",
    update:0
  }
},


[TabNames.LOGIN_VIEW]: {
  type: TabNames.LOGIN_VIEW,
  module: TabNames.LOGIN_VIEW,
  title: "Login",
  title_ios: "Login",
  icon: "mir_calendar_month",
  icon_ios: "mir_calendar_month",
  web:{
    android_url_f:"https://nandbox.com/wp-content/uploads/2024/12/login-_AN_F.webp",
    android_url_l:"https://nandbox.com/wp-content/uploads/2024/12/login-_AN_L.webp",
    android_url_s:"https://nandbox.com/wp-content/uploads/2024/12/login-_AN_S.webp",
    ios_url_f: "https://nandbox.com/wp-content/uploads/2024/12/login-_iOS_F.webp",
    ios_url_l: "https://nandbox.com/wp-content/uploads/2024/12/login-_iOS_L.webp",
    ios_url_s: "https://nandbox.com/wp-content/uploads/2024/12/login-_iOS_S.webp",

    dark_android_url_f:"https://nandbox.com/wp-content/uploads/2024/12/Android-Login-Dark_AN_F.webp",
    dark_android_url_l:"https://nandbox.com/wp-content/uploads/2024/12/Android-Login-Dark_AN_L.webp",
    dark_android_url_s:"https://nandbox.com/wp-content/uploads/2024/12/Android-Login-Dark_AN_S.webp",
    dark_ios_url_f:"https://nandbox.com/wp-content/uploads/2024/12/login_Dark_iOS_F.webp",
    dark_ios_url_l:"https://nandbox.com/wp-content/uploads/2024/12/login_Dark_iOS_L.webp",
    dark_ios_url_s:"https://nandbox.com/wp-content/uploads/2024/12/login_Dark_iOS_S.webp",

    name: "Smart Calendar",
    hint: "Provide scheduling tools with event management.",
    desc:"",
    feature:"",
    category:"",
    update:0
  }
},









////***************************** end of display Tabs**************************************///
 /////// ***** not yet completed ********** ////
///// *************************************///
/////*************************************///


[TabNames.ONBOARDING]: {
  type: TabNames.OPENCHAT,
  module: TabNames.OPENCHAT,
  title: "Chat Group",
  title_ios: "Chat Group",
  icon: "mir_chat",
  icon_ios: "mir_chat",

  web:{

  android_url_f:"",
  android_url_l:"",
  android_url_s:"",
  ios_url_f: "",
  ios_url_l: "",
  ios_url_s: "",

  dark_android_url_f:"https://nandbox.com/wp-content/uploads/2024/12/Chat_Groups_Dark_AN_F.webp",
  dark_android_url_l:"https://nandbox.com/wp-content/uploads/2024/12/Chat_Groups_Dark_AN_L.webp",
  dark_android_url_s:"https://nandbox.com/wp-content/uploads/2024/12/Chat_Groups_Dark_AN_S.webp",
  // dark_android_url_f:"",
  // dark_android_url_l:"",
  // dark_android_url_s:"",
  dark_ios_url_f:"",
  dark_ios_url_l:"",
  dark_ios_url_s:"",

  name: "Onboarding and Support Group",
  hint: "User chats in private with Group Adminstrator based on one to one chat.",
  desc:"",
  feature:"",
  category:"",
  update:0
  }
},
[TabNames.OPENCHAT]: {
  type: TabNames.OPENCHAT,
  module: TabNames.OPENCHAT,
  title: "Chat Group",
  title_ios: "Chat Group",
  icon: "mir_chat",
  icon_ios: "mir_chat",
  web:{
    android_url_f:"https://nandbox.com/wp-content/uploads/2024/12/Bot__Chat_AN_F.webp",
    android_url_l:"https://nandbox.com/wp-content/uploads/2024/12/Bot__Chat_AN_L.webp",
    android_url_s:"https://nandbox.com/wp-content/uploads/2024/12/Bot__Chat_AN_S.webp",
    ios_url_f: "https://nandbox.com/wp-content/uploads/2024/12/Bot__Chat__iOS_F.webp",
    ios_url_l:"https://nandbox.com/wp-content/uploads/2024/12/Bot__Chat__iOS_L.webp",
    ios_url_s:"https://nandbox.com/wp-content/uploads/2024/12/Bot__Chat__iOS_S.webp",

    dark_android_url_f:"https://nandbox.com/wp-content/uploads/2024/12/Bot__Chat__Dark_AN_F.webp",
    dark_android_url_l:"https://nandbox.com/wp-content/uploads/2024/12/Bot__Chat__Dark_AN_L.webp",
    dark_android_url_s:"https://nandbox.com/wp-content/uploads/2024/12/Bot__Chat__Dark_AN_S.webp",
    dark_ios_url_f:"https://nandbox.com/wp-content/uploads/2024/12/Bot__Chat_Dark_iOS_F.webp",
    dark_ios_url_l:"https://nandbox.com/wp-content/uploads/2024/12/Bot__Chat_Dark_iOS_L.webp",
    dark_ios_url_s:"https://nandbox.com/wp-content/uploads/2024/12/Bot__Chat_Dark_iOS_S.webp",

    name: "Chat with Bot",
    hint: "Allow user to chat with a bot",
    desc:"",
    feature:"",
    category:"",
    update:0
  }
  },

[TabNames.VIDEOAUDIO]: {
  type: TabNames.CHAT,
  module: TabNames.CHAT,
  icon: "mir_video_call",
  icon_ios: "mir_video_call",
  title: "Calls",
  title_ios: "Calls",
  video_call: true,
  web:{
    android_url_f:"",
    android_url_l: "",
    android_url_s:"",
    ios_url_f: "",
    ios_url_l:  "",
    ios_url_s:  "",

    dark_android_url_f:"",
    dark_android_url_l:"",
    dark_android_url_s:"",
    dark_ios_url_f:"",
    dark_ios_url_l:"",
    dark_ios_url_s:"",

    name: "Video & Audio Chats",
    hint: "A full functioning messenger with Video and Audio Calls",
    desc:"",
    feature:"",
    category:"",
    update:0
  }
},

[TabNames.BOOKING]: {
  type: TabNames.BOOKING,
  title: "Booking",
  title_ios: "Booking",
  icon: "mir_shopping_cart",
  icon_ios: "mir_shopping_cart",
  web:{
    android_url_f:"",
    android_url_l:"",
    android_url_s:"",
    ios_url_f: "",
    ios_url_l: "",
    ios_url_s: "",

    dark_android_url_f:"",
    dark_android_url_l:"",
    dark_android_url_s:"",
    dark_ios_url_f:"",
    dark_ios_url_l:"",
    dark_ios_url_s:"",

    name: "Booking",
    hint: "Allows to book a ticket",
    desc:"",
    feature:"",
    category:"",
    update:0
  }
},

[TabNames.BOOKINGLIST]: {
  type: TabNames.BOOKINGLIST,
  title: "Booking List",
  title_ios: "Booking List",
  icon: "mir_shopping_cart",
  icon_ios: "mir_shopping_cart",
  web:{
    android_url_f:"",
    android_url_l:"",
    android_url_s:"",
    ios_url_f: "",
    ios_url_l: "",
    ios_url_s: "",
    name: "Booking",
    hint: "Allows user to list booking or events",
    update:0
  }
},
  [TabNames.REDEEM_TICKET]: {
    type: TabNames.QR_PAGE,
    title: "Redemption",
    title_ios: "Redemption",
    icon: "mir_qr_code",
    icon_ios: "mir_qr_code",
    web:{
      android_url_f:"",
      android_url_l:"",
      android_url_s:"",
      ios_url_f: "",
      ios_url_l: "",
      ios_url_s: "",


      name: "Ticket Redeemtion",
      hint: "Allows adminstators to redeem or cancel the user booking or queuing tickets using QR scan",
      update:0
    }
  },
  [TabNames.VALIDATE_TICKET]: {
    type: TabNames.QR_PAGE,
    title: "Validation",
    title_ios: "Validation",
    icon: "mir_qr_code_scanner",
    icon_ios: "mir_qr_code_scanner",
    web:{
      android_url_f:"",
      android_url_l:"",
      android_url_s:"",
      ios_url_f: "",
      ios_url_l: "",
      ios_url_s: "",
      name: "Ticket Validation",
      hint: "Allows adminstators to validate the user booking or queuing tickets using QR scan",
      update:0
     }
  },

  [TabNames.EMPTY]: {
    type: TabNames.EMPTY,
    title: "New Tab",
    title_ios: "New Tab",
    icon: "mir_add",
    icon_ios: "mir_add",
    web:{
      android_url_l: "./assets/images/emptyImageL.webp",
      android_url_s: "./assets/images/emptyImageS.webp",
      update:0
    }

  },
  [TabNames.ORDERLIST]: {
    type: TabNames.ORDERLIST,
    title: "Order List",
    title_ios: "Order List",
    icon: "mir_shopping_bag",
    icon_ios: "mir_shopping_bag",
    web:{
      android_url_l: "./assets/images/empty-imageL.webp",
      android_url_s: "./assets/images/emptyImageS.webp",
      name: "User Order List",
      hint: "Display user recent order history.",
      update:0
    }
  },
  [TabNames.EVENTLIST]: {
    type: TabNames.EVENTLIST,
    title: "Event List",
    title_ios: "Event List",
    icon: "fas_fa_list_ul",
    icon_ios: "fas_fa_list_ul",

    web:{
      android_url_l: "./assets/images/empty-imageL.webp",
      android_url_s: "./assets/images/emptyImageS.webp",
      name: "App Event List",
      hint: "Display all avaliable App booking list.",
      update:0
    }
  },
  [TabNames.EVENT]: {
    type: TabNames.EVENT,
    title: "Event",
    title_ios: "Event",
    icon: "far_fa_calendar_plus",
    icon_ios: "far_fa_calendar_plus",
    web:{
      android_url_l: "./assets/images/empty-imageL.webp",
      android_url_s: "./assets/images/emptyImageS.webp",
      update:0
    }
  },
  [TabNames.QUEUINGLIST]: {
    type: TabNames.QUEUINGLIST,
    title: "Queuing List",
    title_ios: "Queuing List",
    icon: "fas_fa_th_list",
    icon_ios: "fas_fa_th_list",
    web:{
      android_url_l: "./assets/images/empty-imageL.webp",
      android_url_s: "./assets/images/emptyImageS.webp",
      name: "App Queuing List",
      hint: "Display all avaliable App queuing list. Allow user to reserve slot or ticket in the queue service",
      update:0
    }
  },
  [TabNames.SPLASH]: {
    type: TabNames.SPLASH,
    module: TabNames.SPLASH,
    cat: "splash",
    title: "Splash",
    title_ios: "Splash",
    icon: "",
    icon_ios: "",
    param: {
      splash: {
        style: "",
        title: null,
        button_title: null,
        button_bgcolor: null,
        button_text_color: null,
        tnc_text_color: null,
        tnc_link_color: null,
        tnc_bgcolor: null,
        bg_color: {},
      },
    },
    web:{
      android_url_l: "",
      android_url_s: "",
      update:0
    }
  },
  [TabNames.PALETTE]: {
    type: TabNames.PALETTE,
    module: TabNames.PALETTE,
    title: "Plaette",
    title_ios: "Plaette",
    icon: "",
    icon_ios: "",
    web:{
      android_url_l: "./assets/images/empty-imageL.webp",
      android_url_s: "./assets/images/emptyImageS.webp",
      update:0
    }
  },
  //
  //  sub tabs
  //  This sub tabs only for wireframe
  //  always start with sub_
  //

  [TabNames.SUB_MESSENGER_VOICE_MESSAGE_1]: {
    id: TabNames.SUB_MESSENGER_VOICE_MESSAGE_1,
    type: TabNames.SUB_MESSENGER_VOICE_MESSAGE_1,
    module: TabNames.SUB_MESSENGER_VOICE_MESSAGE_1,
    outputIds: [TabNames.SUB_MESSENGER_VOICE_MESSAGE_1],
    title: "Messenger Voice 1",
    title_ios: "Messenger Voice 1",
    cat: "sub",
    web: {
      android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_voice-message_AN_L-1.webp",
      android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_voice-message_AN_L-1.webp",
      android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_voice-message_AN_L-1.webp",
      ios_url_f: "",
      ios_url_l: "",
      ios_url_s: "",
      dark_android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_voice-message_AN_L-1.webp",
      dark_android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_voice-message_AN_L-1.webp",
      dark_android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_voice-message_AN_L-1.webp",
      dark_ios_url_f: "",
      dark_ios_url_l: "",
      dark_ios_url_s: ""
    }
  },
  [TabNames.SUB_MESSENGER_VOICE_MESSAGE_2]: {
    id: TabNames.SUB_MESSENGER_VOICE_MESSAGE_2,
    type: TabNames.SUB_MESSENGER_VOICE_MESSAGE_2,
    module: TabNames.SUB_MESSENGER_VOICE_MESSAGE_2,
    outputIds: [TabNames.SUB_MESSENGER_VOICE_MESSAGE_2],
    title: "Messenger Voice 2",
    title_ios: "Messenger Voice 2",
    cat: "sub",
    web: {
      android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_voice-message_AN_L-2.webp",
      android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_voice-message_AN_L-2.webp",
      android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_voice-message_AN_L-2.webp",
      ios_url_f: "",
      ios_url_l: "",
      ios_url_s: "",
      dark_android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_voice-message_AN_L-2.webp",
      dark_android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_voice-message_AN_L-2.webp",
      dark_android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_voice-message_AN_L-2.webp",
      dark_ios_url_f: "",
      dark_ios_url_l: "",
      dark_ios_url_s: ""
    }
  },
  [TabNames.SUB_MESSENGER_VOICE_MESSAGE_3]: {
    id: TabNames.SUB_MESSENGER_VOICE_MESSAGE_3,
    type: TabNames.SUB_MESSENGER_VOICE_MESSAGE_3,
    module: TabNames.SUB_MESSENGER_VOICE_MESSAGE_3,
    outputIds: [TabNames.SUB_MESSENGER_VOICE_MESSAGE_3],
    title: "Messenger Voice 3",
    title_ios: "Messenger Voice 3",
    cat: "sub",
    web: {
      android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_voice-message_AN_L-3.webp",
      android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_voice-message_AN_L-3.webp",
      android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_voice-message_AN_L-3.webp",
      ios_url_f: "",
      ios_url_l: "",
      ios_url_s: "",
      dark_android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_voice-message_AN_L-3.webp",
      dark_android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_voice-message_AN_L-3.webp",
      dark_android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_voice-message_AN_L-3.webp",
      dark_ios_url_f: "",
      dark_ios_url_l: "",
      dark_ios_url_s: ""
    }
  },
  [TabNames.SUB_MESSENGER_VOICE_MESSAGE_4]: {
    id: TabNames.SUB_MESSENGER_VOICE_MESSAGE_4,
    type: TabNames.SUB_MESSENGER_VOICE_MESSAGE_4,
    module: TabNames.SUB_MESSENGER_VOICE_MESSAGE_4,
    outputIds: [TabNames.SUB_MESSENGER_VOICE_MESSAGE_4],
    title: "Messenger Voice 4",
    title_ios: "Messenger Voice 4",
    cat: "sub",
    web: {
      android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_voice-message_AN_L-4.webp",
      android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_voice-message_AN_L-4.webp",
      android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_voice-message_AN_L-4.webp",
      ios_url_f: "",
      ios_url_l: "",
      ios_url_s: "",
      dark_android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_voice-message_AN_L-4.webp",
      dark_android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_voice-message_AN_L-4.webp",
      dark_android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_voice-message_AN_L-4.webp",
      dark_ios_url_f: "",
      dark_ios_url_l: "",
      dark_ios_url_s: ""
    }
  },
  [TabNames.SUB_MESSENGER_VIDEO_CALL]: {
    id: TabNames.SUB_MESSENGER_VIDEO_CALL,
    type: TabNames.SUB_MESSENGER_VIDEO_CALL,
    module: TabNames.SUB_MESSENGER_VIDEO_CALL,
    outputIds: [TabNames.SUB_MESSENGER_VIDEO_CALL],
    title: "Messenger Video Call",
    title_ios: "Messenger Video Call",
    cat: "subF",
    web: {
      android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_video_call_AN_L-1.webp",
      android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_video_call_AN_L-1.webp",
      android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_video_call_AN_L-1.webp",
      ios_url_f: "",
      ios_url_l: "",
      ios_url_s: "",
      dark_android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_video_call_AN_L-1.webp",
      dark_android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_video_call_AN_L-1.webp",
      dark_android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_video_call_AN_L-1.webp",
      dark_ios_url_f: "",
      dark_ios_url_l: "",
      dark_ios_url_s: ""
    }
  },
  [TabNames.SUB_MESSENGER_USER_PROFILE]: {
    id: TabNames.SUB_MESSENGER_USER_PROFILE,
    type: TabNames.SUB_MESSENGER_USER_PROFILE,
    module: TabNames.SUB_MESSENGER_USER_PROFILE,
    outputIds: [TabNames.SUB_MESSENGER_USER_PROFILE],
    title: "Messenger User Profile",
    title_ios: "Messenger User Profile",
    cat: "sub",
    web: {
      android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_user-profile_AN_L-1.webp",
      android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_user-profile_AN_L-1.webp",
      android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_user-profile_AN_L-1.webp",
      ios_url_f: "",
      ios_url_l: "",
      ios_url_s: "",
      dark_android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_user-profile_AN_L-1.webp",
      dark_android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_user-profile_AN_L-1.webp",
      dark_android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_user-profile_AN_L-1.webp",
      dark_ios_url_f: "",
      dark_ios_url_l: "",
      dark_ios_url_s: ""
    }
  },
  [TabNames.SUB_MESSENGER_SEND_VIDEO]: {
    id: TabNames.SUB_MESSENGER_SEND_VIDEO,
    type: TabNames.SUB_MESSENGER_SEND_VIDEO,
    module: TabNames.SUB_MESSENGER_SEND_VIDEO,
    outputIds: [TabNames.SUB_MESSENGER_SEND_VIDEO],
    title: "Messenger Send Video",
    title_ios: "Messenger Send Video",
    cat: "subF",
    web: {
      android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_send-video-_AN_L.webp",
      android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_send-video-_AN_L.webp",
      android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_send-video-_AN_L.webp",
      ios_url_f: "",
      ios_url_l: "",
      ios_url_s: "",
      dark_android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_send-video-_AN_L.webp",
      dark_android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_send-video-_AN_L.webp",
      dark_android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_send-video-_AN_L.webp",
      dark_ios_url_f: "",
      dark_ios_url_l: "",
      dark_ios_url_s: ""
    }
  },
  [TabNames.SUB_MESSENGER_SEND_PHOTO]: {
    id: TabNames.SUB_MESSENGER_SEND_PHOTO,
    type: TabNames.SUB_MESSENGER_SEND_PHOTO,
    module: TabNames.SUB_MESSENGER_SEND_PHOTO,
    outputIds: [TabNames.SUB_MESSENGER_SEND_PHOTO],
    title: "Messenger Send Photo",
    title_ios: "Messenger Send Photo",
    cat: "subF",
    web: {
      android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_send-photo-_AN_L.webp",
      android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_send-photo-_AN_L.webp",
      android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_send-photo-_AN_L.webp",
      ios_url_f: "",
      ios_url_l: "",
      ios_url_s: "",
      dark_android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_send-photo-_AN_L.webp",
      dark_android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_send-photo-_AN_L.webp",
      dark_android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_send-photo-_AN_L.webp",
      dark_ios_url_f: "",
      dark_ios_url_l: "",
      dark_ios_url_s: ""
    }
  },
  [TabNames.SUB_MESSENGER_SEND_FILE]: {
    id: TabNames.SUB_MESSENGER_SEND_FILE,
    type: TabNames.SUB_MESSENGER_SEND_FILE,
    module: TabNames.SUB_MESSENGER_SEND_FILE,
    outputIds: [TabNames.SUB_MESSENGER_SEND_FILE],
    title: "Messenger Send File",
    title_ios: "Messenger Send File",
    cat: "sub",
    web: {
      android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_send-file_AN_L-1.webp",
      android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_send-file_AN_L-1.webp",
      android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_send-file_AN_L-1.webp",
      ios_url_f: "",
      ios_url_l: "",
      ios_url_s: "",
      dark_android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_send-file_AN_L-1.webp",
      dark_android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_send-file_AN_L-1.webp",
      dark_android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_send-file_AN_L-1.webp",
      dark_ios_url_f: "",
      dark_ios_url_l: "",
      dark_ios_url_s: ""
    }
  },
  [TabNames.SUB_MESSENGER_SEARCH]: {
    id: TabNames.SUB_MESSENGER_SEARCH,
    type: TabNames.SUB_MESSENGER_SEARCH,
    module: TabNames.SUB_MESSENGER_SEARCH,
    outputIds: [TabNames.SUB_MESSENGER_SEARCH],
    title: "Messenger Search",
    title_ios: "Messenger Search",
    cat: "sub",
    web: {
      android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_search_AN_L-1.webp",
      android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_search_AN_L-1.webp",
      android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_search_AN_L-1.webp",
      ios_url_f: "",
      ios_url_l: "",
      ios_url_s: "",
      dark_android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_search_AN_L-1.webp",
      dark_android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_search_AN_L-1.webp",
      dark_android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_search_AN_L-1.webp",
      dark_ios_url_f: "",
      dark_ios_url_l: "",
      dark_ios_url_s: ""
    }
  },
  [TabNames.SUB_MESSENGER_REPLY_MESSAGE]: {
    id: TabNames.SUB_MESSENGER_REPLY_MESSAGE,
    type: TabNames.SUB_MESSENGER_REPLY_MESSAGE,
    module: TabNames.SUB_MESSENGER_REPLY_MESSAGE,
    outputIds: [TabNames.SUB_MESSENGER_REPLY_MESSAGE],
    title: "Messenger Reply Message",
    title_ios: "Messenger Reply Message",
    cat: "sub",
    web: {
      android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_reply-message_AN_L-1.webp",
      android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_reply-message_AN_L-1.webp",
      android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_reply-message_AN_L-1.webp",
      ios_url_f: "",
      ios_url_l: "",
      ios_url_s: "",
      dark_android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_reply-message_AN_L-1.webp",
      dark_android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_reply-message_AN_L-1.webp",
      dark_android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_reply-message_AN_L-1.webp",
      dark_ios_url_f: "",
      dark_ios_url_l: "",
      dark_ios_url_s: ""
    }
  },
  [TabNames.SUB_MESSENGER_MULTI_PROFILE]: {
    id: TabNames.SUB_MESSENGER_MULTI_PROFILE,
    type: TabNames.SUB_MESSENGER_MULTI_PROFILE,
    module: TabNames.SUB_MESSENGER_MULTI_PROFILE,
    outputIds: [TabNames.SUB_MESSENGER_MULTI_PROFILE],
    title: "Messenger Multi Profile",
    title_ios: "Messenger Multi Profile",
    cat: "sub",
    web: {
      android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_multi-profile_AN_L-1.webp",
      android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_multi-profile_AN_L-1.webp",
      android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_multi-profile_AN_L-1.webp",
      ios_url_f: "",
      ios_url_l: "",
      ios_url_s: "",
      dark_android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_multi-profile_AN_L-1.webp",
      dark_android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_multi-profile_AN_L-1.webp",
      dark_android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_multi-profile_AN_L-1.webp",
      dark_ios_url_f: "",
      dark_ios_url_l: "",
      dark_ios_url_s: ""
    }
  },
  [TabNames.SUB_MESSENGER_MEDIA_FILE]: {
    id: TabNames.SUB_MESSENGER_MEDIA_FILE,
    type: TabNames.SUB_MESSENGER_MEDIA_FILE,
    module: TabNames.SUB_MESSENGER_MEDIA_FILE,
    outputIds: [TabNames.SUB_MESSENGER_MEDIA_FILE],
    title: "Messenger Media File",
    title_ios: "Messenger Media File",
    cat: "sub",
    web: {
      android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_media-file_AN_L-1.webp",
      android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_media-file_AN_L-1.webp",
      android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_media-file_AN_L-1.webp",
      ios_url_f: "",
      ios_url_l: "",
      ios_url_s: "",
      dark_android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_media-file_AN_L-1.webp",
      dark_android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_media-file_AN_L-1.webp",
      dark_android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_media-file_AN_L-1.webp",
      dark_ios_url_f: "",
      dark_ios_url_l: "",
      dark_ios_url_s: ""
    }
  },
   [TabNames.SUB_MESSENGER_SEND_LOCATION]: {
    id: TabNames.SUB_MESSENGER_SEND_LOCATION,
    type: TabNames.SUB_MESSENGER_SEND_LOCATION,
    module: TabNames.SUB_MESSENGER_SEND_LOCATION,
    outputIds: [TabNames.SUB_MESSENGER_SEND_LOCATION],
    title: "Messenger Group Send Location",
    title_ios: "Messenger Group Send Location",
    cat: "subF",
    web: {
      android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_group-send-location_AN_L-1.webp",
      android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_group-send-location_AN_L-1.webp",
      android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_group-send-location_AN_L-1.webp",
      ios_url_f: "",
      ios_url_l: "",
      ios_url_s: "",
      dark_android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_group-send-location_AN_L-1.webp",
      dark_android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_group-send-location_AN_L-1.webp",
      dark_android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_group-send-location_AN_L-1.webp",
      dark_ios_url_f: "",
      dark_ios_url_l: "",
      dark_ios_url_s: ""
    }
  },
  [TabNames.SUB_MESSENGER_SELECT_MESSAGE]: {
    id: TabNames.SUB_MESSENGER_SELECT_MESSAGE,
    type: TabNames.SUB_MESSENGER_SELECT_MESSAGE,
    module: TabNames.SUB_MESSENGER_SELECT_MESSAGE,
    outputIds: [TabNames.SUB_MESSENGER_SELECT_MESSAGE],
    title: "Messenger Group Select Message",
    title_ios: "Messenger Group Select Message",
    cat: "sub",
    web: {
      android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_group-select-message_AN_L-1.webp",
      android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_group-select-message_AN_L-1.webp",
      android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_group-select-message_AN_L-1.webp",
      ios_url_f: "",
      ios_url_l: "",
      ios_url_s: "",
      dark_android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_group-select-message_AN_L-1.webp",
      dark_android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_group-select-message_AN_L-1.webp",
      dark_android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_group-select-message_AN_L-1.webp",
      dark_ios_url_f: "",
      dark_ios_url_l: "",
      dark_ios_url_s: ""
    }
  },
  [TabNames.SUB_MESSENGER_GALLERY]: {
    id: TabNames.SUB_MESSENGER_GALLERY,
    type: TabNames.SUB_MESSENGER_GALLERY,
    module: TabNames.SUB_MESSENGER_GALLERY,
    outputIds: [TabNames.SUB_MESSENGER_GALLERY],
    title: "Messenger Gallery",
    title_ios: "Messenger Gallery",
    cat: "subF",
    web: {
      android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_gallery_AN_L.webp",
      android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_gallery_AN_L.webp",
      android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_gallery_AN_L.webp",
      ios_url_f: "",
      ios_url_l: "",
      ios_url_s: "",
      dark_android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_gallery_AN_L.webp",
      dark_android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_gallery_AN_L.webp",
      dark_android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_gallery_AN_L.webp",
      dark_ios_url_f: "",
      dark_ios_url_l: "",
      dark_ios_url_s: ""
    }
  },
  [TabNames.SUB_MESSENGER_DELETE_MESSAGE]: {
    id: TabNames.SUB_MESSENGER_DELETE_MESSAGE,
    type: TabNames.SUB_MESSENGER_DELETE_MESSAGE,
    module: TabNames.SUB_MESSENGER_DELETE_MESSAGE,
    outputIds: [TabNames.SUB_MESSENGER_DELETE_MESSAGE],
    title: "Messenger Delete Message",
    title_ios: "Messenger Delete Message",
    cat: "sub",
    web: {
      android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_delete-message_AN_L-1.webp",
      android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_delete-message_AN_L-1.webp",
      android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_delete-message_AN_L-1.webp",
      ios_url_f: "",
      ios_url_l: "",
      ios_url_s: "",
      dark_android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_delete-message_AN_L-1.webp",
      dark_android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_delete-message_AN_L-1.webp",
      dark_android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_delete-message_AN_L-1.webp",
      dark_ios_url_f: "",
      dark_ios_url_l: "",
      dark_ios_url_s: ""
    }
  },
  [TabNames.SUB_MESSENGER_CONTACTS]: {
    id: TabNames.SUB_MESSENGER_CONTACTS,
    type: TabNames.SUB_MESSENGER_CONTACTS,
    module: TabNames.SUB_MESSENGER_CONTACTS,
    outputIds: [TabNames.SUB_MESSENGER_CONTACTS],
    title: "Messenger Contacts",
    title_ios: "Messenger Contacts",
    cat: "sub",
    web: {
      android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_contacts_AN_L.webp",
      android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_contacts_AN_L.webp",
      android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_contacts_AN_L.webp",
      ios_url_f: "",
      ios_url_l: "",
      ios_url_s: "",
      dark_android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_contacts_AN_L.webp",
      dark_android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_contacts_AN_L.webp",
      dark_android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_contacts_AN_L.webp",
      dark_ios_url_f: "",
      dark_ios_url_l: "",
      dark_ios_url_s: ""
    }
  },
  [TabNames.SUB_MESSENGER_CHAT_MENU]: {
    id: TabNames.SUB_MESSENGER_CHAT_MENU,
    type: TabNames.SUB_MESSENGER_CHAT_MENU,
    module: TabNames.SUB_MESSENGER_CHAT_MENU,
    outputIds: [TabNames.SUB_MESSENGER_CHAT_MENU],
    title: "Messenger Chat Menu",
    title_ios: "Messenger Chat Menu",
    cat: "sub",
    web: {
      android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_chat-menu_AN_L-1.webp",
      android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_chat-menu_AN_L-1.webp",
      android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_chat-menu_AN_L-1.webp",
      ios_url_f: "",
      ios_url_l: "",
      ios_url_s: "",
      dark_android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_chat-menu_AN_L-1.webp",
      dark_android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_chat-menu_AN_L-1.webp",
      dark_android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_chat-menu_AN_L-1.webp",
      dark_ios_url_f: "",
      dark_ios_url_l: "",
      dark_ios_url_s: ""
    }
  },
  [TabNames.SUB_MESSENGER_CHAT_TEXTM]: {
    id: TabNames.SUB_MESSENGER_CHAT_TEXTM,
    type: TabNames.SUB_MESSENGER_CHAT_TEXTM,
    module: TabNames.SUB_MESSENGER_CHAT_TEXTM,
    outputIds: [TabNames.SUB_MESSENGER_CHAT_TEXTM],
    title: "Messenger Chat TextM",
    title_ios: "Messenger Chat TextM",
    cat: "sub",
    web: {
      android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_Chat_textM_AN_L.webp",
      android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_Chat_textM_AN_L.webp",
      android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_Chat_textM_AN_L.webp",
      ios_url_f: "",
      ios_url_l: "",
      ios_url_s: "",
      dark_android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_Chat_textM_AN_L.webp",
      dark_android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_Chat_textM_AN_L.webp",
      dark_android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_Chat_textM_AN_L.webp",
      dark_ios_url_f: "",
      dark_ios_url_l: "",
      dark_ios_url_s: ""
    }
  },
  [TabNames.SUB_MESSENGER_CHAT]: {
    id: TabNames.SUB_MESSENGER_CHAT,
    type: TabNames.SUB_MESSENGER_CHAT,
    module: TabNames.SUB_MESSENGER_CHAT,
    outputIds: [TabNames.SUB_MESSENGER_CHAT],
    title: "Messenger Chat",
    title_ios: "Messenger Chat",
    cat: "sub",
    web: {
      android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_Chat_AN_L-1.webp",
      android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_Chat_AN_L-1.webp",
      android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_Chat_AN_L-1.webp",
      ios_url_f: "",
      ios_url_l: "",
      ios_url_s: "",
      dark_android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_Chat_AN_L-1.webp",
      dark_android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_Chat_AN_L-1.webp",
      dark_android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_Chat_AN_L-1.webp",
      dark_ios_url_f: "",
      dark_ios_url_l: "",
      dark_ios_url_s: ""
    }
  },
  [TabNames.SUB_MESSENGER_AUDIO_FILE]: {
    id: TabNames.SUB_MESSENGER_AUDIO_FILE,
    type: TabNames.SUB_MESSENGER_AUDIO_FILE,
    module: TabNames.SUB_MESSENGER_AUDIO_FILE,
    outputIds: [TabNames.SUB_MESSENGER_AUDIO_FILE],
    title: "Messenger Audio File",
    title_ios: "Messenger Audio File",
    cat: "sub",
    web: {
      android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_audio-file_AN_L-1.webp",
      android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_audio-file_AN_L-1.webp",
      android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_audio-file_AN_L-1.webp",
      ios_url_f: "",
      ios_url_l: "",
      ios_url_s: "",
      dark_android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_audio-file_AN_L-1.webp",
      dark_android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_audio-file_AN_L-1.webp",
      dark_android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_audio-file_AN_L-1.webp",
      dark_ios_url_f: "",
      dark_ios_url_l: "",
      dark_ios_url_s: ""
    }
  },
  [TabNames.SUB_MESSENGER_AUDIO_CALL]: {
    id: TabNames.SUB_MESSENGER_AUDIO_CALL,
    type: TabNames.SUB_MESSENGER_AUDIO_CALL,
    module: TabNames.SUB_MESSENGER_AUDIO_CALL,
    outputIds: [TabNames.SUB_MESSENGER_AUDIO_CALL],
    title: "Messenger Audio Call",
    title_ios: "Messenger Audio Call",
    cat: "sub",
    web: {
      android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_audio_call_AN_L-1.webp",
      android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_audio_call_AN_L-1.webp",
      android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_audio_call_AN_L-1.webp",
      ios_url_f: "",
      ios_url_l: "",
      ios_url_s: "",
      dark_android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_audio_call_AN_L-1.webp",
      dark_android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_audio_call_AN_L-1.webp",
      dark_android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_audio_call_AN_L-1.webp",
      dark_ios_url_f: "",
      dark_ios_url_l: "",
      dark_ios_url_s: ""
    }
  },
  [TabNames.SUB_MESSENGER_ATTACH]: {
    id: TabNames.SUB_MESSENGER_ATTACH,
    type: TabNames.SUB_MESSENGER_ATTACH,
    module: TabNames.SUB_MESSENGER_ATTACH,
    outputIds: [TabNames.SUB_MESSENGER_ATTACH],
    title: "Messenger Attach",
    title_ios: "Messenger Attach",
    cat: "sub",
    web: {
      android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_attach_AN_L-1.webp",
      android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_attach_AN_L-1.webp",
      android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_attach_AN_L-1.webp",
      ios_url_f: "",
      ios_url_l: "",
      ios_url_s: "",
      dark_android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_attach_AN_L-1.webp",
      dark_android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_attach_AN_L-1.webp",
      dark_android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_attach_AN_L-1.webp",
      dark_ios_url_f: "",
      dark_ios_url_l: "",
      dark_ios_url_s: ""
    }
  },
  [TabNames.SUB_EMOJI]: {
    id: TabNames.SUB_EMOJI,
    type: TabNames.SUB_EMOJI,
    module: TabNames.SUB_EMOJI,
    outputIds: [TabNames.SUB_EMOJI],
    title: "Emoji",
    title_ios: "Emoji",
    cat: "sub",
    web: {
      android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/WF_Emoji_AN_L.webp",
      android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/WF_Emoji_AN_L.webp",
      android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/WF_Emoji_AN_L.webp",
      ios_url_f: "",
      ios_url_l: "",
      ios_url_s: "",
      dark_android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Emoji_AN_L.webp",
      dark_android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Emoji_AN_L.webp",
      dark_android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Emoji_AN_L.webp",
      dark_ios_url_f: "",
      dark_ios_url_l: "",
      dark_ios_url_s: ""
    }
  },
  [TabNames.SUB_COLORED_MESSAGE_CHAT]: {
    id: TabNames.SUB_COLORED_MESSAGE_CHAT,
    type: TabNames.SUB_COLORED_MESSAGE_CHAT,
    module: TabNames.SUB_COLORED_MESSAGE_CHAT,
    outputIds: [TabNames.SUB_COLORED_MESSAGE_CHAT],
    title: "Colored Message Chat",
    title_ios: "Colored Message Chat",
    cat: "sub",
    web: {
      android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/WF_Colored-Message_Chat_AN_L.webp",
      android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/WF_Colored-Message_Chat_AN_L.webp",
      android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/WF_Colored-Message_Chat_AN_L.webp",
      ios_url_f: "",
      ios_url_l: "",
      ios_url_s: "",
      dark_android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Colored-Message_Chat_AN_L.webp",
      dark_android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Colored-Message_Chat_AN_L.webp",
      dark_android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Colored-Message_Chat_AN_L.webp",
      dark_ios_url_f: "",
      dark_ios_url_l: "",
      dark_ios_url_s: ""
    }
  },
  [TabNames.SUB_CHANNELS_FORWARD_MESSAGE]: {
    id: TabNames.SUB_CHANNELS_FORWARD_MESSAGE,
    type: TabNames.SUB_CHANNELS_FORWARD_MESSAGE,
    module: TabNames.SUB_CHANNELS_FORWARD_MESSAGE,
    outputIds: [TabNames.SUB_CHANNELS_FORWARD_MESSAGE],
    title: "Channels Forward Message",
    title_ios: "Channels Forward Message",
    cat: "sub",
    web: {
      android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/WF_channels_forword-message_AN_L.webp",
      android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/WF_channels_forword-message_AN_L.webp",
      android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/WF_channels_forword-message_AN_L.webp",
      ios_url_f: "",
      ios_url_l: "",
      ios_url_s: "",
      dark_android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_channels_forword-message_AN_L.webp",
      dark_android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_channels_forword-message_AN_L.webp",
      dark_android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_channels_forword-message_AN_L.webp",
      dark_ios_url_f: "",
      dark_ios_url_l: "",
      dark_ios_url_s: ""
    }
  },
////
[TabNames.SUB_MESSENGER_CHAT_GROUP]: {
  id: TabNames.SUB_MESSENGER_CHAT_GROUP,
  type: TabNames.SUB_MESSENGER_CHAT_GROUP,
  module: TabNames.SUB_MESSENGER_CHAT_GROUP,
  outputIds: [ TabNames.SUB_MESSENGER_CHAT_GROUP ],
  title: "Messenger Chat Group",
  title_ios: "Messenger Chat Group",
  cat: "sub",
  web: {
    android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_chat-group_AN_L-1.webp",
    android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_chat-group_AN_L-1.webp",
    android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_chat-group_AN_L-1.webp",
    ios_url_f: "",
    ios_url_l: "",
    ios_url_s: "",
    dark_android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_chat-group_AN_L-1.webp",
    dark_android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_chat-group_AN_L-1.webp",
    dark_android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_chat-group_AN_L-1.webp",
    dark_ios_url_f: "",
    dark_ios_url_l: "",
    dark_ios_url_s: ""
  }
},
[TabNames.SUB_CREATE_TYPE_CHAT_GROUP_INFO]: {
  id: TabNames.SUB_CREATE_TYPE_CHAT_GROUP_INFO,
  type: TabNames.SUB_CREATE_TYPE_CHAT_GROUP_INFO,
  module: TabNames.SUB_CREATE_TYPE_CHAT_GROUP_INFO,
  outputIds: [TabNames.SUB_CREATE_TYPE_CHAT_GROUP_INFO ],
  title: "Create Type Chat Group Info",
  title_ios: "Create Type Chat Group Info",
  cat: "sub",
  web: {
    android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/WF_create_type_chat-group_info_AN_L.webp",
    android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/WF_create_type_chat-group_info_AN_L.webp",
    android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/WF_create_type_chat-group_info_AN_L.webp",
    ios_url_f: "",
    ios_url_l: "",
    ios_url_s: "",
    dark_android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_create_type_chat-group_info_AN_L.webp",
    dark_android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_create_type_chat-group_info_AN_L.webp",
    dark_android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_create_type_chat-group_info_AN_L.webp",
    dark_ios_url_f: "",
    dark_ios_url_l: "",
    dark_ios_url_s: ""
  }
},
[TabNames.SUB_CREATE_CHAT_GROUP_INFO]: {
  id: TabNames.SUB_CREATE_CHAT_GROUP_INFO,
  type: TabNames.SUB_CREATE_CHAT_GROUP_INFO,
  module: TabNames.SUB_CREATE_CHAT_GROUP_INFO,
  outputIds: [TabNames.SUB_CREATE_CHAT_GROUP_INFO ],
  title: "Create Chat Group Info",
  title_ios: "Create Chat Group Info",
  cat: "sub",
  web: {
    android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/WF_create_chat-group_info_AN_L.webp",
    android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/WF_create_chat-group_info_AN_L.webp",
    android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/WF_create_chat-group_info_AN_L.webp",
    ios_url_f: "",
    ios_url_l: "",
    ios_url_s: "",
    dark_android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_create_chat-group_info_AN_L.webp",
    dark_android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_create_chat-group_info_AN_L.webp",
    dark_android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_create_chat-group_info_AN_L.webp",
    dark_ios_url_f: "",
    dark_ios_url_l: "",
    dark_ios_url_s: ""
  }
},
[TabNames.SUB_CREATE_CHAT_GROUP]: {
  id: TabNames.SUB_CREATE_CHAT_GROUP,
  type: TabNames.SUB_CREATE_CHAT_GROUP,
  module: TabNames.SUB_CREATE_CHAT_GROUP,
  outputIds: [TabNames.SUB_CREATE_CHAT_GROUP ],
  title: "Create Chat Group",
  title_ios: "Create Chat Group",
  cat: "sub",
  web: {
    android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/WF_create_chat-group_AN_L.webp",
    android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/WF_create_chat-group_AN_L.webp",
    android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/WF_create_chat-group_AN_L.webp",
    ios_url_f: "",
    ios_url_l: "",
    ios_url_s: "",
    dark_android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_create_chat-group_AN_L.webp",
    dark_android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_create_chat-group_AN_L.webp",
    dark_android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_create_chat-group_AN_L.webp",
    dark_ios_url_f: "",
    dark_ios_url_l: "",
    dark_ios_url_s: ""
  }
},
[TabNames.SUB_CHAT_GROUP_TEXT_MESSAGE_BACKGROUND]: {
  id: TabNames.SUB_CHAT_GROUP_TEXT_MESSAGE_BACKGROUND,
  type: TabNames.SUB_CHAT_GROUP_TEXT_MESSAGE_BACKGROUND,
  module: TabNames.SUB_CHAT_GROUP_TEXT_MESSAGE_BACKGROUND,
  outputIds: [TabNames.SUB_CHAT_GROUP_TEXT_MESSAGE_BACKGROUND  ],
  title: "Chat Group Text Message Background",
  title_ios: "Chat Group Text Message Background",
  cat: "sub",
  web: {
    android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/WF_chat-group_text_message_background_AN_L.webp",
    android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/WF_chat-group_text_message_background_AN_L.webp",
    android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/WF_chat-group_text_message_background_AN_L.webp",
    ios_url_f: "",
    ios_url_l: "",
    ios_url_s: "",
    dark_android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat-group_text_message_background_AN_L.webp",
    dark_android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat-group_text_message_background_AN_L.webp",
    dark_android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat-group_text_message_background_AN_L.webp",
    dark_ios_url_f: "",
    dark_ios_url_l: "",
    dark_ios_url_s: ""
  }
},
[TabNames.SUB_CHAT_GROUP_TEXT_MESSAGE]: {
  id: TabNames.SUB_CHAT_GROUP_TEXT_MESSAGE,
  type: TabNames.SUB_CHAT_GROUP_TEXT_MESSAGE,
  module: TabNames.SUB_CHAT_GROUP_TEXT_MESSAGE,
  outputIds: [TabNames.SUB_CHAT_GROUP_TEXT_MESSAGE  ],
  title: "Chat Group Text Message",
  title_ios: "Chat Group Text Message",
  cat: "sub",
  web: {
    android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/WF_chat-group_text_message_AN_L.webp",
    android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/WF_chat-group_text_message_AN_L.webp",
    android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/WF_chat-group_text_message_AN_L.webp",
    ios_url_f: "",
    ios_url_l: "",
    ios_url_s: "",
    dark_android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat-group_text_message_AN_L.webp",
    dark_android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat-group_text_message_AN_L.webp",
    dark_android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat-group_text_message_AN_L.webp",
    dark_ios_url_f: "",
    dark_ios_url_l: "",
    dark_ios_url_s: ""
  }
},
[TabNames.SUB_CHAT_GROUP_SEND_PHOTO]: {
  id: TabNames.SUB_CHAT_GROUP_SEND_PHOTO,
  type: TabNames.SUB_CHAT_GROUP_SEND_PHOTO,
  module: TabNames.SUB_CHAT_GROUP_SEND_PHOTO,
  outputIds: [TabNames.SUB_CHAT_GROUP_SEND_PHOTO ],
  title: "Chat Group Send Photo",
  title_ios: "Chat Group Send Photo",
  cat: "sub",
  web: {
    android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/WF_chat-group_send_photo_AN_L.webp",
    android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/WF_chat-group_send_photo_AN_L.webp",
    android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/WF_chat-group_send_photo_AN_L.webp",
    ios_url_f: "",
    ios_url_l: "",
    ios_url_s: "",
    dark_android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat-group_send_photo_AN_L.webp",
    dark_android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat-group_send_photo_AN_L.webp",
    dark_android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat-group_send_photo_AN_L.webp",
    dark_ios_url_f: "",
    dark_ios_url_l: "",
    dark_ios_url_s: ""
  }
},
[TabNames.SUB_CHAT_GROUP_SELECT_MESSAGE]: {
  id: TabNames.SUB_CHAT_GROUP_SELECT_MESSAGE,
  type: TabNames.SUB_CHAT_GROUP_SELECT_MESSAGE,
  module: TabNames.SUB_CHAT_GROUP_SELECT_MESSAGE,
  outputIds: [TabNames.SUB_CHAT_GROUP_SELECT_MESSAGE ],
  title: "Chat Group Select Message",
  title_ios: "Chat Group Select Message",
  cat: "sub",
  web: {
    android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/WF_chat-group_select_message_AN_L.webp",
    android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/WF_chat-group_select_message_AN_L.webp",
    android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/WF_chat-group_select_message_AN_L.webp",
    ios_url_f: "",
    ios_url_l: "",
    ios_url_s: "",
    dark_android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat-group_select_message_AN_L.webp",
    dark_android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat-group_select_message_AN_L.webp",
    dark_android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat-group_select_message_AN_L.webp",
    dark_ios_url_f: "",
    dark_ios_url_l: "",
    dark_ios_url_s: ""
  }
},
[TabNames.SUB_CHAT_GROUP_NOTIFICATION]: {
  id: TabNames.SUB_CHAT_GROUP_NOTIFICATION,
  type: TabNames.SUB_CHAT_GROUP_NOTIFICATION,
  module: TabNames.SUB_CHAT_GROUP_NOTIFICATION,
  outputIds: [TabNames.SUB_CHAT_GROUP_NOTIFICATION  ],
  title: "Chat Group Notification",
  title_ios: "Chat Group Notification",
  cat: "sub",
  web: {
    android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/WF_chat-group_notification_AN_L.webp",
    android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/WF_chat-group_notification_AN_L.webp",
    android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/WF_chat-group_notification_AN_L.webp",
    ios_url_f: "",
    ios_url_l: "",
    ios_url_s: "",
    dark_android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat-group_notification_AN_L.webp",
    dark_android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat-group_notification_AN_L.webp",
    dark_android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat-group_notification_AN_L.webp",
    dark_ios_url_f: "",
    dark_ios_url_l: "",
    dark_ios_url_s: ""
  }
},
[TabNames.SUB_CHAT_GROUP_INFO]: {
  id: TabNames.SUB_CHAT_GROUP_INFO,
  type: TabNames.SUB_CHAT_GROUP_INFO,
  module: TabNames.SUB_CHAT_GROUP_INFO,
  outputIds: [TabNames.SUB_CHAT_GROUP_INFO ],
  title: "Chat Group Info",
  title_ios: "Chat Group Info",
  cat: "sub",
  web: {
    android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/WF_chat-group_info_AN_L.webp",
    android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/WF_chat-group_info_AN_L.webp",
    android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/WF_chat-group_info_AN_L.webp",
    ios_url_f: "",
    ios_url_l: "",
    ios_url_s: "",
    dark_android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat-group_info_AN_L.webp",
    dark_android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat-group_info_AN_L.webp",
    dark_android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat-group_info_AN_L.webp",
    dark_ios_url_f: "",
    dark_ios_url_l: "",
    dark_ios_url_s: ""
  }
},
[TabNames.SUB_CHAT_GROUP_EMOJE]: {
  id: TabNames.SUB_CHAT_GROUP_EMOJE,
  type: TabNames.SUB_CHAT_GROUP_EMOJE,
  module: TabNames.SUB_CHAT_GROUP_EMOJE,
  outputIds: [TabNames.SUB_CHAT_GROUP_EMOJE  ],
  title: "Chat Group Emoje",
  title_ios: "Chat Group Emoje",
  cat: "sub",
  web: {
    android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/WF_chat-group_emoje_AN_L.webp",
    android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/WF_chat-group_emoje_AN_L.webp",
    android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/WF_chat-group_emoje_AN_L.webp",
    ios_url_f: "",
    ios_url_l: "",
    ios_url_s: "",
    dark_android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat-group_emoje_AN_L.webp",
    dark_android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat-group_emoje_AN_L.webp",
    dark_android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat-group_emoje_AN_L.webp",
    dark_ios_url_f: "",
    dark_ios_url_l: "",
    dark_ios_url_s: ""
  }
},
[TabNames.SUB_CHAT_GROUP_VOICE_MESSAGE_CHECK]: {
  id: TabNames.SUB_CHAT_GROUP_VOICE_MESSAGE_CHECK,
  type: TabNames.SUB_CHAT_GROUP_VOICE_MESSAGE_CHECK,
  module: TabNames.SUB_CHAT_GROUP_VOICE_MESSAGE_CHECK,
  outputIds: [TabNames.SUB_CHAT_GROUP_VOICE_MESSAGE_CHECK  ],
  title: "Chat Group Voice Message Check",
  title_ios: "Chat Group Voice Message Check",
  cat: "sub",
  web: {
    android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/WF_chat_group-voice-message_check_AN_L.webp",
    android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/WF_chat_group-voice-message_check_AN_L.webp",
    android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/WF_chat_group-voice-message_check_AN_L.webp",
    ios_url_f: "",
    ios_url_l: "",
    ios_url_s: "",
    dark_android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat_group-voice-message_check_AN_L.webp",
    dark_android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat_group-voice-message_check_AN_L.webp",
    dark_android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat_group-voice-message_check_AN_L.webp",
    dark_ios_url_f: "",
    dark_ios_url_l: "",
    dark_ios_url_s: ""
  }
},
[TabNames.SUB_CHAT_GROUP_VOICE_MESSAGE]: {
  id: TabNames.SUB_CHAT_GROUP_VOICE_MESSAGE,
  type: TabNames.SUB_CHAT_GROUP_VOICE_MESSAGE,
  module: TabNames.SUB_CHAT_GROUP_VOICE_MESSAGE,
  outputIds: [TabNames.SUB_CHAT_GROUP_VOICE_MESSAGE ],
  title: "Chat Group Voice Message",
  title_ios: "Chat Group Voice Message",
  cat: "sub",
  web: {
    android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/WF_chat_group-voice-message_AN_L.webp",
    android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/WF_chat_group-voice-message_AN_L.webp",
    android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/WF_chat_group-voice-message_AN_L.webp",
    ios_url_f: "",
    ios_url_l: "",
    ios_url_s: "",
    dark_android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat_group-voice-message_AN_L.webp",
    dark_android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat_group-voice-message_AN_L.webp",
    dark_android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat_group-voice-message_AN_L.webp",
    dark_ios_url_f: "",
    dark_ios_url_l: "",
    dark_ios_url_s: ""
  }
},
[TabNames.SUB_CHAT_GROUP_ATTACH]: {
  id: TabNames.SUB_CHAT_GROUP_ATTACH,
  type: TabNames.SUB_CHAT_GROUP_ATTACH,
  module: TabNames.SUB_CHAT_GROUP_ATTACH,
  outputIds: [TabNames.SUB_CHAT_GROUP_ATTACH ],
  title: "Chat Group Attach",
  title_ios: "Chat Group Attach",
  cat: "sub",
  web: {
    android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/WF_chat_group-attach_AN_L.webp",
    android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/WF_chat_group-attach_AN_L.webp",
    android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/WF_chat_group-attach_AN_L.webp",
    ios_url_f: "",
    ios_url_l: "",
    ios_url_s: "",
    dark_android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat_group-attach_AN_L.webp",
    dark_android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat_group-attach_AN_L.webp",
    dark_android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat_group-attach_AN_L.webp",
    dark_ios_url_f: "",
    dark_ios_url_l: "",
    dark_ios_url_s: ""
  }
},
[TabNames.SUB_CHAT_GROUP_USER_PERMISSIONS]: {
  id: TabNames.SUB_CHAT_GROUP_USER_PERMISSIONS,
  type: TabNames.SUB_CHAT_GROUP_USER_PERMISSIONS,
  module: TabNames.SUB_CHAT_GROUP_USER_PERMISSIONS,
  outputIds: [TabNames.SUB_CHAT_GROUP_USER_PERMISSIONS ],
  title: "Chat Group User Permissions",
  title_ios: "Chat Group User Permissions",
  cat: "sub",
  web: {
    android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/WF_chat_group_user_Permissions_AN_L.webp",
    android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/WF_chat_group_user_Permissions_AN_L.webp",
    android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/WF_chat_group_user_Permissions_AN_L.webp",
    ios_url_f: "",
    ios_url_l: "",
    ios_url_s: "",
    dark_android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat_group_user_Permissions_AN_L.webp",
    dark_android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat_group_user_Permissions_AN_L.webp",
    dark_android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat_group_user_Permissions_AN_L.webp",
    dark_ios_url_f: "",
    dark_ios_url_l: "",
    dark_ios_url_s: ""
  }
},
[TabNames.SUB_CHAT_GROUP_SHARE]: {
  id: TabNames.SUB_CHAT_GROUP_SHARE,
  type: TabNames.SUB_CHAT_GROUP_SHARE,
  module: TabNames.SUB_CHAT_GROUP_SHARE,
  outputIds: [TabNames.SUB_CHAT_GROUP_SHARE ],
  title: "Chat Group Share",
  title_ios: "Chat Group Share",
  cat: "sub",
  web: {
    android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/WF_chat_group_share_AN_L.webp",
    android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/WF_chat_group_share_AN_L.webp",
    android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/WF_chat_group_share_AN_L.webp",
    ios_url_f: "",
    ios_url_l: "",
    ios_url_s: "",
    dark_android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat_group_share_AN_L.webp",
    dark_android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat_group_share_AN_L.webp",
    dark_android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat_group_share_AN_L.webp",
    dark_ios_url_f: "",
    dark_ios_url_l: "",
    dark_ios_url_s: ""
  }
},
[TabNames.SUB_CHAT_GROUP_SEND_VOICE_MESSAGE]: {
  id: TabNames.SUB_CHAT_GROUP_SEND_VOICE_MESSAGE,
  type: TabNames.SUB_CHAT_GROUP_SEND_VOICE_MESSAGE,
  module: TabNames.SUB_CHAT_GROUP_SEND_VOICE_MESSAGE,
  outputIds: [TabNames.SUB_CHAT_GROUP_SEND_VOICE_MESSAGE ],
  title: "Chat Group Send Voice Message",
  title_ios: "Chat Group Send Voice Message",
  cat: "sub",
  web: {
    android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/WF_chat_group_send_voice-message_AN_L.webp",
    android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/WF_chat_group_send_voice-message_AN_L.webp",
    android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/WF_chat_group_send_voice-message_AN_L.webp",
    ios_url_f: "",
    ios_url_l: "",
    ios_url_s: "",
    dark_android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat_group_send_voice-message_AN_L.webp",
    dark_android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat_group_send_voice-message_AN_L.webp",
    dark_android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat_group_send_voice-message_AN_L.webp",
    dark_ios_url_f: "",
    dark_ios_url_l: "",
    dark_ios_url_s: ""
  }
},
[TabNames.SUB_CHAT_GROUP_REPLIES]: {
  id: TabNames.SUB_CHAT_GROUP_REPLIES,
  type: TabNames.SUB_CHAT_GROUP_REPLIES,
  module: TabNames.SUB_CHAT_GROUP_REPLIES,
  outputIds: [TabNames.SUB_CHAT_GROUP_REPLIES ],
  title: "Chat Group Replies",
  title_ios: "Chat Group Replies",
  cat: "sub",
  web: {
    android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/WF_chat_group_replies_AN_L.webp",
    android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/WF_chat_group_replies_AN_L.webp",
    android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/WF_chat_group_replies_AN_L.webp",
    ios_url_f: "",
    ios_url_l: "",
    ios_url_s: "",
    dark_android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat_group_replies_AN_L.webp",
    dark_android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat_group_replies_AN_L.webp",
    dark_android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat_group_replies_AN_L.webp",
    dark_ios_url_f: "",
    dark_ios_url_l: "",
    dark_ios_url_s: ""
  }
},
[TabNames.SUB_CHAT_GROUP_QR]: {
  id: TabNames.SUB_CHAT_GROUP_QR,
  type: TabNames.SUB_CHAT_GROUP_QR,
  module: TabNames.SUB_CHAT_GROUP_QR,
  outputIds: [TabNames.SUB_CHAT_GROUP_QR  ],
  title: "Chat Group Qr",
  title_ios: "Chat Group Qr",
  cat: "sub",
  web: {
    android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/WF_chat_group_QR_AN_L.webp",
    android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/WF_chat_group_QR_AN_L.webp",
    android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/WF_chat_group_QR_AN_L.webp",
    ios_url_f: "",
    ios_url_l: "",
    ios_url_s: "",
    dark_android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat_group_QR_AN_L.webp",
    dark_android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat_group_QR_AN_L.webp",
    dark_android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat_group_QR_AN_L.webp",
    dark_ios_url_f: "",
    dark_ios_url_l: "",
    dark_ios_url_s: ""
  }
},
[TabNames.SUB_CHAT_GROUP_LOCK_VOICE_MESSAGE]: {
  id: TabNames.SUB_CHAT_GROUP_LOCK_VOICE_MESSAGE,
  type: TabNames.SUB_CHAT_GROUP_LOCK_VOICE_MESSAGE,
  module: TabNames.SUB_CHAT_GROUP_LOCK_VOICE_MESSAGE,
  outputIds: [TabNames.SUB_CHAT_GROUP_LOCK_VOICE_MESSAGE ],
  title: "Chat Group Lock Voice Message",
  title_ios: "Chat Group Lock Voice Message",
  cat: "sub",
  web: {
    android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/WF_chat_group_lock_voice-message_AN_L.webp",
    android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/WF_chat_group_lock_voice-message_AN_L.webp",
    android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/WF_chat_group_lock_voice-message_AN_L.webp",
    ios_url_f: "",
    ios_url_l: "",
    ios_url_s: "",
    dark_android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat_group_lock_voice-message_AN_L.webp",
    dark_android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat_group_lock_voice-message_AN_L.webp",
    dark_android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat_group_lock_voice-message_AN_L.webp",
    dark_ios_url_f: "",
    dark_ios_url_l: "",
    dark_ios_url_s: ""
  }
},
[TabNames.SUB_CHAT_GROUP_INVITE_ADMIN_PARTICIPANTS]: {
  id: TabNames.SUB_CHAT_GROUP_INVITE_ADMIN_PARTICIPANTS,
  type: TabNames.SUB_CHAT_GROUP_INVITE_ADMIN_PARTICIPANTS,
  module: TabNames.SUB_CHAT_GROUP_INVITE_ADMIN_PARTICIPANTS,
  outputIds: [TabNames.SUB_CHAT_GROUP_INVITE_ADMIN_PARTICIPANTS  ],
  title: "Chat Group Invite Admin Participants",
  title_ios: "Chat Group Invite Admin Participants",
  cat: "sub",
  web: {
    android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/WF_Chat_Group_invite_admin-participants_AN_L.webp",
    android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/WF_Chat_Group_invite_admin-participants_AN_L.webp",
    android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/WF_Chat_Group_invite_admin-participants_AN_L.webp",
    ios_url_f: "",
    ios_url_l: "",
    ios_url_s: "",
    dark_android_url_f: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Chat_Group_invite_admin-participants_AN_L.webp",
    dark_android_url_l: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Chat_Group_invite_admin-participants_AN_L.webp",
    dark_android_url_s: "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Chat_Group_invite_admin-participants_AN_L.webp",
    dark_ios_url_f: "",
    dark_ios_url_l: "",
    dark_ios_url_s: ""
  }
}

};

export const common_tab_migration_map = {
  [TabNames.BOOKING]: {
    icon: common_components_map[TabNames.BOOKING].icon,
    icon_ios: common_components_map[TabNames.BOOKING].icon_ios,
  },

  [TabNames.BOOKINGLIST]: {
    icon: common_components_map[TabNames.BOOKINGLIST].icon,
    icon_ios: common_components_map[TabNames.BOOKINGLIST].icon_ios,
  },

  [TabNames.CALENDAR]: {
    icon: common_components_map[TabNames.CALENDAR].icon,
    icon_ios: common_components_map[TabNames.CALENDAR].icon_ios,
  },

  [TabNames.CALL_LOG]: {
    icon: common_components_map[TabNames.CALL_LOG].icon,
    icon_ios: common_components_map[TabNames.CALL_LOG].icon_ios,
  },

  [TabNames.CHANNEL]: {
    icon: common_components_map[TabNames.CHANNEL].icon,
    icon_ios: common_components_map[TabNames.CHANNEL].icon_ios,
  },

  [TabNames.CHANNELLIST]: {
    icon: common_components_map[TabNames.CHANNELLIST].icon,
    icon_ios: common_components_map[TabNames.CHANNELLIST].icon_ios,
  },

  [TabNames.CHAT]: {
    icon: common_components_map[TabNames.CHAT].icon,
    icon_ios: common_components_map[TabNames.CHAT].icon_ios,
  },

  [TabNames.CONTACT]: {
    icon: common_components_map[TabNames.CONTACT].icon,
    icon_ios: common_components_map[TabNames.CONTACT].icon_ios,
  },

  [TabNames.EMPTY]: {
    icon: common_components_map[TabNames.EMPTY].icon,
    icon_ios: common_components_map[TabNames.EMPTY].icon_ios,
  },

  [TabNames.EVENT]: {
    icon: common_components_map[TabNames.EVENT].icon,
    icon_ios: common_components_map[TabNames.EVENT].icon_ios,
  },
  [TabNames.EVENTLIST]: {
    icon: common_components_map[TabNames.EVENTLIST].icon,
    icon_ios: common_components_map[TabNames.EVENTLIST].icon_ios,
  },
  [TabNames.FEED]: {
    icon: common_components_map[TabNames.FEED].icon,
    icon_ios: common_components_map[TabNames.FEED].icon_ios,
    like_icon: "mir_thumb_up",
    liked_icon: "mis_thumb_up",
    reply_icon: "mir_mode_comment",
    view_icon: "mis_check_circle",
    share_icon: "mir_east",
  },
  [TabNames.GROUP]: {
    icon: common_components_map[TabNames.GROUP].icon,
    icon_ios: common_components_map[TabNames.GROUP].icon_ios,
  },
  [TabNames.INVITATION]: {
    icon: common_components_map[TabNames.INVITATION].icon,
    icon_ios: common_components_map[TabNames.INVITATION].icon_ios,
  },
  [TabNames.MAP_SEARCH]: {
    icon: common_components_map[TabNames.MAP_SEARCH].icon,
    icon_ios: common_components_map[TabNames.MAP_SEARCH].icon_ios,
  },


  [TabNames.MENU]: {
    icon: common_components_map[TabNames.MENU].icon,
    icon_ios: common_components_map[TabNames.MENU].icon_ios,
  },

  [TabNames.ONLINECHANNEL]: {
    icon: common_components_map[TabNames.ONLINECHANNEL].icon,
    icon_ios: common_components_map[TabNames.ONLINECHANNEL].icon_ios,
  },
  [TabNames.ONLINEGROUP]: {
    icon: common_components_map[TabNames.ONLINEGROUP].icon,
    icon_ios: common_components_map[TabNames.ONLINEGROUP].icon_ios,
  },

  [TabNames.OPENBOT]: {
    icon: common_components_map[TabNames.OPENBOT].icon,
    icon_ios: common_components_map[TabNames.OPENBOT].icon_ios,
  },

  [TabNames.OPENCHAT]: {
    icon: common_components_map[TabNames.OPENCHAT].icon,
    icon_ios: common_components_map[TabNames.OPENCHAT].icon_ios,
  },

  [TabNames.OPENGROUP]: {
    icon: common_components_map[TabNames.OPENGROUP].icon,
    icon_ios: common_components_map[TabNames.OPENGROUP].icon_ios,
  },

  [TabNames.ORDERLIST]: {
    icon: common_components_map[TabNames.ORDERLIST].icon,
    icon_ios: common_components_map[TabNames.ORDERLIST].icon_ios,
  },

  [TabNames.PAGE]: {
    icon: common_components_map[TabNames.PAGE].icon,
    icon_ios: common_components_map[TabNames.PAGE].icon_ios,
  },

  [TabNames.QR]: {
    icon: common_components_map[TabNames.QR].icon,
    icon_ios: common_components_map[TabNames.QR].icon_ios,
  },

    [TabNames.QUEUINGLIST]: {
    icon: common_components_map[TabNames.QUEUINGLIST].icon,
    icon_ios: common_components_map[TabNames.QUEUINGLIST].icon_ios,
  },

  [TabNames.REDEEM_TICKET]: {
    icon: common_components_map[TabNames.REDEEM_TICKET].icon,
    icon_ios: common_components_map[TabNames.REDEEM_TICKET].icon_ios,
  },

  [TabNames.SEARCH]: {
    icon: common_components_map[TabNames.SEARCH].icon,
    icon_ios: common_components_map[TabNames.SEARCH].icon_ios,
  },

  [TabNames.SETTINGS]: {
    icon: common_components_map[TabNames.SETTINGS].icon,
    icon_ios: common_components_map[TabNames.SETTINGS].icon_ios,
  },

  [TabNames.SPEEDDIALER]: {
    icon: common_components_map[TabNames.SPEEDDIALER].icon,
    icon_ios: common_components_map[TabNames.SPEEDDIALER].icon_ios,
  },

  [TabNames.VALIDATE_TICKET]: {
    icon: common_components_map[TabNames.VALIDATE_TICKET].icon,
    icon_ios: common_components_map[TabNames.VALIDATE_TICKET].icon_ios,
  },

  [TabNames.VIDEO]: {
    icon: common_components_map[TabNames.VIDEO].icon,
    icon_ios: common_components_map[TabNames.VIDEO].icon_ios,
  },

  [TabNames.VIDEOAUDIO]: {
    icon: common_components_map[TabNames.VIDEOAUDIO].icon,
    icon_ios: common_components_map[TabNames.VIDEOAUDIO].icon_ios,
  },

  [TabNames.WEBVIEW]: {
    icon: common_components_map[TabNames.WEBVIEW].icon,
    icon_ios: common_components_map[TabNames.WEBVIEW].icon_ios,
  },

  [TabNames.PACKAGE]: {
    icon: common_components_map[TabNames.EMPTY].icon,
    icon_ios: common_components_map[TabNames.EMPTY].icon_ios,
  },

  [TabNames.BUNDLE]: {
    icon: common_components_map[TabNames.EMPTY].icon,
    icon_ios: common_components_map[TabNames.EMPTY].icon_ios,
  },
  [TabNames.CATEGORY]: {
    icon: common_components_map[TabNames.CATEGORY].icon,
    icon_ios: common_components_map[TabNames.CATEGORY].icon_ios,
  },
  [TabNames.COLLECTION]: {
    icon: common_components_map[TabNames.COLLECTION].icon,
    icon_ios: common_components_map[TabNames.COLLECTION].icon_ios,
  },
  [TabNames.BOOKINGPRODUCT]: {
    icon: common_components_map[TabNames.BOOKINGPRODUCT].icon,
    icon_ios: common_components_map[TabNames.BOOKINGPRODUCT].icon_ios,
  },
  [TabNames.EVENTPRODUCT]: {
    icon: common_components_map[TabNames.EVENTPRODUCT].icon,
    icon_ios: common_components_map[TabNames.EVENTPRODUCT].icon_ios,
  },
  [TabNames.CART]: {
    icon: common_components_map[TabNames.CART].icon,
    icon_ios: common_components_map[TabNames.CART].icon_ios,
  },
  [TabNames.PRODUCT]: {
    icon: common_components_map[TabNames.PRODUCT].icon,
    icon_ios: common_components_map[TabNames.PRODUCT].icon_ios,
  },


  [TabNames.QR_PAGE]: {
    icon: common_components_map[TabNames.QR].icon,
    icon_ios: common_components_map[TabNames.QR].icon_ios,
    type: common_components_map[TabNames.QR].type,
  },
};

export const splash_album_map = {
  [SPLASH_STYLE.STYLE_01]: {
    albums: [
      {
        id: 1,
        en: false,
        emptyImage: "",
        padding: "0",
        opacity: 0,
        blur: "0px",
      },
      {
        id: 2,
        en: true,
        emptyImage: "./assets/images/mobile/splash81.webp",
        padding: 0,
        opacity: 0,
        blur: "0px",
      },
      {
        id: 3,
        en: false,
        emptyImage: "",
        padding: "3px",
        opacity: 0,
        blur: "0px",
        round: "rounded-0",
      },
      {
        id: 4,
        en: true,
        emptyImage: "./assets/images/mobile/splash19.webp",
        padding: 0,
        opacity: 0,
        blur: "0px",
        round: "rounded-0",
      },
      {
        id: 5,
        en: false,
        emptyImage: "",
        padding: "0",
        opacity: 0,
        blur: "0px",
      },
    ],
  },
  [SPLASH_STYLE.STYLE_02]: {
    albums: [
      {
        id: 1,
        en: true,
        emptyImage: "./assets/images/mobile/splash12.webp",
        padding: "0",
        opacity: 0,
        blur: "0px",
      },
      {
        id: 2,
        en: true,
        emptyImage: "./assets/images/mobile/splash38.webp",
        padding: "0",
        opacity: 0,
        blur: "0px",
      },
      {
        id: 3,
        en: true,
        emptyImage: "./assets/images/mobile/splash25.webp",
        padding: "3px",
        opacity: 0,
        blur: "0px",
        round: "rounded-0",
      },
      {
        id: 4,
        en: true,
        emptyImage: "./assets/images/mobile/splash25.webp",
        padding: "0",
        opacity: 0,
        blur: "0px",
      },
      {
        id: 5,
        en: false,
        emptyImage: "",
        padding: "0",
        opacity: 0,
        blur: "0px",
      },
    ],
  },
  [SPLASH_STYLE.STYLE_03]: {
    albums: [
      {
        id: 1,
        en: true,
        emptyImage: "./assets/images/mobile/splash12.webp",
        padding: "0",
        opacity: 0,
        blur: "0px",
      },
      {
        id: 2,
        en: true,
        emptyImage: "./assets/images/mobile/splash50.webp",
        padding: "0",
        opacity: 0,
        blur: "0px",
      },
      {
        id: 3,
        en: true,
        emptyImage: "./assets/images/mobile/splash19.webp",
        padding: "3px",
        opacity: 0,
        blur: "20px",
        round: "rounded-top-4",
      },
      {
        id: 4,
        en: true,
        emptyImage: "./assets/images/mobile/splash19.webp",
        padding: "0",
        opacity: 0,
        blur: "20px",
        round: "rounded-0",
      },
      {
        id: 5,
        en: false,
        emptyImage: "",
        padding: "0",
        opacity: 0,
        blur: "30px",
      },
    ],
  },
  [SPLASH_STYLE.STYLE_04]: {
    albums: [
      {
        id: 1,
        en: true,
        emptyImage: "./assets/images/mobile/splash10.webp",
        padding: "0",
        opacity: 0,
        blur: "0px",
      },
      {
        id: 2,
        en: true,
        emptyImage: "./assets/images/mobile/splash33.webp",
        padding: "0",
        opacity: 0,
        blur: "0px",
      },
      {
        id: 3,
        en: true,
        emptyImage: "./assets/images/mobile/splash38.webp",
        padding: "3px",
        opacity: 0,
        blur: "0px",
      },
      {
        id: 4,
        en: true,
        emptyImage: "./assets/images/mobile/splash19.webp",
        padding: "0",
        opacity: 0,
        blur: "0px",
        round: "rounded-top-4",
      },
      {
        id: 5,
        en: false,
        emptyImage: "",
        padding: "0",
        opacity: 0,
        blur: "0px",
      },
    ],
  },
  [SPLASH_STYLE.STYLE_05]: {
    albums: [
      {
        id: 1,
        en: false,
        emptyImage: "",
        padding: "0",
        opacity: 0,
        blur: "0px",
      },
      {
        id: 2,
        en: true,
        emptyImage: "./assets/images/mobile/splash50.webp",
        padding: "0",
        opacity: 0,
        blur: "0px",
      },
      {
        id: 3,
        en: true,
        emptyImage: "./assets/images/mobile/splash27.webp",
        padding: "3px",
        opacity: 0,
        blur: "0px",
        round: "rounded-0",
      },
      {
        id: 4,
        en: true,
        emptyImage: "./assets/images/mobile/splash23.webp",
        padding: "0",
        opacity: 0,
        blur: "0px",
        round: "rounded-0",
      },
      {
        id: 5,
        en: false,
        emptyImage: "",
        padding: "0",
        opacity: 0,
        blur: "0px",
      },
    ],
  },
  [SPLASH_STYLE.STYLE_06]: {
    albums: [
      {
        id: 1,
        en: true,
        emptyImage: "./assets/images/mobile/splash8.webp",
        padding: "0",
        opacity: 0,
        blur: "0px",
      },
      {
        id: 2,
        en: true,
        emptyImage: "./assets/images/mobile/splash31.webp",
        padding: "0",
        opacity: 0,
        blur: "0px",
      },
      {
        id: 3,
        en: true,
        emptyImage: "./assets/images/mobile/splash38.webp",
        padding: "5px",
        opacity: 0,
        blur: "0px",
        round: "rounded-0",
      },
      {
        id: 4,
        en: true,
        emptyImage: "./assets/images/mobile/splash23.webp",
        padding: "0",
        opacity: 0,
        blur: "0px",
      },
      {
        id: 5,
        en: false,
        emptyImage: "",
        padding: "0",
        opacity: 0,
        blur: "0px",
      },
    ],
  },
  [SPLASH_STYLE.STYLE_07]: {
    albums: [
      {
        id: 1,
        en: true,
        emptyImage: "./assets/images/mobile/splash12.webp",
        padding: "0",
        opacity: 0,
        blur: "0px",
      },
      {
        id: 2,
        en: true,
        emptyImage: "./assets/images/mobile/splash27.webp",
        padding: "0",
        opacity: 0,
        blur: "0px",
      },
      {
        id: 3,
        en: true,
        emptyImage: "./assets/images/mobile/splash38.webp",
        padding: "3px",
        opacity: 0,
        blur: "0px",
        round: "rounded-0",
      },
      {
        id: 4,
        en: true,
        emptyImage: "./assets/images/mobile/splash23.webp",
        padding: "0",
        opacity: 0,
        blur: "0px",
      },
      {
        id: 5,
        en: false,
        emptyImage: "",
        padding: "0",
        opacity: 0,
        blur: "0px",
      },
    ],
  },
};



export const album_input_map = {
  [MENU_BUTTON_STYLE?.STYLE_01]: {
    albums: [
      {
        id: 0,
        en: true,
        emptyImage: {
          1: "./assets/images/mobile/splash8.webp",
          2: "./assets/images/mobile-2/splash8.webp",
          3: "./assets/images/mobile-3/splash8.webp",
        },
        padding: 0,
        opacity: 0,
        blur: "0px",
        round: "rounded-2",
        border: "border border-2 rounded-2",
        border_s: "border border-1 rounded-1",
      },
    ],
    emptyFullImage: {
      1: "./assets/images/mobile/splash10.webp",
      2: "./assets/images/mobile-2/splash10.webp",
      3: "./assets/images/mobile-3/splash10.webp",
    }, //100
    settings: { titleSize: "14px", round: "rounded-2", round_s: "rounded-1" },
  },

  [MENU_BUTTON_STYLE?.STYLE_02]: {
    albums: [
      {
        id: 0,
        en: true,
        emptyImage: {
          1: "./assets/images/mobile/splash8.webp",
          2: "./assets/images/mobile-2/splash8.webp",
          3: "./assets/images/mobile-3/splash8.webp",
        },
        padding: 0,
        opacity: 0,
        blur: "0px",
        round: "rounded-2",
        border: "border border-2 rounded-2",
        border_s: "border border-1 rounded-1",
      },
    ],
    emptyFullImage: {
      1: "./assets/images/mobile/splash8.webp",
      2: "./assets/images/mobile-2/splash8.webp",
      3: "./assets/images/mobile-3/splash8.webp",
    },
    settings: { titleSize: "14px", round: "rounded-2", round_s: "rounded-1" },
  },

  [MENU_BUTTON_STYLE?.STYLE_03]: {
    albums: [
      {
        id: 0,
        en: true,
        emptyImage: {
          1: "./assets/images/mobile/splash8.webp",
          2: "./assets/images/mobile-2/splash8.webp",
          3: "./assets/images/mobile-3/splash8.webp",
        },
        padding: 0,
        opacity: 0,
        blur: "0px",
        round: "rounded-2",
        border: "",
        border_s: "",
      },
    ],
    emptyFullImage: {
      1: "./assets/images/mobile/splash8.webp",
      2: "./assets/images/mobile-2/splash8.webp",
      3: "./assets/images/mobile-3/splash8.webp",
    }, //100
    settings: {
      titleSize: "14px",
      round: "rounded-top-2",
      round_s: "rounded-top-1",
    },
  },
};




export const AndroidAPILevel = {
  "14": "Ice Cream Sandwich",
  "15": "Ice Cream Sandwich",
  "16": "Jelly Bean",
  "17": "Jelly Bean",
  "18": "Jelly Bean",
  "19": "Kitkat",
  "20": "Kitkat Watch",
  "21": "Lollipop",
  "22": "Lollipop",
  "23": "Marshmallow",
  "24": "Nougat",
  "25": "Nougat",
  "26": "Oreo",
  "27": "Oreo",
  "28": "Pie",
  "29": "Android 10",
  "30": "Android 11",
  "31": "Android 12",
  "32": "Android 12",
  "33": "Android 13",
  "34": "Android 14",
  "14+": "Ice Cream Sandwich",
  "15+": "Ice Cream Sandwich",
  "16+": "Jelly Bean",
  "17+": "Jelly Bean",
  "18+": "Jelly Bean",
  "19+": "Kitkat",
  "20+": "Kitkat Watch",
  "21+": "Lollipop",
  "22+": "Lollipop",
  "23+": "Marshmallow",
  "24+": "Nougat",
  "25+": "Nougat",
  "26+": "Oreo",
  "27+": "Oreo",
  "28+": "Pie",
  "29+": "Android 10",
  "30+": "Android 11",
  "31+": "Android 12",
  "32+": "Android 12",
  "33+": "Android 13",
  "34+": "Android 14",
};


export const material_icons = [
  {
    name: "Account Balance",
    id: "account_balance",
    cat: "Business",
  },
  {
    name: "Account Balance Wallet",
    id: "account_balance_wallet",
    cat: "Business",
  },
  {
    name: "Account Circle",
    id: "account_circle",
    cat: "Common Actions",
  },
  {
    name: "Add",
    id: "add",
    cat: "UI actions",
  },
  {
    name: "Add Card",
    id: "add_card",
    cat: "Business",
  },
  {
    name: "Airlines",
    id: "airlines",
    cat: "Transportation",
  },
  {
    name: "Airplane Ticket",
    id: "airplane_ticket",
    cat: "Travel",
  },
  {
    name: "Album",
    id: "album",
    cat: "Audio & Video",
  },
  {
    name: "Apartment",
    id: "apartment",
    cat: "Travel",
  },
  {
    name: "Apps",
    id: "apps",
    cat: "UI actions",
  },
  {
    name: "Arrow Right",
    id: "arrow_right",
    cat: "UI actions",
  },
  {
    name: "Article",
    id: "article",
    cat: "Text Formatting",
  },
  {
    name: "Assignment",
    id: "assignment",
    cat: "Text Formatting",
  },
  {
    name: "Assured Workload",
    id: "assured_workload",
    cat: "Privacy & Security",
  },
  {
    name: "Attach Money",
    id: "attach_money",
    cat: "Business",
  },
  {
    name: "Auto Stories",
    id: "auto_stories",
    cat: "Photo & Image",
  },
  {
    name: "Badge",
    id: "badge",
    cat: "Privacy & Security",
  },
  {
    name: "Beach Access",
    id: "beach_access",
    cat: "Travel",
  },
  {
    name: "Bed",
    id: "bed",
    cat: "Household",
  },
  {
    name: "Bloodtype",
    id: "bloodtype",
    cat: "Social",
  },
  {
    name: "Book",
    id: "book",
    cat: "Text Formatting",
  },
  {
    name: "Bookmarks",
    id: "bookmarks",
    cat: "Common Actions",
  },
  {
    name: "Build",
    id: "build",
    cat: "Common Actions",
  },
  {
    name: "Calculate",
    id: "calculate",
    cat: "Business",
  },
  {
    name: "Calendar month",
    id: "calendar_month",
    cat: "Common Actions",
  },
  {
    name: "Call",
    id: "call",
    cat: "Communication",
  },
  {
    name: "Call Made",
    id: "call_made",
    cat: "Communication",
  },
  {
    name: "Camera",
    id: "camera",
    cat: "Photo & Image",
  },
  {
    name: "Campaign",
    id: "campaign",
    cat: "Activities",
  },
  {
    name: "Car Rental",
    id: "car_rental",
    cat: "Travel",
  },
  {
    name: "Car Repair",
    id: "car_repair",
    cat: "Travel",
  },
  {
    name: "Card Membership",
    id: "card_membership",
    cat: "Business",
  },
  {
    name: "Carpenter",
    id: "carpenter",
    cat: "Travel",
  },
  {
    name: "Category",
    id: "category",
    cat: "Common Actions",
  },
  {
    name: "Chair",
    id: "chair",
    cat: "Household",
  },
  {
    name: "Chalet",
    id: "chalet",
    cat: "Travel",
  },
  {
    name: "Chat",
    id: "chat",
    cat: "Communication",
  },
  {
    name: "Chat Bubble",
    id: "chat_bubble",
    cat: "Communication",
  },
  {
    name: "Check Circle",
    id: "check_circle",
    cat: "Travel",
  },
  {
    name: "Checkroom",
    id: "checkroom",
    cat: "Household",
  },
  {
    name: "Church",
    id: "church",
    cat: "Maps",
  },
  {
    name: "Circle",
    id: "circle",
    cat: "Photo & Image",
  },
  {
    name: "Cleaning Services",
    id: "cleaning_services",
    cat: "Maps",
  },
  {
    name: "Clear All",
    id: "clear_all",
    cat: "UI actions",
  },
  {
    name: "Coffee",
    id: "coffee",
    cat: "Household",
  },
  {
    name: "Comment",
    id: "comment",
    cat: "Communication",
  },
  {
    name: "Confirmation Number",
    id: "confirmation_number",
    cat: "Activities",
  },
  {
    name: "Construction",
    id: "construction",
    cat: "Activities",
  },
  {
    name: "Contact Page",
    id: "contact_page",
    cat: "Text Formatting",
  },
  {
    name: "Contacts",
    id: "contacts",
    cat: "Communication",
  },
  {
    name: "Credit Card",
    id: "credit_card",
    cat: "Business",
  },
  {
    name: "Dashboard",
    id: "dashboard",
    cat: "Text Formatting",
  },
  {
    name: "Dataset",
    id: "dataset",
    cat: "UI actions",
  },
  {
    name: "Dehaze",
    id: "dehaze",
    cat: "Photo & Image",
  },
  {
    name: "Diamond",
    id: "diamond",
    cat: "Social",
  },
  {
    name: "Dining",
    id: "dining",
    cat: "Household",
  },
  {
    name: "Directions Bus",
    id: "directions_bus",
    cat: "Transportation",
  },
  {
    name: "Diversity 1",
    id: "diversity_1",
    cat: "Social",
  },
  {
    name: "Diversity 3",
    id: "diversity_3",
    cat: "Social",
  },
  {
    name: "Domain",
    id: "domain",
    cat: "Business",
  },
  {
    name: "Done",
    id: "done",
    cat: "Travel",
  },
  {
    name: "Doorbell",
    id: "doorbell",
    cat: "Household",
  },
  {
    name: "Double Arrow",
    id: "double_arrow",
    cat: "UI actions",
  },
  {
    name: "Downhill Skiing",
    id: "downhill_skiing",
    cat: "Activities",
  },
  {
    name: "Drag Indicator",
    id: "drag_indicator",
    cat: "UI actions",
  },
  {
    name: "East",
    id: "east",
    cat: "Maps",
  },
  {
    name: "Eco",
    id: "eco",
    cat: "Social",
  },
  {
    name: "Electric Bolt",
    id: "electric_bolt",
    cat: "Household",
  },
  {
    name: "Electrical services",
    id: "electrical_services",
    cat: "Maps",
  },
  {
    name: "Emergency",
    id: "emergency",
    cat: "Maps",
  },
  {
    name: "Emoji Events",
    id: "emoji_events",
    cat: "Social",
  },
  {
    name: "Emoji Objects",
    id: "emoji_objects",
    cat: "Social",
  },
  {
    name: "Emoji Transportation",
    id: "emoji_transportation",
    cat: "Social",
  },
  {
    name: "Equalizer",
    id: "equalizer",
    cat: "Audio & Video",
  },
  {
    name: "Event",
    id: "event",
    cat: "Common Actions",
  },
  {
    name: "Event Available",
    id: "event_available",
    cat: "Common Actions",
  },
  {
    name: "Explore",
    id: "explore",
    cat: "Maps",
  },
  {
    name: "Fastfood",
    id: "fastfood",
    cat: "Maps",
  },
  {
    name: "Favorite",
    id: "favorite",
    cat: "UI actions",
  },
  {
    name: "Feed",
    id: "feed",
    cat: "Text Formatting",
  },
  {
    name: "Fitness Center",
    id: "fitness_center",
    cat: "Travel",
  },
  {
    name: "Flag",
    id: "flag",
    cat: "Maps",
  },
  {
    name: "Flatware",
    id: "flatware",
    cat: "Household",
  },
  {
    name: "Flight",
    id: "flight",
    cat: "Transportation",
  },
  {
    name: "Format List Bulleted",
    id: "format_list_bulleted",
    cat: "Text Formatting",
  },
  {
    name: "Forum",
    id: "forum",
    cat: "Communication",
  },
  {
    name: "Fullscreen",
    id: "fullscreen",
    cat: "UI actions",
  },
  {
    name: "Gite",
    id: "gite",
    cat: "Travel",
  },
  {
    name: "Grade",
    id: "grade",
    cat: "UI actions",
  },
  {
    name: "Grain",
    id: "grain",
    cat: "Photo & Image",
  },
  {
    name: "Grass",
    id: "grass",
    cat: "Household",
  },
  {
    name: "Grid View",
    id: "grid_view",
    cat: "Text Formatting",
  },
  {
    name: "Group",
    id: "group",
    cat: "Social",
  },
  {
    name: "Group Add",
    id: "group_add",
    cat: "Social",
  },
  {
    name: "Groups",
    id: "groups",
    cat: "Social",
  },
  {
    name: "Handshake",
    id: "handshake",
    cat: "Social",
  },
  {
    name: "Handyman",
    id: "handyman",
    cat: "Maps",
  },
  {
    name: "Hdr Strong",
    id: "hdr_strong",
    cat: "Photo & Image",
  },
  {
    name: "Headphones",
    id: "headphones",
    cat: "Hardware",
  },
  {
    name: "Health And Safety",
    id: "health_and_safety",
    cat: "Social",
  },
  {
    name: "Help",
    id: "help",
    cat: "Common Actions",
  },
  {
    name: "Help Center",
    id: "help_center",
    cat: "Common Actions",
  },
  {
    name: "Hexagon",
    id: "hexagon",
    cat: "Text Formatting",
  },
  {
    name: "Hiking",
    id: "hiking",
    cat: "Activities",
  },
  {
    name: "Hive",
    id: "hive",
    cat: "Social",
  },
  {
    name: "Home",
    id: "home",
    cat: "Travel",
  },
  {
    name: "Home Repair Service",
    id: "home_repair_service",
    cat: "Maps",
  },
  {
    name: "Home Work",
    id: "home_work",
    cat: "Maps",
  },
  {
    name: "Hotel",
    id: "hotel",
    cat: "Travel",
  },
  {
    name: "House",
    id: "house",
    cat: "Household",
  },
  {
    name: "House Siding",
    id: "house_siding",
    cat: "Household",
  },
  {
    name: "Import Contacts",
    id: "import_contacts",
    cat: "Communication",
  },
  {
    name: "Info",
    id: "info",
    cat: "Common Actions",
  },
  {
    name: "Interests",
    id: "interests",
    cat: "Common Actions",
  },
  {
    name: "Keyboard Arrow Down",
    id: "keyboard_arrow_down",
    cat: "Hardware",
  },
  {
    name: "Keyboard Arrow Left",
    id: "keyboard_arrow_left",
    cat: "Hardware",
  },
  {
    name: "Keyboard Arrow Right",
    id: "keyboard_arrow_right",
    cat: "Hardware",
  },
  {
    name: "Keyboard Arrow Up",
    id: "keyboard_arrow_up",
    cat: "Hardware",
  },
  {
    name: "Keyboard Backspace",
    id: "keyboard_backspace",
    cat: "Hardware",
  },
  {
    name: "Landscape",
    id: "landscape",
    cat: "Photo & Image",
  },
  {
    name: "Lens Blur",
    id: "lens_blur",
    cat: "Photo & Image",
  },
  {
    name: "Library Books",
    id: "library_books",
    cat: "Audio & Video",
  },
  {
    name: "Library Music",
    id: "library_music",
    cat: "Audio & Video",
  },
  {
    name: "Light",
    id: "light",
    cat: "Household",
  },
  {
    name: "List",
    id: "list",
    cat: "Text Formatting",
  },
  {
    name: "Live Help",
    id: "live_help",
    cat: "Communication",
  },
  {
    name: "Local Activity",
    id: "local_activity",
    cat: "Maps",
  },
  {
    name: "Local Cafe",
    id: "local_cafe",
    cat: "Travel",
  },
  {
    name: "Local Car wash",
    id: "local_car_wash",
    cat: "Maps",
  },
  {
    name: "Local Library",
    id: "local_library",
    cat: "Maps",
  },
  {
    name: "Local Mall",
    id: "local_mall",
    cat: "Maps",
  },
  {
    name: "Local Shipping",
    id: "local_shipping",
    cat: "Text Formatting",
  },
  {
    name: "Local Taxi",
    id: "local_taxi",
    cat: "Transportation",
  },
  {
    name: "Location City",
    id: "location_city",
    cat: "Travel",
  },
  {
    name: "Loyalty",
    id: "loyalty",
    cat: "Business",
  },
  {
    name: "Luggage",
    id: "luggage",
    cat: "Travel",
  },
  {
    name: "Lunch Dining",
    id: "lunch_dining",
    cat: "Travel",
  },
  {
    name: "Medical Information",
    id: "medical_information",
    cat: "Social",
  },
  {
    name: "Medical Services",
    id: "medical_services",
    cat: "Maps",
  },
  {
    name: "Medication",
    id: "medication",
    cat: "Social",
  },
  {
    name: "Menu",
    id: "menu",
    cat: "Travel",
  },
  {
    name: "Menu Book",
    id: "menu_book",
    cat: "Text Formatting",
  },
  {
    name: "Mode Comment",
    id: "mode_comment",
    cat: "Communication",
  },
  {
    name: "Mood",
    id: "mood",
    cat: "Social",
  },
  {
    name: "More Vert",
    id: "more_vert",
    cat: "UI actions",
  },
  {
    name: "Mosque",
    id: "mosque",
    cat: "Maps",
  },
  {
    name: "movie",
    id: "movie",
    cat: "Audio & Video",
  },
  {
    name: "Music Note",
    id: "music_note",
    cat: "Audio & Video",
  },
  {
    name: "Music Video",
    id: "music_video",
    cat: "Audio & Video",
  },
  {
    name: "Navigate Before",
    id: "navigate_before",
    cat: "Photo & Image",
  },
  {
    name: "Navigate Next",
    id: "navigate_next",
    cat: "Photo & Image",
  },
  {
    name: "Navigation",
    id: "navigation",
    cat: "Maps",
  },
  {
    name: "Near Me",
    id: "near_me",
    cat: "Maps",
  },
  {
    name: "Newspaper",
    id: "newspaper",
    cat: "Text Formatting",
  },
  {
    name: "North",
    id: "north",
    cat: "Maps",
  },
  {
    name: "North East",
    id: "north_east",
    cat: "Maps",
  },
  {
    name: "North West",
    id: "north_west",
    cat: "Maps",
  },
  {
    name: "Notes",
    id: "notes",
    cat: "Text Formatting",
  },
  {
    name: "Notifications Active",
    id: "notifications_active",
    cat: "Communication",
  },
  {
    name: "Paid",
    id: "paid",
    cat: "Business",
  },
  {
    name: "Payments",
    id: "payments",
    cat: "Business",
  },
  {
    name: "Pedal Bike",
    id: "pedal_bike",
    cat: "Transportation",
  },
  {
    name: "Pentagon",
    id: "pentagon",
    cat: "Text Formatting",
  },
  {
    name: "Person",
    id: "person",
    cat: "Social",
  },
  {
    name: "Person Add",
    id: "person_add",
    cat: "Social",
  },
  {
    name: "Person Search",
    id: "person_search",
    cat: "Communication",
  },
  {
    name: "Pest Control",
    id: "pest_control",
    cat: "Maps",
  },
  {
    name: "Pets",
    id: "pets",
    cat: "Social",
  },
  {
    name: "Phone In Talk",
    id: "phone_in_talk",
    cat: "Communication",
  },
  {
    name: "Piano",
    id: "piano",
    cat: "Activities",
  },
  {
    name: "Play Arrow",
    id: "play_arrow",
    cat: "Audio & Video",
  },
  {
    name: "Play Circle",
    id: "play_circle",
    cat: "Audio & Video",
  },
  {
    name: "Playlist Add",
    id: "playlist_add",
    cat: "Audio & Video",
  },
  {
    name: "Playlist Add Check",
    id: "playlist_add_check",
    cat: "Audio & Video",
  },
  {
    name: "Plumbing",
    id: "plumbing",
    cat: "Maps",
  },
  {
    name: "Podcasts",
    id: "podcasts",
    cat: "Audio & Video",
  },
  {
    name: "Policy",
    id: "policy",
    cat: "Privacy & Security",
  },
  {
    name: "Privacy Tip",
    id: "privacy_tip",
    cat: "Privacy & Security",
  },
  {
    name: "Psychology",
    id: "psychology",
    cat: "Social",
  },
  {
    name: "Public",
    id: "public",
    cat: "Social",
  },
  {
    name: "QR Code",
    id: "qr_code",
    cat: "Business",
  },
  {
    name: "QR Code 2",
    id: "qr_code_2",
    cat: "Business",
  },
  {
    name: "QR Code Scanner",
    id: "qr_code_scanner",
    cat: "Business",
  },
  {
    name: "Question Mark",
    id: "question_mark",
    cat: "Common Actions",
  },
  {
    name: "Quiz",
    id: "quiz",
    cat: "Social",
  },
  {
    name: "Radio",
    id: "radio",
    cat: "Audio & Video",
  },
  {
    name: "Real Estate Agent",
    id: "real_estate_agent",
    cat: "Activities",
  },
  {
    name: "Receipt",
    id: "receipt",
    cat: "Business",
  },
  {
    name: "Rectangle",
    id: "rectangle",
    cat: "Text Formatting",
  },
  {
    name: "Report",
    id: "report",
    cat: "Privacy & Security",
  },
  {
    name: "Restaurant",
    id: "restaurant",
    cat: "Maps",
  },
  {
    name: "Restaurant Menu",
    id: "restaurant_menu",
    cat: "Maps",
  },
  {
    name: "Reviews",
    id: "reviews",
    cat: "Communication",
  },
  {
    name: "Rocket Launch",
    id: "rocket_launch",
    cat: "Social",
  },
  {
    name: "Roofing",
    id: "roofing",
    cat: "Household",
  },
  {
    name: "RSS Feed",
    id: "rss_feed",
    cat: "Android",
  },
  {
    name: "Saved Search",
    id: "saved_search",
    cat: "UI actions",
  },
  {
    name: "Schedule",
    id: "schedule",
    cat: "Common Actions",
  },
  {
    name: "School",
    id: "school",
    cat: "Activities",
  },
  {
    name: "Science",
    id: "science",
    cat: "Activities",
  },
  {
    name: "Search",
    id: "search",
    cat: "Travel",
  },
  {
    name: "Security",
    id: "security",
    cat: "Privacy & Security",
  },
  {
    name: "Self Improvement",
    id: "self_improvement",
    cat: "Activities",
  },
  {
    name: "Sell",
    id: "sell",
    cat: "Business",
  },
  {
    name: "Send",
    id: "send",
    cat: "Communication",
  },
  {
    name: "Settings",
    id: "settings",
    cat: "Travel",
  },
  {
    name: "Settings Phone",
    id: "settings_phone",
    cat: "Communication",
  },
  {
    name: "Shield",
    id: "shield",
    cat: "Privacy & Security",
  },
  {
    name: "Shopping Bag",
    id: "shopping_bag",
    cat: "Business",
  },
  {
    name: "Shopping Basket",
    id: "shopping_basket",
    cat: "Business",
  },
  {
    name: "Shopping Cart",
    id: "shopping_cart",
    cat: "Business",
  },
  {
    name: "Sms",
    id: "sms",
    cat: "Communication",
  },
  {
    name: "Snowboarding",
    id: "snowboarding",
    cat: "Activities",
  },
  {
    name: "Snowing",
    id: "snowing",
    cat: "Social",
  },
  {
    name: "South",
    id: "south",
    cat: "Maps",
  },
  {
    name: "South East",
    id: "south_east",
    cat: "Maps",
  },
  {
    name: "South West",
    id: "south_west",
    cat: "Maps",
  },
  {
    name: "Spa",
    id: "spa",
    cat: "Travel",
  },
  {
    name: "Sports Baseball",
    id: "sports_baseball",
    cat: "Activities",
  },
  {
    name: "Sports Basketball",
    id: "sports_basketball",
    cat: "Activities",
  },
  {
    name: "Sports Cricket",
    id: "sports_cricket",
    cat: "Activities",
  },
  {
    name: "Sports Gymnastics",
    id: "sports_gymnastics",
    cat: "Activities",
  },
  {
    name: "Sports Martial Arts",
    id: "sports_martial_arts",
    cat: "Activities",
  },
  {
    name: "Sports Soccer",
    id: "sports_soccer",
    cat: "Activities",
  },
  {
    name: "Sports Tennis",
    id: "sports_tennis",
    cat: "Activities",
  },
  {
    name: "Sports Volleyball",
    id: "sports_volleyball",
    cat: "Activities",
  },
  {
    name: "Star Half",
    id: "star_half",
    cat: "UI actions",
  },
  {
    name: "Star Rate",
    id: "star_rate",
    cat: "UI actions",
  },
  {
    name: "Stars",
    id: "stars",
    cat: "Common Actions",
  },
  {
    name: "Store",
    id: "store",
    cat: "Business",
  },
  {
    name: "Storefront",
    id: "storefront",
    cat: "Business",
  },
  {
    name: "Straight",
    id: "straight",
    cat: "Hardware",
  },
  {
    name: "subscriptions",
    id: "subscriptions",
    cat: "Audio & Video",
  },
  {
    name: "Subtitles",
    id: "subtitles",
    cat: "Audio & Video",
  },
  {
    name: "Subway",
    id: "subway",
    cat: "Transportation",
  },
  {
    name: "Sunny",
    id: "sunny",
    cat: "Social",
  },
  {
    name: "Support Agent",
    id: "support_agent",
    cat: "Social",
  },
  {
    name: "Surfing",
    id: "surfing",
    cat: "Activities",
  },
  {
    name: "Table Restaurant",
    id: "table_restaurant",
    cat: "Household",
  },
  {
    name: "Temple Hindu",
    id: "temple_hindu",
    cat: "Maps",
  },
  {
    name: "Theaters",
    id: "theaters",
    cat: "Activities",
  },
  {
    name: "Thumb Up",
    id: "thumb_up",
    cat: "Social",
  },
  {
    name: "Thumbs Up down",
    id: "thumbs_up_down",
    cat: "Social",
  },
  {
    name: "Train",
    id: "train",
    cat: "Transportation",
  },
  {
    name: "Travel Explore",
    id: "travel_explore",
    cat: "Social",
  },
  {
    name: "Trending Flat",
    id: "trending_flat",
    cat: "Business",
  },
  {
    name: "Tty",
    id: "tty",
    cat: "Hardware",
  },
  {
    name: "Vaccines",
    id: "vaccines",
    cat: "Social",
  },
  {
    name: "Vaping Rooms",
    id: "vaping_rooms",
    cat: "Social",
  },
  {
    name: "Verified User",
    id: "verified_user",
    cat: "Privacy & Security",
  },
  {
    name: "Vertical Split",
    id: "vertical_split",
    cat: "Text Formatting",
  },
  {
    name: "Video Call",
    id: "video_call",
    cat: "Audio & Video",
  },
  {
    name: "Video Camera Front",
    id: "video_camera_front",
    cat: "Audio & Video",
  },
  {
    name: "Video Library",
    id: "video_library",
    cat: "Audio & Video",
  },
  {
    name: "Videocam",
    id: "videocam",
    cat: "Audio & Video",
  },
  {
    name: "View Agenda",
    id: "view_agenda",
    cat: "Text Formatting",
  },
  {
    name: "View Comfy Alt",
    id: "view_comfy_alt",
    cat: "UI actions",
  },
  {
    name: "View Cozy",
    id: "view_cozy",
    cat: "UI actions",
  },
  {
    name: "View Headline",
    id: "view_headline",
    cat: "Text Formatting",
  },
  {
    name: "View Timeline",
    id: "view_timeline",
    cat: "UI actions",
  },
  {
    name: "Warning",
    id: "warning",
    cat: "Common Actions",
  },
  {
    name: "Water Drop",
    id: "water_drop",
    cat: "Social",
  },
  {
    name: "Web",
    id: "web",
    cat: "Common Actions",
  },
  {
    name: "West",
    id: "west",
    cat: "Maps",
  },
  {
    name: "Wheelchair Pickup",
    id: "wheelchair_pickup",
    cat: "Travel",
  },
  {
    name: "Work",
    id: "work",
    cat: "Business",
  },
  {
    name: "Workspace Premium",
    id: "workspace_premium",
    cat: "Social",
  },
  {
    name: "Workspaces",
    id: "workspaces",
    cat: "Social",
  },
  {
    name: "Psychology Alt",
    id: "psychology_alt",
    cat: "Social",
  },
  {
    name: "Waving Hand",
    id: "waving_hand",
    cat: "Social",
  },
  {
    name: "Back Hand",
    id: "back_hand",
    cat: "Social",
  },
  {
    name: "Front Hand",
    id: "front_hand",
    cat: "Social",
  },
  {
    name: "Personal Injury",
    id: "personal_injury",
    cat: "Social",
  },
  {
    name: "Military Tech",
    id: "military_tech",
    cat: "Social",
  },
  {
    name: "Pages",
    id: "pages",
    cat: "Social",
  },
  {
    name: "History Edu",
    id: "history_edu",
    cat: "Social",
  },
  {
    name: "Sign Language",
    id: "sign_language",
    cat: "Social",
  },
  {
    name: "Sports Motorsports",
    id: "sports_motorsports",
    cat: "Social",
  },
  {
    name: "Outdoor Grill",
    id: "outdoor_grill",
    cat: "Social",
  },
  {
    name: "Masks",
    id: "masks",
    cat: "Social",
  },
  {
    name: "Groups 3",
    id: "groups_3",
    cat: "Social",
  },
  {
    name: "Notifications",
    id: "notifications",
    cat: "Social",
  },
  {
    name: "Engineering",
    id: "engineering",
    cat: "Social",
  },
  {
    name: "Man",
    id: "man",
    cat: "Social",
  },
  {
    name: "Woman",
    id: "woman",
    cat: "Social",
  },
  {
    name: "Text Snippet",
    id: "text_snippet",
    cat: "File",
  },
  {
    name: "Ballot",
    id: "ballot",
    cat: "Content",
  },
  {
    name: "Biotech",
    id: "biotech",
    cat: "Content",
  },
  {
    name: "Inventory",
    id: "inventory",
    cat: "Content",
  },
  {
    name: "Inventory 2",
    id: "inventory_2",
    cat: "Content",
  },
  {
    name: "Waves",
    id: "waves",
    cat: "Content",
  },
  {
    name: "Iron",
    id: "iron",
    cat: "Places",
  },
  {
    name: "Holiday Village",
    id: "holiday_village",
    cat: "Places",
  },
  {
    name: "AC Unit",
    id: "ac_unit",
    cat: "Places",
  },
  {
    name: "Perm Phone Msg",
    id: "perm_phone_msg",
    cat: "Action",
  },
  {
    name: "Chrome Reader Mode",
    id: "chrome_reader_mode",
    cat: "Action",
  },
  {
    name: "Redeem",
    id: "redeem",
    cat: "Action",
  },
  {
    name: "Space Dashboard",
    id: "space_dashboard",
    cat: "Action",
  },
  {
    name: "Join Full",
    id: "join_full",
    cat: "Action",
  },
  {
    name: "Fact Check",
    id: "fact_check",
    cat: "Action",
  },
  {
    name: "Query Builder",
    id: "query_builder",
    cat: "Action",
  },
  {
    name: "Location On",
    id: "location_on",
    cat: "Communication",
  },
  {
    name: "Priority High",
    id: "priority_high",
    cat: "Notification",
  },
  {
    name: "Star",
    id: "star",
    cat: "Toggle",
  },
  {
    name: "Blur Linear",
    id: "blur_linear",
    cat: "Image",
  },
  {
    name: "Filter Center Focus",
    id: "filter_center_focus",
    cat: "Image",
  },
  {
    name: "Filter Vintage",
    id: "filter_vintage",
    cat: "Image",
  },
  {
    name: "Receipt Long",
    id: "receipt_long",
    cat: "Image",
  },
  {
    name: "Blur On",
    id: "blur_on",
    cat: "Image",
  },
  {
    name: "Auto Awesome",
    id: "auto_awesome",
    cat: "Image",
  },
  {
    name: "Garage",
    id: "garage",
    cat: "Search",
  },
  {
    name: "Post Add",
    id: "post_add",
    cat: "Editor",
  },
  {
    name: "Monitor Heart",
    id: "monitor_heart",
    cat: "Device",
  },
  {
    name: "Splitscreen",
    id: "splitscreen",
    cat: "Device",
  },
  {
    name: "Phishing",
    id: "phishing",
    cat: "Device",
  },
  {
    name: "Medication Liquid",
    id: "medication_liquid",
    cat: "Device",
  },
  {
    name: "Local Hospital",
    id: "local_hospital",
    cat: "Maps",
  },
  {
    name: "Local Pharmacy",
    id: "local_pharmacy",
    cat: "Maps",
  },
  {
    name: "Emergency Share",
    id: "emergency_share",
    cat: "Maps",
  },
  {
    name: "Map",
    id: "map",
    cat: "Maps",
  },
  {
    name: "Local Florist",
    id: "local_florist",
    cat: "Maps",
  },
  {
    name: "Two Wheeler",
    id: "two_wheeler",
    cat: "Maps",
  },
  {
    name: "Temple Buddhist",
    id: "temple_buddhist",
    cat: "Maps",
  },
  {
    name: "Dry Cleaning",
    id: "dry_cleaning",
    cat: "Maps",
  },
  {
    name: "Stadium",
    id: "stadium",
    cat: "Maps",
  },
  {
    name: "Celebration",
    id: "celebration",
    cat: "Maps",
  },
  {
    name: "Warehouse",
    id: "warehouse",
    cat: "Maps",
  },
  // added recently
  { name:"Theaters",id:"theaters",cat:"Activities"},
{ name:"Self Improvement",id:"self_improvement",cat:"Activities"},
{ name:"Hiking",id:"hiking",cat:"Activities"},
{ name:"Confirmation Number",id:"confirmation_number",cat:"Activities"},
{ name:"Movie",id:"movie",cat:"Audio & Video"},
{ name:"Playlist Add",id:"playlist_add",cat:"Audio & Video"},
{ name:"Mic",id:"mic",cat:"Audio & Video"},
{ name:"Barcode Reader",id:"barcode_reader",cat:"Business"},
{ name:"QR Code 2",id:"qr_code_2",cat:"Business"},
{ name:"QR Code Scanner",id:"qr_code_scanner",cat:"Business"},
{ name:"Schedule",id:"schedule",cat:"Common actions"},
{ name:"Visibility",id:"visibility",cat:"Common actions"},
{ name:"Upload File",id:"upload_file",cat:"Common actions"},
{ name:"Send",id:"send",cat:"Communication"},
{ name:"Link",id:"link",cat:"Communication"},
{ name:"Chat",id:"chat",cat:"Communication"},
{ name:"Chat Bubble",id:"chat_bubble",cat:"Communication"},
{ name:"Comment",id:"comment",cat:"Communication"},
{ name:"Mode Comment",id:"mode_comment",cat:"Communication"},
{ name:"Speaker Notes",id:"speaker_notes",cat:"Communication"},
{ name:"Drafts",id:"drafts",cat:"Communication"},
{ name:"Mail",id:"mail",cat:"Communication"},
{ name:"Smart Toy",id:"smart_toy",cat:"Hardware"},
// { name:"Robot 2",id:"robot_2",cat:"Hardware"},
{ name:"Book Online",id:"book_online",cat:"Hardware"},
{ name:"Event Seat",id:"event_seat",cat:"Household"},
{ name:"Theater Comedy",id:"theater_comedy",cat:"Maps"},
{ name:"Stadium",id:"stadium",cat:"Maps"},
{ name:"Local Activity",id:"local_activity",cat:"Maps"},
{ name:"Image",id:"image",cat:"Photo & Image"},
{ name:"Picture As Pdf",id:"picture_as_pdf",cat:"Photo & Image"},
{ name:"Photo",id:"photo",cat:"Photo & Image"},
{ name:"Edit",id:"edit",cat:"Photo & Image"},
{ name:"Photo Library",id:"photo_library",cat:"Photo & Image"},
{ name:"Add A Photo",id:"add_a_photo",cat:"Photo & Image"},
{ name:"Add Photo Alternate",id:"add_photo_alternate",cat:"Photo & Image"},
{ name:"Add To Photos",id:"add_to_photos",cat:"Photo & Image"},
{ name:"Report",id:"report",cat:"Privacy & Security"},
{ name:"Group",id:"group",cat:"Social"},
{ name:"Groups",id:"groups",cat:"Social"},
{ name:"Group Add",id:"group_add",cat:"Social"},
{ name:"Groups 3",id:"groups_3",cat:"Social"},
{ name:"Thumb Up",id:"thumb_up",cat:"Social"},
{ name:"Mood",id:"mood",cat:"Social"},
{ name:"Add Reaction",id:"add_reaction",cat:"Social"},
{ name:"Thumb Down",id:"thumb_down",cat:"Social"},
{ name:"Share",id:"share",cat:"Social"},
{ name:"List",id:"list",cat:"Text Formatting"},
{ name:"Format List Bulleted",id:"format_list_bulleted",cat:"Text Formatting"},
{ name:"File Copy",id:"file_copy",cat:"Text Formatting"},
{ name:"List Alt",id:"list_alt",cat:"Text Formatting"},
{ name:"Content Copy",id:"content_copy",cat:"Text Formatting"},
{ name:"Festival",id:"festival",cat:"Travel"},
{ name:"Museum",id:"museum",cat:"Travel"},
{ name:"Airplane Ticket",id:"airplane_ticket",cat:"Travel"},
{ name:"Favorite",id:"favorite",cat:"UI actions"},
{ name:"Delete",id:"delete",cat:"UI actions"},
{ name:"Delete Forever",id:"delete_forever",cat:"UI actions"},
// { name:"Selete Sweep",id:"selete_sweep",cat:"UI actions"},
{ name:"Backspace",id:"backspace",cat:"UI actions"},
{ name:"Remove",id:"remove",cat:"UI actions"},
{ name:"Close",id:"close",cat:"UI actions"},
{ name:"Cancel",id:"cancel",cat:"UI actions"},
{ name:"Block",id:"block",cat:"UI actions"},
{ name:"Library Add",id:"library_add",cat:"UI actions"},
{ name:"Settings",id:"settings",cat:"UI actions"},
{ name:"Settings Applications",id:"settings_applications",cat:"UI actions"},
{ name:"Reply",id:"reply",cat:"UI actions"},
{ name:"Arrow Forward",id:"arrow_forward",cat:"UI actions"},
{ name:"Forward",id:"forward",cat:"UI actions"},
{ name:"Arrow Forward iOS",id:"arrow_forward_ios",cat:"UI actions"},
{ name:"Download",id:"download",cat:"UI actions"},

];

export const material_icons_cat = [
  "Action",
  "Activities",
  "Android",
  "Audio & Video",
  "Business",
  "Common Actions",
  "Communication",
  "Content",
  "Device",
  "Editor",
  "File",
  "Hardware",
  "Household",
  "Image",
  "Maps",
  "Notification",
  "Photo & Image",
  "Places",
  "Privacy & Security",
  "Search",
  "Social",
  "Text Formatting",
  "Toggle",
  "Transportation",
  "Travel",
  "UI actions",

];

export enum LOGIN_TYPES {
  MSISDN = "MSISDN",
  EMAIL = "EMAIL",
  NO_LOGIN = "NO_LOGIN",
  FACEBOOK = "Facebook",
  LINKEDIN = "Linkedin",
  TWITTER = "Twitter",
}

export const EMAIL_VERIFICATION_MESSAGE = `
<h4 style="text-align:center;">$app_name - Verification Code</h4>
<h2 style="text-align:center;">$code</h2>
<p>Thank you for signing up for $app_name. Please enter this verification code in the app to complete the signup process. If you received this code in error, please ignore this message.</p>
`;

export const SMS_VERIFICATION_MESSAGE = `<#> $app_name verification code: $code Please use the code to complete registration.`;

export enum VendorsTypes {
  SMS = "sms_http",
  WHATSAPP = "whatsapp",
  VIBER = "viber",
}
export enum VendorsNames {
  CLICKATELL = "clickatell",
  VONAGE = "vonage",
  INFOBIP = "infobip",
}

export const mobCountries = [
  {
    isoCode2: "AF",
    isoCode3: "AFG",
    dial: "93",
    name: "Afghanistan",
  },
  {
    isoCode2: "AL",
    isoCode3: "ALB",
    dial: "355",
    name: "Albania",
  },
  {
    isoCode2: "DZ",
    isoCode3: "DZA",
    dial: "213",
    name: "Algeria",
  },
  {
    isoCode2: "AS",
    isoCode3: "ASM",
    dial: "1 - 684",
    name: "American Samoa",
  },
  {
    isoCode2: "AD",
    isoCode3: "AND",
    dial: "376",
    name: "Andorra",
  },
  {
    isoCode2: "AO",
    isoCode3: "AGO",
    dial: "244",
    name: "Angola",
  },
  {
    isoCode2: "AI",
    isoCode3: "AIA",
    dial: "1 - 264",
    name: "Anguilla",
  },
  {
    isoCode2: "AQ",
    isoCode3: "ATA",
    dial: "672",
    name: "Antarctica",
  },
  {
    isoCode2: "AG",
    isoCode3: "ATG",
    dial: "1 - 268",
    name: "Antigua & Barbuda",
  },
  {
    isoCode2: "AR",
    isoCode3: "ARG",
    dial: "54",
    name: "Argentina",
  },
  {
    isoCode2: "AM",
    isoCode3: "ARM",
    dial: "374",
    name: "Armenia",
  },
  {
    isoCode2: "AW",
    isoCode3: "ABW",
    dial: "297",
    name: "Aruba",
  },
  {
    isoCode2: "AU",
    isoCode3: "AUS",
    dial: "61",
    name: "Australia",
  },
  {
    isoCode2: "AT",
    isoCode3: "AUT",
    dial: "43",
    name: "Austria",
  },
  {
    isoCode2: "AZ",
    isoCode3: "AZE",
    dial: "994",
    name: "Azerbaijan",
  },
  {
    isoCode2: "BS",
    isoCode3: "BHS",
    dial: "1 - 242",
    name: "Bahamas",
  },
  {
    isoCode2: "BH",
    isoCode3: "BHR",
    dial: "973",
    name: "Bahrain",
  },
  {
    isoCode2: "BD",
    isoCode3: "BGD",
    dial: "880",
    name: "Bangladesh",
  },
  {
    isoCode2: "BB",
    isoCode3: "BRB",
    dial: "1 - 246",
    name: "Barbados",
  },
  {
    isoCode2: "BY",
    isoCode3: "BLR",
    dial: "375",
    name: "Belarus",
  },
  {
    isoCode2: "BE",
    isoCode3: "BEL",
    dial: "32",
    name: "Belgium",
  },
  {
    isoCode2: "BZ",
    isoCode3: "BLZ",
    dial: "501",
    name: "Belize",
  },

  {
    isoCode2: "BJ",
    isoCode3: "BEN",
    dial: "229",
    name: "Benin",
  },

  {
    isoCode2: "BM",
    isoCode3: "BMU",
    dial: "1 - 441",
    name: "Bermuda",
  },
  {
    isoCode2: "BT",
    isoCode3: "BTN",
    dial: "975",
    name: "Bhutan",
  },
  {
    isoCode2: "BO",
    isoCode3: "BOL",
    dial: "591",
    name: "Bolivia",
  },
  {
    isoCode2: "BQ",
    isoCode3: "BES",
    dial: "599",
    name: "Caribbean Netherlands",
  },
  {
    isoCode2: "BA",
    isoCode3: "BIH",
    dial: "387",
    name: "Bosnia & Herzegovina",
  },
  {
    isoCode2: "BW",
    isoCode3: "BWA",
    dial: "267",
    name: "Botswana",
  },

  {
    isoCode2: "BV",
    isoCode3: "BVT",
    dial: "47",
    name: "Bouvet Island",
  },
  {
    isoCode2: "BR",
    isoCode3: "BRA",
    dial: "55",
    name: "Brazil",
  },
  {
    isoCode2: "IO",
    isoCode3: "IOT",
    dial: "246",
    name: "British Indian Ocean Territory",
  },

  {
    isoCode2: "BN",
    isoCode3: "BRN",
    dial: "673",
    name: "Brunei",
  },

  {
    isoCode2: "BG",
    isoCode3: "BGR",
    dial: "359",
    name: "Bulgaria",
  },
  {
    isoCode2: "BF",
    isoCode3: "BFA",
    dial: "226",
    name: "Burkina Faso",
  },
  {
    isoCode2: "BI",
    isoCode3: "BDI",
    dial: "257",
    name: "Burundi",
  },

  {
    isoCode2: "KH",
    isoCode3: "KHM",
    dial: "855",
    name: "Cambodia",
  },

  {
    isoCode2: "CM",
    isoCode3: "CMR",
    dial: "237",
    name: "Cameroon",
  },
  {
    isoCode2: "CA",
    isoCode3: "CAN",
    dial: "1",
    name: "Canada",
  },
  {
    isoCode2: "CV",
    isoCode3: "CPV",
    dial: "238",
    name: "Cape Verde",
  },
  {
    isoCode2: "KY",
    isoCode3: "CYM",
    dial: "1 - 345",
    name: "Cayman Islands",
  },
  {
    isoCode2: "CF",
    isoCode3: "CAF",
    dial: "236",
    name: "Central African Republic",
  },
  {
    isoCode2: "TD",
    isoCode3: "TCD",
    dial: "235",
    name: "Chad",
  },
  {
    isoCode2: "CL",
    isoCode3: "CHL",
    dial: "56",
    name: "Chile",
  },
  {
    isoCode2: "CN",
    isoCode3: "CHN",
    dial: "86",
    name: "China",
  },
  {
    isoCode2: "CX",
    isoCode3: "CXR",
    dial: "61",
    name: "Christmas Island",
  },
  {
    isoCode2: "CC",
    isoCode3: "CCK",
    dial: "61",
    name: "Cocos (Keeling) Islands",
  },
  {
    isoCode2: "CO",
    isoCode3: "COL",
    dial: "57",
    name: "Colombia",
  },
  {
    isoCode2: "KM",
    isoCode3: "COM",
    dial: "269",
    name: "Comoros",
  },
  {
    isoCode2: "CG",
    isoCode3: "COG",
    dial: "242",
    name: "Congo - Brazzaville",
  },
  {
    isoCode2: "CD",
    isoCode3: "COD",
    dial: "243",
    name: "Congo - Kinshasa",
  },
  {
    isoCode2: "CK",
    isoCode3: "COK",
    dial: "682",
    name: "Cook Islands",
  },
  {
    isoCode2: "CR",
    isoCode3: "CRI",
    dial: "506",
    name: "Costa Rica",
  },
  {
    isoCode2: "HR",
    isoCode3: "HRV",
    dial: "385",
    name: "Croatia",
  },
  {
    isoCode2: "CU",
    isoCode3: "CUB",
    dial: "53",
    name: "Cuba",
  },
  {
    isoCode2: "CW",
    isoCode3: "CUW",
    dial: "599",
    name: "Curaçao",
  },
  {
    isoCode2: "CY",
    isoCode3: "CYP",
    dial: "357",
    name: "Cyprus",
  },
  {
    isoCode2: "CZ",
    isoCode3: "CZE",
    dial: "420",
    name: "Czechia",
  },
  {
    isoCode2: "CI",
    isoCode3: "CIV",
    dial: "225",
    name: "Côte d’Ivoire",
  },
  {
    isoCode2: "DK",
    isoCode3: "DNK",
    dial: "45",
    name: "Denmark",
  },
  {
    isoCode2: "DJ",
    isoCode3: "DJI",
    dial: "253",
    name: "Djibouti",
  },

  {
    isoCode2: "DM",
    isoCode3: "DMA",
    dial: "1 - 767",
    name: "Dominica",
  },
  {
    isoCode2: "DO",
    isoCode3: "DOM",
    dial: "1 - 809 ",
    name: "Dominican Republic (1 - 809)",
  },
  {
    isoCode2: "DO",
    isoCode3: "DOM",
    dial: "1 -  829",
    name: "Dominican Republic (1 - 829)",
  },
  {
    isoCode2: "DO",
    isoCode3: "DOM",
    dial: "1 - 849",
    name: "Dominican Republic (1 - 849)",
  },
  {
    isoCode2: "EC",
    isoCode3: "ECU",
    dial: "593",
    name: "Ecuador",
  },

  {
    isoCode2: "EG",
    isoCode3: "EGY",
    dial: "20",
    name: "Egypt",
  },
  {
    isoCode2: "SV",
    isoCode3: "SLV",
    dial: "503",
    name: "El Salvador",
  },
  {
    isoCode2: "GQ",
    isoCode3: "GNQ",
    dial: "240",
    name: "Equatorial Guinea",
  },
  {
    isoCode2: "ER",
    isoCode3: "ERI",
    dial: "291",
    name: "Eritrea",
  },

  {
    isoCode2: "EE",
    isoCode3: "EST",
    dial: "372",
    name: "Estonia",
  },
  {
    isoCode2: "ET",
    isoCode3: "ETH",
    dial: "251",
    name: "Ethiopia",
  },
  {
    isoCode2: "FK",
    isoCode3: "FLK",
    dial: "500",
    name: "Falkland Islands (Islas Malvinas)",
  },

  {
    isoCode2: "FO",
    isoCode3: "FRO",
    dial: "298",
    name: "Faroe Islands",
  },
  {
    isoCode2: "FJ",
    isoCode3: "FJI",
    dial: "679",
    name: "Fiji",
  },
  {
    isoCode2: "FI",
    isoCode3: "FIN",
    dial: "358",
    name: "Finland",
  },
  {
    isoCode2: "FR",
    isoCode3: "FRA",
    dial: "33",
    name: "France",
  },
  {
    isoCode2: "GF",
    isoCode3: "GUF",
    dial: "594",
    name: "French Guiana",
  },
  {
    isoCode2: "PF",
    isoCode3: "PYF",
    dial: "689",
    name: "French Polynesia",
  },
  {
    isoCode2: "TF",
    isoCode3: "ATF",
    dial: "262",
    name: "French Southern Territories",
  },
  {
    isoCode2: "GA",
    isoCode3: "GAB",
    dial: "241",
    name: "Gabon",
  },
  {
    isoCode2: "GM",
    isoCode3: "GMB",
    dial: "220",
    name: "Gambia",
  },

  {
    isoCode2: "GE",
    isoCode3: "GEO",
    dial: "995",
    name: "Georgia",
  },
  {
    isoCode2: "DE",
    isoCode3: "DEU",
    dial: "49",
    name: "Germany",
  },
  {
    isoCode2: "GH",
    isoCode3: "GHA",
    dial: "233",
    name: "Ghana",
  },
  {
    isoCode2: "GI",
    isoCode3: "GIB",
    dial: "350",
    name: "Gibraltar",
  },

  {
    isoCode2: "GR",
    isoCode3: "GRC",
    dial: "30",
    name: "Greece",
  },
  {
    isoCode2: "GL",
    isoCode3: "GRL",
    dial: "299",
    name: "Greenland",
  },
  {
    isoCode2: "GD",
    isoCode3: "GRD",
    dial: "1 - 473",
    name: "Grenada",
  },
  {
    isoCode2: "GP",
    isoCode3: "GLP",
    dial: "590",
    name: "Guadeloupe",
  },
  {
    isoCode2: "GU",
    isoCode3: "GUM",
    dial: "1 - 671",
    name: "Guam",
  },
  {
    isoCode2: "GT",
    isoCode3: "GTM",
    dial: "502",
    name: "Guatemala",
  },
  {
    isoCode2: "GG",
    isoCode3: "GGY",
    dial: "44",
    name: "Guernsey",
  },
  {
    isoCode2: "GN",
    isoCode3: "GIN",
    dial: "224",
    name: "Guinea",
  },
  {
    isoCode2: "GW",
    isoCode3: "GNB",
    dial: "245",
    name: "Guinea-Bissau",
  },
  {
    isoCode2: "GY",
    isoCode3: "GUY",
    dial: "592",
    name: "Guyana",
  },
  {
    isoCode2: "HT",
    isoCode3: "HTI",
    dial: "509",
    name: "Haiti",
  },
  {
    isoCode2: "HM",
    isoCode3: "HMD",
    dial: "672",
    name: "Heard & McDonald Islands",
  },
  {
    isoCode2: "VA",
    isoCode3: "VAT",
    dial: "39 - 06",
    name: "Vatican City",
  },
  {
    isoCode2: "HN",
    isoCode3: "HND",
    dial: "504",
    name: "Honduras",
  },
  {
    isoCode2: "HK",
    isoCode3: "HKG",
    dial: "852",
    name: "Hong Kong",
  },
  {
    isoCode2: "HU",
    isoCode3: "HUN",
    dial: "36",
    name: "Hungary",
  },
  {
    isoCode2: "IS",
    isoCode3: "ISL",
    dial: "354",
    name: "Iceland",
  },
  {
    isoCode2: "IN",
    isoCode3: "IND",
    dial: "91",
    name: "India",
  },
  {
    isoCode2: "ID",
    isoCode3: "IDN",
    dial: "62",
    name: "Indonesia",
  },
  {
    isoCode2: "IR",
    isoCode3: "IRN",
    dial: "98",
    name: "Iran",
  },
  {
    isoCode2: "IQ",
    isoCode3: "IRQ",
    dial: "964",
    name: "Iraq",
  },
  {
    isoCode2: "IE",
    isoCode3: "IRL",
    dial: "353",
    name: "Ireland",
  },
  {
    isoCode2: "IM",
    isoCode3: "IMN",
    dial: "44",
    name: "Isle of Man",
  },
  {
    isoCode2: "IL",
    isoCode3: "ISR",
    dial: "972",
    name: "Israel",
  },

  {
    isoCode2: "IL",
    isoCode3: "ISR",
    dial: "972",
    name: "Israel",
  },

  {
    isoCode2: "IT",
    isoCode3: "ITA",
    dial: "39",
    name: "Italy",
  },
  {
    isoCode2: "JM",
    isoCode3: "JAM",
    dial: "1 - 876",
    name: "Jamaica",
  },
  {
    isoCode2: "JP",
    isoCode3: "JPN",
    dial: "81",
    name: "Japan",
  },
  {
    isoCode2: "JE",
    isoCode3: "JEY",
    dial: "44",
    name: "Jersey",
  },
  {
    isoCode2: "JO",
    isoCode3: "JOR",
    dial: "962",
    name: "Jordan",
  },
  {
    isoCode2: "KZ",
    isoCode3: "KAZ",
    dial: "7",
    name: "Kazakhstan",
  },
  {
    isoCode2: "KE",
    isoCode3: "KEN",
    dial: "254",
    name: "Kenya",
  },
  {
    isoCode2: "KI",
    isoCode3: "KIR",
    dial: "686",
    name: "Kiribati",
  },
  {
    isoCode2: "KP",
    isoCode3: "PRK",
    dial: "850",
    name: "North Korea",
  },

  {
    isoCode2: "KR",
    isoCode3: "KOR",
    dial: "82",
    name: "South Korea",
  },
  {
    isoCode2: "KW",
    isoCode3: "KWT",
    dial: "965",
    name: "Kuwait",
  },
  {
    isoCode2: "KG",
    isoCode3: "KGZ",
    dial: "996",
    name: "Kyrgyzstan",
  },
  {
    isoCode2: "LA",
    isoCode3: "LAO",
    dial: "856",
    name: "Laos",
  },
  {
    isoCode2: "LV",
    isoCode3: "LVA",
    dial: "371",
    name: "Latvia",
  },
  {
    isoCode2: "LB",
    isoCode3: "LBN",
    dial: "961",
    name: "Lebanon",
  },
  {
    isoCode2: "LS",
    isoCode3: "LSO",
    dial: "266",
    name: "Lesotho",
  },
  {
    isoCode2: "LR",
    isoCode3: "LBR",
    dial: "231",
    name: "Liberia",
  },
  {
    isoCode2: "LY",
    isoCode3: "LBY",
    dial: "218",
    name: "Libya",
  },
  {
    isoCode2: "LI",
    isoCode3: "LIE",
    dial: "423",
    name: "Liechtenstein",
  },
  {
    isoCode2: "LT",
    isoCode3: "LTU",
    dial: "370",
    name: "Lithuania",
  },

  {
    isoCode2: "LU",
    isoCode3: "LUX",
    dial: "352",
    name: "Luxembourg",
  },

  {
    isoCode2: "MO",
    isoCode3: "MAC",
    dial: "853",
    name: "Macau",
  },
  {
    isoCode2: "MK",
    isoCode3: "MKD",
    dial: "389",
    name: "Macedonia (FYROM)",
  },

  {
    isoCode2: "MG",
    isoCode3: "MDG",
    dial: "261",
    name: "Madagascar",
  },
  {
    isoCode2: "MW",
    isoCode3: "MWI",
    dial: "265",
    name: "Malawi",
  },
  {
    isoCode2: "MY",
    isoCode3: "MYS",
    dial: "60",
    name: "Malaysia",
  },

  {
    isoCode2: "MV",
    isoCode3: "MDV",
    dial: "960",
    name: "Maldives",
  },

  {
    isoCode2: "ML",
    isoCode3: "MLI",
    dial: "223",
    name: "Mali",
  },
  {
    isoCode2: "MT",
    isoCode3: "MLT",
    dial: "356",
    name: "Malta",
  },

  {
    isoCode2: "MH",
    isoCode3: "MHL",
    dial: "692",
    name: "Marshall Islands",
  },
  {
    isoCode2: "MQ",
    isoCode3: "MTQ",
    dial: "596",
    name: "Martinique",
  },
  {
    isoCode2: "MR",
    isoCode3: "MRT",
    dial: "222",
    name: "Mauritania",
  },
  {
    isoCode2: "MU",
    isoCode3: "MUS",
    dial: "230",
    name: "Mauritius",
  },
  {
    isoCode2: "YT",
    isoCode3: "MYT",
    dial: "262",
    name: "Mayotte",
  },

  {
    isoCode2: "MX",
    isoCode3: "MEX",
    dial: "52",
    name: "Mexico",
  },

  {
    isoCode2: "FM",
    isoCode3: "FSM",
    dial: "691",
    name: "Micronesia",
  },
  {
    isoCode2: "MD",
    isoCode3: "MDA",
    dial: "373",
    name: "Moldova",
  },
  {
    isoCode2: "MC",
    isoCode3: "MCO",
    dial: "377",
    name: "Monaco",
  },
  {
    isoCode2: "MN",
    isoCode3: "MNG",
    dial: "976",
    name: "Mongolia",
  },
  {
    isoCode2: "ME",
    isoCode3: "MNE",
    dial: "382",
    name: "Montenegro",
  },
  {
    isoCode2: "MS",
    isoCode3: "MSR",
    dial: "1 - 664",
    name: "Montserrat",
  },
  {
    isoCode2: "MA",
    isoCode3: "MAR",
    dial: "212",
    name: "Morocco",
  },
  {
    isoCode2: "MZ",
    isoCode3: "MOZ",
    dial: "258",
    name: "Mozambique",
  },
  {
    isoCode2: "MM",
    isoCode3: "MMR",
    dial: "95",
    name: "Myanmar (Burma)",
  },
  {
    isoCode2: "NA",
    isoCode3: "NAM",
    dial: "264",
    name: "Namibia",
  },
  {
    isoCode2: "NR",
    isoCode3: "NRU",
    dial: "674",
    name: "Nauru",
  },
  {
    isoCode2: "NP",
    isoCode3: "NPL",
    dial: "977",
    name: "Nepal",
  },
  {
    isoCode2: "NL",
    isoCode3: "NLD",
    dial: "31",
    name: "Netherlands",
  },
  {
    isoCode2: "NC",
    isoCode3: "NCL",
    dial: "687",
    name: "New Caledonia",
  },
  {
    isoCode2: "NZ",
    isoCode3: "NZL",
    dial: "64",
    name: "New Zealand",
  },
  {
    isoCode2: "NI",
    isoCode3: "NIC",
    dial: "505",
    name: "Nicaragua",
  },
  {
    isoCode2: "NE",
    isoCode3: "NER",
    dial: "227",
    name: "Niger",
  },
  {
    isoCode2: "NG",
    isoCode3: "NGA",
    dial: "234",
    name: "Nigeria",
  },
  {
    isoCode2: "NU",
    isoCode3: "NIU",
    dial: "683",
    name: "Niue",
  },
  {
    isoCode2: "NF",
    isoCode3: "NFK",
    dial: "672",
    name: "Norfolk Island",
  },
  {
    isoCode2: "MP",
    isoCode3: "MNP",
    dial: "1 - 670",
    name: "Northern Mariana Islands",
  },
  {
    isoCode2: "NO",
    isoCode3: "NOR",
    dial: "47",
    name: "Norway",
  },
  {
    isoCode2: "OM",
    isoCode3: "OMN",
    dial: "968",
    name: "Oman",
  },
  {
    isoCode2: "PK",
    isoCode3: "PAK",
    dial: "92",
    name: "Pakistan",
  },
  {
    isoCode2: "PW",
    isoCode3: "PLW",
    dial: "680",
    name: "Palau",
  },
  {
    isoCode2: "PS",
    isoCode3: "PSE",
    dial: "970",
    name: "Palestine",
  },
  {
    isoCode2: "PA",
    isoCode3: "PAN",
    dial: "507",
    name: "Panama",
  },

  {
    isoCode2: "PG",
    isoCode3: "PNG",
    dial: "675",
    name: "Papua New Guinea",
  },
  {
    isoCode2: "PY",
    isoCode3: "PRY",
    dial: "595",
    name: "Paraguay",
  },
  {
    isoCode2: "PE",
    isoCode3: "PER",
    dial: "51",
    name: "Peru",
  },
  {
    isoCode2: "PH",
    isoCode3: "PHL",
    dial: "63",
    name: "Philippines",
  },
  {
    isoCode2: "PN",
    isoCode3: "PCN",
    dial: "870",
    name: "Pitcairn Islands",
  },
  {
    isoCode2: "PL",
    isoCode3: "POL",
    dial: "48",
    name: "Poland",
  },
  {
    isoCode2: "PT",
    isoCode3: "PRT",
    dial: "351",
    name: "Portugal",
  },
  {
    isoCode2: "PR",
    isoCode3: "PRI",
    dial: "1",
    name: "Puerto Rico",
  },
  {
    isoCode2: "QA",
    isoCode3: "QAT",
    dial: "974",
    name: "Qatar",
  },
  {
    isoCode2: "RO",
    isoCode3: "ROU",
    dial: "40",
    name: "Romania",
  },
  {
    isoCode2: "RU",
    isoCode3: "RUS",
    dial: "7",
    name: "Russia",
  },
  {
    isoCode2: "RW",
    isoCode3: "RWA",
    dial: "250",
    name: "Rwanda",
  },
  {
    isoCode2: "RE",
    isoCode3: "REU",
    dial: "262",
    name: "Réunion",
  },
  {
    isoCode2: "BL",
    isoCode3: "BLM",
    dial: "590",
    name: "St. Barthélemy",
  },
  {
    isoCode2: "SH",
    isoCode3: "SHN",
    dial: "290",
    name: "St. Helena",
  },
  {
    isoCode2: "KN",
    isoCode3: "KNA",
    dial: "1 - 869",
    name: "St. Kitts & Nevis",
  },
  {
    isoCode2: "LC",
    isoCode3: "LCA",
    dial: "1 - 758",
    name: "St. Lucia",
  },
  {
    isoCode2: "MF",
    isoCode3: "MAF",
    dial: "590",
    name: "St. Martin",
  },
  {
    isoCode2: "PM",
    isoCode3: "SPM",
    dial: "508",
    name: "St. Pierre & Miquelon",
  },
  {
    isoCode2: "VC",
    isoCode3: "VCT",
    dial: "1 - 784",
    name: "St. Vincent & Grenadines",
  },
  {
    isoCode2: "WS",
    isoCode3: "WSM",
    dial: "685",
    name: "Samoa",
  },
  {
    isoCode2: "SM",
    isoCode3: "SMR",
    dial: "378",
    name: "San Marino",
  },
  {
    isoCode2: "ST",
    isoCode3: "STP",
    dial: "239",
    name: "São Tomé & Príncipe",
  },
  {
    isoCode2: "SA",
    isoCode3: "SAU",
    dial: "966",
    name: "Saudi Arabia",
  },
  {
    isoCode2: "SN",
    isoCode3: "SEN",
    dial: "221",
    name: "Senegal",
  },
  {
    isoCode2: "RS",
    isoCode3: "SRB",
    dial: "381",
    name: "Serbia",
  },
  {
    isoCode2: "SC",
    isoCode3: "SYC",
    dial: "248",
    name: "Seychelles",
  },
  {
    isoCode2: "SL",
    isoCode3: "SLE",
    dial: "232",
    name: "Sierra Leone",
  },
  {
    isoCode2: "SG",
    isoCode3: "SGP",
    dial: "65",
    name: "Singapore",
  },
  {
    isoCode2: "SX",
    isoCode3: "SXM",
    dial: "1 - 721",
    name: "Sint Maarten",
  },
  {
    isoCode2: "SK",
    isoCode3: "SVK",
    dial: "421",
    name: "Slovakia",
  },

  {
    isoCode2: "SI",
    isoCode3: "SVN",
    dial: "386",
    name: "Slovenia",
  },
  {
    isoCode2: "SB",
    isoCode3: "SLB",
    dial: "677",
    name: "Solomon Islands",
  },
  {
    isoCode2: "SO",
    isoCode3: "SOM",
    dial: "252",
    name: "Somalia",
  },
  {
    isoCode2: "ZA",
    isoCode3: "ZAF",
    dial: "27",
    name: "South Africa",
  },
  {
    isoCode2: "GS",
    isoCode3: "SGS",
    dial: "500",
    name: "South Georgia & South Sandwich Islands",
  },
  {
    isoCode2: "SS",
    isoCode3: "SSD",
    dial: "211",
    name: "South Sudan",
  },
  {
    isoCode2: "SS",
    isoCode3: "SSD",
    dial: "211",
    name: "South Sudan",
  },
  {
    isoCode2: "ES",
    isoCode3: "ESP",
    dial: "34",
    name: "Spain",
  },
  {
    isoCode2: "LK",
    isoCode3: "LKA",
    dial: "94",
    name: "Sri Lanka",
  },
  {
    isoCode2: "SD",
    isoCode3: "SDN",
    dial: "249",
    name: "Sudan",
  },
  {
    isoCode2: "SR",
    isoCode3: "SUR",
    dial: "597",
    name: "Suriname",
  },
  {
    isoCode2: "SJ",
    isoCode3: "SJM",
    dial: "47",
    name: "Svalbard & Jan Mayen",
  },
  {
    isoCode2: "SZ",
    isoCode3: "SWZ",
    dial: "268",
    name: "Swaziland",
  },
  {
    isoCode2: "SE",
    isoCode3: "SWE",
    dial: "46",
    name: "Sweden",
  },
  {
    isoCode2: "CH",
    isoCode3: "CHE",
    dial: "41",
    name: "Switzerland",
  },
  {
    isoCode2: "SY",
    isoCode3: "SYR",
    dial: "963",
    name: "Syria",
  },
  {
    isoCode2: "TW",
    isoCode3: "TWN",
    dial: "886",
    name: "Taiwan",
  },
  {
    isoCode2: "TJ",
    isoCode3: "TJK",
    dial: "992",
    name: "Tajikistan",
  },
  {
    isoCode2: "TZ",
    isoCode3: "TZA",
    dial: "255",
    name: "Tanzania",
  },
  {
    isoCode2: "TH",
    isoCode3: "THA",
    dial: "66",
    name: "Thailand",
  },
  {
    isoCode2: "TL",
    isoCode3: "TLS",
    dial: "670",
    name: "Timor-Leste",
  },
  {
    isoCode2: "TG",
    isoCode3: "TGO",
    dial: "228",
    name: "Togo",
  },
  {
    isoCode2: "TK",
    isoCode3: "TKL",
    dial: "690",
    name: "Tokelau",
  },
  {
    isoCode2: "TO",
    isoCode3: "TON",
    dial: "676",
    name: "Tonga",
  },
  {
    isoCode2: "TT",
    isoCode3: "TTO",
    dial: "1 - 868",
    name: "Trinidad & Tobago",
  },
  {
    isoCode2: "TN",
    isoCode3: "TUN",
    dial: "216",
    name: "Tunisia",
  },
  {
    isoCode2: "TR",
    isoCode3: "TUR",
    dial: "90",
    name: "Turkey",
  },
  {
    isoCode2: "TM",
    isoCode3: "TKM",
    dial: "993",
    name: "Turkmenistan",
  },
  {
    isoCode2: "TC",
    isoCode3: "TCA",
    dial: "1 - 649",
    name: "Turks & Caicos Islands",
  },
  {
    isoCode2: "TV",
    isoCode3: "TUV",
    dial: "688",
    name: "Tuvalu",
  },
  {
    isoCode2: "UG",
    isoCode3: "UGA",
    dial: "256",
    name: "Uganda",
  },
  {
    isoCode2: "UA",
    isoCode3: "UKR",
    dial: "380",
    name: "Ukraine",
  },

  {
    isoCode2: "AE",
    isoCode3: "ARE",
    dial: "971",
    name: "United Arab Emirates",
  },
  {
    isoCode2: "GB",
    isoCode3: "GBR",
    dial: "44",
    name: "United Kingdom",
  },
  {
    isoCode2: "US",
    isoCode3: "USA",
    dial: "1",
    name: "United States",
  },
  {
    isoCode2: "UM",
    isoCode3: "UMI",
    dial: "1",
    name: "U.S. Outlying Islands",
  },
  {
    isoCode2: "UY",
    isoCode3: "URY",
    dial: "598",
    name: "Uruguay",
  },
  {
    isoCode2: "UZ",
    isoCode3: "UZB",
    dial: "998",
    name: "Uzbekistan",
  },
  {
    isoCode2: "VU",
    isoCode3: "VUT",
    dial: "678",
    name: "Vanuatu",
  },

  {
    isoCode2: "VE",
    isoCode3: "VEN",
    dial: "58",
    name: "Venezuela",
  },
  {
    isoCode2: "VN",
    isoCode3: "VNM",
    dial: "84",
    name: "Vietnam",
  },
  {
    isoCode2: "VG",
    isoCode3: "VGB",
    dial: "1 - 284",
    name: "British Virgin Islands",
  },
  {
    isoCode2: "VI",
    isoCode3: "VIR",
    dial: "1 - 340",
    name: "U.S. Virgin Islands",
  },
  {
    isoCode2: "WF",
    isoCode3: "WLF",
    dial: "681",
    name: "Wallis & Futuna",
  },
  {
    isoCode2: "EH",
    isoCode3: "ESH",
    dial: "212",
    name: "Western Sahara",
  },
  {
    isoCode2: "YE",
    isoCode3: "YEM",
    dial: "967",
    name: "Yemen",
  },
  {
    isoCode2: "ZM",
    isoCode3: "ZMB",
    dial: "260",
    name: "Zambia",
  },
  {
    isoCode2: "ZW",
    isoCode3: "ZWE",
    dial: "263",
    name: "Zimbabwe",
  },
  {
    isoCode2: "AX",
    isoCode3: "ALA",
    dial: "358",
    name: "Åland Islands",
  },
];

export enum PaymentProviderIDs {
  WEACCEPT = "WEACCEPT",
  STRIPE = "STRIPE",
  PAYTABS = "PAYTABS",
  PAYPAL = "PAYPAL",
  GOOGLEPROVIDER = "GOOGLEADS",
  CASH = "CASH",
  INAPPGOOGLE = "INAPP_GOOGLE",
  STRIPE_CONNECT = "STRIPE_CONNECT",
}

export const plan1 = {
  id: "plan_1000",
  name: "Basic",
  tagline: "Jump Start Your Dream App Now!",
  price: "$59",
  total: 59,
  desc: "Per month billed monthly",
  save: "Save $120 on the Annual Plan",
  per: "Month",
  annually: false,
  type: "main_plan",
  totalPrice: "$59.00",
  annuallyPlanID: 'plan_11000',
  level: 1,
  signupPrice: 19.99,
  main_price: "$59"
};
export const plan2 = {
  id: "plan_2000",
  name: "Professional",
  tagline: "For Professional Businesses",
  price: "$159",
  total: 159,
  desc: "Per month billed monthly",
  save: "Save $240 on the Annual Plan",
  per: "Month",
  annually: false,
  type: "main_plan",
  totalPrice: "$159.00",
  annuallyPlanID: 'plan_12000',
  level: 2,
  signupPrice: 19.99,
  main_price: "$59"
};
export const plan3 = {
  id: "plan_3000",
  name: "Premium",
  tagline: "As your Business Scales",
  price: "$359",
  total: 359,
  desc: "Per month billed monthly",
  save: "Save $720 on the Annual Plan",
  per: "Month",
  annually: false,
  type: "main_plan",
  totalPrice: "$359.00",
  annuallyPlanID: 'plan_13000',
  level: 3,
  signupPrice: 19.99,
  main_price: "$59"
};

export const plan4 = {
  id: "plan_4",
  name: "Free",
  tagline: "Trial",
  price: "$359",
  total: 359,
  desc: "Per month billed monthly",
  save: "$720 off ($299/mo)",
  per: "Month",
  annually: false,
  type: "main_plan",
  totalPrice: "$359.00",
  annuallyPlanID: 'plan_13000',
  level: 0
};

export const plan5 = {
  id: "plan_11000",
  name: "Basic",
  tagline: "Jump Start Your Dream App Now!",
  price: "$49",
  main_price: "$59",
  total: 588,
  desc: "Per month billed annually",
  save: "$120 discount applied",
  savePrice: 120,
  per: "Year",
  annually: true,
  type: "main_plan",
  totalPrice: "$588.00",
  monthlyPlanID: 'plan_1000',
  level: 1,
  signupPrice: 558.99
};
export const plan6 = {
  id: "plan_12000",
  name: "Professional",
  tagline: "For Professional Businesses",
  price: "$139",
  main_price: "$159",
  total: 1668,
  desc: "Per month billed annually",
  save: "$240 discount applied",
  savePrice: 240,
  per: "Year",
  annually: true,
  type: "main_plan",
  totalPrice: "$1,668.00",
  monthlyPlanID: 'plan_2000',
  level: 2,
  signupPrice: 1548.99
};
export const plan7 = {
  id: "plan_13000",
  name: "Premium",
  tagline: "As your Business Scales",
  price: "$299",
  main_price: "$359",
  total: 3588,
  desc: "Per month billed annually",
  save: "$720 discount applied",
  savePrice: 720,
  per: "Year",
  annually: true,
  type: "main_plan",
  totalPrice: "$3,588.00",
  monthlyPlanID: 'plan_3000',
  level: 3,
  signupPrice: 3308.99
};

export const plan8 = {
  id: "plan_50",
  name: "Additional 5 Builds",
  price: "$10",
  totalPrice: "$10",
  total: 10,
  tagline: "Add additional 5 builds per month to your plan.",
  desc: "",
  save: "",
  per: "Month",
  annually: false,
  type: "sub_plan",
};

export const plan9 = {
  id: "plan_55",
  name: "Additional 5000 Users",
  price: "$49",
  totalPrice: "$49",
  total: 49,
  tagline: "Adds support for an additional 5000 users to your plan.",
  desc: "",
  save: "",
  per: "Month",
  annually: false,
  type: "sub_plan",
};


export enum ChatTypes {
  GROUP = 'Group',
  CHANNEL = 'Channel',
  EVENT_CHANNEL = 'EventChannel',
  EVENT_GROUP = 'EventGroup',
  BOOKING_CHANNEL = 'BookingChannel',
  BOOKING_GROUP = 'BookingGroup',
  INDIVIDUAL = 'Individual',
  VIRTUAL_APP = 'VirtualApp'
}

export const Choices_Styles= [
  {
    value: '1',
    label: 'Only Icon',
    label_ios: 'Only Icon',
  },
  {
    value: '2',
    label: 'Only Image',
    label_ios: 'Only Image',
  },
  {
    value: '3',
    label: 'No Icon or Image',
    label_ios: 'No Icon or Image',
  },
];
export const Buttons_Styles= [
  {
    value: '01',
    label: 'Filled button',
    label_ios: 'Filled button',
  },
  {
    value: '02',
    label: 'Outlined button',
    label_ios: 'Outlined button',
  },
  {
    value: '03',
    label: 'Tonal Button',
    label_ios: 'Tonal Button',
  },
  {
    value: '04',
    label: 'Elevated button',
    label_ios: 'Elevated button',
  },
  {
    value: '05',
    label: 'Text Button',
    label_ios: 'Text Button',
  },
];
export const Field_Text_Styles= [
  {
    value: '01',
    label: 'Outline Field',
    label_ios: 'Outline Field',
  },
  {
    value: '02',
    label: 'Text Field',
    label_ios: 'Text Field',
  },
  {
    value: '03',
    label: 'Elevated Field',
    label_ios: 'Elevated Field',
  }
];
export const Card_Styles= [
  {
    value: '03',
    label: 'Card 03',
    label_ios: 'Outline Field',
  },
  {
    value: '04',
    label: 'Card 04',
    label_ios: 'Text Field',
  },
  {
    value: '05',
    label: 'Card 05',
    label_ios: 'Elevated Field',
  },
  {
    value: '06',
    label: 'Card 06',
    label_ios: 'Elevated Field',
  },
  {
    value: '07',
    label: 'Card 07',
    label_ios: 'Elevated Field',
  },
];

export const DropDown_Styles= [
  {
    value: '1',
    label: 'Outline Field',
    label_ios: 'Outline Field',
  },
  {
    value: '2',
    label: 'Text Field',
    label_ios: 'Text Field',
  },
  {
    value: '3',
    label: 'Elevated Field',
    label_ios: 'Elevated Field',
  }
];
export const Cards_Types= [
  {
    value: '0',
    label: 'Elevated',
    label_ios: 'Elevated',
  },
  {
    value: '1',
    label: 'Filled',
    label_ios: 'Filled',
  },
  {
    value: '2',
    label: 'Outline',
    label_ios: 'Outline',
  },
  {
    value: '3',
    label: 'Custom',
    label_ios: 'Custom',
  },
  {
    value: '4',
    label: 'Transparent',
    label_ios: 'Transparent',
  }
];

export enum ADMIN_PRIVILEGES {
  CHANGE_CHANNEL_INFO = '2',
  POST_MESSAGES = '4',
  EDIT_MESSAGE_OF_OTHERS = '8',
  DELETE_MESSAGE_OF_OTHERS = '16',
  INVITE_PARTICIPANT = '32',
  INVITE_ADMIN = '64',
  REPLY_TO_MESSAGES = '128',
  MOBILE_ACCESS = '256',
  WEB_ACCESS = '512',
  EDIT_ADMIN_PRIVILEGES = '1024',
  REMOVE_PARTICIPANT = '2048',
  BAN_PARTICIPANT = '4096',
  GET_USER_LOGIN_ID = '32768'
}


export const colorSchemaDefault = {
  color_schema: {
    style: {
      lightStatusBar: "1",
      lightBottomStatusBar: "0"
    },
    schemes: {
      light: {
        primary: "#0072f0",
        onPrimary: "#ffffff",
        primaryContainer: "#d8e2ff",
        onPrimaryContainer: "#001a41",
        secondary: "#505e7c",
        onSecondary: "#ffffff",
        secondaryContainer: "#d8e2ff",
        onSecondaryContainer: "#0c1b36",
        tertiary: "#94679a",
        onTertiary: "#ffffff",
        tertiaryContainer: "#fed6ff",
        onTertiaryContainer: "#300a38",
        error: "#ba1a1a",
        onError: "#ffffff",
        errorContainer: "#ffdad6",
        onErrorContainer: "#410002",
        surface: "#faf9fd",
        onSurface: "#1b1b1f",
        surfaceVariant: "#e1e2ec",
        onSurfaceVariant: "#44474f",
        outline: "#74777f",
        outlineVariant: "#c4c6d0",
        shadow: "#000000",
        scrim: "#000000",
        surfaceDim: "#dbd9dd",
        surfaceDimmer: "#d5d4d8",
        surfaceBright: "#faf9fd",
        surfaceContainerLowest: "#ffffff",
        surfaceContainerLow: "#f5f3f7",
        surfaceContainer: "#efedf1",
        surfaceContainerHigh: "#e9e7ec",
        surfaceContainerHighest: "#e3e2e6",
        secondaryDim: "#4c5977",
        surfaceInverse: "#303033",
        onSurfaceInverse: "#f2f0f4",
        PrimaryInverse: "#adc6ff",
        success: "#006b5c",
        onSuccess: "#ffffff",
        successContainer: "#64fade",
        onSuccessContainer: "#00201b",
        warning: "#8c5000",
        onWarning: "#ffffff",
        warningContainer: "#ffdcbf",
        onWarningContainer: "#2d1600",
        mPrimaryContainerBright: "#e4ebff",
        mPrimaryContainer: "#d8e2ff",
        mOnPrimaryContainer: "#001a41",
        mPrimaryInverseTransparent: "#E0adc6ff",
        mSurfaceContainerBright: "#ffffff",
        mSurfaceContainerHighest: "#e3e2e6",
        mOnSurfaceVariant: "#44474f",
        m2Primary: "#0072f0",
        m2OnSurfaceVariant: "#44474f",
        m2APrimary: "#0072f0",
        m2Surface: "#faf9fd",
        m2ASurface: "#faf9fd",
        m2AonSurfaceVariant: "#e1e2ec",
        m2CSurface: "#faf9fd",
        m2DSurface: "#faf9fd",
        m2BSurface: "#faf9fd",
        m2SurfaceContainer: "#efedf1",
        m2SurfaceContainerHigh: "#e9e7ec"
      },
      dark: {
        primary: "#80abff",
        onPrimary: "#002e69",
        primaryContainer: "#004493",
        onPrimaryContainer: "#d8e2ff",
        secondary: "#b8c6e9",
        onSecondary: "#22304c",
        secondaryContainer: "#394764",
        onSecondaryContainer: "#d8e2ff",
        tertiary: "#cc9bd1",
        onTertiary: "#47214f",
        tertiaryContainer: "#603867",
        onTertiaryContainer: "#fed6ff",
        error: "#ffb4ab",
        onError: "#690005",
        errorContainer: "#93000a",
        onErrorContainer: "#ffb4ab",
        surface: "#121316",
        onSurface: "#e3e2e6",
        surfaceVariant: "#44474f",
        onSurfaceVariant: "#c4c6d0",
        outline: "#8e9099",
        outlineVariant: "#44474f",
        shadow: "#000000",
        scrim: "#000000",
        surfaceDim: "#121316",
        surfaceDimmer: "#17171b",
        surfaceBright: "#38393c",
        surfaceContainerLowest: "#0d0e11",
        surfaceContainerLow: "#1b1b1f",
        surfaceContainer: "#1f1f23",
        surfaceContainerHigh: "#292a2d",
        surfaceContainerHighest: "#343538",
        secondaryDim: "#beccef",
        surfaceInverse: "#e3e2e6",
        onSurfaceInverse: "#303033",
        PrimaryInverse: "#005bc1",
        success: "#3fddc2",
        onSuccess: "#00382f",
        successContainer: "#005045",
        onSuccessContainer: "#64fade",
        warning: "#ffb873",
        onWarning: "#4a2800",
        warningContainer: "#6a3b00",
        onWarningContainer: "#ffdcbf",
        mPrimaryContainerBright: "#003f8b",
        mPrimaryContainer: "#004493",
        mOnPrimaryContainer: "#d8e2ff",
        mPrimaryInverseTransparent: "#E0005bc1",
        mSurfaceContainerBright: "#0d0e11",
        mSurfaceContainerHighest: "#343538",
        mOnSurfaceVariant: "#c4c6d0",
        m2Primary: "#80abff",
        m2OnSurfaceVariant: "#c4c6d0",
        m2APrimary: "#80abff",
        m2Surface: "#121316",
        m2ASurface: "#121316",
        m2AonSurfaceVariant: "#44474f",
        m2CSurface: "#121316",
        m2DSurface: "#121316",
        m2BSurface: "#121316",
        m2SurfaceContainer: "#1f1f23",
        m2SurfaceContainerHigh: "#292a2d"
      }
    },
    palettes: {
      primary: {
        hue: 265.8095211405306,
        chroma: 70.77559372126427,
        keyColor: {
          argb: 4278221567,
          internalHue: 265.8095211405306,
          internalChroma: 70.77559372126427,
          internalTone: 53.10136015859106,
          id: "#007aff"
        },
        cache: {}
      },
      secondary: {
        hue: 265.8095211405306,
        chroma: 23.59186457375476,
        keyColor: {
          argb: 4284773009,
          internalHue: 265.51050099318957,
          internalChroma: 23.530775409929692,
          internalTone: 47.992161503068786,
          id: "#647291"
        },
        cache: {}
      },
      tertiary: {
        hue: 325.8095211405306,
        chroma: 35.387796860632136,
        keyColor: {
          argb: 4288113309,
          internalHue: 325.6972291347542,
          internalChroma: 35.43653267978909,
          internalTone: 51.061473560896545,
          id: "#976a9d"
        },
        cache: {}
      },
      error: {
        hue: 25,
        chroma: 84,
        keyColor: {
          argb: 4292753200,
          internalHue: 25.01013502609243,
          internalChroma: 84.04509263062816,
          internalTone: 50.06767986742766,
          id: "#de3730"
        },
        cache: {}
      },
      neutral: {
        hue: 265.8095211405306,
        chroma: 4,
        keyColor: {
          argb: 4286151037,
          internalHue: 264.9459695736879,
          internalChroma: 4.194727314477241,
          internalTone: 50.94402689968517,
          id: "#79797d"
        },
        cache: {}
      },
      neutralVariant: {
        hue: 265.8095211405306,
        chroma: 8,
        keyColor: {
          argb: 4285822847,
          internalHue: 260.4958082837206,
          internalChroma: 7.7430462265074125,
          internalTone: 50.02424974644671,
          id: "#74777f"
        },
        cache: {}
      }
    },
    source: "#007aff"
  }
};

export enum BuildTarget {
  APK = "apk",
  APN = "apn",
  BUNDLE = "bundle",
  PUBLISH = "publish",
}


 export const aiTestMap = {
  data: {
    config: {
      onboarding:{},
      app_info:{
                  id: "app_info",
                  image: "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_c_xxxhdpi.png?temp_url_sig=15b8f28c7aa8669edf34fc277e601240a5ec05b2&temp_url_expires=2039097296",
                  app_name: "E-Shop",
                  currency: "USD",
                  language: { value: "en", key:"English", direction:"ltr"},
                },
      colors:{ id: "colors",
                 color_schema: {source: "#FF5733" },
                 color_schema_ios:{source: "#FF5733" },
                },
      theme: {android:{navigation:{position: "top", icon:true, label: true}, drawer:{enabled:true,}, material:"m3" ,},
              ios:{navigation:{icon: true, label: true, title: "large"}, drawer:{enabled:true, position:"bottom",},material:"modern"}},

      chat_label:{id: "chat_label", labels:[{id:"0", name: "Default",default: true}, {id:"1", name: "Default",default: false}]},

      settings:{},

      default:{ id:"default", feed:{}, channel:{},  },

      version: 212

    },
    drawer: {
        "id": "drawer",
         "components": []
      },
    navigation: {
       id: "navigation",
        menus: [
          {
            menu_id: "f3f94hL2Xx0Ly2D",
            version: "915a00cab68945a",
            rows: [
              {
                row_id: "751fd5c79c14410",
                version: "86c84d5d3c944e2",
                row_order: 0,
                cells: [
                  { cell_id: "K3L4M5N6A7B8C9D",
                    form: "heading",
                    style: "TOP",
                    cell_order: 0,
                    version: "daf17c24b8314c0",
                    headline: "Supplementary Options",
                    heading: "Additional Features",
                    subhead: "Extra Utilities",
                    divider: 0,
                    text_size: { subhead: "medium", body: "medium",headline: "large" },
                    text_align: {subhead: "center",body: "center",headline: "center"  }
                  }
                ],
              },
              {
                row_id: "d19dcc5cdd6b407",
                version: "9a707945db34424",
                row_order: 1,
                cells: [
                  {
                    cell_id: "D1E2F3G4H5I6J7K",
                    form: "card",
                    style: "1",
                    cell_order: 0,
                    version: "62bbde73eb9b4bc",
                    image: "featured_product_image.jpg",

                    heading: "Featured Product",
                    subhead: "Highlighted Product",
                    headline: "Featured Item",
                    text_size: { subhead: "medium", body: "medium", headline: "large" },
                    text_align: { subhead: "center", body: "center",  headline: "center"  },

                    next_id: "f3f94hL2Xx0Ly2D",

                  },
                  {
                    cell_id: "L0M1N2O3P4Q5R6S",
                    form: "input",
                    style: "FILLED",
                    cell_order: 1,
                    version: "670c76505ff3425",
                    heading: "Search Products",
                    label: "Search for Items",
                    icon: "mir_dashboard",
                    trailing_icon: "mir_dashboard",
                    input: "Search Products",
                    placeholder: "Type product name here",
                    helper: "Enter the product name you wish to search",
                  },
                  {
                    cell_id: "T7U8V9W0X1Y2Z3A",
                    form: "button",
                    style: "ELEVATED",
                    version: "300b8fb89275469",
                    cell_order: 2,
                    heading: "Search",
                    label: "Find",
                    icon: "mir_feed",
                    next_id: "f3f94hL2Xx0Ly2D"
                  }
                ],

              },
              {

                row_id: "0c605dd597094e7",
                row_order: 2,
                version: "ff423153b12a429",
                cells: [
                  {
                    cell_id: "A3B4C5D6E7F8G9H",
                    form: "output",
                    style: "BODY",
                    cell_order: 0,
                    version: "136a150c40c94b6",
                    heading: "Search Results",
                    headline: "Search Outcome",
                    subhead: "List of Findings",
                    body: "Here are the results based on your query.",
                    text_size: {subhead: "medium", body: "medium", headline: "large"    },
                    text_align: {subhead: "left",   body: "left",    headline: "center"      },
                 }
                ],

              },
              {
                row_id: "94830305ac72405",
                row_order: 3,
                version: "f47dbe8b2eb94ed",
                cells: [
                  {
                    heading: "Sort By",
                    cell_order: 0,
                    subhead: "Choose Sorting Criteria",
                    version: "b688e8ec8e504a1",
                    form: "radio",
                    helper: "Select an option",
                    text_size: {
                    subhead: "medium",
                    body: "medium",
                    headline: "large"
                    },
                    options: [
                      {
                        divider: false,
                        id: "A1B2C3D4E5F6G7H8I9J",
                        label: "Sort By Price",
                        value: "sort_price"
                      },
                      {
                        divider: false,
                        id: "J9I8H7G6F5E4D3C2B1A",
                        label: "Sort By Ratings",
                        value: "sort_ratings"
                      },
                      {
                        divider: false,
                        id: "K8J9I0H1G2F3E4D5",
                        label: "Sort By Popularity",
                        value: "sort_popularity"
                      },
                      {
                        divider: false,
                        id: "X0Y1Z2A3B4C5D6E7",
                        label: "Sort By New Arrivals",
                        value: "sort_new"
                      }
                    ],
                    style: "PLAIN",
                    headline: "Select Sorting Method",
                    value: {
                    divider: false,
                    id: "A1B2C3D4E5F6G7H8I9J",
                    label: "Sort By Price",
                    value: "sort_price"
                    },
                    cell_id: "I8J9K0L1M2N3O4P",
                    text_align: {
                      subhead: "left",
                      body: "left",
                      headline: "left"
                    }
                  }
                ],

              },
              {
                row_id: "27e10a5777fb419",
                row_order: 4,
                version: "e6c60dfb32ca4db",
                cells: [
                  {
                    heading: "Filter Options",
                    cell_order: 0,
                    subhead: "Specify Your Preferences",
                    version: "50b16a778b99414",
                    form: "checkbox",
                    helper: "Provide different filtering criteria for your selection",
                    text_size: {
                      subhead: "medium",
                      body: "medium",
                      headline: "large"
                    },
                    options: [
                      {
                        divider: true,
                        id: "A1B2C3D4E5F6G7H8",
                        label: "Category",
                        value: "category"
                      },
                      {
                        divider: false,
                        id: "J9I8H7G6F5E4D3C2",
                        label: "Price Range",
                        value: "price_range"
                      },
                      {
                        divider: false,
                        id: "W7X8Y9Z0A1B2C3D4",
                        label: "Brand",
                        value: "brand"
                      }
                    ],
                    style: "PLAIN",
                    headline: "Preferences for Filtering",
                    value: {
                      divider: true,
                      id: "A1B2C3D4E5F6G7H8",
                      label: "Category",
                      value: "category"
                    },
                    cell_id: "D5E6F7G8H9I0J1K",
                    text_align: {
                      subhead: "left",
                      body: "left",
                      headline: "left"
                    }
                  }
                ],

              },
              {
                row_id: "1f0f186b725b460",
                row_order: 5,
                version: "98da1320ee1b4af",
                cells: [
                  {
                    heading: "Show Out Of Stock Items",
                    cell_order: 0,
                    subhead: "Items Not Ready for Purchase",
                    version: "b5965545f1734e5",
                    form: "toggle",
                    helper: "Show Unavailable Products",
                    text_size: {
                      subhead: "medium",
                      body: "medium",
                      headline: "large"
                    },
                    options: [
                      {
                        divider: false,
                        id: "Q1W2E3R4T5Y6U7I",
                        label: "Display Temporarily Unavailable Stock",
                        value: "out_of_stock"
                      },
                      {
                        divider: false,
                        id: "H8J9K0L1M2N3O4P",
                        label: "Highlight Products Not Currently Available",
                        value: "not_available"
                      }
                    ],
                    style: "PLAIN",
                    headline: "Show Products Lacking Stock",
                    value: {
                     divider: false,
                     id: "Q1W2E3R4T5Y6U7I",
                     label: "Display Temporarily Unavailable Stock",
                     value: "out_of_stock"
                    },
                    cell_id: "Y2Z3A4B5C6D7E8F",
                    text_align: {
                      subhead: "center",
                      body: "center",
                      headline: "center"
                    }
                  }
                ],
              },
              {
                row_id: "29590b45eb3b44b",
                row_order: 6,
                version: "39ffb4a72109421",
                cells: [
                  {
                    heading: "Select Category",
                    cell_order: 0,
                    subhead: "Please select a category",
                    version: "73c900a9246a47b",
                    form: "dropdown",
                    helper: "Select a category for your products.",
                    text_size: {
                      subhead: "medium",
                      body: "medium",
                      headline: "large"
                    },
                    options: [
                      {
                        divider: false,
                        id: "A1B2C3D4E5F6G7H8I9J",
                        label: "Choose a Product Category",
                        value: "category1"
                      },
                      {
                        divider: false,
                        id: "J9I8H7G6F5E4D3C2B1A",
                        label: "Pick your Desired Category",
                        value: "category2"
                      },
                      {
                        divider: false,
                        id: "L1M2N3O4P5Q6R7S8",
                        label: "Select Your Preferred Category",
                        value: "category3"
                      }
                    ],
                    style: "OUTLINED",
                    headline: "Choose a Category",
                    value: {
                      divider: false,
                      id: "A1B2C3D4E5F6G7H8I9J",
                      label: "Choose a Product Category",
                      value: "category1"
                    },
                    cell_id: "Q5R6S7T8U9V0W1X",
                    text_align: {
                      subhead: "center",
                      body: "center",
                      headline: "center"
                    }
                  },
                  {
                    form: "space",
                    heading: "",
                    cell_order: 1,
                    style: "",
                    version: "c136b553728a4a5",
                    cell_id: "A1B2C3D4E5F6G8"
                  }
                ],
              }
            ],

          },
          {
            menu_id: "t9d3L4X1cZ3fU0k",
            version: "092b348ffa9c402",
            rows: [
              {
                cells: [
                  {
                    form: "heading",
                    text_size: {
                      subhead: "medium",
                      body: "medium",
                      headline: "large"
                    },
                    heading: "Additional Features",
                    divider: 0,
                    cell_order: 0,
                    subhead: "Extra Options",
                    style: "TOP",
                    version: "4953712da5c046f",
                    headline: "Additional Features",
                    cell_id: "B4Y5C8E9F1A2A3K",
                    text_align: {
                      subhead: "center",
                      body: "center",
                      headline: "center"
                    }
                  }
                ],
                row_order: 0,
                row_id: "5868d27407bd40e",
                version: "5166b95fa6ab412"
              },
              {
                cells: [
                  {
                    image: "path/to/special_offer_image.jpg",
                    heading: "Special Offer",
                    cell_order: 0,
                    subhead: "Exclusive Deal",
                    version: "a81f9f1ce8a5409",
                    form: "card",
                    text_size: {
                     subhead: "medium",
                     body: "small",
                      headline: "large"
                    },
                    style: "1",
                    headline: "Limited Time Offer",
                    cell_id: "Z9V0Y1A2F3G4H5J",
                    next_id: "f3f94hL2Xx0Ly2D",
                    text_align: {
                      subhead: "left",
                      body: "center",
                      headline: "center"
                    }
                  },
                  {
                    trailing_icon: "mir_feed",
                    heading: "Enter Promo Code",
                    cell_order: 1,
                    icon: "mir_feed",
                    label: "Enter Discount Code",
                    version: "c8be2bba0c06497",
                    input: "Promo Code",
                    form: "input",
                    helper: "Input your promotional code here.",
                    style: "FILLED",
                    placeholder: "Promo Code",
                    cell_id: "X8Y7T6R5A4B3C9D"
                  },
                  {
                    form: "button",
                    heading: "Apply Code",
                    cell_order: 2,
                    icon: "mir_feed",
                    style: "ELEVATED",
                    label: "Apply Discount Code",
                    version: "9656616931ab4cf",
                    cell_id: "Q3W4E5R6T7X8Y9Z",
                    next_id: "f3f94hL2Xx0Ly2D"
                  }
                ],
                row_order: 1,
                row_id: "08a99a24b08b4cd",
                version: "f8757516f1f24f2"
              },
              {
                cells: [
                  {
                    form: "output",
                    text_size: {
                    subhead: "medium",
                    body: "medium",
                    headline: "large"
                    },
                    heading: "Promo Code Status",
                    cell_order: 0,
                    subhead: "Promo Code Progress",
                    style: "SIDE",
                    body: "Check your promo code status here.",
                    version: "f8a19abae88246a",
                    headline: "Promo Code Update",
                    cell_id: "K1L2N3O4P5Q6R7S",
                    text_align: {
                      subhead: "center",
                      body: "left",
                      headline: "center"
                    }
                  }
                ],
                row_order: 2,
                row_id: "e6c222bbc61b4fa",
                version: "15a940f2044746b"
              },
              {
                cells: [
                  {
                    heading: "Choose Delivery Option",
                    cell_order: 0,
                    subhead: "Select Your Delivery Method",
                    version: "9de1d424c62743e",
                    form: "radio",
                    helper: "Choose Delivery Option",
                    text_size: {
                      subhead: "medium",
                      body: "medium",
                      headline: "large"
                    },
                    options: [
                      {
                        divider: false,
                        id: "1",
                        label: "Standard Delivery",
                        value: "Delivery Option 1"
                      },
                      {
                        divider: false,
                        id: "2",
                        label: "Express Delivery",
                        value: "Delivery Option 2"
                      }
                    ],
                    style: "PLAIN",
                    headline: "Delivery Method Selection",
                    value: {
                      divider: false,
                      id: "1",
                      label: "Standard Delivery",
                      value: "Delivery Option 1"
                    },
                    cell_id: "A2B3C4D5E6F7G8H",
                    text_align: {
                      subhead: "center",
                      body: "center",
                      headline: "center"
                    }
                  }
                ],
                row_order: 3,
                row_id: "2ce1ddaa44d042c",
                version: "713c59e2424d4d5"
              },
              {
                cells: [
                  {
                    heading: "Select Extra Services",
                    cell_order: 0,
                    subhead: "Extra Services Available",
                    version: "8dd159d0afd34fd",
                    form: "checkbox",
                    helper: "Choose any additional services you would like to add to your cart.",
                    text_size: {
                     subhead: "medium",
                      body: "medium",
                      headline: "large"
                    },
                    options: [
                      {
                        divider: false,
                        id: "1A2B3C4D5E",
                        label: "Standard Delivery",
                        value: "standard_delivery"
                      },
                      {
                        divider: false,
                        id: "2B3C4D5E6F",
                        label: "Express Delivery",
                        value: "express_delivery"
                      },
                      {
                        divider: false,
                        id: "3C4D5E6F7G",
                        label: "Gift Wrapping",
                        value: "gift_wrapping"
                      },
                      {
                        divider: false,
                        id: "4D5E6F7G8H",
                        label: "Insurance",
                        value: "insurance"
                      }
                    ],
                    style: "PLAIN",
                    headline: "Additional Service Options",
                    value: {
                      divider: false,
                      id: "1A2B3C4D5E",
                      label: "Standard Delivery",
                      value: "standard_delivery"
                    },
                    cell_id: "I9J0K1L2M3N4O5P",
                    text_align: {
                      subhead: "center",
                      body: "center",
                      headline: "center"
                    }
                  }
                ],
                row_order: 4,
                row_id: "b8705e8355ce481",
                version: "f17e872a317348b"
              },
              {
                cells: [
                  {
                    heading: "Enable Notifications",
                    cell_order: 0,
                    subhead: "Set Up Notifications",
                    version: "1d1b557120de479",
                    form: "toggle",
                    helper: "Choose your preferred notification settings.",
                    text_size: {
                     subhead: "medium",
                      body: "medium",
                      headline: "large"
                    },
                    options: [
                      {
                        divider: false,
                        id: "x1y2z3a4b5c6d7e",
                        label: "Email Notifications",
                        value: "email"
                      },
                      {
                        divider: false,
                        id: "z1y2x3a4b5c6d7e",
                        label: "SMS Notifications",
                        value: "sms"
                      }
                    ],
                    style: "PLAIN",
                    headline: "Configure Alerts",
                    value: {
                      divider: false,
                      id: "x1y2z3a4b5c6d7e",
                      label: "Email Notifications",
                      value: "email"
                    },
                    cell_id: "Q2W3E4R5T6Y7U8V",
                    text_align: {
                      subhead: "left",
                      body: "center",
                      headline: "center"
                    }
                  }
                ],
                row_order: 5,
                row_id: "3a688af110864cb",
                version: "658a14176366459"
              },
              {
                cells: [
                  {
                    heading: "Select Payment Method",
                    cell_order: 0,
                    subhead: "Pick Your Payment Type",
                    version: "f74e8ee3caff413",
                    form: "dropdown",
                    helper: "provide options for selecting a payment method",
                    text_size: {
                      subhead: "medium",
                      body: "medium",
                      headline: "large"
                    },
                    options: [
                      {
                        divider: false,
                        id: "A1B2C3D4E5F6G7H",
                        label: "Choose Card Payment Option",
                        value: "credit_card"
                      },
                      {
                        divider: false,
                        id: "J9I8H7G6F5E4D3C2B1A",
                        label: "Opt for PayPal Payment Method",
                        value: "paypal"
                      }
                    ],
                    style: "OUTLINED",
                    headline: "Choose Preferred Payment Option",
                    value: {
                      divider: false,
                      id: "A1B2C3D4E5F6G7H",
                      label: "Choose Card Payment Option",
                      value: "credit_card"
                    },
                    cell_id: "H7J6K5L4M3N2O1P",
                    text_align: {
                      subhead: "center",
                      body: "center",
                      headline: "center"
                    }
                  },
                  {
                    form: "space",
                    heading: "",
                    cell_order: 1,
                    style: "",
                    version: "c136b553728a4a5",
                    cell_id: "A1B2C3D4E5F6G8"
                  }
                ],
                row_order: 6,
                row_id: "cd4b4bce1fda454",
                version: "b132fd23fa09435"
              }
            ],

          }
        ],
         components: [
            {
              id: "f3f94hL2Xx0Ly2D",
              module: "workflow",
              cat: "navigation",
              icon: "mir_dashboard",
              title: "Product List",
              order: 0
            },
            {
              id: "t9d3L4X1cZ3fU0k",
              module: "workflow",
              cat: "navigation",
              icon: "mir_feed",
              title: "Cart",
              order: 1
            }
          ],
       },
    version: 2,
    is_json: true,
    reply: "Created an additional workflow screen with various components including heading, card, input, button, output, radio, checkbox, toggle, and dropdown.",

    }
  }

 export const Languages: any[] = [
  {
    key: 'English',
    value: 'en',
    direction: 'ltr'
  },
  // {
  //   key: 'Arabic',
  //   value: 'ar',
  //   direction: 'rtl'
  // },
  {
    key: 'German',
    value: 'de',
    direction: 'ltr'
  },
  {
    key: 'Spanish',
    value: 'es',
    direction: 'ltr'
  },
  // {
  //   key: 'Persian',
  //   value: 'fa',
  //   direction: 'rtl'
  // },
  {
    key: 'French',
    value: 'fr',
    direction: 'ltr'
  },
  {
    key: 'Italian',
    value: 'it',
    direction: 'ltr'
  },
  // {
  //   key: 'Japanese',
  //   value: 'ja',
  //   direction: 'ltr'
  // },
  // {
  //   key: 'Korean',
  //   value: 'ko',
  //   direction: 'ltr'
  // },
  // {
  //   key: 'Dutch',
  //   value: 'nl',
  //   direction: 'ltr'
  // },
  {
    key: 'Portuguese',
    value: 'pt',
    direction: 'ltr'
  },
  {
    key: 'Chinese',
    value: 'zh',
    direction: 'ltr'
  }
];

export enum AI2CAT{
  navigation = 'home',
  drawer = 'side',
  };
  export enum ai2ButtonForm{
  separator  = MENU_BUTTON_FORM.SEPARATOR,
  card = MENU_BUTTON_FORM.CARD,
  text_field =MENU_BUTTON_FORM.INPUT,
  button = MENU_BUTTON_FORM.BUTTON,
  output = MENU_BUTTON_FORM.OUTPUT,
  radio = MENU_BUTTON_FORM.SINGLECHOICE,
  checkbox = MENU_BUTTON_FORM.MULTICHOICE,
  toggle = MENU_BUTTON_FORM.TOGGLE,
  specer = MENU_BUTTON_FORM.FILLER,
  collection =MENU_BUTTON_FORM.COLLECTION,
  }
  export enum ai2ButtonStyle{
    centered = MENU_BUTTON_STYLE.STYLE_0603,
    balanced = MENU_BUTTON_STYLE.STYLE_0303,
    overlay = MENU_BUTTON_STYLE.STYLE_0503,
    gradient = MENU_BUTTON_STYLE.STYLE_0403,
    inline = MENU_BUTTON_STYLE.STYLE_0703,
    detached = MENU_BUTTON_STYLE.STYLE_0803,
    filled = MENU_BUTTON_STYLE.STYLE_01,
    outlined=MENU_BUTTON_STYLE.STYLE_02,
    floating =MENU_BUTTON_STYLE.STYLE_03,
    elevated =MENU_BUTTON_STYLE.STYLE_04,
    tonal = MENU_BUTTON_STYLE.STYLE_03,
    text =  MENU_BUTTON_STYLE.STYLE_05,
    standard = MENU_BUTTON_STYLE.STYLE_1000,
    image = MENU_BUTTON_STYLE.STYLE_2000,
    iconic=MENU_BUTTON_STYLE.STYLE_3000,
    highlighted= MENU_BUTTON_STYLE.STYLE_01,
    split = MENU_BUTTON_STYLE.STYLE_02,
    stacked= MENU_BUTTON_STYLE.STYLE_03,
    lined = MENU_BUTTON_STYLE.STYLE_1004,
    blank =MENU_BUTTON_STYLE.STYLE_01,
    twin_layered=MENU_BUTTON_STYLE.STYLE_0103,
    twin_image_only=MENU_BUTTON_STYLE.STYLE_0403,
    twin_captioned=MENU_BUTTON_STYLE.STYLE_0503,
    image_only=MENU_BUTTON_STYLE.STYLE_0403,
    captioned=MENU_BUTTON_STYLE.STYLE_0503,
    circular=MENU_BUTTON_STYLE.STYLE_0204,

  }

  export enum ai2ButtonCode {
    seperator_lined ="bar_01",
    card_centered = "card_06",
    card_balanced = "card_03",
    card_overlay = "card_05",
    card_gradient = "card_04",
    card_inline = "card_07",
    card_floating = "card_08",
    text_field_filled = "inout_01",
    text_field_floating = "inout_02",
    text_field_outlined = "inout_03",
    button_filled = "button_01",
    button_elevated = "button_04",
    button_tonal = "button_03",
    button_outlined = "button_02",
    button_text = "button_05",
    output_highlighted = "out_01",
    output_split = "out_02",
    output_stacked = "out_03",
    radio_standard = "singlechoice_1000",
    radio_image = "singlechoice_2000",
    radio_iconic = "singlechoice_3000",
    checkbox_standard = "multiplechoice_1000",
    checkbox_image = "multiplechoice_2000",
    checkbox_iconic = "multiplechoice_3000",
    toggle_standard = "toggle_1000",
    toggle_image = "toggle_2000",
    toggle_iconic = "toggle_3000",
    dropdown_filed = "dropdown_01",
    dropdown_outlined = "dropdown_02",
    dropdown_floating = "dropdown_03",
    spacer_blank = "spacer_01",
    collection_twin_layered ="collection_01",
    collection_twin_image_only ="collection_04",
    collection_twin_captioned ="collection_05",
    collection_image_only ="collection_04",
    collection_captioned ="collection_05",
    collection_circular ="collection_02",
  }

  // COLLECTION = "collection",
  //   COLLECTION_01 = "collection_01",
  //   COLLECTION_02 = "collection_02",
  //   COLLECTION_03 = "collection_03",
  //   COLLECTION_04 = "collection_04",
  //   COLLECTION_05 = "collection_05",
  //   COLLECTION_06 = "collection_06",
  //   COLLECTION_07 = "collection_07",
  //   COLLECTION_08 = "collection_08",
  //   COLLECTION_09 = "collection_09",
  //   COLLECTION_10 = "collection_10",
  //   COLLECTION_11 = "collection_11",
  //   COLLECTION_12 = "collection_12",
  //   COLLECTION_13 = "collection_13",
  //   COLLECTION_14 = "collection_14",
  //   COLLECTION_15 = "collection_15",
  //   COLLECTION_16 = "collection_16",
  //   SPLASH_01 = "splash_01",
  //   SPLASH_02 = "splash_02",
  //   SPLASH_03 = "splash_03",
  //   SPLASH_04 = "splash_04",
  //   SPLASH_05 = "splash_05",
  //   SPLASH_06 = "splash_06",
  //   SPLASH_07 = "splash_07",


export enum AI2Tabs {
  feed = TabNames.FEED,
  web_view = TabNames.WEBVIEW,
  channel = TabNames.CHANNEL,
  open_bot = TabNames.OPENBOT,
  chat = TabNames.CHAT,
  contact = TabNames.CONTACT,
  channel_list = TabNames.CHANNELLIST,
  setting = TabNames.SETTINGS,
  calender = TabNames.CALENDAR,
  invitation = TabNames.INVITATION,
  openchat = TabNames.OPENCHAT,
  opengroup = TabNames.OPENGROUP,
  qr = TabNames.QR,
  search = TabNames.SEARCH,
  group = TabNames.GROUP,
  market = TabNames.MARKET,
  call_log = TabNames.CALL_LOG,
  video = TabNames.VIDEO,
  map_search = TabNames.MAP_SEARCH,
  workflow = TabNames.MENU,
  store =TabNames.STORE,
  online_channels = TabNames.ONLINECHANNEL,
  online_groups = TabNames.ONLINEGROUP,
  call_dialer = TabNames.SPEEDDIALER,
  my_orders= TabNames.MYORDERS,
}

export enum Payment_Status_cc{
  PENDING = 'Pending', //warrning
  POSTED= 'Paid', // sucess
  DECLINED ='Failed', // danger
  REFUNDED= 'Refunded',//primary
  CANCELLED= 'Cancelled',// danger
}

export enum Payment_Status_Cash{
  PENDING = 'Accepted',
  POSTED = 'Paid',
  DECLINED = 'Failed',
  REFUNDED = 'Refunded',
  CANCELLED = 'Cancelled'
}
export enum Product_Payment_Status{
  PENDING = 'Preparing',
  POSTED='Completed',
  DECLINED= 'Failed to Deliver',
  REFUNDED= 'Refunded',
  CANCELLED= 'Cancelled'
}
export enum BookingOREvent_Payment_Status{
  PENDING = 'Pending',
  POSTED='Confirmed',
  DECLINED= 'Not Reserved',
  REFUNDED= 'Refunded',
  CANCELLED= 'Cancelled'
}
export enum Ticket_Payment_Status{
  PENDING = 'Pending',
  POSTED = 'Active',
  DECLINED = 'Not Reserved',
  REFUNDED = 'Refunded',
  CANCELLED = 'Cancelled',
  USED = 'Used',
  CONFIRMED = 'Confirmed',
  EXPIRED=  'Expired'
}


export const  defaultLogoColor = {
                        "logo_color": {
                        "hdpi": "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684304113821_c_hdpi.png?temp_url_sig=9bf718f9331b98ecfab6202330604915568f3f59&temp_url_expires=2055898382",
                        "mdpi": "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684304113821_c_mdpi.png?temp_url_sig=41c9738a10410d99910fb4b85a4d4f4bd589223f&temp_url_expires=2055898386",
                        "xhdpi": "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684304113821_c_xhdpi.png?temp_url_sig=35c3b46df4aeef87428334037cfa4f57577c2174&temp_url_expires=2055898388",
                        "xxhdpi": "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684304113821_c_xxhdpi.png?temp_url_sig=496101992b6c9b68113bb72ae56304a9cf8782a8&temp_url_expires=2055898391",
                        "xxxhdpi": "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684304113821_c_xxxhdpi.png?temp_url_sig=26ee8c92f753a63acfc5986bcf4f37709ea6b20e&temp_url_expires=2055898394",
                        "hdpi_rounded": "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684304113821_c_hdpi_foreground.png?temp_url_sig=133531f59e0baaaa2b2b026f15fcc3df38f8eaba&temp_url_expires=2055898396",
                        "mdpi_rounded": "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684304113821_c_mdpi_foreground.png?temp_url_sig=6bdfbe85dd13a6648903ed9ec490d6768d540d8c&temp_url_expires=2055898399",
                        "xhdpi_rounded": "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684304113821_c_xhdpi_foreground.png?temp_url_sig=f543d094337a339af9cc9feac6d53733e4c5d4a4&temp_url_expires=2055898401",
                        "xxhdpi_rounded": "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684304113821_c_xxhdpi_foreground.png?temp_url_sig=fc0a46c23a3530416d59adecf2c9f9d3d1c9e376&temp_url_expires=2055898403",
                        "xxxhdpi_rounded": "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684304113821_c_xxxhdpi_foreground.png?temp_url_sig=74cae9e497440cc5fe09e4dd19b70b2153ecbb6b&temp_url_expires=2055898406",
                        "ios20_1x": "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684304113821_c_20@1x.png?temp_url_sig=4ae7227f28799a3c0c563021a99ef038417eeeea&temp_url_expires=2055898408",
                        "ios20_2x": "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684304113821_c_20@2x.png?temp_url_sig=03bba9215b4e4566c16137f3141990783105cf5c&temp_url_expires=2055898411",
                        "ios20_3x": "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684304113821_c_20@3x.png?temp_url_sig=4a096f264e32047fafbe4c17d036698b1b6fb065&temp_url_expires=2055898413",
                        "ios29_1x": "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684304113821_c_29@1x.png?temp_url_sig=61d916801f524b348f62fec98d45cf8715245941&temp_url_expires=2055898416",
                        "ios29_2x": "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684304113821_c_29@2x.png?temp_url_sig=6606b337a10db869a5f9f46d382ffbcd7ce5a538&temp_url_expires=2055898419",
                        "ios29_3x": "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684304113821_c_29@3x.png?temp_url_sig=bc4bb01c8961aea2790642b7a4414bd530196a29&temp_url_expires=2055898422",
                        "ios40_2x": "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684304113821_c_40@2x.png?temp_url_sig=c58675c145c39573ce4d6dab9159555e736a9bd2&temp_url_expires=2055898424",
                        "ios40_3x": "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684304113821_c_40@3x.png?temp_url_sig=7f164654fbb56b934dc72d0e83ebf20622764e70&temp_url_expires=2055898426",
                        "ios60_3x": "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684304113821_c_60@3x.png?temp_url_sig=a79f4a3cc30247469455f89035a11ec8a3d6da1d&temp_url_expires=2055898430",
                        "ios76_1x": "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684304113821_c_76@1x.png?temp_url_sig=cef05982cff3a64e709acee4f094c5a3fedd7dc5&temp_url_expires=2055898432",
                        "ios76_2x": "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684304113821_c_76@2x.png?temp_url_sig=5e711b502a5c87d1e58e77739d72f72dff925585&temp_url_expires=2055898435",
                        "ios83_2x": "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684304113821_c_83@2x.png?temp_url_sig=804d7ab482f4dd9636d0ce458a32e2328864ac85&temp_url_expires=2055898437",
                        "ios150_1x": "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684304113821_c_150@1x.png?temp_url_sig=3c173a6e7a22dbc4dcbe8ee9b4f4dbe355fcf8f2&temp_url_expires=2055898439",
                        "ios300_2x": "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684304113821_c_300@2x.png?temp_url_sig=82e54984642dd076cbbe5fc49da61f60f3665797&temp_url_expires=2055898443",
                        "ios450_3x": "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684304113821_c_450@3x.png?temp_url_sig=545edffac8063ca5d0ef2415cf3a56ebe1d6faf9&temp_url_expires=2055898448",
                        "id": "logo_color"
                    },
                    "logo_white": {
                        "hdpi": "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684304113821_c_hdpi.png?temp_url_sig=9bf718f9331b98ecfab6202330604915568f3f59&temp_url_expires=2055898382",
                        "mdpi": "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684304113821_c_mdpi.png?temp_url_sig=41c9738a10410d99910fb4b85a4d4f4bd589223f&temp_url_expires=2055898386",
                        "xhdpi": "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684304113821_c_xhdpi.png?temp_url_sig=35c3b46df4aeef87428334037cfa4f57577c2174&temp_url_expires=2055898388",
                        "xxhdpi": "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684304113821_c_xxhdpi.png?temp_url_sig=496101992b6c9b68113bb72ae56304a9cf8782a8&temp_url_expires=2055898391",
                        "xxxhdpi": "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684304113821_c_xxxhdpi.png?temp_url_sig=26ee8c92f753a63acfc5986bcf4f37709ea6b20e&temp_url_expires=2055898394",
                        "ios16_1x": "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684304113821_c_hdpi_foreground.png?temp_url_sig=133531f59e0baaaa2b2b026f15fcc3df38f8eaba&temp_url_expires=2055898396",
                        "ios32_2x": "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684304113821_c_mdpi_foreground.png?temp_url_sig=6bdfbe85dd13a6648903ed9ec490d6768d540d8c&temp_url_expires=2055898399",
                        "ios48_3x": "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684304113821_c_xhdpi_foreground.png?temp_url_sig=f543d094337a339af9cc9feac6d53733e4c5d4a4&temp_url_expires=2055898401",
                        "id": "logo_white"
                    },
                    "color_logo_bg": "#eeeee7",
                    "image": "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684304113821_c_xxxhdpi.png?temp_url_sig=26ee8c92f753a63acfc5986bcf4f37709ea6b20e&temp_url_expires=2055898394",
                    "dominant_color": "#cc3b3d",
                    "image_id": "90090684304113821_c_xxxhdpi.png"

}
