<!-- ********* Android Active **************** -->
<div *ngIf="isActive && isAndroid && pStyle==1" class="row  rounded-1 m-0 p-0 d-flex justify-content-center w-100 align-items-center"  [ngClass]="fontSmaller?'mt-0':'mt-2'">
  <fieldset class="d-flex align-items-center m-0" [ngClass]="padding" [style]="'border-bottom: solid 2px; height:' +  cellHight + '; border-color: ' + border_color + ' !important'" [ngStyle]="pStyl3">
    <div class="row m-0 p-0 d-flex justify-content-center align-items-center w-100" [ngClass]="fontSmaller?'p-0':'p-1'" >
      <div *ngIf="leading_icon" class="col-1 m-0 px-0  d-flex justify-content-center " >
        <!-- <i class="float-none w-auto  m-0 " [ngStyle]="leadingIconColor" class="{{leading_icon}}" [ngClass]="sublabelPadding">
        </i> -->
        <i class="float-none w-auto m-0 d-flex align-items-center" [ngStyle]="leadingIconColor" [ngClass]="sublabelPadding"  [class]="builderService.getIcon(leading_icon).type=='mir'?'material-icons-outlined':'material-icons'">
          {{ builderService.getIcon(leading_icon).id }}
        </i>
      </div>
      <div  [ngClass]="leading_icon? trailing_icon? 'col-10':'col-11':  trailing_icon?'col-11':'col-12'"  class="m-0 p-0  d-flex justify-content-start" >
          <p class="float-none w-auto m-0" *ngIf="inputText" [ngStyle]="inputTextColor" [ngClass]="sublabelPadding">
           {{inputText}}
        </p>
      </div>
      <div *ngIf="trailing_icon" class="col-1 m-0 p-0 d-flex justify-content-center" >
        <!-- <i class="float-none w-auto  m-0 " [ngStyle]="traillingIconColor" class="{{trailing_icon}}" [ngClass]="sublabelPadding">
        </i> -->
        <i class="float-none w-auto m-0 d-flex align-items-center" [ngStyle]="traillingIconColor" [ngClass]="sublabelPadding"  [class]="builderService.getIcon(trailing_icon).type=='mir'?'material-icons-outlined':'material-icons'">
          {{ builderService.getIcon(trailing_icon).id }}
        </i>
      </div>
    </div>
  </fieldset>
</div>

<div *ngIf="isActive && isAndroid && pStyle==2" class="row  rounded-1 m-0 p-0" >
  <fieldset class="d-flex align-items-center m-0" [ngClass]="padding" [style]="'border-bottom: solid 2px; height:' +  cellHight + '; border-color: ' + border_color + ' !important'" [ngStyle]="pStyl3">
    <div class="row m-0 p-0 d-flex justify-content-center align-items-center w-100 h-100" [ngClass]="fontSmaller?'p-0':'p-1'" >
      <div *ngIf="leading_icon" class="col-1 m-0 px-0  d-flex justify-content-center " >
        <!-- <i class="float-none w-auto  m-0 " [ngStyle]="leadingIconColor" class="{{leading_icon}}" [ngClass]="sublabelPadding">
        </i> -->
        <i class="float-none w-auto m-0 d-flex align-items-center" [ngStyle]="leadingIconColor" [ngClass]="sublabelPadding"  [class]="builderService.getIcon(leading_icon).type=='mir'?'material-icons-outlined':'material-icons'">
          {{ builderService.getIcon(leading_icon).id }}
        </i>
      </div>
      <div  [ngClass]="leading_icon? avatar_icon? 'col-10':'col-11':  avatar_icon?'col-11':'col-12'"  class="m-0 p-0  d-flex justify-content-start" >
          <p class="float-none w-auto m-0" *ngIf="inputText" [ngStyle]="inputTextColor" [ngClass]="sublabelPadding">
           {{inputText}}
        </p>
      </div>
      <div *ngIf="avatar_icon" class="col-1 m-0 p-0 d-flex align-items-center justify-content-center h-100" >
        <!-- <i class="float-none w-auto  m-0 " [ngStyle]="traillingIconColor" class="{{trailing_icon}}" [ngClass]="sublabelPadding">
        </i> -->
        <img
            class="rounded-circle h-75 p-0"
            src="{{ profile_image }}"
            onerror="this.onerror=null; this.src='assets/images/users/account.webp'"
          />
      </div>
    </div>
  </fieldset>
</div>

<div *ngIf="isActive && isAndroid && pStyle==3" class="row  rounded-1 m-0 p-0 d-flex justify-content-center w-100 align-items-center" [ngClass]="fontSmaller?'mt-0':'mt-2'">
  <fieldset class="d-flex align-items-center m-0" [ngClass]="padding" [style]="'border-bottom: solid 2px; height:' +  cellHight + '; border-color: ' + border_color + ' !important'" [ngStyle]="pStyl3">
    <div class="row m-0 p-0 d-flex justify-content-center align-items-center w-100" [ngClass]="fontSmaller?'p-0':'p-1'" >
      <div *ngIf="leading_icon" class="col-1 m-0 px-0  d-flex justify-content-center " >
        <!-- <i class="float-none w-auto  m-0 " [ngStyle]="leadingIconColor" class="{{leading_icon}}" [ngClass]="sublabelPadding">
        </i> -->
        <i class="float-none w-auto m-0 d-flex align-items-center" [ngStyle]="leadingIconColor" [ngClass]="sublabelPadding"  [class]="builderService.getIcon(leading_icon).type=='mir'?'material-icons-outlined':'material-icons'">
          {{ builderService.getIcon(leading_icon).id }}
        </i>
      </div>
      <div  [ngClass]="leading_icon? trailing_icon? 'col-9':'col-11':  trailing_icon?'col-10':'col-12'"  class="m-0 p-0  d-flex justify-content-start" >
          <p class="float-none w-auto m-0" *ngIf="inputText" [ngStyle]="inputTextColor" [ngClass]="sublabelPadding">
           {{inputText}}
        </p>
      </div>
      <div *ngIf="trailing_icon" class="col-2 m-0 p-0 d-flex justify-content-center" >
        <!-- <i class="float-none w-auto  m-0 " [ngStyle]="traillingIconColor" class="{{trailing_icon}}" [ngClass]="sublabelPadding">
        </i> -->
        <i *ngIf="second_trailing_icon" class="float-none w-auto m-0 d-flex align-items-center" [ngStyle]="traillingIconColor" [ngClass]="sublabelPadding"  [class]="builderService.getIcon(second_trailing_icon).type=='mir'?'material-icons-outlined':'material-icons'">
          {{ builderService.getIcon(second_trailing_icon).id }}
        </i>
        <i class="float-none w-auto m-0 d-flex align-items-center" [ngStyle]="traillingIconColor" [ngClass]="sublabelPadding"  [class]="builderService.getIcon(trailing_icon).type=='mir'?'material-icons-outlined':'material-icons'">
          {{ builderService.getIcon(trailing_icon).id }}
        </i>
      </div>
    </div>
    </fieldset>
  </div>

  <div *ngIf="isActive && isAndroid && pStyle==4" class="row  rounded-1 m-0 p-0" >
    <fieldset class="d-flex align-items-center m-0" [ngClass]="padding" [style]="'border-bottom: solid 2px; height:' +  cellHight + '; border-color: ' + border_color + ' !important'" [ngStyle]="pStyl3">
      <div class="row m-0 p-0 d-flex justify-content-center align-items-center w-100 h-100" [ngClass]="fontSmaller?'p-0':'p-1'" >
        <div *ngIf="leading_icon" class="col-1 m-0 px-0  d-flex justify-content-center " >
          <!-- <i class="float-none w-auto  m-0 " [ngStyle]="leadingIconColor" class="{{leading_icon}}" [ngClass]="sublabelPadding">
          </i> -->
          <i class="float-none w-auto m-0 d-flex align-items-center" [ngStyle]="leadingIconColor" [ngClass]="sublabelPadding"  [class]="builderService.getIcon(leading_icon).type=='mir'?'material-icons-outlined':'material-icons'">
            {{ builderService.getIcon(leading_icon).id }}
          </i>
        </div>
        <div  [ngClass]="leading_icon? avatar_icon? 'col-9':'col-11':  avatar_icon?'col-10':'col-12'"  class="m-0 p-0  d-flex justify-content-start" >
            <p class="float-none w-auto m-0" *ngIf="inputText" [ngStyle]="inputTextColor" [ngClass]="sublabelPadding">
             {{inputText}}
          </p>
        </div>
        <div *ngIf="avatar_icon" class="col-2 m-0 p-0 d-flex align-items-center justify-content-center h-100" >
          <!-- <i class="float-none w-auto  m-0 " [ngStyle]="traillingIconColor" class="{{trailing_icon}}" [ngClass]="sublabelPadding">
          </i> -->
          <i *ngIf="second_trailing_icon" class="float-none w-auto m-0 d-flex align-items-center" [ngStyle]="traillingIconColor" [ngClass]="sublabelPadding"  [class]="builderService.getIcon(second_trailing_icon).type=='mir'?'material-icons-outlined':'material-icons'">
            {{ builderService.getIcon(second_trailing_icon).id }}
          </i>
          <img
              class="rounded-circle h-75 p-0"
              src="{{ profile_image }}"
              onerror="this.onerror=null; this.src='assets/images/users/account.webp'"
            />
        </div>
      </div>
    </fieldset>
  </div>

  <!-- ********* Android Default  **************** -->
  <div *ngIf="!isActive && isAndroid && pStyle==1" class="row  rounded-1 m-0 p-0"  [ngClass]="fontSmaller?'mt-0':'mt-2'">
    <div class="m-0 p-0">
      <fieldset class="d-flex align-items-center m-0" [ngClass]="padding" attr.style="{{'border-bottom: solid 1px; height:' +  cellHight + '; border-color: ' + border_color + ' !important' }}" [ngStyle]="pStyl3">
        <div  class="row m-0 p-1 d-flex  justify-content-center w-100 h-100">
          <div  *ngIf="leading_icon" class="col-1 m-0 px-0 d-flex justify-content-center" >
            <!-- <i class="float-none w-auto m-0" [ngStyle]="leadingIconColor" class="{{leading_icon}}"  [ngClass]="labellPadding">
            </i> -->
            <i class="float-none w-auto m-0 d-flex align-items-center" [ngStyle]="leadingIconColor" [ngClass]="sublabelPadding"  [class]="builderService.getIcon(leading_icon).type=='mir'?'material-icons-outlined':'material-icons'">
              {{ builderService.getIcon(leading_icon).id }}
            </i>
          </div>
          <div  [ngClass]="leading_icon? avatar_icon? 'col-10':'col-11':  avatar_icon?'col-11':'col-12'"  class="m-0 p-0 d-flex justify-content-start">
            <p class="float-none w-auto  m-0" [ngStyle]="labelColor"  [ngClass]="labellPadding">
              {{label}}
            </p>
          </div>

          <div *ngIf="avatar_icon" class="col-1 m-0 p-0 d-flex align-items-center justify-content-center h-100" >
            <img
            class="rounded-circle h-75 p-0"
            src="{{ profile_image }}"
            onerror="this.onerror=null; this.src='assets/images/users/account.webp'"
          />
          </div>
        </div>
      </fieldset>
    </div>
    </div>

<div *ngIf="!isActive && isAndroid && pStyle==2" class="row  rounded-1 m-0 p-0" [ngClass]="fontSmaller?'mt-0':'mt-2'" >
  <fieldset class="d-flex align-items-center m-0" [ngClass]="padding" attr.style="{{'border-bottom: solid 1px; height:' +  cellHight + '; border-color: ' + border_color + ' !important' }}" [ngStyle]="pStyl3">
    <div  class="row m-0 p-1 d-flex  justify-content-center w-100">
      <div  *ngIf="leading_icon" class="col-1 m-0 px-0 d-flex justify-content-center" >
        <!-- <i class="float-none w-auto m-0" [ngStyle]="leadingIconColor" class="{{leading_icon}}"  [ngClass]="labellPadding">
        </i> -->
        <i class="float-none w-auto m-0 d-flex align-items-center" [ngStyle]="leadingIconColor" [ngClass]="sublabelPadding"  [class]="builderService.getIcon(leading_icon).type=='mir'?'material-icons-outlined':'material-icons'">
          {{ builderService.getIcon(leading_icon).id }}
        </i>
      </div>
      <div  [ngClass]="leading_icon? trailing_icon? 'col-10':'col-11':  trailing_icon?'col-11':'col-12'"  class="m-0 p-0 d-flex justify-content-start">
        <p class="float-none w-auto  m-0" [ngStyle]="labelColor"  [ngClass]="labellPadding">
          {{label}}
        </p>
      </div>

      <div   *ngIf="trailing_icon" class="col-1 m-0 p-0  d-flex justify-content-center" >
        <i *ngIf="second_trailing_icon" class="float-none w-auto m-0 d-flex align-items-center" [ngStyle]="traillingIconColor" [ngClass]="sublabelPadding"  [class]="builderService.getIcon(second_trailing_icon).type=='mir'?'material-icons-outlined':'material-icons'">
          {{ builderService.getIcon(second_trailing_icon).id }}
        </i>
        <i class="float-none w-auto m-0 d-flex align-items-center" [ngStyle]="traillingIconColor" [ngClass]="sublabelPadding"  [class]="builderService.getIcon(trailing_icon).type=='mir'?'material-icons-outlined':'material-icons'">
          {{ builderService.getIcon(trailing_icon).id }}
        </i>
      </div>
    </div>
  </fieldset>
</div>

<div *ngIf="!isActive && isAndroid && pStyle==3" class="row  rounded-1 m-0 p-0" [ngClass]="fontSmaller?'mt-0':'mt-2'">
  <fieldset class="d-flex align-items-center m-0" [ngClass]="padding" attr.style="{{'border-bottom: solid 1px; height:' +  cellHight + '; border-color: ' + border_color + ' !important' }}" [ngStyle]="pStyl3">
    <div  class="row m-0 p-1 d-flex  justify-content-center w-100">
      <div  *ngIf="leading_icon" class="col-1 m-0 px-0 d-flex justify-content-center" >
        <!-- <i class="float-none w-auto m-0" [ngStyle]="leadingIconColor" class="{{leading_icon}}"  [ngClass]="labellPadding">
        </i> -->
        <i class="float-none w-auto m-0 d-flex align-items-center" [ngStyle]="leadingIconColor" [ngClass]="sublabelPadding"  [class]="builderService.getIcon(leading_icon).type=='mir'?'material-icons-outlined':'material-icons'">
          {{ builderService.getIcon(leading_icon).id }}
        </i>
      </div>
      <div  [ngClass]="leading_icon? trailing_icon? 'col-10':'col-11':  trailing_icon?'col-11':'col-12'"  class="m-0 p-0 d-flex justify-content-start">
        <p class="float-none w-auto  m-0" [ngStyle]="labelColor"  [ngClass]="labellPadding">
          {{label}}
        </p>
      </div>

      <div   *ngIf="trailing_icon" class="col-2 m-0 p-0  d-flex justify-content-center" >
        <i *ngIf="second_trailing_icon" class="float-none w-auto m-0 d-flex align-items-center" [ngStyle]="traillingIconColor" [ngClass]="sublabelPadding"  [class]="builderService.getIcon(second_trailing_icon).type=='mir'?'material-icons-outlined':'material-icons'">
          {{ builderService.getIcon(second_trailing_icon).id }}
        </i>
        <i class="float-none w-auto m-0 d-flex align-items-center" [ngStyle]="traillingIconColor" [ngClass]="sublabelPadding"  [class]="builderService.getIcon(trailing_icon).type=='mir'?'material-icons-outlined':'material-icons'">
          {{ builderService.getIcon(trailing_icon).id }}
        </i>
      </div>
    </div>
  </fieldset>
</div>

<div *ngIf="!isActive && isAndroid && pStyle==4" class="row  rounded-1 m-0 p-0"  [ngClass]="fontSmaller?'mt-0':'mt-2'">
  <div class="m-0 p-0">
    <fieldset class="d-flex align-items-center m-0" [ngClass]="padding" attr.style="{{'border-bottom: solid 1px; height:' +  cellHight + '; border-color: ' + border_color + ' !important' }}" [ngStyle]="pStyl3">
      <div  class="row m-0 p-1 d-flex  justify-content-center w-100 h-100">
        <div  *ngIf="leading_icon" class="col-1 m-0 px-0 d-flex justify-content-center" >
          <!-- <i class="float-none w-auto m-0" [ngStyle]="leadingIconColor" class="{{leading_icon}}"  [ngClass]="labellPadding">
          </i> -->
          <i class="float-none w-auto m-0 d-flex align-items-center" [ngStyle]="leadingIconColor" [ngClass]="sublabelPadding"  [class]="builderService.getIcon(leading_icon).type=='mir'?'material-icons-outlined':'material-icons'">
            {{ builderService.getIcon(leading_icon).id }}
          </i>
        </div>
        <div  [ngClass]="leading_icon? avatar_icon? 'col-9':'col-11':  avatar_icon?'col-10':'col-12'"  class="m-0 p-0 d-flex justify-content-start">
          <p class="float-none w-auto  m-0" [ngStyle]="labelColor"  [ngClass]="labellPadding">
            {{label}}
          </p>
        </div>

        <div *ngIf="avatar_icon" class="col-2 m-0 p-0  d-flex align-items-center justify-content-center h-100">
          <i *ngIf="second_trailing_icon" class="float-none w-auto m-0 d-flex align-items-center" [ngStyle]="traillingIconColor" [ngClass]="sublabelPadding"  [class]="builderService.getIcon(second_trailing_icon).type=='mir'?'material-icons-outlined':'material-icons'">
            {{ builderService.getIcon(second_trailing_icon).id }}
          </i>
          <img
          class="rounded-circle h-75 p-0"
          src="{{ profile_image }}"
          onerror="this.onerror=null; this.src='assets/images/users/account.webp'"
          />
        </div>
      </div>
    </fieldset>
  </div>
  </div>



<!-- ********* iOS Active and Default **************** -->
<div *ngIf="!isAndroid && pStyle==1" class="row  rounded-1 m-0 p-0 d-flex justify-content-center w-100 align-items-center"  [ngClass]="fontSmaller?'mt-0':'mt-2'">
  <fieldset class="d-flex align-items-center m-0" [ngClass]="padding" [style]="'border-bottom: solid 2px; height:' +  cellHight + '; border-color: ' + border_color + ' !important'" [ngStyle]="pStyl3">
    <div class="row m-0 p-0 d-flex justify-content-center align-items-center w-100" [ngClass]="fontSmaller?'p-0':'p-1'" >
      <div *ngIf="leading_icon" class="col-1 m-0 px-0  d-flex justify-content-center " >
        <!-- <i class="float-none w-auto  m-0 " [ngStyle]="leadingIconColor" class="{{leading_icon}}" [ngClass]="sublabelPadding">
        </i> -->
        <i class="float-none w-auto m-0 d-flex align-items-center" [ngStyle]="leadingIconColor" [ngClass]="sublabelPadding"  [class]="builderService.getIcon(leading_icon).type=='mir'?'material-icons-outlined':'material-icons'">
          {{ builderService.getIcon(leading_icon).id }}
        </i>
      </div>
      <div  [ngClass]="leading_icon? trailing_icon? 'col-10':'col-11':  trailing_icon?'col-11':'col-12'"  class="m-0 p-0  d-flex justify-content-start" >
          <p class="float-none w-auto m-0" *ngIf="inputText" [ngStyle]="inputTextColor" [ngClass]="sublabelPadding">
           {{inputText}}
        </p>
      </div>
      <div *ngIf="trailing_icon" class="col-1 m-0 p-0 d-flex justify-content-center" >
        <!-- <i class="float-none w-auto  m-0 " [ngStyle]="traillingIconColor" class="{{trailing_icon}}" [ngClass]="sublabelPadding">
        </i> -->
        <i class="float-none w-auto m-0 d-flex align-items-center" [ngStyle]="traillingIconColor" [ngClass]="sublabelPadding"  [class]="builderService.getIcon(trailing_icon).type=='mir'?'material-icons-outlined':'material-icons'">
          {{ builderService.getIcon(trailing_icon).id }}
        </i>
      </div>
    </div>
  </fieldset>
</div>
  <div *ngIf="!isAndroid && pStyle==2" class="row m-0 p-0"  [ngClass]="fontSmaller?'mt-0':'mt-2'" >
    <fieldset class="d-flex align-item-center m-0 bg-white" [ngClass]="padding" attr.style="{{'border-bottom: solid 1px; height:' +  cellHight + '; border-color :' + border_color_ios +  '!important' }}" [ngStyle]="pStyl3">
      <p class="float-none w-auto  m-0" *ngIf="label" [ngStyle]="labelColor"  [ngClass]="labellPadding">
        {{label}}
      </p>
      <p class=" m-0 text-wrap" *ngIf="inputText"  [ngStyle]="inputTextColor" [ngClass]="sublabelPadding">
        {{inputText}}
        <a style="color:#007aff">
          |
         </a>
      </p>
    </fieldset>
  </div>


  <div *ngIf="!isAndroid && pStyle==3" class="row m-0 p-0 d-flex align-item-center"  [ngClass]="fontSmaller?'mt-0':'mt-2'">
    <fieldset class="d-flex align-item-center m-0 bg-white" [ngClass]="padding" attr.style="{{'border-bottom: solid 1px; height:' +  cellHight + '; border-color:' + border_color_ios +  '!important' }}">
      <p class="float-none w-auto  m-0 text-wrap" *ngIf="label" [ngStyle]="labelColor"  [ngClass]="labellPadding">
        {{label}}
      </p>
      <p class="float-none w-auto m-0 text-wrap" *ngIf="inputText"  [ngStyle]="inputTextColor" [ngClass]="sublabelPadding">
        {{inputText}}
        <a style="color:#007aff">
          |
         </a>
      </p>
      </fieldset>
  </div>

  <div *ngIf="!isAndroid && pStyle==4" class="row m-0 p-0"  [ngClass]="fontSmaller?'mt-0':'mt-2'" >
    <fieldset class="d-flex align-item-center m-0 bg-white" [ngClass]="padding" attr.style="{{'border-bottom: solid 1px; height:' +  cellHight + '; border-color :' + border_color_ios +  '!important' }}" [ngStyle]="pStyl3">
      <p class="float-none w-auto  m-0" *ngIf="label" [ngStyle]="labelColor"  [ngClass]="labellPadding">
        {{label}}
      </p>
      <p class=" m-0 text-wrap" *ngIf="inputText"  [ngStyle]="inputTextColor" [ngClass]="sublabelPadding">
        {{inputText}}
        <a style="color:#007aff">
          |
         </a>
      </p>
    </fieldset>
  </div>
  <div *ngIf="helper || error" class="ps-1" >
    <p *ngIf="!error" class="p-0 m-0" [ngStyle]="helperColor">
    {{helper}}
    </p>
    <p *ngIf="error" class="p-0 m-0" [ngStyle]="errorColor">
      {{error}}
      </p>
  </div>
