import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import {
  AI2CAT,
  AI2Tabs,
  ASSIGN_STORE,
  AppItems,
  BuildTarget,
  MENU_BUTTON_CODE,
  MENU_BUTTON_FORM,
  MobileView,
  SPLASH_STYLE,
  TabNames,
  ValidateItemKey,
  ai2ButtonCode,
  ai2ButtonForm,
  ai2ButtonStyle,
  aiTestMap,
  app_check_list,
  c1,
  c10,
  c11,
  c12,
  c13,
  c14,
  c15,
  c16,
  c17,
  c18,
  c2,
  c3,
  c4,
  c5,
  c6,
  c7,
  c8,
  c9,
  common_button_migration_map,
  common_components_map,
  common_tab_migration_map,
  defaultLogoColor,
  errorMap,
  menu_button_map,
  splash_config_map,

} from "./constants";
import { aiAppInfo, Chat, colorSchema, Menu, MenuButton, MenuRow, Message, Tab } from "./interface";
import { IndexDBService } from "./indexDB.service";
import { BuilderService } from "src/app/sharedservices/builder.service";
// import { argbFromHex, CorePaletteColors, CorePalette, hexFromArgb, themeFromImage, Scheme, CustomColor, themeFromSourceColor } from "@material/material-color-utilities";

import { CorePalette, CorePaletteColors, CustomColor, Hct, Scheme, hexFromArgb, themeFromImage } from "@material/material-color-utilities";
import { argbFromHex, themeFromSourceColor, applyTheme } from "@material/material-color-utilities";
import { Router } from "@angular/router";
import Swal from 'sweetalert2';
import { WebsocketService } from './websocket.service';
@Injectable({
  providedIn: "root",
})
export class MiddlwareService {
  constructor(
    private ws: WebsocketService,
    public indexDBService: IndexDBService,
    public builderService: BuilderService,
    private router: Router
  ) {}

  id: any;
  email: any;
  token: any;
  order: any;
  chatID: any;
  templateID: any;
  user: any;
  bot: any;
  date: any;
  chatList: any;
  selectedPlan: any;
  planDetails: any;
  tempModeStatus: boolean;
  paymentPRO: any;
  profile: any;
  tabList: any;
  returnUrl = "/";
  recharge_amount: any;
  showAdmin = false;
  menu_id: string;
  menu: any;
  balance: any;
  vappLogo: any;
  appInfoSettings: any;
  buildDetails: any;

  ////
  AppConfigVersion: number = 0;
  modes: any = [];
  atopBar: string = "./assets/images/mobile/topbar.webp";

  anavBarL: string = "./assets/images/mobile/navbarL.webp"; // for wider navBar
  anavBarS: string = "./assets/images/mobile/navbarS.webp";

  afull: string = "./assets/images/mobile/emptyImageF.webp";

  // aLong: string = "./assets/images/mobile/emptyImageL.webp";
   aLong: string = "./assets/images/mobile/empty-newscreen-AN-L.webp";
  aSide: string = "./assets/images/mobile/emptyImageSide.png";
  aShort: string = "./assets/images/mobile/emptyImageS.webp";
  // aShortS: string = "./assets/images/mobile/emptyImageSS.webp"; // for wide nav bar
  aShortS: string = "./assets/images/mobile/Empty-newscreen_AN_S.webp"; // for wide nav bar


  ifull: string = "./assets/images/mobile/emptyImageF_ios.webp"; // to be replaced later on by iOS Hazem
  // iLong: string = "./assets/images/mobile/emptyImageL_ios.webp";
  iLong: string = "./assets/images/mobile/empty-newscreen-AN-L.webp";
  iShort: string = "./assets/images/mobile/emptyImageS_ios.webp";
  iLongS: string = "./assets/images/mobile/emptyImageLS_ios.webp"; // for large font
  iShortS: string = "./assets/images/mobile/emptyImageSS_ios.webp"; // for large font

  iSide: string = "./assets/images/mobile/emptyImageF_ios.webp";   //for Side full screen no even nav bottom bar

  itopBar: string = "./assets/images/mobile/topbar_ios.webp";
  itopbarTitle: string = "./assets/images/mobile/topbar_title_ios.webp"; // for large font
  inavBar: string = "./assets/images/mobile/navbar_ios.webp";

  view: string;
  imageTheme: any ={};
  imagePalette: any[];


  async changeTab(
    data,
    mode,
    view?: boolean,
    tabStop?: boolean,
    noBuilderVersionUpdate?: boolean
  ) {
    if (data && data.tab && data.tab.module) {
      switch (data.tab.module) {
        case TabNames.OPENCHAT:
        case TabNames.FEED:
        case TabNames.SEARCH:
        case TabNames.VIDEO:
        case TabNames.WEBVIEW:
        case TabNames.MENU:
        case TabNames.PAGE:
        case TabNames.QR:
        case TabNames.QR_PAGE:
        case TabNames.BOOKING:
        case TabNames.CALENDAR:
        case TabNames.CALL_LOG:
        case TabNames.CHANNELLIST:
        case TabNames.CHAT:
        case TabNames.CONTACT:
        case TabNames.MAP_SEARCH:
        case TabNames.GROUP:
        case TabNames.INVITATION:
        case TabNames.CHANNEL:
        case TabNames.OPENBOT:
        case TabNames.VIDEOAUDIO:
        case TabNames.ONLINEGROUP:
        case TabNames.ONLINECHANNEL:
        case TabNames.OPENGROUP:
        case TabNames.BOOKINGLIST:
        case TabNames.EMPTY:
        case TabNames.SPLASH:
        case TabNames.STORE:
        case TabNames.MARKET:
        case TabNames.POLL:
        case TabNames.PALETTE:
        case TabNames.SPEEDDIALER:
        case TabNames.QUERY:
        case TabNames.MYORDERS:
        case TabNames.SETTINGS:
        case TabNames.PAYMENT_VIEW:
        case TabNames.LOGIN_VIEW:
        case TabNames.PRODUCT:
        case TabNames.COLLECTION:
        case TabNames.CATEGORY:
        case TabNames.BOOKINGPRODUCT:
        case TabNames.EVENTPRODUCT:
        case TabNames.CART:
          {
            if (
              data &&
              (data.tab.module === TabNames.MENU ||
                data.tab.module === TabNames.PAGE ||
                data.tab.module === TabNames.STORE ||
                data.tab.module === TabNames.MARKET ||
                data.tab.module === TabNames.POLL ||
                data.tab.module === TabNames.QUERY ||
                data.tab.module === TabNames.SPLASH)
            ) {
                try {
                  await this.updateMenu(data);
                } catch (error) {

                }

              if (!data["menu"]) {
                data["menu"] = { ...this.menu };
              }

              if (!noBuilderVersionUpdate && data.tab.module != TabNames.STORE &&   data.tab.module != TabNames.MARKET && data.tab.module != TabNames.POLL && data.tab.module != TabNames.SPLASH &&(!data.tab.cat ||
                  (data.tab.cat != "store" && data.tab.cat != "market" && data.tab.cat != "poll"))
              ) {
                localStorage.setItem("version", "1");
              }
            }
            if (!tabStop) {
              if ((data.tab.type != TabNames.EMPTY || data.tab.module != TabNames.EMPTY) &&  !view  ) {
                // update the tab version
                data.tab.tab_version = this.makeRef(16);
                if ( !noBuilderVersionUpdate &&
                  data.tab.cat != TabNames.STORE &&
                  data.tab.cat != TabNames.MARKET &&
                  data.tab.cat != TabNames.POLL
                ) {
                  data.tab.web['update'] = 1;
                  localStorage.setItem("version", "1");
                }
                const response = this.indexDBService.updateItem( "tab", data.tab );
                response.onsuccess = () => {
                  let inputData = { ...data, mode: mode };
                  this._updateBuilderComponentContainer.next(inputData);
                };
              } else {
                let inputData = { ...data, mode: mode };
                this._updateBuilderComponentContainer.next(inputData);
              }
            }
          }
          break;
      }
    }
  }

  async updateMenu(inputData) {
    // input from subscription
    delete inputData["mode"];
    // if there in tab as input return menu

    if (inputData && inputData.tab.menu_id) {
      this.menu = await this.constructMenuSync(inputData.tab.menu_id);
    }

    // All menu , row and button are exist in the data.
    else if (
      inputData &&
      inputData["menu"] &&
      inputData["menu"].menu_id &&
      inputData["row"] &&
      inputData["row"].row_id &&
      inputData["button"] &&
      inputData["button"].button_id
    ) {
      if (inputData.menu["new"]) {
        delete inputData.menu["new"];
        inputData["menu"] = { ...inputData["menu"] };
      }
      if (inputData.button["new"]) {
        delete inputData.button["new"];
        inputData["button"] = {
          ...inputData["button"],
          ...menu_button_map[inputData["menu"].menu_id],
        };
      }

      // update the menu version
      inputData["menu"].menu_version = this.makeRef(16);
      inputData["menu"].update = 1;

      const menu = this.indexDBService.updateItem("menu", inputData["menu"]);
      menu.onsuccess = async () => {
        inputData["row"].row_version = this.makeRef(16);
        inputData["row"].update = 1;

        const row = this.indexDBService.updateItem("row", inputData["row"]);
        row.onsuccess = async () => {
          inputData["button"].button_version = this.makeRef(16);
          inputData["button"].update = 1;

          const button = this.indexDBService.updateItem(
            "button",
            inputData["button"]
          );
          button.onsuccess = async () => {
            this.menu = await this.builderService.constructMenus(
              inputData.tab.menu_id
            );
          };
        };
      };
    }
    // only row and button are exist in the data.
    if (
      inputData &&
      !inputData["menu"] &&
      inputData["row"] &&
      inputData["row"].row_id &&
      inputData["button"] &&
      inputData["button"].button_id
    ) {
      if (inputData.button["new"]) {
        delete inputData.button["new"];
        inputData["button"] = {
          ...inputData["button"],
          ...menu_button_map[inputData["button"].button_code],
        };
      }

      inputData["row"].row_version = this.makeRef(16);
      inputData["row"].update = 1;

      const row = this.indexDBService.updateItem("row", inputData["row"]);
      row.onsuccess = async () => {
        inputData["button"].button_version = this.makeRef(16);
        inputData["button"].update = 1;

        const button = this.indexDBService.updateItem(
          "button",
          inputData["button"]
        );
        button.onsuccess = async () => {
          this.menu = await this.builderService.constructMenus(
            inputData.row.menu_id
          );
        };
      };
    }

    // only Menu is exist in the data.
    if (
      inputData &&
      inputData["menu"] &&
      inputData["menu"].menu_id &&
      !inputData["row"] &&
      !inputData["button"]
    ) {
      if (inputData.menu["new"]) {
        delete inputData.menu["new"];
        inputData["menu"] = { ...inputData["menu"] };
      }

      inputData["menu"].menu_version = this.makeRef(16);
      inputData["menu"].update = 1;

      const menu = this.indexDBService.updateItem("menu", inputData["menu"]);
      menu.onsuccess = async () => {
        this.menu = await this.builderService.constructMenus(
          inputData.menu.menu_id
        );
      };
    }

    // only row is exist in the data.
    if (
      inputData &&
      !inputData["menu"] &&
      inputData["row"] &&
      inputData["row"].row_id &&
      !inputData["button"]
    ) {
      inputData["row"].row_version = this.makeRef(16);
      inputData["row"].update = 1;

      const row = this.indexDBService.updateItem("row", inputData["row"]);
      row.onsuccess = async () => {
        this.menu = await this.builderService.constructMenus(
          row.result.menu_id
        );
      };
    }

    // only button is exist in the data.
    if (
      inputData &&
      !inputData["menu"] &&
      !inputData["row"] &&
      inputData["button"] &&
      inputData["button"].button_id
    ) {
      if (inputData.button["new"]) {
        delete inputData.button["new"];
        inputData["button"] = {
          ...inputData["button"],
          ...menu_button_map[inputData["button"].button_code],
        };
      }

      inputData["button"].button_version = this.makeRef(16);
      inputData["button"].update = 1;

      const button = this.indexDBService.updateItem(
        "button",
        inputData["button"]
      );
      button.onsuccess = async () => {
        const row = this.indexDBService.getItem(
          "row",
          inputData["button"].row_id
        );
        row.onsuccess = async () => {
          this.menu = await this.builderService.constructMenus(
            row.result.menu_id
          );
        };
      };
    }
  }

  /// extract the dark color from original color.////////////////////
  ColorLuminance(hex, lum) {
    // validate hex string
    hex = String(hex).replace(/[^0-9a-f]/gi, "");
    if (hex.length < 6) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    lum = lum || 0;

    // convert to decimal and change luminosity
    let rgb = "#";
    let c;
    let i;
    for (i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i * 2, 2), 16);
      c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
      rgb += ("00" + c).substr(c.length);
    }

    return rgb;
  }

  public _editChat = new BehaviorSubject<any>(undefined);
  public editChat$ = this._editChat.asObservable();

  public _editUser = new BehaviorSubject<any>(undefined);
  public editUser$ = this._editUser.asObservable();

  public _editBot = new BehaviorSubject<any>(undefined);
  public editBot$ = this._editBot.asObservable();

  public _scheduleMessageDate = new BehaviorSubject<any>(undefined);
  public scheduleMessageDate$ = this._scheduleMessageDate.asObservable();

  public _chatList = new BehaviorSubject<any>(undefined);
  public chatList$ = this._chatList.asObservable();

  public _selectedPlan = new BehaviorSubject<any>(undefined);
  public selectedPlan$ = this._selectedPlan.asObservable();

  public _editDomainContainer = new BehaviorSubject<any>(undefined);
  public editDomainContainer$ = this._editDomainContainer.asObservable();

  public _rechargeContainer = new BehaviorSubject<any>(undefined);
  public rechargeContainer$ = this._rechargeContainer.asObservable();

  public _idContainer = new BehaviorSubject<any>(undefined);
  public idContainer$ = this._idContainer.asObservable();

  public _emailContainer = new BehaviorSubject<any>(undefined);
  public emailContainer$ = this._emailContainer.asObservable();

  public _tokenContainer = new BehaviorSubject<any>(undefined);
  public tokenContainer$ = this._tokenContainer.asObservable();

  public _orderContainer = new BehaviorSubject<any>(undefined);
  public orderContainer$ = this._orderContainer.asObservable();

  public _profileContainer = new BehaviorSubject<any>(undefined);
  public profileContainer$ = this._profileContainer.asObservable();

  public _showAdminContainer = new BehaviorSubject<any>(undefined);
  public showAdminContainer$ = this._showAdminContainer.asObservable();
  public _showRootAdminContainer = new BehaviorSubject<any>(undefined);
  public showRootAdminContainer$ = this._showRootAdminContainer.asObservable();

  public _colorsComponentContainer = new BehaviorSubject<any>(undefined);
  public colorsComponentContainer$ =
    this._colorsComponentContainer.asObservable();

  public _updateBuilderComponentContainer = new BehaviorSubject<any>(undefined);
  public updateBuilderComponentContainer$ =
    this._updateBuilderComponentContainer.asObservable();

  public _updateButtonComponentContainer = new BehaviorSubject<MenuButton>(
    undefined
  );
  public updateButtonComponentContainer$ =
    this._updateButtonComponentContainer.asObservable();

  public _responseBuilderComponentContainer = new BehaviorSubject<any>(
    undefined
  );
  public responseBuilderComponentContainer$ =
    this._responseBuilderComponentContainer.asObservable();

  public _currentTabContainer = new BehaviorSubject<any>(undefined);
  public currentTabContainer$ = this._currentTabContainer.asObservable();

  public _currentMenuContainer = new BehaviorSubject<any>(undefined);
  public currentMenuContainer$ = this._currentMenuContainer.asObservable();

  public _offCanvasContainer = new BehaviorSubject<any>(undefined);
  public offCanvasContainer$ = this._offCanvasContainer.asObservable();

  public _offCanvasResponseContainer = new Subject<any>();
  public offCanvasResponseContainer$ =
    this._offCanvasResponseContainer.asObservable();

  public _selectedMessageRepliesContainer = new BehaviorSubject<Message>(null);
  public selectedMessageRepliesContainer$ =
    this._selectedMessageRepliesContainer.asObservable();




  public _refreshMessageContainer = new BehaviorSubject<any>(undefined);
  public refreshMessageContainer$ = this._refreshMessageContainer.asObservable();



  public _userSavedNotifyContainer = new BehaviorSubject<any>(null);
  public userSavedNotifyContainer$ =
    this._userSavedNotifyContainer.asObservable();

  public _getAppConfigContainer = new BehaviorSubject<any>(undefined);
  public getAppConfigContainer$ = this._getAppConfigContainer.asObservable();

  public _getAppConfigOnceContainer = new Subject<any>();
  public getAppConfigOnceContainer$ =
    this._getAppConfigOnceContainer.asObservable();

  public _vappLogoContainer = new BehaviorSubject<any>(undefined);
  public vappLogoContainer$ = this._vappLogoContainer.asObservable();

  public _activeChatContainer = new BehaviorSubject<any>(undefined);
  public activeChatContainer$ = this._activeChatContainer.asObservable();



  public _saveAppConfigSignupContainer = new Subject<any>();
  public saveAppConfigSignupContainer$ = this._saveAppConfigSignupContainer.asObservable();


  public _saveAppConfigChangeTemplateContainer = new Subject<any>();
  public saveAppConfigChangeTemplateContainer$ = this._saveAppConfigChangeTemplateContainer.asObservable();


  public _layoutRefreshContainer = new Subject<any>();
  public layoutRefreshContainer$ = this._layoutRefreshContainer.asObservable();

  public _schemeChangeContainer = new Subject<any>();
  public schemeChangeContainer$ = this._schemeChangeContainer.asObservable();

  public _storeContainer = new Subject<any>();
  public storeContainer$ = this._storeContainer.asObservable();

  public _pollContainer = new Subject<any>();
  public pollContainer$ = this._pollContainer.asObservable();

  public _getStoreMethodContainer = new Subject<any>();
  public getStoreMethodContainer$ =
    this._getStoreMethodContainer.asObservable();

  public _getPollMethodContainer = new Subject<any>();
  public getPollMethodContainer$ = this._getPollMethodContainer.asObservable();

  public _appInfoContainer = new BehaviorSubject<any>(null);
  public appInfoContainer$ = this._appInfoContainer.asObservable();

  public _planDetails = new Subject<any>();
  public planDetails$ = this._planDetails.asObservable();

  public _tempModeStatus = new Subject<any>();
  public tempModeStatus$ = this._tempModeStatus.asObservable();

  public _templateID = new Subject<any>();
  public templateID$ = this._templateID.asObservable();

  public _recallMessageContainer = new Subject<any>();
  public recallMessageContainer$ = this._recallMessageContainer.asObservable();

  public _resetAppConfigContainer = new Subject<any>();
  public resetAppConfigContainer$ = this._resetAppConfigContainer.asObservable();

  public _chatSelectedContainer = new Subject<any>();
  public chatSelectedContainer$ = this._chatSelectedContainer.asObservable();

  public _paymentPROContainer = new Subject<any>();
  public paymentPROContainer$ = this._paymentPROContainer.asObservable();

  public _buildDetailsContainer = new Subject<any>();
  public buildDetailsContainer$ = this._buildDetailsContainer.asObservable();

  public _toggleModeContainer = new Subject<any>();
  public toggleModeContainer$ = this._toggleModeContainer.asObservable();

  public _tourContainer = new Subject<any>();
  public tourContainer$ = this._tourContainer.asObservable();

  idSubscription = this.idContainer$.subscribe((received_id) => {
    this.id = received_id;
  });

  emailSubscription = this.emailContainer$.subscribe((received_email) => {
    this.email = received_email;
  });

  tokenSubscription = this.tokenContainer$.subscribe((received_token) => {
    this.token = received_token;
  });

  orderSubscription = this.orderContainer$.subscribe((received_order) => {
    this.order = received_order;
  });

  profileSubscription = this.profileContainer$.subscribe((received_profile) => {
    this.profile = received_profile;
  });

  rechargeSubscription = this.rechargeContainer$.subscribe(
    (received_amount) => {
      this.recharge_amount = received_amount;
    }
  );

  vappSubscription = this.vappLogoContainer$.subscribe((logo) => {
    this.vappLogo = logo;
    // console.log("VAPPPPP LOGO");
    // console.log(this.vappLogo);
  });

  channelSubscription = this.editChat$.subscribe(
    (chatID) => (this.chatID = chatID)
  );

  templateIDSubscription = this.templateID$.subscribe(
    (id) => {
      this.templateID = id ? id : null;
    }
  );
  appInfoSubscription = this.appInfoContainer$.subscribe(
    (data) => (this.appInfoSettings = data ? data.data : null)
  );

  userSubscription = this.editUser$.subscribe((user) => (this.user = user));
  botSubscription = this.editBot$.subscribe((bot) => (this.bot = bot));
  dateSubscription = this.scheduleMessageDate$.subscribe(
    (date) => (this.date = date)
  );
  chatListSubscription = this.chatList$.subscribe(
    (chatList) => (this.chatList = chatList)
  );

  selectedPlanSubscription = this.selectedPlan$.subscribe(
    (plan) => (this.selectedPlan = plan)
  );



  planDetailsSubscription = this.planDetails$.subscribe(
    (data) => (this.planDetails = {
      plan_id: data && data.plan_id ? data.plan_id : null,
      cancel_end: data && data.cancel_end ? data.cancel_end : null,
      trial_end_date: data && data.trial_end_date ? data.trial_end_date : null,
      expire: data && data.expire ? data.expire : null,
      signup_coupon: data && data.signup_coupon ? data.signup_coupon : false
    })
  );

  tempModeStatusSubscription = this.tempModeStatus$.subscribe(
    (data) => (this.tempModeStatus = data)
  );

  paymentPROSubscription = this.paymentPROContainer$.subscribe(
    (data) => (this.paymentPRO = data)
  );

  buildDetailsSubscription = this.buildDetailsContainer$.subscribe(
    (data) => (this.buildDetails = data)
  );

  getChatID() {
    return this.chatID;
  }

  getDate() {
    return this.date;
  }

  getUser() {
    return this.user;
  }

  getBot() {
    return this.bot;
  }

  getchatList() {
    return this.chatList;
  }

  getSelectedPlan() {
    return this.selectedPlan;
  }

  getPlanDetails() {
    return this.planDetails;
  }

  getTempModeStatus() {
    return this.tempModeStatus;
  }

  getPaymentProviders() {
    return this.paymentPRO;
  }

  getID() {
    return this.id;
  }

  getEmail() {
    return this.email;
  }

  getToken() {
    return this.token;
  }

  getOrder() {
    return this.order;
  }

  getProfile() {
    return this.profile;
  }

  getRecharge() {
    return this.recharge_amount;
  }

  mapError(code: number) {
    return errorMap[code];
  }

  getTemplateID(){
    return this.templateID;
  }

  getAppInfoSettings(){
    return this.appInfoSettings
  }

  getBuildsDetails(){
    return this.buildDetails;
  }

  clear() {
    this.id = null;
    this.email = null;
    this.token = null;
    this.order = null;
    this.profile = null;
    this.returnUrl = "/";
  }

  makeRef(length: number) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  makRefNumber(length: number) {
    let result = "";
    const numbers = "0123456789";
    const numbersLength = numbers.length;
    let counter = 0;
    while (counter < length) {
      result += numbers.charAt(Math.floor(Math.random() * numbersLength));
      counter += 1;
    }
    return Number(result);
  }

  message(msg): Observable<void> {
    return msg;
  }

  localCommonComponentsMap(module) {
    return common_components_map[module];
  }

  isValidHexaCode(str) {
    // Regex to check valid
    // hexadecimalColor_code
    let regex = new RegExp(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/);

    // if str
    // is empty return false
    if (str == null) {
      return "false";
    }

    // Return true if the str
    // matched the ReGex
    if (regex.test(str) == true) {
      return true;
    } else {
      return false;
    }
  }

  customColor(color, source?, mode:string = 'color') {
    let customColor: CustomColor = {
      name: "test",
      value: argbFromHex(color),
      blend: true,
    };
    let theme = themeFromSourceColor(argbFromHex(source), [customColor]);
    // let customScheme = {light: theme.customColors[0].light, dark: theme.customColors[0].dark};
    let scheme: any;
    if (theme) {
      scheme = {
        light: hexFromArgb(theme.customColors[0].light[mode]),
        dark: hexFromArgb(theme.customColors[0].dark[mode]),
      };
    } else {
      scheme = { light: color, dark: color };
    }
    return scheme;
  }


  async saveBuilderDB(data) {
    if (localStorage.getItem("flush")==="true"){
      this.deleteAppConfig();
      localStorage.removeItem("flush")
    }
    if (data) {
      if (data && data.sample){
        localStorage.setItem("sample", "true")
      }else {
        localStorage.removeItem("sample")
      }
      if (data.appConfig && data.appConfig.app && data.appConfig.app.version) {
        this.AppConfigVersion = data.appConfig.app.version.value? data.appConfig.app.version.value: 0;
      } else {
        this.AppConfigVersion = 0;
        // this.deleteAppConfig();
      }
      if (data.genAI) {
        // localStorage.removeItem("start");
        localStorage.setItem("version", "1");
        await this.deleteAppConfig()
        await this.saveAppConfiguration(data)

      }
      let dBVersion: any = {};
      dBVersion = await this.indexDBService.getItemOnsucss("item", "version");
      if ( (!dBVersion || !dBVersion.value || dBVersion.value < this.AppConfigVersion) || data.template_id) {

         if (data.template_id &&  dBVersion && dBVersion.value >= 0 ){
          let swalWithBootstrapButtons = Swal.mixin({
            customClass: { confirmButton: "btn btn-success", cancelButton: "btn btn-danger ms-2", },
            buttonsStyling: false,
          });
          swalWithBootstrapButtons
          .fire({
            title: "Overwrite the current App configuration?",
            text: "Please save current App configuration!",
            icon: "warning",
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, cancel!",
            showCancelButton: true,
          })
          .then(async (result) => {
            if (result.value) {
              // to only update certian field
              // if (data.appConfig.app.system && data.appConfig.app.system.app_info){
              //   data.appConfig.app.system['app_info'] = await this.getUpdatedCurrentAppInfo(data.appConfig.app.system.app_info);
              // }else {
              //   data.appConfig.app.system['app_info'] = await this.getUpdatedCurrentAppInfo(null)
              // }
              // delete and clean existing configuration before apply the template
              await this.deleteAppConfig()
              delete data.template_id;
              let template_id = localStorage.getItem("template_id")
                   if (template_id){
                this._saveAppConfigChangeTemplateContainer.next(data)
              }
              await this.saveAppConfiguration(data)
              localStorage.setItem("version", "1")
              this._getStoreMethodContainer.next(null);

              if (data.onlineChannelConfig && data.onlineChannelConfig.app && data.onlineChannelConfig.app.tabs){
                this.sendColors(data.onlineChannelConfig.app.tabs)
              }

            } else {
              localStorage.removeItem("template_id")
            }
          });

        } else if (data.template_id && (!dBVersion || !dBVersion.value)){
          delete data.template_id;
          let template_id = localStorage.getItem("template_id")
          if (template_id){
            this._saveAppConfigSignupContainer.next(data)
          }
          await this.saveAppConfiguration(data)
          localStorage.setItem("version", "0")
          this._getStoreMethodContainer.next(null);

          if (data.onlineChannelConfig && data.onlineChannelConfig.app && data.onlineChannelConfig.app.tabs){
            this.sendColors(data.onlineChannelConfig.app.tabs)
          }

        } else {
          await this.deleteAppConfig()

          await this.saveAppConfiguration(data)
          localStorage.setItem("version", "0")
          if (data.onlineChannelConfig && data.onlineChannelConfig.app && data.onlineChannelConfig.app.tabs){
            this.sendColors(data.onlineChannelConfig.app.tabs)
          }
        }
      }
      this._getAppConfigOnceContainer.next(true);
      this._getAppConfigContainer.next(true);
    }
  }

  sendColors(data){
    let colors  ={};
    if(data.color_schema){
      colors['color_schema'] = data.color_schema;
    }
    if(data.color_schema_ios){
      colors['color_schema_ios'] = data.color_schema_ios;
    }
    if (data.mode){
      let mode = data.mode
      localStorage.setItem("mode", mode);
      let isDrak : boolean;
      let modes =[];
      if (localStorage.getItem("light")){
        isDrak= localStorage.getItem("light") =="false"? true: false
        if (isDrak) {
          modes =  [   {id:c16.ID,value:c16.DARK},{ id: c2.ID, value: c2.FULL } ];
          } else {
          modes =  [   {id:c16.ID,value:c16.LIGHT}, { id: c2.ID, value: c2.FULL } ];
        }

        this.changeMobileTheme(modes);
      }
      colors['light']= mode[15]==c16.LIGHT? true:false;
    }
    this._colorsComponentContainer.next(colors);
  }

  getUpdatedCurrentAppInfo(newAppInfo){
    const appInfo = this.indexDBService.getItem("item", "app_info");
    return new Promise((resolve, reject) =>{
      appInfo.onsuccess = async (event) => {
        if (appInfo.result){
          const nAppInfo = await this.updateCurrentAppInfo (newAppInfo, appInfo.result);
          resolve(nAppInfo);
        }
      }
    });
  }


  async updateCurrentAppInfo(newAppInfo, currentAppInfo){
    if (newAppInfo!= null){
      currentAppInfo['call_enabled']=newAppInfo['call_enabled']
      currentAppInfo['demoVideo']=newAppInfo['demoVideo']
      currentAppInfo['demo_video']=newAppInfo['demo_video']
      currentAppInfo['login_type']=newAppInfo['login_type']
      currentAppInfo['video_enabled']=newAppInfo['video_enabled']
      currentAppInfo['same_tone']=newAppInfo['same_tone']

      if (currentAppInfo.logo_color.length <2 ){
        currentAppInfo['logo_color']=newAppInfo['logo_color']
        currentAppInfo['logo_white']=newAppInfo['logo_white']
        currentAppInfo['image']=newAppInfo['image']
        currentAppInfo['image_id']=newAppInfo['image_id']
        currentAppInfo['dominant_color']=newAppInfo['dominant_color']
        currentAppInfo['color_logo_bg']=newAppInfo['color_logo_bg']
      }
      if(!currentAppInfo.channel_name){
        currentAppInfo['channel_name']=newAppInfo['channel_name']
      }
    }
    return currentAppInfo
  }


  async deleteAppConfig(){
    this.indexDBService.deleteTable("tab");
    this.indexDBService.deleteTable("menu");
    this.indexDBService.deleteTable("row");
    this.indexDBService.deleteTable("button");
    this.indexDBService.deleteTable("item");
    this.indexDBService.deleteTable("app");
    localStorage.removeItem("store");
    localStorage.removeItem("storemenu")
    localStorage.removeItem("market");
    localStorage.removeItem("marketmenu")
    localStorage.removeItem("version")

  }
  async saveAppConfiguration(data){
    if (data.appConfig) {
      data.appConfig.app["version"] = { value: this.AppConfigVersion };
    }
    if (this.AppConfigVersion == 0 && data.onlineChannelConfigStaging) {
      console.log("staging");
      await this.saveOnlineChannelConfig(data.onlineChannelConfigStaging);
      } else {
      console.log("migratted");
      if(data.onlineChannelConfig && data.onlineChannelConfig.app && data.onlineChannelConfig.app.tabs){
        this.sendColors(data.onlineChannelConfig.app.tabs)
      }
      await this.saveOnlineChannelConfig(data.onlineChannelConfig);
    }
    if (this.AppConfigVersion == 0 && data.onlineAppConfig) {
      await this.saveOnlineAppConfig(data.onlineAppConfig, true);
    } else {
      console.log("migratted");
      await this.saveOnlineAppConfig(data.onlineAppConfig, false);
    }
    if(data.onlineChannelConfig && data.onlineChannelConfig.app && data.onlineChannelConfig.app.tabs && data.onlineChannelConfig.app.tabs.color_schema && data.onlineChannelConfig.app.tabs.color_schema_ios){

      let colors = {color_schema: data.onlineChannelConfig.app.tabs.color_schema , color_schema_ios: data.onlineChannelConfig.app.tabs.color_schema_ios};
      await this.saveAppConfig(data.appConfig, colors);
    }else {
      await this.saveAppConfig(data.appConfig, null);
    }


    localStorage.setItem("version", "0");


  }

  async saveOnlineChannelConfig(data) {
    let dbData: any = {};
    dbData = data;

    // console.log(" from save onlineChannel Config", data);
    // console.log(" from save onlineChannel Config", data.app.tabs);

    if (data && data.app) {
      // get the default store regardless if you have the store or not
      this._getStoreMethodContainer.next(null);
      this.menusInsertion(data.app.menus, true);
      dbData = this.appStyle(data.app.tabs, dbData);
      this.tabsInsertion(data.app.tabs, "tabs", "home");

      // add id
      if (!dbData.id) {
        dbData.id = "home";
      }
      // clean up
      if (dbData && dbData.app && dbData.app.side_menu) {
        delete dbData.app.side_menu;
      }

      if (dbData && dbData.app && dbData.app.tabs && !dbData.app.tabs.default) {
        dbData.app.tabs.default = { feed: { chat_label: "0" } };
      }

      if (dbData && dbData.app && dbData.app && !dbData.app.app_bar){
        let app_bar= {"notification": 1, "add_group":2, "setting":1 };
        dbData.app['app_bar'] = {...app_bar};
      }

      delete dbData.app.menus;
      delete dbData.app.tabs.tabs;
      dbData.app["menus"] = [];
      dbData.app.tabs["tabs"] = [];
      await this.indexDBService.insertItem("app", dbData);
    } else {
      // no data
      let newData: any;
      newData = {
        id: "home",
        app: {
          menus: [],
          tabs: { tabs: [], default: { feed: { chat_label: "0" } } },
        },
      };
      newData = this.appStyle(newData.app.tabs, newData);
      await this.indexDBService.insertItem("app", newData);
    }
  }
  menusInsertion(menus, online?, menu_group?, cat?, navigate?: boolean) {
    if (menus) {
      let menuOrder = 0;
      // menus insertions
      let storesIds=[];
      menus.forEach((menu) => {
        if (!menu.cat) {
          menu.cat = "menu";
        }
        if (!menu.menu_version) {
          menu.menu_version = this.makeRef(16);
        }

        if (!menu.menu_order) {
          menu.menu_order = menuOrder;
        }
        if(!menu.rows){
          menu.rows=[];
        }

        if ((menu.cat == "store" ||  menu.cat == "market")  && menu.menu_group && online && !storesIds.includes(menu.menu_group)) {
          storesIds.push(menu.menu_group);
          this._getStoreMethodContainer.next(menu.menu_group);
        }

        // no update needed
        menu.update = 0;

        menuOrder++;
        let rowOrder = 0;
        menu.rows.forEach((row) => {
          if (!row.menu_id) {
            row.menu_id = menu.menu_id;
          }
          if (!row.row_version) {
            row.row_version = this.makeRef(16);
          }
          if (!row.row_id) {
            row.row_id = menu.menu_id + rowOrder;
          }
          if (!row.row_order) {
            row.row_order = rowOrder++;
          }
          // no update needed
          row.update = 0;

          let btnOrder = 0;
          row.buttons.forEach((btn) => {
            if (!btn.row_id) {
              btn.row_id = row.row_id;
            }
            if (!btn.button_order) {
              btn.button_order = btnOrder++;
            }
            if (!btn.button_verion) {
              btn.button_version = this.makeRef(16);
            }
            if (!btn.button_code && btn.button_form) {
              btn.button_code = common_button_migration_map[btn.button_form].button_code;
              btn.button_headline = common_button_migration_map[btn.button_form].button_headline;
              btn.button_subhead = common_button_migration_map[btn.button_form].button_subhead;
              btn.button_form = common_button_migration_map[btn.button_form].button_form;
            }

            if (!btn.info && btn.button_code) {
              btn.info = menu_button_map[btn.button_code] ?  menu_button_map[btn.button_code].info : {};
            }
            if (btn.button_code && !btn.button_style_ios) {
              btn.button_style = menu_button_map[btn.button_code].button_style;
              btn.button_style_ios =
                menu_button_map[btn.button_code].button_style_ios;
            }

            // no update needed
            btn.update = 0;

            if (btn.button_code) {
              this.indexDBService.insertItem("button", btn);
            }
          });
          delete row.buttons;
          this.indexDBService.insertItem("row", row);
        });
        delete menu.rows;
        this.indexDBService.insertItem("menu", menu);
      });
    }
  }
  appStyle(tabs, dbData) {
    if (tabs) {
      if (!tabs.color_schema) {
        let android_material: string ="3"

        if (tabs.material=="M3"){
          android_material = "3"
        }else {
          android_material = "2"
        }
        dbData.app.tabs.color_schema = tabs.active_title_color
          ? { source: tabs.active_title_color }
          : { source: "#386A1F" };
        let colors: any = {};
        colors.primary = dbData.app.tabs.color_schema.source;
        dbData.app.tabs.color_schema = this.createColorsTheme(
          "many",
          colors,
          null,
          c1.ANDROID,
          android_material,
          null,
        );
      }
      if (!tabs.color_schema_ios) {
        let material_ios: string ="3"

        if (tabs.material_ios=="modern"){
          material_ios = "3"
        }else {
          material_ios = "2"
        }
        dbData.app.tabs.color_schema_ios = { source: "#007aff" };
        let colors_ios: any = {};
        colors_ios.primary = "#007aff";
        colors_ios.secondary = "#767778";
        colors_ios.tertiary = "#bedbf8";
        dbData.app.tabs.color_schema_ios = this.createColorsTheme(
          "many",
          colors_ios,
          null,
          c1.IOS,
          null,
          material_ios
        );
      }
      // this for AI as it will send only source color for now
      if(tabs.color_schema && tabs.color_schema.source && !tabs.color_schema.schemes){
        let colors: any = {};
        colors.primary = dbData.app.tabs.color_schema.source;
        dbData.app.tabs.color_schema = this.createColorsTheme(
          "many",
          colors,
          null,
          c1.ANDROID
        );
      }
      if(tabs.color_schema_ios && tabs.color_schema_ios.source && !tabs.color_schema_ios.schemes){
        let colors_ios: any = {};
        colors_ios.primary = dbData.app.tabs.color_schema_ios.source;
        colors_ios.secondary = "#767778";
        colors_ios.tertiary = "#bedbf8";
        dbData.app.tabs.color_schema_ios = this.createColorsTheme(
          "many",
          colors_ios,
          null,
          c1.IOS
        );
      }
      let lightValue: boolean;
      if (tabs.color_schema || tabs.color_schema_ios) {
        if (localStorage.getItem("mode") && localStorage.getItem("mode")[15] == null) {
          lightValue = localStorage.getItem("light") === "true" ?true :false;
          if (lightValue) {
            let modes: any;
            modes = [ { id: c16.ID, value: c16.LIGHT }];
            this.changeMobileTheme(modes);
          } else {
            let modes: any;
            modes = [{ id: c16.ID, value: c16.DARK }];
            this.changeMobileTheme(modes);
          }
        } else {
          lightValue =localStorage.getItem("mode")[15] == c16.LIGHT ? true : false;
        }

        this._colorsComponentContainer.next({
          color_schema: tabs.color_schema,
          color_schema_ios: tabs.color_schema_ios,
          light: lightValue,
        });
      }
      if (dbData.app.tabs.active_icon_color) {
        delete dbData.app.tabs.active_icon_color;
      }

      if (dbData.app.tabs.active_icon_color_ios) {
        delete dbData.app.tabs.active_icon_color_ios;
      }

      if (dbData.app.tabs.active_title_color) {
        delete dbData.app.tabs.active_title_color;
      }

      if (dbData.app.tabs.active_title_color_ios) {
        delete dbData.app.tabs.active_title_color_ios;
      }

      if (dbData.app.tabs.bg) {
        delete dbData.app.tabs.bg;
      }

      if (dbData.app.tabs.bg_ios) {
        delete dbData.app.tabs.bg_ios;
      }

      if (dbData.app.tabs.default_color) {
        delete dbData.app.tabs.default_color;
      }

      if (dbData.app.tabs.default_color_ios) {
        delete dbData.app.tabs.default_color_ios;
      }

      // tab style migration
      if (tabs.tab_style && !tabs.mode) {
        this.modes = [];
        switch (tabs.tab_style) {
          case 0:
            // top wide
            this.modes = [
              { id: c4.ID, value: c4.TOP },
              { id: c5.ID, value: c5.WIDE },
            ];
            break;

          case 1:
            // bottom wide
            this.modes = [
              { id: c4.ID, value: c4.BOTTOM },
              { id: c5.ID, value: c5.WIDE },
            ];
            break;

          case 2:
            // Top Standard
            this.modes = [
              { id: c4.ID, value: c4.TOP },
              { id: c5.ID, value: c5.STANDARD },
            ];
            break;

          case 3:
            // bottom Standard
            this.modes = [
              { id: c4.ID, value: c4.BOTTOM },
              { id: c5.ID, value: c5.STANDARD },
            ];
            break;
        }

        this.changeMobileTheme(this.modes);
      }
      // // tab style migration for IOS
      // if (tabs.tab_style_ios) {
      //   this.modes = [];

      //   switch (tabs.tab_style_ios) {
      //     case 0:
      //       // Wide
      //       this.modes = [{ id: c10.ID, value: c10.WIDE }];
      //       break;

      //     case 1:
      //       // Standard
      //       this.modes = [{ id: c10.ID, value: c10.STANDARD }];
      //       break;
      //   }
      //   this.changeMobileTheme(this.modes);
      // } else {
      //   // console.log("log 3");
      //   this.modes = [{ id: c10.ID, value: c10.WIDE }];
      //   this.changeMobileTheme(this.modes);
      // }

      // tab style migration for IOS
      if (tabs.tab_style_ios) {
        // console.log("log 4");
        this.modes = [];
        switch (tabs.tab_style_ios) {
          case 0:
            // Wide
            this.modes = [{ id: c10.ID, value: c10.WIDE }];
            break;

          case 1:
            // Standard
            this.modes = [{ id: c10.ID, value: c10.STANDARD }];
            break;
        }
        this.changeMobileTheme(this.modes);
      } else {
        // console.log("log 5");
        this.modes = [{ id: c10.ID, value: c10.WIDE }];
        this.changeMobileTheme(this.modes);
      }

      // Large and Small font for iOS
      if (tabs.large_tile) {
        // console.log("log 6");
        this.modes = [];
        switch (tabs.large_title) {
          case 0:
            // Small Title
            this.modes = [{ id: c7.ID, value: c7.STANDARD }];
            break;

          case 1:
            // Standard
            this.modes = [{ id: c7.ID, value: c7.LARGE }];
            break;
        }
        this.changeMobileTheme(this.modes);
      } else {
        this.modes = [{ id: c7.ID, value: c7.STANDARD }];
        this.changeMobileTheme(this.modes);
      }
    }
    return dbData;
  }
  tabsInsertion(tabs, key, cat) {
    if (tabs && tabs[key]) {
      let tab_order: any = 0;
      tabs[key].forEach((tab) => {
        if (!tab.cat) {
          if (tab.layout && tab.layout === "button") {
            tab.cat = "next";
          } else {
            tab.cat = cat;
          }
        }

        if (!tab.id || tab.id===16) {
          tab.id =  this.makeRef(16);
        }


        if (tab.title && tab.title.default) {
          tab.title = tab.title.default;
          tab.title_ios = tab.title.default;
        }

        if (!tab.tab_version) {
          tab.tab_version = this.makeRef(16);
        }

        if (tab.tab_update) {
          delete tab.tab_update;
        }

        if (tab.update) {
          delete tab.update;
        }

        // no update needed
        if (tab.web && tab.web.upate){
          tab.web.update = 0;
        }

        if (!tab.type){
          if (tab.module){
            tab.type = tab.module
          }
        }

        if (!tab.tab_order) {
          tab.tab_order = tab_order;
        }

        tab_order++;
        if (this.AppConfigVersion == 0) {
          // migration  from version 0
          // setting the right icons
          if (tab.type =="web_view" && tab.url){
            const web_view = {
              wv_url: tab.url
            }
            tab.web_view = web_view;
            delete tab.url;
          }

          if(tab.type == 'feed'){
            tab.chat_label = tab.id;
          }
          tab.icon = common_tab_migration_map[tab.type].icon;
          tab.icon_ios = common_tab_migration_map[tab.type].icon_ios;
          if (common_tab_migration_map[tab.type].type) {
            tab.module = common_tab_migration_map[tab.type].type;
            tab.type = common_tab_migration_map[tab.type].type;
          }
          if (tab.type == TabNames.FEED && tab.footer && tab.footer.action) {
            //Hazem to revert
            // for (let i = 0; i < tab.footer.action.length; i++) {
            //   switch (tab.footer.action[i].link) {
            //     case "like":
            //       tab.footer.action[i].icon =
            //         common_tab_migration_map[tab.type].like_icon;
            //       break;
            //     case "reply":
            //       tab.footer.action[i].icon =
            //         common_tab_migration_map[
            //           tab.type
            //         ].reply_icon;
            //       break;
            //     case "view":
            //       tab.footer.action[i].icon =
            //         common_tab_migration_map[tab.type].view_icon;
            //       break;
            //     case "share":
            //       tab.footer.action[i].icon =
            //         common_tab_migration_map[
            //           tab.type
            //         ].share_icon;
            //       break;
            //     case "like_highlight":
            //       tab.footer.action[i].icon =
            //         common_tab_migration_map[
            //           tab.type
            //         ].liked_icon;
            //       break;
            //   }
            // }
          }
          if (tab.sub_menus) {
            tab.sub_menus.forEach((menu_id) => {
              const response = this.indexDBService.getItem("menu", menu_id);
              response.onsuccess = async () => {
                let menu = response.result;
                if (!menu.menu_group) {
                  menu.menu_group = tab.id;
                  this.indexDBService.updateItem("menu", menu);
                }
              };
            });
          }

          if (tab.menu_id) {
            const response = this.indexDBService.getItem("menu", tab.menu_id);
            response.onsuccess = async () => {
              let menu = response.result;
              menu.menu_group = tab.id;
              this.indexDBService.updateItem("menu", menu);
            };
            // fix the tab does not have a menu Group.
            if (!tab.menu_group){
              tab.menu_group = tab.id;
            }
          }

        }

        if (tab.menu_id) {
           if (!tab.menu_group){
            tab.menu_group = tab.id;
          }
        }

          if (!tab.module) {
          tab.module = tab.type; // temp solution to add module from type
        }
        if(tab.module == "store" && tab.param && tab.param.category =="store" && tab.param.id == null && tab.menu_group && tab.menu_id){
          localStorage.setItem("store", tab.menu_group)
          localStorage.setItem("storemenu", tab.menu_id)
        }

        if(tab.module == "market" && tab.param && tab.param.category =="market" && tab.param.id == null && tab.menu_group && tab.menu_id){
          localStorage.setItem("market", tab.menu_group)
          localStorage.setItem("marketmenu", tab.menu_id)
        }

        //add web information
        tab.web = common_components_map[tab.module].web;
        this.indexDBService.insertItem("tab", tab);
      });
    }
  }

createColorsTheme(type, baseColors, HTMLImageElement?, os?, android_material?: string, ios_material?: string){

  if (type == 'image') {
    let colors: colorSchema = {
      style: {lightStatusBar: '1'},
      schemes: { light: {}, dark: {} }, palettes: {
        primary: { keyColor: { id: null } },
        secondary: { keyColor: { id: null } }, tertiary: { keyColor: { id: null } }, error: { keyColor: { id: null } }, neutral: { keyColor: { id: null } }, neutralVariant: { keyColor: { id: null } }
      }
    }

      let theme: any = { schemes: { light: { props: {} } } }

      themeFromImage(HTMLImageElement).then(async (Theme) => {
        theme = Theme;

        Object.keys(theme.schemes.light.props).forEach(key => {
          colors.schemes.light[key] = hexFromArgb(theme.schemes.light.props[key]);
        })

        Object.keys(theme.schemes.dark.props).forEach(key => {
          colors.schemes.dark[key] = hexFromArgb(theme.schemes.dark.props[key]);
        })
        colors.source = hexFromArgb(theme.source);

        let palette: any;
        palette = theme.palettes;

        let palette_temp: any = {primary:{}, secondary:{}, tertiary:{}, error:{}, neutral:{},neutralVariant:{} };

        palette_temp.primary = palette.primary;
        palette_temp.primary.keyColor.id= hexFromArgb(palette.primary['keyColor']['argb']);
        colors.palettes.primary = palette_temp.primary;


        palette_temp.secondary = palette.secondary;
        palette_temp.secondary.keyColor.id= hexFromArgb(palette.secondary['keyColor']['argb']);
        colors.palettes.secondary = palette_temp.secondary;


        palette_temp.tertiary = palette.tertiary;
        palette_temp.tertiary.keyColor.id= hexFromArgb(palette.tertiary['keyColor']['argb']);
        colors.palettes.tertiary = palette_temp.tertiary;


        palette_temp.error = palette.error;
        palette_temp.error.keyColor.id= hexFromArgb(palette.error['keyColor']['argb']);
        colors.palettes.error = palette_temp.error;


        palette_temp.neutral = palette.neutral;
        palette_temp.neutral.keyColor.id= hexFromArgb(palette.neutral['keyColor']['argb']);
        colors.palettes.neutral = palette_temp.neutral;


        palette_temp.neutralVariant = palette.neutralVariant;
        palette_temp.neutralVariant.keyColor.id= hexFromArgb(palette.neutralVariant['keyColor']['argb']);
        colors.palettes.neutralVariant = palette_temp.neutralVariant;

        let updatedColros: colorSchema;
        let newColors : colorSchema;

      if (os == c1.ANDROID){
        updatedColros = this.builderService.additionalColors(palette, colors, 'image')
        newColors = this.builderService.extraMaterialColor(palette ,updatedColros,c1.ANDROID,android_material,null,ios_material);
        }else if (os == c1.IOS){
          newColors = this.builderService.extraMaterialColor(palette ,updatedColros,c1.IOS,android_material,null,ios_material );
        }

        // THIS LOGIC SHOULD BE MOVED UP
        this.imageTheme = newColors;
        if (this.imageTheme) {
          let imageColors:any = [];
          Object.keys(this.imageTheme.schemes.light).forEach(key => {
            imageColors.push (this.imageTheme.schemes.light[key]);
          })
          this.imagePalette = imageColors;
        }
        //////////////// END OF LOGIC //////////////////
        return  newColors
      })
    }
    if (baseColors && baseColors.primary){
      if (type == 'many') {
        let colors: colorSchema = { style: {lightStatusBar: '1'}, schemes: { light: {}, dark: {} },  palettes:{ primary:{keyColor:{id:null}},
          secondary:{keyColor:{id:null} }, tertiary:{keyColor:{id:null} } , error:{keyColor:{id:null} }, neutral:{keyColor:{id:null} }, neutralVariant:{keyColor:{id:null} } }}
        let theme: any = {};
        let CorePaletteColors: CorePaletteColors = {
          primary: argbFromHex(baseColors.primary),
          secondary: baseColors.secondary? argbFromHex(baseColors.secondary): null,
          tertiary:  baseColors.tertiary? argbFromHex(baseColors.tertiary): null,
        }
        let palette: CorePalette;
        palette = CorePalette.contentFromColors(CorePaletteColors);
        theme.light = Scheme.lightFromCorePalette(palette);
        theme.dark = Scheme.darkFromCorePalette(palette);
        Object.keys(theme.light.props).forEach(key => {
          colors.schemes.light[key] = hexFromArgb(theme.light.props[key]);
        })
        Object.keys(theme.dark.props).forEach(key => {
          colors.schemes.dark[key] = hexFromArgb(theme.dark.props[key]);
        })
        // console.log("palette", palette);
        colors.source = baseColors.primary;   // check if this primary
        let palette_temp: any = {primary:{}, secondary:{}, tertiary:{}, error:{}, neutral:{},neutralVariant:{} };
        palette_temp.primary = palette.a1;
        palette_temp.primary.keyColor.id= hexFromArgb(palette.a1['keyColor']['argb']);
        colors.palettes.primary = palette_temp.primary;

        palette_temp.secondary = palette.a2;
        palette_temp.secondary.keyColor.id= hexFromArgb(palette.a2['keyColor']['argb']);
        colors.palettes.secondary = palette_temp.secondary;

        palette_temp.tertiary = palette.a3;
        palette_temp.tertiary.keyColor.id= hexFromArgb(palette.a3['keyColor']['argb']);
        colors.palettes.tertiary = palette_temp.tertiary;

        palette_temp.error = palette.error;
        palette_temp.error.keyColor.id= hexFromArgb(palette.error['keyColor']['argb']);
        colors.palettes.error = palette_temp.error;

        palette_temp.neutral = palette.n1;
        palette_temp.neutral.keyColor.id= hexFromArgb(palette.n1['keyColor']['argb']);
        colors.palettes.neutral = palette_temp.neutral;

        palette_temp.neutralVariant = palette.n2;
        palette_temp.neutralVariant.keyColor.id= hexFromArgb(palette.n2['keyColor']['argb']);
        colors.palettes.neutralVariant = palette_temp.neutralVariant;

        let updatedColros: colorSchema
        let newColors : colorSchema


        // additional colors are colors required by android and ios but it is always there with determinstic colors.
        updatedColros = this.builderService.additionalColors(palette, colors)

        // this to set some variant styles either m2 or m3. Default m3.
        newColors = this.builderService.extraMaterialColor(palette,updatedColros,os);


        localStorage.setItem("version","1")

        // console.log("newColors", newColors);
        return newColors
      }
    }
  }



  changeColorAlpha(color, opacity) {
    //if it has an alpha, remove it
    if (color.length > 7)
      color = color.substring(0, color.length - 2);

    // coerce values so ti is between 0 and 1.
    const _opacity = Math.round(Math.min(Math.max(opacity, 0), 1) * 255);
    let opacityHex = _opacity.toString(16).toUpperCase()

    // opacities near 0 need a trailing 0
    if (opacityHex.length == 1)
      opacityHex = "0" + opacityHex;

    return '#' + opacityHex + color.replace('#', '');
  }


  changeMobileTheme(modes, tab?, configMode?: string) {
    // let modes =
    // [
    //   {id:c1.ID,value:c1.ANDROID},
    //   {id:c2.ID,value:c2.FULL},
    //   {id:c3.ID,value:c3.SINGLE},
    //   {id:c4.ID,value:c4.TOP},
    //   {id:c5.ID,value:c5.WIDE},
    //   {id:c6.ID,value:c6.BOTTOM},
    //   {id:c7.ID,value:c7.STANDARD},
    //   {id:c8.ID,value:c8.SIDE},
    //
    // ]

    // this.mdw.changeMobileTheme(modes);
      if (configMode){
        localStorage.setItem("mode", configMode);
      }
    let str = localStorage.getItem("mode");
    if (str && str.length != 18) {
      let isDrak : boolean;
      isDrak= localStorage.getItem("light") =="false"? true: false
      if (isDrak) {
        localStorage.setItem("mode", "ahmtwblttw3a3p0dps");
        str = "ahmtwblttw3a3p0dps";
        } else {
        localStorage.setItem("mode", "ahmtwblttw3a3p0lps");
        str = "ahmtwblttw3a3p0lps";
      }
    }
    // mode code as following xxxxxx
    // xxxxx as c1 c2 c3 c4 c5
    // c1: android and ios
    // c2: full, home, side, component
    // c3: single, notab, manytab
    // c4: android home- top, bottom
    // c5: android home- wide and narraw
    // c6: ios home - side menu top, bottom
    // c7: ios home - standard and large title
    // c8: android no side(false) and side (true)
    // c9: ios no side(false) and side (true)
    // c10: ios home- wide and narraw(standard)
    // c11: android style m2 (2) or m3(3)
    // c12: android apply on home& side (a) only home(h) or only side (s)
    // c13: ios style m2 (2) or m3(3)
    // c14: sender buble color "primary" (p), secondary (s), tertiary (t)
    // c15: receiver buble color lowest(0), low(1), high(2)
    // c16: light (l) and dark (d) mode
    // c17: floating button color "primary" (p), secondary (s), tertiary (t)
    // c18: for android and ios sidemenu (s) View or tab view (t)


    let c1_value: string;
    let c2_value: string;
    let c3_value: string;
    let c4_value: string;
    let c5_value: string;
    let c6_value: string;
    let c7_value: string;
    let c8_value: string;
    let c9_value: string;
    let c10_value: string;
    let c11_value: string;
    let c12_value: string;
    let c13_value: string;
    let c14_value: string;
    let c15_value: string;
    let c16_value: string;
    let c17_value: string;
    let c18_value: string;

    var regc1 = /(?<=^.{0})./gi;
    var regc2 = /(?<=^.{1})./gi;
    var regc3 = /(?<=^.{2})./gi;
    var regc4 = /(?<=^.{3})./gi;
    var regc5 = /(?<=^.{4})./gi;
    var regc6 = /(?<=^.{5})./gi;
    var regc7 = /(?<=^.{6})./gi;
    var regc8 = /(?<=^.{7})./gi;
    var regc9 = /(?<=^.{8})./gi;
    var regc10 = /(?<=^.{9})./gi;
    var regc11 = /(?<=^.{10})./gi;
    var regc12 = /(?<=^.{11})./gi;
    var regc13 = /(?<=^.{12})./gi;
    var regc14 = /(?<=^.{13})./gi;
    var regc15 = /(?<=^.{14})./gi;
    var regc16 = /(?<=^.{15})./gi;
    var regc17 = /(?<=^.{16})./gi;
    var regc18 = /(?<=^.{17})./gi;

    modes.forEach((mode) => {
      switch (mode.id) {
        case "c1":
          if (mode.value == c1.ANDROID) {
            c1_value = c1.ANDROID;
          } else {
            c1_value = c1.IOS;
          }
          str = str.replace(regc1, c1_value);
          break;
        case "c2":
          switch (mode.value) {
            case c2.FULL:
              c2_value = c2.FULL;
              break;
            case c2.HOME:
              c2_value = c2.HOME;
              break;
            case c2.SIDE:
              c2_value = c2.SIDE;
              break;
            case c2.COMPONENT:
              c2_value = c2.COMPONENT;
              break;
          }
          str = str.replace(regc2, c2_value);
          break;

        case "c3":
          switch (mode.value) {
            case c3.SINGLE:
              c3_value = c3.SINGLE;
              break;
            case c3.NOTAB:
              c3_value = c3.NOTAB;
              break;
            case c3.MANYTAB:
              c3_value = c3.MANYTAB;
              break;
          }
          str = str.replace(regc3, c3_value);
          break;

        case "c4":
          if (mode.value == c4.TOP) {
            c4_value = c4.TOP;
          } else {
            c4_value = c4.BOTTOM;
          }
          str = str.replace(regc4, c4_value);
          break;

        case "c5":
          if (mode.value == c5.WIDE) {
            c5_value = c5.WIDE;
          } else {
            c5_value = c5.STANDARD;
          }
          str = str.replace(regc5, c5_value);
          break;

        case "c6":
          if (mode.value == c6.TOP) {
            c6_value = c6.TOP;
          } else {
            c6_value = c6.BOTTOM;
          }
          str = str.replace(regc6, c6_value);
          break;

        case "c7":
          if (mode.value == c7.LARGE) {
            c7_value = c7.LARGE;
          } else {
            c7_value = c7.STANDARD;
          }
          str = str.replace(regc7, c7_value);
          break;

        case "c8":
          if (mode.value == c8.NOSIDE) {
            c8_value = c8.NOSIDE;
          } else {
            c8_value = c8.SIDE;
          }
          str = str.replace(regc8, c8_value);
          break;

        case "c9":
          if (mode.value == c9.NOSIDE) {
            c9_value = c9.NOSIDE;
          } else {
            c9_value = c9.SIDE;
          }
          str = str.replace(regc9, c9_value);
          break;

        case "c10":
          if (mode.value == c10.STANDARD) {
            c10_value = c10.STANDARD;
          } else {
            c10_value = c10.WIDE;
          }
          str = str.replace(regc10, c10_value);
          break;

        case "c11":
          if (mode.value == c11.M3) {
            c11_value = c11.M3;
          } else {
            c11_value = c11.M2;
          }
          str = str.replace(regc11, c11_value);
          break;

        case "c12":
          switch (mode.value) {
            case c12.ALL:
              c12_value = c12.ALL;
              break;
            case c12.HOME:
              c12_value = c12.HOME;
              break;
            case c12.SIDE:
              c12_value = c12.SIDE;
              break;
          }
          str = str.replace(regc12, c12_value);
          break;

        case "c13":
          if (mode.value == c13.M3) {
            c13_value = c13.M3;
          } else {
            c13_value = c13.M2;
          }
          str = str.replace(regc13, c13_value);
          break;

        case "c14":
          switch (mode.value) {
            case c14.PRIMARY:
              c14_value = c14.PRIMARY;
              break;
            case c14.SECONDARY:
              c14_value = c14.SECONDARY;
              break;
            case c14.TERTIARY:
              c14_value = c14.TERTIARY;
              break;
          }
          str = str.replace(regc14, c14_value);
          break;

        case "c15":
          switch (mode.value) {
            case c15.LOWEST:
              c15_value = c15.LOWEST;
              break;
            case c15.LOW:
              c15_value = c15.LOW;
              break;
            case c15.HIGH:
              c15_value = c15.HIGH;
              break;
          }
          str = str.replace(regc15, c15_value);
          break;

        case "c16":
          if (mode.value == c16.LIGHT) {
            c16_value = c16.LIGHT;
          } else {
            c16_value = c16.DARK;
          }
          str = str.replace(regc16, c16_value);
          break;

        case "c17":
            switch (mode.value) {
              case c17.PRIMARY:
                c17_value = c17.PRIMARY;
                break;
              case c17.SECONDARY:
                c1_value = c17.SECONDARY;
                break;
              case c14.TERTIARY:
                c17_value = c17.TERTIARY;
                break;
            }
           str = str.replace(regc17, c17_value);
        break;

        case "c18":
          if (mode.value == c18.SIDEVIEW) {
              c18_value = c18.SIDEVIEW;
            } else {
              c18_value = c18.TABVIEW;
            }
            str = str.replace(regc18, c18_value);
        break;
      }
    });

    localStorage.setItem("mode", str);

    if (tab) {
      this.mobileTheme(tab);
      let data = { ...{ tab: tab } };
      this._updateBuilderComponentContainer.next(data);
    }
  }
  mobileTheme(tab?: any ) {

    let mode = localStorage.getItem("mode");
    let data: any = {};

    if (mode && mode.length != 18) {
      localStorage.setItem("mode", "ahmtwblttw3a3p0lps");
      mode = "ahmtwblttw3a3p0lps";
    }
    switch (mode[0]) {
      case c1.ANDROID:
        {
          switch (mode[1]) {
            case c2.FULL:
              {
                //
                data["emptyImage"] = this.afull;
                data["view"] = MobileView.a_full;
                data["roundBottom"] = true;
                data["sidemode"] = false;
                if (tab) {
                  data["image"] =this.switchImages(tab.module,'android_url_f')
                }
              }
              break;
            case c2.HOME:
            case c2.SIDE:
              //
              {
                if (mode[7] == c8.NOSIDE) {
                  data["hideside"] = true;
                } else {
                  data["hideside"] = false;
                }
                data["sidemode"] = mode[1] == c2.SIDE ? true : false;
                data["emptyImageNavDrawerBar"] = this.aSide;
                data["tabview"] = mode[17] ==c18.TABVIEW? true: false

                switch (mode[2]) {
                  case c3.SINGLE:
                    // single tab
                    {
                      data["emptyImage"] = this.aLong;
                      data["emptyImageTop"] = this.atopBar;
                      data["view"] = MobileView.a_main_tab;
                      data["roundBottom"] = true;
                      if (tab) {
                        data["image"] =this.switchImages(tab.module,'android_url_l');
                      }
                    }
                    break;

                  case c3.NOTAB:
                    // no tab
                    {
                      data["emptyImage"] = this.aLong;
                      data["emptyImageTop"] = this.atopBar;
                      data["view"] = MobileView.a_main_notab;
                      data["roundBottom"] = true;
                      if (tab) {
                        data["image"] =this.switchImages(tab.module,'android_url_l');
                      }
                    }
                    break;

                  case c3.MANYTAB:
                    // many tabs
                    switch (mode[3]) {
                      case c4.TOP:
                        // Top wide
                        if (mode[4] == c5.WIDE) {
                          data["emptyImage"] = this.aShortS;
                          data["emptyImageTop"] = this.atopBar;
                          data["emptyImageNavBar"] = this.anavBarL;
                          data["view"] = MobileView.a_main_many_top_wide;
                          data["roundBottom"] = false;
                          if (tab) {
                            data["image"] =this.switchImages(tab.module,'android_url_s');
                          }
                        } else {
                          // Top Standard
                          data["emptyImage"] = this.aShort;
                          data["emptyImageTop"] = this.atopBar;
                          data["emptyImageNavBar"] = this.anavBarS;
                          data["view"] = MobileView.a_main_many_top_std;
                          data["roundBottom"] = true;
                          if (tab) {
                            data["image"] =this.switchImages(tab.module,'android_url_s');
                          }
                        }

                        break;
                      case c4.BOTTOM:
                        // Bottom-wide
                        if (mode[4] == c5.WIDE) {
                          data["emptyImage"] = this.aShortS;
                          data["emptyImageTop"] = this.atopBar;
                          data["emptyImageNavBar"] = this.anavBarL;
                          data["view"] = MobileView.a_main_many_bottom_wide;
                          data["roundBottom"] = false;
                          if (tab) {
                            data["image"] =this.switchImages(tab.module,'android_url_s');
                          }
                        } else {
                          //Bottom Standard
                          data["emptyImage"] = this.aShort;
                          data["emptyImageTop"] = this.atopBar;
                          data["emptyImageNavBar"] = this.anavBarS;
                          data["view"] = MobileView.a_main_many_bottom_std;
                          data["roundBottom"] = false;
                          if (tab) {
                            data["image"] =this.switchImages(tab.module,'android_url_s');
                          }
                        }
                        break;
                    }
                    break;
                }
              }

              break;
            case c2.COMPONENT:
              // Component
              {
                data["emptyImage"] = this.aLong;
                data["emptyImageTop"] = this.atopBar;
                data["view"] = MobileView.a_component;
                data["backNavComponent"] = "ture";
                data["roundBottom"] = true;
                data["sidemode"] = false;
                if (tab) {
                  data["image"] =this.switchImages(tab.module,'android_url_l');
                }
              }
              break;
          }
        }
        break;
      case c1.IOS:
        {
          switch (mode[1]) {
            case c2.FULL:
              //
              data["emptyImage"] = this.ifull;
              data["view"] = MobileView.i_full;
              data["roundBottom"] = true;
              data["sidemode"] = false;
              if (tab) {
                data["image"] = this.switchImages(tab.module,'ios_url_f');
              }
              break;
            case c2.HOME:
            case c2.SIDE:
              if (mode[7] == c9.NOSIDE) {
                data["hideside"] = true;
              } else {
                data["hideside"] = false;
              }
              data["view"] = MobileView.i_side;
              data["sidemode"] = mode[1] == c2.SIDE ? true : false;
              data["emptyImageNavDrawerBar"] = this.iSide;

              switch (mode[2]) {
                case c3.SINGLE:
                  // single tab
                  {
                    if (mode[6] == c7.STANDARD) {
                      data["emptyImage"] = this.iLong;
                      data["emptyImageTop"] = this.itopBar;
                      data["emptyImageTitle"] = null;
                      data["emptyImageNavBar"] = null;
                      data["view"] = MobileView.i_main_tab;
                      data["backNavComponent"] = "ture";
                      data["roundBottom"] = true;
                      data["emptyImageNavDrawer"] = this.iLong;
                      if (tab) {
                        data["image"] =this.switchImages(tab.module,'ios_url_l');
                      }
                    } else {
                      data["emptyImage"] = this.iLongS;
                      data["emptyImageTop"] = this.itopBar;
                      data["emptyImageTitle"] = this.itopbarTitle;
                      data["emptyImageNavBar"] = null;
                      data["view"] = MobileView.i_main_tab;
                      data["backNavComponent"] = "ture";
                      data["roundBottom"] = true;
                      data["emptyImageNavDrawer"] = this.iLongS;
                      if (tab) {
                        data["image"] =this.switchImages(tab.module,'ios_url_l');
                      }
                    }
                  }
                  break;
                case c3.NOTAB:
                  // no tab
                  {
                    data["emptyImage"] = this.iLong;
                    data["emptyImageTop"] = this.itopBar;
                    data["emptyImageTitle"] = null;
                    data["view"] = MobileView.i_main_notab;
                    data["backNavComponent"] = "ture";
                    data["roundBottom"] = true;
                    data["emptyImageNavDrawer"] = this.iLong;
                    if (tab) {
                      data["image"] =this.switchImages(tab.module,'ios_url_l');
                    }
                  }
                  break;
                case c3.MANYTAB:
                  // many tabs
                  switch (mode[5]) {
                    // c6: ios home side menu- top, bottom

                    case c6.BOTTOM:
                      if (mode[6] == c7.STANDARD) {
                        data["emptyImage"] = this.iShort;
                        data["emptyImageTop"] = this.itopBar;
                        data["emptyImageTitle"] = null;
                        data["emptyImageNavBar"] = this.inavBar;
                        data["view"] = MobileView.i_main_bottom_small;
                        data["roundBottom"] = false;
                        data["emptyImageNavDrawer"] = this.iShort;
                        if (tab) {
                          data["image"] =this.switchImages(tab.module,'ios_url_s');
                        }
                      } else {
                        data["emptyImage"] = this.iShortS;
                        data["emptyImageTop"] = this.itopBar;
                        data["emptyImageTitle"] = this.itopbarTitle;
                        data["emptyImageNavBar"] = this.inavBar;
                        data["view"] = MobileView.i_main_bottom_large;
                        data["roundBottom"] = false;
                        data["emptyImageNavDrawer"] = this.iShortS;
                        if (tab) {
                          data["image"] =this.switchImages(tab.module,'ios_url_s');
                        }
                      }

                      break;

                    case c6.TOP:
                      if (mode[6] == c7.STANDARD) {
                        data["emptyImage"] = this.iShort;
                        data["emptyImageTop"] = this.itopBar;
                        data["emptyImageTitle"] = null;
                        data["emptyImageNavBar"] = this.inavBar;
                        data["view"] = MobileView.i_main_top_small;
                        data["backNavComponent"] = "ture";
                        data["roundBottom"] = false;
                        data["emptyImageNavDrawer"] = this.iShort;
                        if (tab) {
                          data["image"] =this.switchImages(tab.module,'ios_url_s');
                        }
                      } else {
                        data["emptyImage"] = this.iShortS;
                        data["emptyImageTop"] = this.itopBar;
                        data["emptyImageTitle"] = this.itopbarTitle;
                        data["emptyImageNavBar"] = this.inavBar;
                        data["view"] = MobileView.i_main_top_large;
                        data["backNavComponent"] = "ture";
                        data["roundBottom"] = false;
                        data["emptyImageNavDrawer"] = this.iShortS;
                        if (tab) {
                          data["image"] =this.switchImages(tab.module,'ios_url_s');
                        }
                      }

                      break;
                  }
                  break;
              }

              break;

            case c2.COMPONENT:
              //
              {
                data["emptyImage"] = this.iLong;
                data["emptyImageTop"] = this.itopBar;
                data["emptyImageTitle"] = null;
                data["emptyImageNavBar"] = null;
                data["view"] = MobileView.i_component;
                data["backNavComponent"] = "ture";
                data["roundBottom"] = true;
                data["sidemode"] = false;
                data["emptyImageNavDrawer"] = this.iLong;
                if (tab) {
                  data["image"] = this.switchImages(tab.module,'ios_url_l');
                }
              }
              break;
          }
        }
        break;
    }
    return data;
  }


  tabMobileTheme(tab?: any ) {

    let mode = localStorage.getItem("mode");
    let data: any = {};

    if (mode && mode.length != 18) {
      localStorage.setItem("mode", "ahmtwblttw3a3p0lps");
      mode = "ahmtwblttw3a3p0lps";
    }
    switch (mode[0]) {
      case c1.ANDROID:
        {
          switch (tab.cat) {
            case "splash":
            case "subF":
              {
                //
                data["emptyImage"] = this.afull;
                data["view"] = MobileView.a_full;
                data["roundBottom"] = true;
                data["sidemode"] = false;
                if (tab) {
                  data["image"] =this.switchImages(tab.module,'android_url_f')
                }
              }
              break;
            case "home":
              //
              {
                data["hideside"] = true;
                data["sidemode"] = false;
                data["emptyImageNavDrawerBar"] = this.aSide;
                data["tabview"] = false
                switch (mode[2]) {
                  case c3.SINGLE:
                    // single tab
                    {
                      data["emptyImage"] = this.aLong;
                      data["emptyImageTop"] = this.atopBar;
                      data["view"] = MobileView.a_main_tab;
                      data["roundBottom"] = true;
                      if (tab) {
                        data["image"] =this.switchImages(tab.module,'android_url_l');
                      }
                    }
                    break;
                  case c3.NOTAB:
                    // no tab
                    {
                      data["emptyImage"] = this.aLong;
                      data["emptyImageTop"] = this.atopBar;
                      data["view"] = MobileView.a_main_notab;
                      data["roundBottom"] = true;
                      if (tab) {
                        data["image"] =this.switchImages(tab.module,'android_url_l');
                      }
                    }
                    break;
                  case c3.MANYTAB:
                    // many tabs
                    switch (mode[3]) {
                      case c4.TOP:
                        // Top wide
                        if (mode[4] == c5.WIDE) {
                          data["emptyImage"] = this.aShortS;
                          data["emptyImageTop"] = this.atopBar;
                          data["emptyImageNavBar"] = this.anavBarL;
                          data["view"] = MobileView.a_main_many_top_wide;
                          data["roundBottom"] = false;
                          if (tab) {
                            data["image"] =this.switchImages(tab.module,'android_url_s');
                          }
                        } else {
                          // Top Standard
                          data["emptyImage"] = this.aShort;
                          data["emptyImageTop"] = this.atopBar;
                          data["emptyImageNavBar"] = this.anavBarS;
                          data["view"] = MobileView.a_main_many_top_std;
                          data["roundBottom"] = true;
                          if (tab) {
                            data["image"] =this.switchImages(tab.module,'android_url_s');
                          }
                        }

                        break;
                      case c4.BOTTOM:
                        // Bottom-wide
                        if (mode[4] == c5.WIDE) {
                          data["emptyImage"] = this.aShortS;
                          data["emptyImageTop"] = this.atopBar;
                          data["emptyImageNavBar"] = this.anavBarL;
                          data["view"] = MobileView.a_main_many_bottom_wide;
                          data["roundBottom"] = false;
                          if (tab) {
                            data["image"] =this.switchImages(tab.module,'android_url_s');
                          }
                        } else {
                          //Bottom Standard
                          data["emptyImage"] = this.aShort;
                          data["emptyImageTop"] = this.atopBar;
                          data["emptyImageNavBar"] = this.anavBarS;
                          data["view"] = MobileView.a_main_many_bottom_std;
                          data["roundBottom"] = false;
                          if (tab) {
                            data["image"] =this.switchImages(tab.module,'android_url_s');
                          }
                        }
                        break;
                    }
                    break;
                }
              }

              break;

            case "side":
                     //
                     {
                      data["hideside"] =false;
                      data["sidemode"] = true;
                      data["emptyImageNavDrawerBar"] = this.aSide;
                      data["tabview"] = false
                      switch (mode[2]) {
                        case c3.SINGLE:
                          // single tab
                          {
                            data["emptyImage"] = this.aLong;
                            data["emptyImageTop"] = this.atopBar;
                            data["view"] = MobileView.a_main_tab;
                            data["roundBottom"] = true;
                            if (tab) {
                              data["image"] =this.switchImages(tab.module,'android_url_l');
                            }
                          }
                          break;
                        case c3.NOTAB:
                          // no tab
                          {
                            data["emptyImage"] = this.aLong;
                            data["emptyImageTop"] = this.atopBar;
                            data["view"] = MobileView.a_main_notab;
                            data["roundBottom"] = true;
                            if (tab) {
                              data["image"] =this.switchImages(tab.module,'android_url_l');
                            }
                          }
                          break;
                        case c3.MANYTAB:
                          // many tabs
                          switch (mode[3]) {
                            case c4.TOP:
                              // Top wide
                              if (mode[4] == c5.WIDE) {
                                data["emptyImage"] = this.aShortS;
                                data["emptyImageTop"] = this.atopBar;
                                data["emptyImageNavBar"] = this.anavBarL;
                                data["view"] = MobileView.a_main_many_top_wide;
                                data["roundBottom"] = false;
                                if (tab) {
                                  data["image"] =this.switchImages(tab.module,'android_url_s');
                                }
                              } else {
                                // Top Standard
                                data["emptyImage"] = this.aShort;
                                data["emptyImageTop"] = this.atopBar;
                                data["emptyImageNavBar"] = this.anavBarS;
                                data["view"] = MobileView.a_main_many_top_std;
                                data["roundBottom"] = true;
                                if (tab) {
                                  data["image"] =this.switchImages(tab.module,'android_url_s');
                                }
                              }

                              break;
                            case c4.BOTTOM:
                              // Bottom-wide
                              if (mode[4] == c5.WIDE) {
                                data["emptyImage"] = this.aShortS;
                                data["emptyImageTop"] = this.atopBar;
                                data["emptyImageNavBar"] = this.anavBarL;
                                data["view"] = MobileView.a_main_many_bottom_wide;
                                data["roundBottom"] = false;
                                if (tab) {
                                  data["image"] =this.switchImages(tab.module,'android_url_s');
                                }
                              } else {
                                //Bottom Standard
                                data["emptyImage"] = this.aShort;
                                data["emptyImageTop"] = this.atopBar;
                                data["emptyImageNavBar"] = this.anavBarS;
                                data["view"] = MobileView.a_main_many_bottom_std;
                                data["roundBottom"] = false;
                                if (tab) {
                                  data["image"] =this.switchImages(tab.module,'android_url_s');
                                }
                              }
                              break;
                          }
                          break;
                      }
                    }
              break;
            case "sub":
            case "next":
              // Component
              {
                data["emptyImage"] = this.aLong;
                data["emptyImageTop"] = this.atopBar;
                data["view"] = MobileView.a_component;
                data["backNavComponent"] = "ture";
                data["roundBottom"] = true;
                data["sidemode"] = false;
                if (tab) {
                  data["image"] =this.switchImages(tab.module,'android_url_l');
                }
              }
              break;
          }
        }
        break;
      case c1.IOS:
        {
          switch (tab.cat) {
            case "splash":
              //
              data["emptyImage"] = this.ifull;
              data["view"] = MobileView.i_full;
              data["roundBottom"] = true;
              data["sidemode"] = false;
              if (tab) {
                data["image"] = this.switchImages(tab.module,'ios_url_f');
              }
              break;
            case "home":
              data["hideside"] = false;
              data["view"] = MobileView.i_side;
              data["sidemode"] = true;
              data["emptyImageNavDrawerBar"] = this.iSide;

              switch (mode[2]) {
                case c3.SINGLE:
                  // single tab
                  {
                    if (mode[6] == c7.STANDARD) {
                      data["emptyImage"] = this.iLong;
                      data["emptyImageTop"] = this.itopBar;
                      data["emptyImageTitle"] = null;
                      data["emptyImageNavBar"] = null;
                      data["view"] = MobileView.i_main_tab;
                      data["backNavComponent"] = "ture";
                      data["roundBottom"] = true;
                      data["emptyImageNavDrawer"] = this.iLong;
                      if (tab) {
                        data["image"] =this.switchImages(tab.module,'ios_url_l');
                      }
                    } else {
                      data["emptyImage"] = this.iLongS;
                      data["emptyImageTop"] = this.itopBar;
                      data["emptyImageTitle"] = this.itopbarTitle;
                      data["emptyImageNavBar"] = null;
                      data["view"] = MobileView.i_main_tab;
                      data["backNavComponent"] = "ture";
                      data["roundBottom"] = true;
                      data["emptyImageNavDrawer"] = this.iLongS;
                      if (tab) {
                        data["image"] =this.switchImages(tab.module,'ios_url_l');
                      }
                    }
                  }
                  break;
                case c3.NOTAB:
                  // no tab
                  {
                    data["emptyImage"] = this.iLong;
                    data["emptyImageTop"] = this.itopBar;
                    data["emptyImageTitle"] = null;
                    data["view"] = MobileView.i_main_notab;
                    data["backNavComponent"] = "ture";
                    data["roundBottom"] = true;
                    data["emptyImageNavDrawer"] = this.iLong;
                    if (tab) {
                      data["image"] =this.switchImages(tab.module,'ios_url_l');
                    }
                  }
                  break;
                case c3.MANYTAB:
                  // many tabs
                  switch (mode[5]) {
                    // c6: ios home side menu- top, bottom

                    case c6.BOTTOM:
                      if (mode[6] == c7.STANDARD) {
                        data["emptyImage"] = this.iShort;
                        data["emptyImageTop"] = this.itopBar;
                        data["emptyImageTitle"] = null;
                        data["emptyImageNavBar"] = this.inavBar;
                        data["view"] = MobileView.i_main_bottom_small;
                        data["roundBottom"] = false;
                        data["emptyImageNavDrawer"] = this.iShort;
                        if (tab) {
                          data["image"] =this.switchImages(tab.module,'ios_url_s');
                        }
                      } else {
                        data["emptyImage"] = this.iShortS;
                        data["emptyImageTop"] = this.itopBar;
                        data["emptyImageTitle"] = this.itopbarTitle;
                        data["emptyImageNavBar"] = this.inavBar;
                        data["view"] = MobileView.i_main_bottom_large;
                        data["roundBottom"] = false;
                        data["emptyImageNavDrawer"] = this.iShortS;
                        if (tab) {
                          data["image"] =this.switchImages(tab.module,'ios_url_s');
                        }
                      }

                      break;

                    case c6.TOP:
                      if (mode[6] == c7.STANDARD) {
                        data["emptyImage"] = this.iShort;
                        data["emptyImageTop"] = this.itopBar;
                        data["emptyImageTitle"] = null;
                        data["emptyImageNavBar"] = this.inavBar;
                        data["view"] = MobileView.i_main_top_small;
                        data["backNavComponent"] = "ture";
                        data["roundBottom"] = false;
                        data["emptyImageNavDrawer"] = this.iShort;
                        if (tab) {
                          data["image"] =this.switchImages(tab.module,'ios_url_s');
                        }
                      } else {
                        data["emptyImage"] = this.iShortS;
                        data["emptyImageTop"] = this.itopBar;
                        data["emptyImageTitle"] = this.itopbarTitle;
                        data["emptyImageNavBar"] = this.inavBar;
                        data["view"] = MobileView.i_main_top_large;
                        data["backNavComponent"] = "ture";
                        data["roundBottom"] = false;
                        data["emptyImageNavDrawer"] = this.iShortS;
                        if (tab) {
                          data["image"] =this.switchImages(tab.module,'ios_url_s');
                        }
                      }

                      break;
                  }
                  break;
              }

              break;

            case "sub":
            case "next":
              //
              {
                data["emptyImage"] = this.iLong;
                data["emptyImageTop"] = this.itopBar;
                data["emptyImageTitle"] = null;
                data["emptyImageNavBar"] = null;
                data["view"] = MobileView.i_component;
                data["backNavComponent"] = "ture";
                data["roundBottom"] = true;
                data["sidemode"] = false;
                data["emptyImageNavDrawer"] = this.iLong;
                if (tab) {
                  data["image"] = this.switchImages(tab.module,'ios_url_l');
                }
              }
              break;
          }
        }
        break;
    }
    return data;
  }
  switchImages(module, image_url){
    let isLight= localStorage.getItem("mode")[15]==c16.LIGHT? true:false
    return isLight? common_components_map[module].web[image_url] :common_components_map[module].web['dark_' + image_url]

  }
  async saveOnlineAppConfig(data, migration) {
    let dbData: any = {};
    dbData = data;
    if (data && data.app) {
      if (data.app.tabs && data.app.tabs.tabs) {
        this.tabsInsertion(dbData.app.tabs, "tabs", "side");
      }
      // add
      if (!dbData.id) {
        dbData.id = "side";
      }
      // clean up
      if (dbData && dbData.app && dbData.app.side_menu) {
        if (migration) {
          this.tabsInsertion(dbData.app.side_menu, "menus", "side");
        }
        delete dbData.app.side_menu;
      }

      if (dbData && dbData.app && dbData.app.tabs && dbData.app.tabs.tabs) {
        delete dbData.app.tabs.tabs;
      }

      if (dbData && dbData.app && dbData.app.app_info) {
        delete dbData.app.app_info;
      }

      if (dbData && dbData.app && dbData.app.tab) {
        dbData.app["tabs"] = { tabs: [] };
      }

      await this.indexDBService.insertItem("app", dbData);
    } else {
      // no data
      let newData: any;
      newData = { id: "side", app: { menus: [], tabs: { tabs: [] } } };
      await this.indexDBService.insertItem("app", newData);
    }
  }
  async saveAppConfig(data, colors?: any) {
    if (data && data.app) {
      await this.saveItem(data, "settings");
      await this.saveItem(data, "forms");
      await this.saveItem(data, "channel_default");
      await this.saveItem(data, "version");

      if (!data.app.system.chat_label) {
        data.app.system["chat_label"] = {
          labels: [{ id: "0", name: "Default" }],
        };
      }
      await this.saveSystemItem(data, "chat_label");

      if (!data.app.login_type) {
        data.app["login_type"] = "NO_LOGIN";
      }

    if (data && data.app && data.app.system && data.app.system.app_info){
      if (!data.app.system.app_info.logo_color){
        data.app.system.app_info = {... data.app.system.app_info, ...defaultLogoColor}
      }
     }

     await this.saveSystemAppInfoItem(data, "app_info");

     await this.saveAppInfoItem(data, "logo_color");
      // await this.saveAppInfoItem(data, "logo_color_ios");
      await this.saveAppInfoItem(data, "logo_white");

      await this.saveItem(data, "splash");
    } else {
      let newData: any;
      newData = {
        app: {
          system: {
            app_info: {
              id: "app_info",
              channel_name: "newApp",
              logo_color: { id: "logo_color" },
              logo_white: { id: "logo_white" },
              login_type: "NO_LOGIN",
              company_name:'newApp',
              company_official_name:'newApp',
              company_url:'https://newApp.com',
              deep_link_schema:'comnewapp'
            },
            chat_label: {
              id: "chat_label",
              labels: [{ id: "0", name: "Default" }],
            },
          },
          version: { value: 0, id: "version" },
        },
      };

      await this.saveSystemAppInfoItem(newData, "app_info");
      await this.saveAppInfoItem(newData, "logo_color");
      await this.saveAppInfoItem(newData, "logo_white");

      await this.saveSystemItem(newData, "chat_label");
      await this.saveItem(newData, "version");

    }
    if (
      data &&
      data.app &&
      data.app.splash &&
      data.app.splash.id &&
      data.app.splash.tab_id
    ) {
      //new
      let screens = data.app.splash.screens;
      let tab: any = { param: { splash: {} } };
      tab["param"]["splash"] = data.app.splash;
      tab.id = data.app.splash.tab_id;
      tab.cat = "splash";
      tab.module = "splash";
      tab.type = "splash";
      tab.order = 0;
      tab.tab_version = data.app.splash.version;
      tab.menu_id = data.app.splash.menu_id;
      tab.web = common_components_map['splash'].web;
      delete tab.version;
      delete tab.screens;
      await this.indexDBService.insertItem("tab", tab);
      ///////////  add the screens ///////////////////////
      screens.forEach((screen) => {
        if (screen) {
          let menu: any = { cat: "splash" };
          menu.cat = "splash";
          menu.menu_id = screen.menu_id;
          menu.menu_ref = screen.menu_id;
          menu.menu_version = screen.menu_id;
          menu.menu_group = tab.id;
          menu.menu_order = screen.order;

          let row: any = { row_id: "0" };
          row.row_id = menu.menu_id;
          row.menu_id = menu.menu_id;
          row.row_version = menu.menu_id;
          row.row_order = 0;
          let button: any = {
            button_id: "",
            info: {},
            button_images: {
              "01": { url: null, imageSet: [] },
              default: { url: null, imageSet: [] },
            },
          };

          // button = structuredClone(menu_button_map[data.app.splash.style]);
          button.button_id = screen.id;
          button.button_callback = screen.id;
          button.button_version = screen.version;
          button.button_code = data.app.splash.style;
          button.button_form = "splash";
          button.button_type = "splash";
          button.button_style = data.app.splash.style;
          button.button_order = 0;
          button.button_label = screen.title;
          button.button_sublabel = screen.desc;
          button.button_img_url = screen.image;
          button.button_img_urlSet = screen.image_set;
          if (data.app.splash.style === "01") {
            button.button_images["01"].url = screen.image;
            button.button_images["01"].imageSet = screen.image_set;
            button.button_images["01"].url_dark = screen.image_dark;
            button.button_images["01"].imageSetDark = screen.image_set_dark;
          } else {
            button.button_images["default"].url = screen.image;
            button.button_images["default"].imageSet = screen.image_set;
            button.button_images["default"].url_dark = screen.image_dark;
            button.button_images["default"].imageSetDark = screen.image_set_dark;
          }
          button.button_bgimage = screen.bg_image;
          button.button_bgimageSet = screen.bg_image_set;
          button.button_bgimage_dark = screen.bg_image_dark;
          button.button_bgimageSet_dark = screen.bg_image_set_dark;


          if( colors && (!screen.color_schema || !screen.color_schema_ios) && screen.start_color && screen.end_color){
            let customColorStart = this.customColor(screen.start_color, colors.color_schema.source);
            // let customColorEnd = this.customColor(screen.end_color, colors.color_schema.source);
            const color_schema = {
              schemes: {
                light: {
                  bgstart: customColorStart.light,
                  bgend: customColorStart.light
                },
                dark: {
                  bgstart: customColorStart.dark,
                  bgend: customColorStart.dark
                },
              }
            };
            let customColorStartIOS = this.customColor(screen.start_color, colors.color_schema_ios.source);
            // let customColorEndIOS = this.customColor(screen.end_color, colors.color_schema_ios.source);
            const color_schema_ios = {
              schemes: {
                light: {
                  bgstart: customColorStartIOS.light,
                  bgend: customColorStartIOS.light
                },
                dark: {
                  bgstart: customColorStartIOS.dark,
                  bgend: customColorStartIOS.dark
                },
              }
            };
            button.button_color_schema = color_schema;
            button.button_color_schema_ios = color_schema_ios;
          }else{
            button.button_color_schema = screen.color_schema;
            button.button_color_schema_ios = screen.color_schema_ios;
          }

          button.row_id = row.row_id;

          let button_code = "splash_" + button.button_code;
          button.info = menu_button_map[button_code].info;


          this.indexDBService.insertItem("menu", menu);
          this.indexDBService.insertItem("row", row);
          this.indexDBService.insertItem("button", button);
        }
      });
    } else {
      //old do nothing.
      if (data && data.app && data.app.system && data.app.system.app_info && data.app.system.app_info.app_slogan){
        let app_slogan = data.app.system.app_info.app_slogan
      this.createNewSplash(app_slogan); //temp commeted out
      }else {
      this.createNewSplash(null); //temp commeted out
      }
    }
  }

  async saveItem(data, item) {
    let dbData: any = {};
    if (data.app[item]) {
      dbData = data.app[item];
      if (!dbData.id) {
        dbData.id = item;
      }
      await this.indexDBService.insertItem("item", dbData);
    }
  }

  async saveSystemItem(data, item) {
    let dbData: any = {};
    if (data.app.system && data.app.system[item]) {
      dbData = data.app.system[item];
      if (!dbData.id) {
        dbData.id = item;
      }
      await this.indexDBService.insertItem("item", dbData);
    }
  }

  async saveSystemAppInfoItem(data, item) {
    let dbData: any = {};
    if (data.app.system && data.app.system[item]) {
      dbData = data.app.system[item];
      if (!dbData.id) {
        dbData.id = item;
      }
      if (!dbData.logo_color) {
        dbData.logo_color = {};
      }
      if (!dbData.logo_white) {
        dbData.logo_white = {};
      }

      await this.indexDBService.insertItem("item", dbData);
    }
  }

  async saveAppInfoItem(data, item) {
    let dbData: any = {};
    if (
      data.app.system &&
      data.app.system.app_info &&
      data.app.system.app_info[item]
    ) {
      dbData = data.app.system.app_info[item];
      if (!dbData.id) {
        dbData.id = item;
      }
      await this.indexDBService.insertItem("item", dbData);
    }
  }

  async createNewSplash(slogan: string) {
    let defaultRow: MenuRow = {
      row_id: "0",
      row_order: 0,
      menu_id: "0",
      buttons: [],
    };


    let defaultButton: MenuButton = {
      button_id: "0",
      row_id: "0",
      button_callback: "0",
      button_style: "0",
      button_style_ios: "0",
      button_form: "splash",
      button_code: "splash",
      button_order: 0,
      info: { min_grid: 60 },
    };

   /// create new tab
    // let id = this.mdw.makeRef(15);
    let id = "splash"; // same id to prevent duplicate splash tab. Only one tab for splash should exist.
    let style = "05";
    let tabList = {};
    let current_tab: { cat: null; module: null };
    let sortedTabList = [];
    let menuList = {};
    let sortedMenuList = [];
    let menu: Menu;
    let row: MenuRow;
    let button: MenuButton;
    let max_buttons: number = 6;


    tabList[id] = structuredClone(common_components_map["splash"]);
    tabList[id].id = id;
    // this.tabList[id].param.splash= structuredClone(splash_config_map[style].tab_part);
    tabList[id].param.splash.style = style;
    sortedTabList.push(tabList[id]);
    current_tab = tabList[id];

    /// create a new menu
    if (current_tab.cat == "splash") {
      // let menu_id = "m_" + this.makeRef(15);
      let menu_id = "m_6XIox0e0fWF2oFy";

      tabList[id]["menu_id"] = menu_id;

      let newMenu: Menu = {
        menu_id: menu_id,
        menu_ref: menu_id,
        menu_version: menu_id,
        cat: "splash",
        menu_group: id,
        menu_order: 0,
      };
      menu = newMenu;
      /// create new row and button
      // let row_id = "r_" + this.makeRef(15);
      // let button_id = "b_" + this.makeRef(15);

      let row_id = "r_6XIox0e0fWF2oFy";
      let button_id = "b_6XIox0e0fWF2oFy";

      // add row
      let newRow = { ...structuredClone(defaultRow) };
      newRow.row_id = row_id;
      newRow.row_version = row_id;
      newRow.row_order = 0;
      newRow.menu_id = menu_id;
      newRow.row_lock = true;

      row = newRow;

      let button_style = "splash_" + style;
      // add button
      let newButton: any = {};
      newButton = structuredClone(menu_button_map[button_style]);

      // get it per template when switching //
      newButton.button_img_url = null;
      newButton.button_img_urlSet = null;
      newButton.button_images = { "01": { url: null }, default: { url: null } };
      newButton.button_bgimage = null;
      newButton.button_bgimageSet = null;
      newButton.button_bgstart = null;
      newButton.button_bgend = null;
      newButton.button_label = slogan?slogan: null;
      /////////////////////////////////////

      newButton.button_id = button_id;
      newButton.button_version = button_id;
      newButton.row_id = row_id;
      newButton.button_callback = button_id;
      newButton.button_order = 0;

      button = newButton;

      /// update tab
      let tabdata = {
        tab: current_tab,
        mode: localStorage.getItem("mode"),
      };
      this.changeTab(tabdata, localStorage.getItem("mode"), false);

      let Menudata = {
        tab: { module: "splash" },
        menu: newMenu,
        row: newRow,
        button: newButton,
      };

      this.changeTab(Menudata, localStorage.getItem("mode"), true);

      /////////////

      sortedMenuList.push(menu);
      menuList[menu.menu_id] = menu;

      let menuData: any = {
        rowList: {},
        sortedRowList: [],
        buttonList: {},
        sortedButtonList: [],
      };

      menuData["rowList"][row_id] = { ...newRow };
      menuData["sortedRowList"] = [
        ...menuData["sortedRowList"],
        menuData["rowList"][row_id],
      ];
      menuData["buttonList"][button_id] = { ...newButton };
      menuData["sortedButtonList"][row_id] = [];
      menuData["sortedButtonList"][row_id] = [
        ...menuData["sortedButtonList"][row_id],
        newButton,
      ];
      menuData["currentButton"] = { ...newButton };

      menuData["tab"] = current_tab;
      menuData["defaultRow"] = defaultRow;
      menuData["defaultButton"] = defaultButton;
      menuData["max_buttons"] = max_buttons;
      menuData["menu"] = menu;
      menuData["module"] = current_tab.module;
      menuData["mode"] = localStorage.getItem("mode");

      menuData["menuList"] = menuList;
      menuData["sortedMenuList"] = sortedMenuList;

      this._currentMenuContainer.next(menuData); // to update menupages
    }

  }
  async sendAppConfig(wireframe:boolean=false, checkOnly: boolean = false) {
// check for error
    if (!wireframe){
      await this.saveMobileViewMode();
    }
    let data: any = {};
    let onlineAppConfig = await this.constructOnlineAppConfig();
    let onlineChannelConfig = await this.constructOnlineChannelConfig(onlineAppConfig.app.tabs.tabs,!checkOnly);
    let sideAndNextTabs = onlineChannelConfig.app.tabs.sideNext
       //extract side and next Tabs if found in any button next and add them to HOME
    let allTabs = [...onlineChannelConfig.app.tabs.tabs,...sideAndNextTabs];

    let matchingTabs: any[] = [];
    matchingTabs = await this.extractMatchingNextTabinSide(sideAndNextTabs,onlineChannelConfig.app.menus, wireframe)

    if (matchingTabs&& matchingTabs.length>0 && !wireframe){
      onlineChannelConfig.app.tabs.tabs.push(...matchingTabs)
  }else if (wireframe){
    data.nextTabs = matchingTabs;
  }
    onlineChannelConfig.app.tabs.tabs= this.sortTabs(onlineChannelConfig.app.tabs.tabs);

    if (!wireframe){
      let appConfig = await this.constructAppConfig();
      // copy in the side configuration as temp solution Hazem
        onlineChannelConfig.app.tabs['default']['channel'] = appConfig.app.channel_default?appConfig.app.channel_default:{};
        delete appConfig.app.channel_default
        data.appConfig = appConfig;
        data.appConfig = this.checkForSomeFlags(allTabs,appConfig)

        if (appConfig && appConfig.app && appConfig.app.version) {
          this.indexDBService.updateItem("item", appConfig.app["version"]);
        }

      }
    if (!checkOnly)
      {
       // remove web key in all tabs
    let allTabs=[]
      if (onlineAppConfig && onlineAppConfig.app && onlineAppConfig.app.tabs && onlineAppConfig.app.tabs.tabs && onlineAppConfig.app.tabs.tabs.length > 0 ){
      let tempList = onlineAppConfig.app.tabs.tabs;
          tempList.forEach(tab => {  delete tab['web'];     });
          onlineAppConfig.app.tabs.tabs = [...tempList];
          allTabs= [...tempList];
        }

      if (onlineChannelConfig && onlineChannelConfig.app && onlineChannelConfig.app.tabs && onlineChannelConfig.app.tabs.tabs && onlineChannelConfig.app.tabs.tabs.length > 0){
      let tempList1 = onlineChannelConfig.app.tabs.tabs;
          tempList1.forEach(tab => {  delete tab['web'];     });
          onlineChannelConfig.app.tabs.tabs = [...tempList1];
          allTabs= [...allTabs,...tempList1];
        }

        // console.log("allTabs....", allTabs);
     // scan store and market found in the configuration
     if (allTabs && allTabs.length > 0){
        const filteredTabs = allTabs.filter(tab => tab.module === 'store' || tab.module === 'market');
        filteredTabs.forEach(tab => this.saveStorePollConfig(tab));

       }

    }
    if (onlineChannelConfig.app.tabs.sideNext){
      delete onlineChannelConfig.sideNext;
    }

    data.onlineAppConfig = onlineAppConfig;
    data.onlineChannelConfig = onlineChannelConfig;


    // convert and convert back and see what we are getting
    // console.log("original Data", data);
    // let aiData : any;
    // aiData = this.appToAiMapper(data)
    // console.log("ai Data", aiData);
    // let newData : any
    // newData = await this.aiToAppMapper(aiData)
    // console.log("new Data", newData.newData);


    return data;
  }
  async validateApp(data, target){
 let result = [];
 let tempResult=[];
 if (data){

  // console.log("data....", data);

        // check list
     if(data.appConfig){
          result = await this.validateJSON(data.appConfig.app.system.app_info,app_check_list[AppItems.APP_INFO], target)
          result =[...result,...await this.validateJSON(data.appConfig.app.system,app_check_list[AppItems.COMPANY], target)];

        }
      let allTabs = [...  data.onlineChannelConfig.app.tabs.tabs, ...  data.onlineAppConfig.app.tabs.tabs]

       if (allTabs){
          result = [...result,...await this.validateTabs(allTabs,target,data)];
        }
        if (data.onlineChannelConfig.app.menus){
          let menus = data.onlineChannelConfig.app.menus;
          result = [...result,...await this.validateMenus(menus,target,data)];
        }
 }
  return result;
}

  checkForSomeFlags(allTabs, appConfig?){
    this.indexDBService.deleteItemBykey("item", 'web')

    let webData = {id:'web', feed:[]}
    let feeds =[]


    allTabs.forEach(tab => {
      //check for Feed
      if (tab.module== TabNames.FEED){
        let feed={tab_id:tab.id, chat_label:{id:tab.chat_label},tab_title: tab.title }
        feeds.push(feed)
      }
    })

    webData = {id:'web', feed:feeds}


    if (appConfig && appConfig.app  && appConfig.app.system && appConfig.app.system.chat_label){

    let chat_labels =Array.isArray(appConfig.app.system.chat_label.labels)? appConfig.app.system.chat_label.labels: [];

    if (feeds && feeds.length) {
      for (let feed of feeds) {
      const exists = chat_labels.some(chat_label=>chat_label.id ===feed.chat_label.id );
      if (!exists) {
        let label = {
          id: feed.chat_label.id,
          name: feed.tab_title,
        }
        chat_labels.push(label);
      }

    }
    appConfig.app.system.chat_label.labels = chat_labels;
    const app = this.indexDBService.insertItem("item", appConfig.app.system.chat_label);
    app.onerror = (event) => {
      this.indexDBService.updateItem("item", appConfig.app.system.chat_label);
    };
    }

  }
    this.indexDBService.insertItem("item", webData)
    return appConfig;
  }


  async extractMatchingNextTabinSide(tabs, menus, wireframe?: boolean){
   let matchingTabs: any[] = [];
      matchingTabs = [];
 if (tabs.length>0 && menus.length>0 ) {
    // Create a new array to store the matching tabs
   const tabIds = new Set(tabs.map(tab => tab.id));
   // Iterate through the menus to find button.next with type "tab" and matching IDs in tabs array
   menus.forEach(menu => {
    if (menu.rows && menu.rows.length  >0 ){
      menu.rows.forEach(row => {
        if (row.buttons && row.buttons.length  >0 ){
          row.buttons.forEach(button => {
            if (button.next && button.next.length >0){
              button.next.forEach(nextItem => {
                if (nextItem && nextItem.type === 'tab' && tabIds.has(nextItem.id)) {
                  const matchingTab = tabs.find(tab => tab.id === nextItem.id);
                 if (matchingTab) {
                  if (wireframe){
                    matchingTab['fromMenu']=menu.menu_id;
                    matchingTab['fromTab']=menu.menu_group;
                    matchingTab['fromButton']=button.button_id;
                  }
                  matchingTabs.push(matchingTab);
                }
              }
            });
            }
          });
        }
      });
    }

   });
   return matchingTabs
 }

}
  async saveMobileViewMode() {
    // c1: android and ios
    // c2: full, home, side, component
    // c3: single, notab, manytab
    // c4: android home- top, bottom
    // c5: android home- wide and narraw
    // c6: ios home - side menu top, bottom
    // c7: ios home - standard and large title
    // c8: android no side(false) and side (true)
    // c9: ios no side(false) and side (true)
    // c10: ios home- wide and narraw

    localStorage.getItem("mode");
    await this.appHomeModeUpdate("mode", localStorage.getItem("mode"));
  }
  async appHomeModeUpdate(item, value) {
    return await this.indexDBService
      .getItemOnsucss("app", "home")
      .then(async (appHome) => {
        if (appHome) {
          let appHomeData: any = {};
          appHomeData = appHome;
          appHomeData.app.tabs[item] = value;
          if (value[3] == c4.TOP && value[4] == c5.WIDE) {
            appHomeData.app.tabs["tab_style"] = 0;
          }
          if (value[3] == c4.BOTTOM && value[4] == c5.WIDE) {
            appHomeData.app.tabs["tab_style"] = 1;
          }
          if (value[3] == c4.TOP && value[4] == c5.STANDARD) {
            appHomeData.app.tabs["tab_style"] = 2;
          }
          if (value[3] == c4.BOTTOM && value[4] == c5.STANDARD) {
            appHomeData.app.tabs["tab_style"] = 3;
          }
          if (value[9] == c10.WIDE) {
            appHomeData.app.tabs["tab_style_ios"] = 0;
          }
          if (value[9] == c10.STANDARD) {
            appHomeData.app.tabs["tab_style_ios"] = 1;
          }
          if (value[6] == c7.LARGE) {
            appHomeData.app.tabs["large_title"] = 1;
          } else {
            appHomeData.app.tabs["large_title"] = 0;
          }
          this.indexDBService.updateItem("app", appHomeData);
        }
        return true;
      });
  }

  async constructOnlineChannelConfig(sideTabList, clean?: boolean) {
    let data: any = {};
    return await this.indexDBService
      .getItemOnsucss("app", "home")
      .then(async (response) => {
        if (response) {
          data = response;
          // check for tabs
          if (data.app) {
            if (data.app.tabs && data.app.tabs.tabs) {
              return await this.indexDBService
                .getItemListOnsucss("tab", "cat", "home")
                .then(async (tabResponse) => {
                  data.app.tabs.tabs = tabResponse;
                  let tempList = this.sortTabs(data.app.tabs.tabs);
                  // tempList.forEach(tab => {  delete tab['web'];     });
                  data.app.tabs.tabs = [...tempList];
                  return await this.indexDBService
                    .getItemListOnsucss("tab", "cat", "next")
                    .then(async (tapMenu: Tab[]) => {
                      // tapMenu.forEach(tab => {  delete tab['web'];     });
                      let sideAndNextTabs: any[] = [];
                      sideAndNextTabs = [...sideTabList, ...tapMenu];
                      data.app.tabs.sideNext = sideAndNextTabs
                      const allTabsList = [...data.app.tabs.tabs, ...sideAndNextTabs];
                      data.app.menus = await this.constructMenusALLSync(
                        "menu",
                        "app",
                        allTabsList,
                        clean
                      );

                      return data;
                    });
                });
            } else {
              return data;
            }
          }
        }
      });
  }

  async constructOnlineAppConfig() {
    let data: any = { app: { tabs: { tabs: [{}] } } };
    return await this.indexDBService
      .getItemOnsucss("app", "side")
      .then(async (response) => {
        if (response) {
          data = response;
          // check for tabs
          if (data.app) {
            return await this.indexDBService
              .getItemListOnsucss("tab", "cat", "side")
              .then(async (tabResponse) => {
                data.app.tabs = {};
                data.app.tabs.tabs = tabResponse;
                let tempList = this.sortTabs(data.app.tabs.tabs);
                // tempList.forEach(tab => {  delete tab['web'];     });
                data.app.tabs.tabs = [...tempList];
                return data;
              });
          }
        }
      });
  }

  async constructAppConfig() {
    ///Saving Splash first //////
    await this.constructSplash();
    /////////////////////////////
    let data: any = {};
    data.app = {};
    data.app.system = {};

    return await this.indexDBService
      .getItemOnsucss("item", "version")
      .then(async (versionResponse) => {
        if (versionResponse) {
          data.app.version = versionResponse;
          data.app.version.value++;
        }
        return await this.indexDBService
          .getItemOnsucss("item", "channel_default")
          .then(async (channelDefault) => {
            if (channelDefault) {
              data.app.channel_default = channelDefault;
            }
            return await this.indexDBService
              .getItemOnsucss("item", "settings")
              .then(async (settingsResponse) => {
                if (settingsResponse) {
                  data.app.settings = settingsResponse;
                }
                return await this.indexDBService
                  .getItemOnsucss("item", "splash")
                  .then(async (splashResponse) => {
                    if (splashResponse) {
                      data.app.splash = splashResponse;
                    }
                    return await this.indexDBService
                      .getItemOnsucss("item", "color")
                      .then(async (colorResponse) => {
                        if (colorResponse) {
                          data.app.system.color = colorResponse;
                        }

                        return await this.indexDBService
                          .getItemOnsucss("item", "chat_label")
                          .then(async (chatLabelResponse) => {
                            if (chatLabelResponse) {
                              data.app.system.chat_label = chatLabelResponse;
                            }

                            return await this.indexDBService
                              .getItemOnsucss("item", "app_info")
                              .then(async (response) => {
                                if (response) {
                                  data.app.system.app_info = response;
                                  // check for tabs
                                  return await this.indexDBService
                                    .getItemOnsucss("item", "logo_color")
                                    .then(async (logoColor) => {
                                      if (logoColor) {
                                        data.app.system.app_info.logo_color =
                                          logoColor;
                                      }
                                      return await this.indexDBService
                                        .getItemOnsucss(
                                          "item",
                                          "logo_color_ios"
                                        )
                                        .then(async (logoColorIos) => {
                                          if (logoColorIos) {
                                            data.app.system.app_info.logo_color_ios =
                                              logoColorIos;
                                          }
                                          return await this.indexDBService
                                            .getItemOnsucss(
                                              "item",
                                              "logo_white"
                                            )
                                            .then(async (logoWhite) => {
                                              if (logoWhite) {
                                                data.app.system.app_info.logo_white =
                                                  logoWhite;
                                              }
                                              return data;
                                            });
                                        });
                                    });
                                } else {
                                  return data;
                                }
                              });
                          });
                      });
                  });
              });
          });
      });
  }

  async constructSplash() {
    let splash: any = { screens: [] };

    return await this.indexDBService
      .getItemListOnsucss("tab", "cat", "splash")
      .then(async (tabResponse) => {
        if (tabResponse && tabResponse[0] != null) {
          let tab = tabResponse[0];
          // splash = tab.param.splash;
          splash = this.getAlltabSplashItem(
            tab.param.splash,
            tab.param.splash.style
          );
          splash.style = tab.param.splash.style;
          splash.version = tab.tab_version;
          splash.id = "splash";
          splash.menu_id = tab.menu_id;
          splash.tab_id = tab.id;

          let menus = await this.constructMenusALLSync("menu", "splash");
          if (menus) {
            let tempList = this.sortMenus(menus);
            menus = tempList;
            let screens = [];
            menus.forEach((menu) => {
              if (menu && menu.rows) {
                menu.rows.forEach((row) => {
                  if (row && row.buttons) {
                    row.buttons.forEach((button) => {
                      if (button) {
                        let splashScreen = {};
                        splashScreen["order"] = menu.menu_order;
                        splashScreen["menu_id"] = menu.menu_id;
                        splashScreen["code"] = tab.param.splash.style;
                        splashScreen["id"] = button.button_id;

                        // data start
                        splashScreen["title"] = this.readSplashItem(
                          button,
                          "button_label",
                          splash.style
                        );
                        splashScreen["desc"] = this.readSplashItem(
                          button,
                          "button_sublabel",
                          splash.style
                        );
                        const image = this.readSplashItem(
                          button,
                          "images",
                          splash.style
                        ).url;
                        if(image){
                          splashScreen["image"] = this.readSplashItem(
                            button,
                            "images",
                            splash.style
                          ).url;
                        }
                        const image_set = this.readSplashItem(
                          button,
                          "images",
                          splash.style
                        ).imageSet;
                        if(image_set){
                          splashScreen["image_set"] = image_set;
                        }
                        const image_dark = this.readSplashItem(
                          button,
                          "images",
                          splash.style
                        ).url_dark;
                        if(image_dark){
                          splashScreen["image_dark"] = image_dark;
                        }
                        const image_set_dark = this.readSplashItem(
                          button,
                          "images",
                          splash.style
                        ).imageSetDark;
                        if(image_set_dark){
                          splashScreen["image_set_dark"] = image_set_dark;
                        }
                        const bg_image = this.readSplashItem(
                          button,
                          "images",
                          splash.style
                        ).bgImage;
                        if(bg_image){
                          splashScreen["bg_image"] = this.readSplashItem(
                            button,
                            "images",
                            splash.style
                          ).bgImage;
                        }
                        const bg_image_set = this.readSplashItem(
                          button,
                          "images",
                          splash.style
                        ).bgImageSet;
                        if(bg_image_set){
                          splashScreen["bg_image_set"] = bg_image_set;
                        }
                        const bg_image_dark = this.readSplashItem(
                          button,
                          "images",
                          splash.style
                        ).bgImageDark;
                        if(bg_image_dark){
                          splashScreen["bg_image_dark"] = bg_image_dark;
                        }
                        const bg_image_set_dark = this.readSplashItem(
                          button,
                          "images",
                          splash.style
                        ).bgImageSetDark;
                        if(bg_image_set_dark){
                          splashScreen["bg_image_set_dark"] = bg_image_set_dark;
                        }

                        splashScreen["color_schema"] = this.readSplashItem(
                          button,
                          "button_color_schema",
                          splash.style
                        );
                        splashScreen["color_schema_ios"] = this.readSplashItem(
                          button,
                          "button_color_schema_ios",
                          splash.style
                        );

                        // data end
                        splashScreen["version"] = button.button_version;
                        screens.push(splashScreen);
                      }
                    });
                  }
                });
              }
            });
            splash.screens = screens;
          }
          const response = this.indexDBService.updateItem("item", splash);
          response.onsuccess = (event) => {
            if (response.result) {
              return splash;
            }
          };
        }
      });
  }
  getAlltabSplashItem(splash, style) {
    let data: any = {};
    data["title"] = this.getTabSplashItem(splash, "title", style);
    data["button_title"] = this.getTabSplashItem(splash, "button_title", style);
    data["color_schema"] = this.getTabSplashItem(splash, "color_schema", style);
    data["color_schema_ios"] = this.getTabSplashItem(splash, "color_schema_ios", style);
    data["policy_url"] = this.getTabSplashItem(splash, "policy_url",    style  );
    data["terms_url"] = this.getTabSplashItem( splash, "terms_url",   style  );

    if (style == "02" || style == '03' || style == "06" || style == "07") {
      if (data["color_schema"] && data["color_schema"]["schemes"] && data["color_schema"]["schemes"]["light"] ){
        delete data["color_schema"]['schemes']['light'].tnc_bg
        delete data["color_schema"]['schemes']['dark'].tnc_bg
      }
      if (data["color_schema_ios"] && data["color_schema_ios"]["schemes"] && data["color_schema_ios"]["schemes"]["light"] ){
      delete data["color_schema_ios"]['schemes']['light'].tnc_bg
      delete data["color_schema_ios"]['schemes']['dark'].tnc_bg
      }
    }
    return data;
  }

  getTabSplashItem(splash, item, style) {
    if (item == "tnc_bgcolor") {
      return splash[item] !== null
        ? style == "02"
          ? null
          : splash[item]
        : splash_config_map[style].tab_part[item];
    } else {
      return splash[item] !== null
        ? splash[item]
        : splash_config_map[style].tab_part[item];
    }
  }

  readSplashItem(button, item, style) {
    if (style) {
      if (item === "button_color_schema") {
        return button.button_color_schema !== null
          ? button.button_color_schema
          : null;
      }
      if (item === "button_color_schema_ios") {
        return button.button_color_schema_ios !== null
          ? button.button_color_schema_ios
          : null;
      }

      if (item === "button_label") {
        return button.button_label !== null
          ? button.button_label
          : splash_config_map[style].button_part.title;
      }

      if (item === "button_sublabel") {
        return button.button_sublabel !== null
          ? button.button_sublabel
          : splash_config_map[style].button_part.desc;
      }

      if (item === "images") {
        let images: any = {};
        switch (style) {
          //we will carry everything except image and bgimage
          case SPLASH_STYLE.STYLE_01:
            images["url"] =
              button.button_images["01"].url !== null
                ? button.button_images["01"].url
                : splash_config_map[style].button_part.images["01"].url;
            images["imageSet"] =
              button.button_images["01"].url !== null
                ? button.button_images["01"].imageSet
                : splash_config_map[style].button_part.images["01"].imageSet;
            images["url_dark"] =
              button.button_images["01"].url_dark
                ? button.button_images["01"].url_dark
                : null;
            images["imageSetDark"] =
              button.button_images["01"].url_dark
                ? button.button_images["01"].imageSetDark
                : null;
            images["bgImage"] = null;
            images["bgImageSet"] = null;
            images["bgImageDark"] = null;
            images["bgImageSetDark"] = null;

            return images;
            break;

          case SPLASH_STYLE.STYLE_03:
            images["url"] = null;
            images["imageSet"] = null;
            images["url_dark"] = null;
            images["imageSetDark"] = null;
            images["bgImage"] =
              button.button_bgimage !== null
                ? button.button_bgimage
                : splash_config_map[style].button_part.bg_image;
            images["bgImageSet"] =
              button.button_bgimageSet !== null
                ? button.button_bgimageSet
                : splash_config_map[style].button_part.bg_imageSet;
            images["bgImageDark"] =
              button.button_bgimage_dark !== null
                ? button.button_bgimage_dark
                : null;
            images["bgImageSetDark"] =
              button.button_bgimageSet_dark !== null
                ? button.button_bgimageSet_dark
                : null;

            return images;
            break;

          default:
            images["url"] =
              button.button_images["default"].url !== null
                ? button.button_images["default"].url
                : splash_config_map[style].button_part.images[style].url;
            images["imageSet"] =
              button.button_images["default"].url !== null
                ? button.button_images["default"].imageSet
                : splash_config_map[style].button_part.images[style].imageSet;
            images["url_dark"] =
                button.button_images["01"].url_dark
                  ? button.button_images["01"].url_dark
                  : null;
            images["imageSetDark"] =
                button.button_images["01"].url_dark
                  ? button.button_images["01"].imageSetDark
                  : null;
            images["bgImage"] = null;
            images["bgImageSet"] = null;
            images["bgImageDark"] = null;
            images["bgImageSetDark"] = null;
            return images;
            break;
        }
      }
    }
  }

  async constructMenusALLSync(table, cat, tabList?, clean?:boolean) {
     return await this.indexDBService
      .getItemListOnsucss(table, null, null)
      .then(async (menuResponse: any) => {
        let menus = menuResponse;
        let newMenus = [];
        // filter all tabs to get tab for stores and get their menu Group and only include those menus into the configuraiton.
        let stores = [];
        let workflows = [];
        if (tabList) {
          for (let i = 0; i < tabList.length; i++) {
            switch (tabList[i].module) {
              case "menu":
                workflows.push(tabList[i].id);
                break;
              case "query":
                workflows.push(tabList[i].id);
                break;
              case "store":
                stores.push(tabList[i].menu_group);
                break;

             case "market":
             stores.push(tabList[i].menu_group);
              break;
            }
          }
        }
        for (let i = 0; i < menus.length; i++) {
          switch (cat) {
            case "app":
              //all
              if (menus[i].cat != "splash" && menus[i].cat != "poll") {
                if ((menus[i].cat == "menu" || menus[i].cat == "query") && workflows.length > 0) {
                  for (let j = 0; j < workflows.length; j++) {
                    if (menus[i].menu_group == workflows[j]) {
                      menus[i].rows = await this.constructMenuRowsSync(
                        menus[i].menu_id,
                        clean
                      );
                      newMenus.push(menus[i]);
                    }
                  }
                } else if ((menus[i].cat == "store" ||menus[i].cat == "market")&& stores.length > 0) {
                  for (let j = 0; j < stores.length; j++) {
                    if (menus[i].menu_group == stores[j]) {
                      (menus[i].rows = await this.constructMenuRowsSync(
                        menus[i].menu_id
                      )),
                        true;
                      newMenus.push(menus[i]);
                    }
                  }
                }
              }
              break;
            case "splash":
              if (menus[i].cat == "splash") {
                menus[i].rows = await this.constructMenuRowsSync(
                  menus[i].menu_id
                );
                newMenus.push(menus[i]);
              }
              break;

            case "store":
              //only store
              if (menus[i].cat == "store") {
                menus[i].rows = await this.constructMenuRowsSync(
                  menus[i].menu_id,
                  true
                );
                newMenus.push(menus[i]);
              }
              break;
              case "market":
                //only store
                if (menus[i].cat == "store") {
                  menus[i].rows = await this.constructMenuRowsSync(
                    menus[i].menu_id,
                    true
                  );
                  newMenus.push(menus[i]);
                }
                break;

            case "poll":
              //only poll
              if (menus[i].cat == "poll") {
                menus[i].rows = await this.constructMenuRowsSync(
                  menus[i].menu_id,
                  true
                );
                newMenus.push(menus[i]);
              }
              break;
          }
        }

        return newMenus;
      });
  }

  async constructMenus(query) {
    return await this.indexDBService
      .getItemListOnsucss("menu", "cat", query)
      .then(async (menuResponse: any) => {
        let menus = [];
        menuResponse.forEach(async (menu) => {
          let newMenu = menu;
          newMenu.rows = await this.constructMenuRows(menu.menu_id);
          menus.push(newMenu);
        });
        return await menus;
      });
  }
  async constructAppStoreMenu(ApptabList, category?) {
    return await this.indexDBService
      .getItemListOnsucss("tab", "cat", "category")
      .then(async (tabResponse) => {
        if (tabResponse) {
          let stores = [];
          let tabStoreList: any;
          let AppStoreTabIdList = [];
          tabStoreList = tabResponse;
          for (let i = 0; i < ApptabList.length; i++) {
            if (ApptabList[i].module == "category") {
              AppStoreTabIdList.push(ApptabList[i].menu_group);
            }
          }
          for (let i = 0; i < tabStoreList.length; i++) {
            if (AppStoreTabIdList.includes(tabResponse[i].id)) {
              let tab = {};
              tab = tabResponse[i];
              // tab params required
              tab = this.getAlltabStoreItem(tabResponse[i]);
              let shop = await this.constructMenusbyMenuGroupSync(
                tabResponse[i].menu_group
              );
              tab["shop"] = shop;
              stores.push(tab);
            }
          }
          this._storeContainer.next(stores);
        }
      });
  }

  async constructStore(store_id) {
    return await this.indexDBService
      .getItemOnsucss("tab", store_id)
      .then(async (tabResponse) => {
        if (tabResponse) {
          let stores = [];
          let tab = {};
          tab = tabResponse;
          // tab params required
          tab = this.getAlltabStoreItem(tab);
          let shop = await this.constructMenusbyMenuGroupSync(store_id);
          tab["shop"] = shop;
          stores.push(tab);
          this._storeContainer.next(stores);
        }
      });
  }

   saveStorePollConfig(tab){
    switch(tab.module){
      case 'store':
      case 'market':
         this.constructStore(tab.menu_group);
        break;
      case 'poll':
         this.constructPoll(tab.menu_group);
        break;
    }
  }

  getAlltabStoreItem(tab) {
    let data: any = {};
    data["id"] = tab.menu_group;
    data["menu_id"] = tab.menu_id;
    data["is_default"] = localStorage.getItem(tab.cat)
      ? tab.id == localStorage.getItem(tab.cat)
        ? 1
        : 0
      : 1;

    data["name"] = tab.title;
    data["description"] = tab.desc;
    data["image"] = [{ url: tab.image_url }];
    data["version"] = tab.tab_version;
    data["category"] = tab.cat;
    if(tab.cat === 'poll' && tab.param){
      data["type"] = tab.param.poll_type;
      data["result_type"] = tab.param.poll_result_type;
      data["expiry_date"] = tab.param.expiry_date;
    }
    if (tab.style) {
      data["style"] = tab.style;
    }

    return data;
  }

  async constructPoll(store_id) {
    return await this.indexDBService
      .getItemOnsucss("tab", store_id)
      .then(async (tabResponse) => {
        if (tabResponse) {
          let polls = [];
          let tab = {};
          tab = tabResponse;
          // tab params required
          tab = this.getAlltabStoreItem(tab);
          let menus = await this.constructMenusbyMenuGroupSync(store_id);
          tab["menus"] = menus;
          polls.push(tab);
          this._pollContainer.next(polls);
        }
      });
  }

  getAlltabPollItem(tab) {
    let data: any = {};
    data["id"] = tab.menu_group;
    data["menu_id"] = tab.menu_id;
    data["name"] = tab.title;
    data["description"] = tab.desc;
    data["image"] = [{ url: tab.image_url }];
    data["version"] = tab.tab_version;
    if (tab.style) {
      data["style"] = tab.style;
    }

    return data;
  }

  async constructMenusbyMenuGroupSync(menu_group) {
    return await this.indexDBService
      .getItemListOnsucss("menu", "grp", menu_group)
      .then(async (menuResponse: any) => {
        let menus = menuResponse;
        let newMenus = [];
        for (let i = 0; i < menus.length; i++) {
          menus[i].rows = await this.constructMenuRowsSync(menus[i].menu_id);
          newMenus.push(menus[i]);
        }
        return newMenus;
      });
  }

  async constructMenuRows(menuId) {
    return await this.indexDBService
      .getItemListOnsucss("row", "menu", menuId)
      .then(async (rowResponse: any) => {
        let rows = await this.sortRows(rowResponse);

        rows.forEach(async (row) => {
          let newRow = row;
          newRow.buttons = await this.constructMenuRowButtons(row.row_id);
        });
        return rows;
      });
  }
  //////////////////////////

  async constructMenuSync(menuId, clean?: boolean) {
    // console.log("menuId", menuId);
    return await this.indexDBService
      .getItemOnsucss("menu", menuId)
      .then(async (menuResponse: any) => {
        let menu = menuResponse;
        if (menu) {
          menu.rows = await this.constructMenuRowsSync(menu.menu_id, clean);
        }
        return await menu;
      });
  }

  async constructMenuRowsSync(menuId, clean?: boolean) {
    return await this.indexDBService
      .getItemListOnsucss("row", "menu", menuId)
      .then(async (rowResponse: any) => {
        let rows = await this.sortRows(rowResponse);
        let newRows = [];
        for (let i = 0; i < rows.length; i++) {
          rows[i].buttons = await this.constructMenuRowButtons(
            rows[i].row_id,
            clean
          );
          newRows.push(rows[i]);
        }
        return newRows;
      });
  }

  async constructMenuRowButtons(rowId, clean?: boolean) {
    return await this.indexDBService
      .getItemListOnsucss("button", "row", rowId)
      .then(async (buttonResponse: any) => {
        let buttons = await this.sortButtons(buttonResponse);
        if (clean) {
          for (let i = 0; i < buttons.length; i++) {
            delete buttons[i].info;
          }
        }

        return buttons;
      });
  }

  sortRows(rows: any[]) {
    return rows.sort((a, b) => Number(a.row_order) - Number(b.row_order));
  }
  sortButtons(buttons: any[]) {
    return buttons.sort(
      (a, b) => Number(a.button_order) - Number(b.button_order)
    );
  }

  sortMenus(menus: Menu[]) {
    return menus.sort((a, b) => Number(a.menu_order) - Number(b.menu_order));

    // Hazem needs to populate the menu_order in order for this function to work.
  }

  sortTabs(tabs: Tab[]) {
    return tabs.sort((a, b) => Number(a.tab_order) - Number(b.tab_order));
  }

  mapAIConfig(data) {
    let config = {};
    config["version"] = data.version;

    let onlineAppConfig = data.side;
    onlineAppConfig.id = onlineAppConfig.app.id;
    delete onlineAppConfig.app.id;
    onlineAppConfig.app["tabs"] = onlineAppConfig.app.config;
    delete onlineAppConfig.app.config;
    onlineAppConfig.app.tabs["tabs"] = onlineAppConfig.app.tabs.components;
    delete onlineAppConfig.app.tabs.components;
    config["onlineAppConfig"] = onlineAppConfig;


    let onlineChannelConfig = data.home;
    onlineChannelConfig.id = onlineChannelConfig.app.id;
    delete onlineChannelConfig.app.id;
    onlineChannelConfig.app["tabs"] = onlineChannelConfig.app.config;
    delete onlineChannelConfig.app.config;
    onlineChannelConfig.app.tabs["tabs"] =
      onlineChannelConfig.app.tabs.components;
    delete onlineChannelConfig.app.tabs.components;



    onlineChannelConfig.app.tabs.tabs.forEach((tab) => {
      tab["tab_order"] = tab.order;
      delete tab.order;
    });
    delete onlineChannelConfig.app.tabs.tabs.order;
    let colorSource = {
      primary: onlineChannelConfig.app.tabs.color_schema.source,
    };
    onlineChannelConfig.app.tabs.color_schema =
      this.createColorThemeFromColor(colorSource);
    let colorSourceIOS = { primary: "#007aff" };
    onlineChannelConfig.app.tabs.color_schema_ios =
      this.createColorThemeFromColor(colorSourceIOS);
    config["onlineChannelConfig"] = onlineChannelConfig;

    return config;
  }


  createColorThemeFromColor(baseColors) {
    if (baseColors && baseColors.primary) {
      let colors: colorSchema = {
        style: { lightStatusBar: "1" },
        schemes: { light: {}, dark: {} },
        palettes: {
          primary: { keyColor: { id: null } },
          secondary: { keyColor: { id: null } },
          tertiary: { keyColor: { id: null } },
          error: { keyColor: { id: null } },
          neutral: { keyColor: { id: null } },
          neutralVariant: { keyColor: { id: null } },
        },
      };

      let theme: any = {};
      let CorePaletteColors: CorePaletteColors = {
        primary: argbFromHex(baseColors.primary),
        secondary: baseColors.secondary
          ? argbFromHex(baseColors.secondary)
          : null,
        tertiary: baseColors.tertiary ? argbFromHex(baseColors.tertiary) : null,
      };
      let palette: CorePalette;
      palette = CorePalette.contentFromColors(CorePaletteColors);
      theme.light = Scheme.lightFromCorePalette(palette);
      theme.dark = Scheme.darkFromCorePalette(palette);

      Object.keys(theme.light.props).forEach((key) => {
        colors.schemes.light[key] = hexFromArgb(theme.light.props[key]);
      });

      Object.keys(theme.dark.props).forEach((key) => {
        colors.schemes.dark[key] = hexFromArgb(theme.dark.props[key]);
      });

      // console.log("palette", palette);
      colors.source = baseColors.primary; // check if this primary

      let palette_temp: any = {
        primary: {},
        secondary: {},
        tertiary: {},
        error: {},
        neutral: {},
        neutralVariant: {},
      };

      palette_temp.primary = palette.a1;
      palette_temp.primary.keyColor.id = hexFromArgb(
        palette.a1["keyColor"]["argb"]
      );
      colors.palettes.primary = palette_temp.primary;

      palette_temp.secondary = palette.a2;
      palette_temp.secondary.keyColor.id = hexFromArgb(
        palette.a2["keyColor"]["argb"]
      );
      colors.palettes.secondary = palette_temp.secondary;

      palette_temp.tertiary = palette.a3;
      palette_temp.tertiary.keyColor.id = hexFromArgb(
        palette.a3["keyColor"]["argb"]
      );
      colors.palettes.tertiary = palette_temp.tertiary;

      palette_temp.error = palette.error;
      palette_temp.error.keyColor.id = hexFromArgb(
        palette.error["keyColor"]["argb"]
      );
      colors.palettes.error = palette_temp.error;

      palette_temp.neutral = palette.n1;
      palette_temp.neutral.keyColor.id = hexFromArgb(
        palette.n1["keyColor"]["argb"]
      );
      colors.palettes.neutral = palette_temp.neutral;

      palette_temp.neutralVariant = palette.n2;
      palette_temp.neutralVariant.keyColor.id = hexFromArgb(
        palette.n2["keyColor"]["argb"]
      );
      colors.palettes.neutralVariant = palette_temp.neutralVariant;

      let updatedColros: colorSchema;
      let newColors: colorSchema;
      updatedColros = this.builderService.additionalColors(palette, colors);
      newColors = this.builderService.extraMaterialColor(
        palette,
        updatedColros,
        null,
        false,
        "all"
      );

      // console.log("newColors", newColors);
      return newColors;
    }
  }
  async saveStore(store, category,  navigate: boolean,  noBuilderVersionUpdate: boolean ) {
    if (!category) {
      category = "store";
    }
    let mode = localStorage.getItem("mode");
    let storeTab: any = {};
    storeTab["id"] = store.id;
    storeTab["tab_version"] = store.version;
    storeTab["module"] = category;
    storeTab["type"] = TabNames.MENU;
    storeTab["cat"] = category;
    storeTab["menu_group"] = store.id;
    storeTab["menu_id"] = store.menu_id;
    storeTab["image_url"] = store.image ? store.image[0].url : null;
    storeTab["title"] = store.name;
    storeTab["desc"] = store.description;

    if (store && store.is_default == 1) {
      localStorage.setItem(category, store.id);
      localStorage.setItem(category + "menu", store.menu_id);
    }

    if(category == "poll"){
      storeTab["param"] = {};
      storeTab["param"]['type'] = store.type;
      storeTab["param"]['result_type'] = store.result_type;
      storeTab["param"]['expiry_date'] = Number(store.expiry_date);
    }


    if (category == "poll") {
      await this.menusInsertion(store.menu,false, store.id,  category, navigate  );
    } else {
      await this.menusInsertion(store.shop, false, store.id,  category,  navigate );
    }
    this.changeTab({ tab: storeTab, navigate: navigate }, mode, false, false,  noBuilderVersionUpdate );
  }

  assignStoreToTab(navigationTab?, operator?: number, existingStore?, type?) {
    let storemenu = type + "menu";

    if (!type) {
      type = "store";
    }
    let id = this.makeRef(16);
    let storeTabId = this.makRefNumber(16).toString();

    let defaultStore = localStorage.getItem(type)
      ? localStorage.getItem(type)
      : null;
    let DefaultStoreMenu_id = localStorage.getItem(storemenu)
      ? localStorage.getItem(storemenu)
      : null;
    let mode = localStorage.getItem("mode");
    let data: any = {};
    let storeTab: any = {};

    switch (operator) {
      case ASSIGN_STORE.DEFAULT:
        navigationTab["menu_id"] = DefaultStoreMenu_id;
        navigationTab["menu_group"] = defaultStore;
        navigationTab["param"] = { category: type };
        this.changeTab(
          { tab: navigationTab },
          localStorage.getItem("mode"),
          false
        );

        break;

      case ASSIGN_STORE.EXISTING:
        this.saveStore(existingStore, type, false, false);
        navigationTab["menu_id"] = existingStore.menu_id;
        navigationTab["menu_group"] = existingStore.id;
        navigationTab["param"] = { category: type, id: existingStore.id };

        this.changeTab(
          { tab: navigationTab },
          localStorage.getItem("mode"),
          false
        );
        break;

      case ASSIGN_STORE.NEW:
        storeTab = this.createNewStore(type);
        navigationTab["menu_id"] = storeTab.menu_id;
        navigationTab["menu_group"] = storeTab.id;
        navigationTab["param"] = { category: type, id: storeTab.id };
        this.changeTab(
          { tab: navigationTab },
          localStorage.getItem("mode"),
          false
        );
        break;
    }

    return navigationTab;
  }

  createNewStore(category?) {
    if (!category) {
      category = TabNames.STORE;
    }
    let id = this.makeRef(16);
    let storeTabId = this.makRefNumber(16).toString();

    let defaultStore = localStorage.getItem(category)
      ? localStorage.getItem(category)
      : null;
    let DefaultStoreMenu_id = localStorage.getItem(category + "menu")
      ? localStorage.getItem(category + "menu")
      : null;
    let mode = localStorage.getItem("mode");
    let current_tab = {};
    current_tab = structuredClone(common_components_map[category]);

    let menu_id = this.makeRef(15);
    current_tab["menu_id"] = menu_id;

    let menu: Menu = {
      menu_id: menu_id,
      menu_ref: menu_id,
      menu_version: menu_id,
      cat: category,
      menu_group: storeTabId,
      menu_order: 0,
      menu_name: "First" + " Screen"
    };

    this.indexDBService.addItem("menu", menu);
    // new tab for store only
    let storeTab: any = {};
    storeTab["id"] = storeTabId;
    storeTab["tab_version"] = storeTabId;
    storeTab["module"] = category;
    storeTab["type"] = TabNames.MENU;
    storeTab["cat"] = category;
    storeTab["menu_group"] = storeTabId;
    storeTab["menu_id"] = menu_id;
    storeTab["title"] = "New "+ category + this.makeRef(6);
    storeTab["image_url"] = common_components_map[category].image_url;

    if (!defaultStore) {
      storeTab["is_default"] = 1;
      localStorage.setItem(category, storeTabId);
      localStorage.setItem(category + "menu", menu_id);
    } else {
      storeTab["is_default"] = 0;
    }

    this.changeTab({ tab: storeTab, navigate: true }, mode, false, false, true);

    return storeTab;
  }

  createPoll() {
    let id = this.makeRef(16);
    let pollTabId = this.makRefNumber(16).toString();

    // let defaultStore =  localStorage.getItem("store")? localStorage.getItem("store"): null
    // let DefaultStoreMenu_id = localStorage.getItem("storemenu")? localStorage.getItem("storemenu"): null
    let mode = localStorage.getItem("mode");
    let current_tab = {};
    current_tab = structuredClone(common_components_map[TabNames.POLL]);

    let menu_id = this.makeRef(15);
    current_tab["menu_id"] = menu_id;

    let menu: Menu = {
      menu_id: menu_id,
      menu_ref: menu_id,
      menu_version: menu_id,
      cat: TabNames.POLL,
      menu_group: pollTabId,
      menu_order: 0,
    };

    this.indexDBService.addItem("menu", menu);

    // new tab for store only
    let pollTab: any = {};
    pollTab["id"] = pollTabId;
    pollTab["tab_version"] = pollTabId;
    pollTab["module"] = TabNames.POLL;
    pollTab["type"] = TabNames.MENU;
    pollTab["cat"] = TabNames.POLL;
    pollTab["menu_group"] = pollTabId;
    pollTab["menu_id"] = menu_id;
    pollTab["title"] = "New poll_" + this.makeRef(6);

    this.changeTab({ tab: pollTab, navigate: true }, mode, false, false, true);

    return pollTab;
  }

  async deleteMenu(menu_id) {
    let menu = await this.constructMenuSync(menu_id);
    if (menu) {
      menu.rows.forEach((row) => {
        row.buttons.forEach((button) => {
          if (button.row_id == row.row_id) {
            const deletereq = this.indexDBService.deleteItemBykey(
              "button",
              button.button_id
            );
          }
        });
        const deletereq = this.indexDBService.deleteItemBykey(
          "row",
          row.row_id
        );
      });
      const deletereq = this.indexDBService.deleteItemBykey("menu", menu_id);
    }
  }



  generateOrdinalName(order: number): string {
    const ordinalNames: string[] = [
      'first', 'second', 'third', 'fourth', 'fifth', 'sixth',
      'seventh', 'eighth', 'ninth', 'tenth', 'eleventh', 'twelfth',
      'thirteenth', 'fourteenth', 'fifteenth', 'sixteenth',
      'seventeenth', 'eighteenth', 'nineteenth', 'twentieth',
    ];

    const tens: string[] = [
      '', '', 'twentieth', 'thirtieth', 'fortieth',
      'fiftieth', 'sixtieth', 'seventieth',
      'eightieth', 'ninetieth',
    ];

    if (order < ordinalNames.length) {
      return this.capitalize(ordinalNames[order]);
    }

    const tensPlace = Math.floor(order / 10);
    const onesPlace = order % 10;

    const tensPart = tens[tensPlace] || `${tensPlace}ieth`;
    const onesPart = onesPlace > 0 ? ordinalNames[onesPlace] : '';

    return this.capitalize(`${tensPart}${onesPart ? '-' + onesPart : ''}`);
  }

  capitalize(word: string): string {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }


  validateMenus(menus, target, config?){
    let result =[]
    menus.forEach(menu => {
      result= [...result,...this.validateMenu(menu,target, config)]
    });
    return result
  }

  validateMenu(menu, target, config?){
    let routingLink : string;
    let result =[];
    routingLink = "/appbuilder/navigation" + this.routingLinkMenu(menu.cat) + menu.menu_group

    if (menu.info && menu.info.validate) {
        menu.info.validate.forEach((item) => {
        item.route = "/appbuilder/navigation" + this.routingLinkMenu(menu.cat) + menu.menu_group
      });
      result= [...result,...this.validateJSON(menu, menu.info.validate, target, config)];
    }
    result= [...result,...this.validateRows( menu.rows, routingLink, target, config)];
    return result
  }

  validateRows(rows, routingLink, target, config?){
    let result =[]
    rows.forEach(row => {
      result =[...result,... (this.validateRow(row, routingLink , target, config))];
    });
    return result
  }
  validateRow(row, routingLink, target, config?){
    let result =[];
    if (row.info && row.info.validate) {
      row.info.validate.forEach((item) => {
        item.route = routingLink
      });
      result=[...result,...this.validateJSON(row, row.info.validate, target, config)];
    }
    result= [...result,...this.validateButtons( row.buttons, routingLink, target, config)]
    return result
  }

  validateButtons(buttons, routingLink, target, config?){
    let result =[]
    buttons.forEach(button => {
      result =[...result,...( this.validateButton(button, routingLink , target, config))];

    });
    return result
  }

  validateButton(button, routingLink, target, config?){
    let result =[];

    if (button.info && button.info.validate) {
      // let route = routingLink
      let route = routingLink +'\?id=' + button.button_id;
      button.info.validate.forEach((item) => {
        item.route = route;
      });
      result= this.validateJSON(button, button.info.validate, target, config);
    }
    return result
  }

  validateTabs(tabs, target, config?) {
     if (tabs){
      let result =[]
      tabs.forEach(tab => {
        result = [...result ,...(this.validateTab(tab, target, config))];
      });
      return result
    }
  }


  validateTab(tab, target, config?) {
    let result =[];

    if (tab && tab.web && tab.web.validate) {
      let r1: string;
      r1= this.routingLinkTab(tab.cat)
      // let route = (tab.cat =='home' || tab.cat =='side')? r1 +  '? id=' + tab.id :  r1 + tab.id;
      let route = r1 +  '\?id=' + tab.id;
      tab.web.validate.forEach((item) => {
        item.route = route;
        item.icon = this.builderService.getIcon(target=='apn'?tab.icon_ios: tab.icon).id
      });
       result = this.validateJSON(tab, tab.web.validate, target,config);
    }
    return result
  };

  validateJSON(json, validators, target, config?) {
    let checkList = [];
    let index = 0;
    validators.forEach((item) => {
      let rs = 0;
      item.validators.forEach((validate) => {
        rs += this.validateItem(json[item.id], validate, config);
      });
      let result = item.target[target] ? rs : 0;
      checkList[index] = {
        id: item.id,
        name: item.name,
        icon: item.icon,
        type: item.type[target]?item.type[target]: item.type['default'],
        level: item.level,
        errorCode: result,
        error: item.error,
        link: item.route,
        setting: item.setting,

      };
      index++;
    });
    return checkList;
  }

  validateItem(item, validate, config?) {
    // console.log("validateItem Item", item);
    // console.log("validateItem validate", validate);
      let rs = 1;
      if (validate.id) {
        switch (validate.id) {
          case ValidateItemKey.NOT:
            if (item != validate.value) {
              return (rs = 0);
            }
            break;

           case ValidateItemKey.DOESNOTCONTAIN:
           try {
            if(item.includes(validate.value)){
              return (rs = 1);
            }else {
              return (rs=0)
            }
           } catch (e) {
            rs = 0
           }


           break;


           case ValidateItemKey.CONTAIN:
          if(item && item.includes(validate.value)){
            return (rs = 0);
          }
           break;

           case ValidateItemKey.LOGO:
          if(item && item.image.id && item.image_id.includes(config.app_id)){
            return (rs = 0);
          }
           break;

          case ValidateItemKey.LENGTH:
            if (item && item.length == validate.value) {
              return (rs = 0);
            }
            break;


          case ValidateItemKey.OBJECTLENGTH:
            if (item && Object.keys(item).length == validate.value) {
              return (rs = 0);
            }
            break;


          case ValidateItemKey.URL:
            try {
              new URL(item);
              rs = 0;
            } catch (e) {
              rs = 1;
            }
        case ValidateItemKey.WEBVIEW:
            try {
                new URL(item.wv_url);
                rs = 0;
            } catch (e) {
              rs = 1;
            }
          break;

          case ValidateItemKey.STATUS:
            if (item.status == "completed") {
              return (rs = 0);
            }
            break;

          case ValidateItemKey.NOTEMPTY:
            if (item.trim().length !== 0) {
              return (rs = 0);
            }
            break;

          case ValidateItemKey.COMPANY:
            if (item) {
            if ((item.company_name == null || item.company_name.trim().length === 0) || (item.company_official_name == null || item.company_official_name.trim().length === 0) || (item.company_url == null || item.company_url.trim().length === 0) || (item.deep_link_schema == null || item.deep_link_schema.trim().length === 0)){
              return (rs = 1);
            }else{
              return (rs = 0);
            }

            }
            break;


          case ValidateItemKey.NEXT:
              if (item != null && item.length != 0 ) {
                let rs=0
                item.forEach(obj => {
                  switch (obj.type){
                    case 'tab' :
                      let tabs =[]
                      tabs = config.onlineChannelConfig.app.tabs.tabs
                      if (tabs.find(t => t.id === obj.id).length !=0 ){
                      rs += 1;
                      }

                    break;

                    case 'menu':
                      let menus =[]
                      menus = config.onlineChannelConfig.app.menus
                      if (menus.find(m => m.menu_id ===  obj.id).length !=0 ){
                        rs += 1;
                      }
                    break

                    case  'url':
                      try {
                        new URL(obj.id);
                        rs += 0;
                      } catch (e) {
                        rs += 1;
                      }
                    break;
                  }
                });
                return rs
              }
              break;
        }
      }
      return rs;
  }


  routingLinkTab(cat){
    let r1: string;
    switch (cat) {
      case "home":
        r1 = "/appbuilder/navigation";
        break;

      case "side":
        r1 = "/appbuilder/side";
        break;

      case "next":
        r1 = "/appbuilder/navigation/workflow";
        break;

      case "store":
        r1 = "/stores/store";
        break;

      case "market":
        r1 = "/market/market";
        break;

      case "poll":
        r1 = "polls/poll";
        break;

    }
    return r1
  }


  routingLinkMenu(cat){
    let r1: string;
    switch (cat) {

      case "menu":
        r1 = "/workflow/";
        break;

      case "store":
        r1 = "/store/";
        break;

      case "market":
        r1 = "/market/";
        break;

      case "poll":
        r1 = "/poll/";
        break;

    }
    return r1
  }
 async aiToAppMapper(data){
 let appConfig ={app:{system: {app_info:{}, chat_label:{}}, splash:{}, version:{}}};
 let onlineAppConfig ={id:'side', app:{tabs:{tabs:[], menu:[] }}};
 let onlineChannelConfig: any;
 onlineChannelConfig = {id:"home", app:{tabs:{tabs:[],color_schema:{}, color_schema_ios:{}, default:{},}, menus:[],}};
 let newData = {}

 // validate keys and type against AI structure
//   const structure ={
//   'config': {
//           'onboearding?':{},
//           'app_info':{
//               'id': 'string',
//               'image': 'string',
//               'app_name': 'string',
//               'currency?': 'string',         //default is USD
//               'language?': {
//                 'value': 'string',
//                 'key': 'string',
//                 'direction?': 'string' // Optional key
//               },
//             },
//               'colors':{'id':'string', 'color_schema':{'source':'string'}, 'color_schema_ios':{'source':'string'} },
//               'theme':{ 'android':{'navigation':{'position':'string', 'icon':'boolean', 'label':'boolean'}, 'drawer':{'enabled':'boolean', }, 'material':'string'},
//                         'ios':{'navigation':{'icon':'boolean', 'label':'boolean', 'title':'string'}, 'setting_screen':{'enabled':'boolean', 'position':'string'}, 'material':'string'}},
//               'chat_label?':{'id':'string', 'labels': [{'id':'string', 'name':'string','default': 'boolean'}] },

//               'default?':{'id':'string','feed?':{}, 'channel?':{} },

//            },

//     'navigation':{
//             'components':[{
//                     'id': 'string',
//                     'module': 'string',
//                     'cat': 'string',
//                     'icon': 'string',
//                     'title': 'string',
//                     'order': 'number',
//                     'menu_group?': 'string',
//                     'menu_id?': 'string',
//                     'chat_label?': 'string',
//                     'api_id?': 'string',
//                     }],
//             'menus':[{
//               'menu_id': "string",
//               'menu_group':'string',
//               'cat':'string',
//               'version': "stirng",
//               'menu_order': 'number',
//               'api_id?': 'string',
//               'rows':[{
//                       'row_id': "string",
//                       'version': "string",
//                       'row_order': 'number',
//                       'cells':[{
//                         'cell_id': 'string',
//                         'form': 'string',
//                         'style': 'string',
//                         'cell_order': 'number',
//                         'version': 'string',
//                         'heading': 'string',
//                         'headline?': 'string',
//                         'subhead?': 'string',
//                         'label?': 'string',
//                         'body?': 'string',
//                         'helper?': 'string',
//                         'error?': 'string',
//                         'suffix?': 'string',
//                         'prefix?': 'string',
//                         'placeholder?': 'string',
//                         'input?': 'string',
//                         'divider?':'bolean',
//                          'options?':[{
//                             'id':'string',
//                             'label?':'string',
//                             'sublabel?':'string',
//                             'icon?':'string',
//                             'trailing_icon?':'string',
//                             'image?':'string',
//                             'value?':'string',
//                             'divider?':'bolean',
//                          }],
//                          'value': [{
//                           'id':'string',
//                           'label?':'string',
//                           'sublabel?':'string',
//                           'icon?':'string',
//                           'trailing_icon?':'string',
//                           'image?':'string',
//                           'value?':'string',
//                           'divider?':'bolean',
//                        }],
//                          'image?': 'string',
//                          'bgimage?':'string',
//                          'icon?':'string',
//                          'trailing_icon?':'string',
//                         'text_size?': {'headline?': 'string', 'subhead?': 'string', 'label?': 'string', 'sublabel?': 'string', 'body?': 'string', 'helper?': 'string', 'error?': 'string'},
//                         'text_align?': {'headline?': 'string', 'subhead?': 'string', 'label?': 'string', 'sublabel?': 'string', 'body?': 'string', 'helper?': 'string', 'error?': 'string'},
//                         'next?':[{'type':'string', 'id':"string"}]

//                       }]
//                   }]

//             }]
//           },
//     'drawer':{'components?':[{
//             'id': 'string',
//             'module': 'string',
//             'cat': 'string',
//             'icon': 'string',
//             'title': 'string',
//             'order': 'number',
//             'menu_group?': 'string',
//             'menu_id?': 'string',
//             'chat_label?': 'string',
//             'api_id?': 'string',
//           }]
//           },
//   'version': 'number',
//   'reference?':'string' ,
//   'nandbox?':'string'
// }

const structure ={
  'app_name?': 'string',
  'colors?':{'id?':'string', 'color_schema':{'source':'string'}, 'color_schema_ios':{'source':'string'} },
  'layout?':{
  'android':{'navigation':{'position':'string', 'icon':'boolean', 'label':'boolean'}, 'drawer':{'enabled':'boolean', }, 'material':'string'},
  'ios':{'navigation':{'icon':'boolean', 'label':'boolean', 'title':'string'}, 'setting_screen':{'enabled?':'boolean', 'position':'string'}, 'material':'string'},
    },
  'chat_label?':{'id?':'string', 'labels': [{'id':'string', 'name':'string','default': 'boolean'}] },
  'default?':{'id?':'string','feed?':{}, 'channel?':{} },
   'onlineChannelConfig':{
          'app':{
            'tabs':{
              'tabs':[{
                'id': 'string',
                'module': 'string',
                'cat': 'string',
                'icon': 'string',
                'title': 'string',
                'order': 'number',
                'menu_group?': 'string',
                'menu_id?': 'string',
                'chat_label?': 'string',
                'api_id?': 'string',
                }],
              'menu?':[

                {
          'menu_id': "string",
          'menu_group':'string',
          'cat':'string',
          'version': "stirng",
          'menu_order': 'number',
          'api_id?': 'string',
          'rows':[{
                  'row_id': "string",
                  'version': "string",
                  'row_order': 'number',
                  'cells':[{
                    'cell_id': 'string',
                    'form': 'string',
                    'style': 'string',
                    'cell_order': 'number',
                    'version': 'string',
                    'heading': 'string',
                    'headline?': 'string',
                    'subhead?': 'string',
                    'label?': 'string',
                    'body?': 'string',
                    'helper?': 'string',
                    'error?': 'string',
                    'suffix?': 'string',
                    'prefix?': 'string',
                    'placeholder?': 'string',
                    'input?': 'string',
                    'divider?':'bolean',
                     'options?':[{
                        'id':'string',
                        'label?':'string',
                        'sublabel?':'string',
                        'icon?':'string',
                        'trailing_icon?':'string',
                        'image?':'string',
                        'value?':'string',
                        'divider?':'bolean',
                     }],
                     'value': [{
                      'id':'string',
                      'label?':'string',
                      'sublabel?':'string',
                      'icon?':'string',
                      'trailing_icon?':'string',
                      'image?':'string',
                      'value?':'string',
                      'divider?':'bolean',
                   }],
                     'image?': 'string',
                     'bgimage?':'string',
                     'icon?':'string',
                     'trailing_icon?':'string',
                    'text_size?': {'headline?': 'string', 'subhead?': 'string', 'label?': 'string', 'sublabel?': 'string', 'body?': 'string', 'helper?': 'string', 'error?': 'string'},
                    'text_align?': {'headline?': 'string', 'subhead?': 'string', 'label?': 'string', 'sublabel?': 'string', 'body?': 'string', 'helper?': 'string', 'error?': 'string'},
                    'next?':[{'type':'string', 'id':"string"}]

                  }]
              }]

        }]
            }
          }
          },
   'onlineAppConfig':{
          'app':{
            'tabs':{
              'tabs':[{
                'id': 'string',
                'module': 'string',
                'cat': 'string',
                'icon': 'string',
                'title': 'string',
                'order': 'number',
                'menu_group?': 'string',
                'menu_id?': 'string',
                'chat_label?': 'string',
                'api_id?': 'string',
                }],
            }
          }
          },
  'version?': 'number',
  'reference?':'string' ,
  'nandbox?':'string'
}
  const result = validateObject(data, structure);
  if (result.length > 0) {
    console.error('Validation Errors:', result);
    return {newData: null, error: result}
  } else {
    console.log('AppConfig is valid according to interface');
    // await this.aiAppTheme(data.layout)
    onlineChannelConfig.app.tabs.mode = localStorage.getItem("mode")
    onlineChannelConfig.app.tabs.tabs=[...data.onlineChannelConfig.app.tabs.tabs];
    if (data.onlineChannelConfig.app.menus){
      // onlineChannelConfig.app.menus = this.aiToMenusMapper(structuredClone(data.onlineChannelConfig.app.menus));
      onlineChannelConfig.app.menus=[...data.onlineChannelConfig.app.menus];
    }
   //******* onlineAppConfig **************//
    // onlineAppConfig.app.tabs.tabs = this.aiToTabsMapper(structuredClone(data.onlineAppConfig.app.tabs.tabs));
    onlineAppConfig.app.tabs.tabs=[...data.onlineAppConfig.app.tabs.tabs];

    if (data.onlineAppConfig.app.menus && data.onlineAppConfig.app.menus.length > 0){
      let sideMenus =[];
      // sideMenus = this.aiToMenusMapper(structuredClone(data.onlineAppConfig.app.menus));
      sideMenus = [...data.onlineAppConfig.app.menus];
      if (sideMenus && sideMenus.length >0 ){
        onlineChannelConfig.app.menus= [...onlineChannelConfig.app.menus, ...sideMenus];
      }
    }
    newData['appConfig'] = appConfig;
    newData['onlineAppConfig'] = onlineAppConfig;
    newData['onlineChannelConfig'] = onlineChannelConfig;
    newData['version']= 3;
    return {newData: newData, error: 0}
  }
  }

  aiToTabsMapper(components: any[]): any[] {
    return components.map(component => ({
      id: component.id,
      module: AI2Tabs[component.module],
      cat:AI2CAT[component.cat],
      icon: component.icon,
      icon_ios: component.icon,
      title: component.title,
      title_ios: component.title,
      tab_order: component.order,
      tab_version : component.version,
      ...(component.menu_group? {menu_group: component.menu_group}:{}),
      ...(component.menu_id? {menu_id: component.menu_id}:{}),
      ...(component.chat_label? {chat_label: component.chat_label}:{}),
      ...(component.api_id? {api_id: component.api_id}:{}),
    }));
  }

 aiToMenusMapper(menus: any[]): any[]{
    if (menus  && menus.length > 0 ){
      return menus.map(menu => ({
        ...menu,
        rows: menu.rows.map(row => ({
          ...row,
          buttons: row.cells.map(cell =>({

        button_id: cell.cell_id,
        row_id: row.row_id,
        button_code:ai2ButtonCode[cell.form + '_' + cell.style],
        button_callback: cell.cell_id,
        button_form: ai2ButtonForm[cell.form],
        button_style: ai2ButtonStyle[cell.style],
        button_style_ios: ai2ButtonStyle[cell.style],
        button_version: cell.version,
        button_order: cell.order,
        ...(cell.span? {button_span: cell.span}:{}),
        ...(cell.keyboard? {button_keyboard: cell.keyboard}:{}),
        ...(cell.divider? {button_divider: cell.divider}:{}),
        ...(cell.divider? {button_divider_ios: cell.divider}:{}),
        ...(cell.api_id? {button_api_id: cell.api_id}:{}),

        ...(cell.headline? {button_headline: cell.headline}:{}),
        ...(cell.subhead? {button_subhead: cell.subhead}:{}),
        ...(cell.label? {button_label: cell.label}:{}),
        ...(cell.sublabel? {button_sublabel: cell.sublabel}:{}),
        ...(cell.body? {button_body: cell.body}:{}),
        ...(cell.helper? {button_helper: cell.helper}:{}),
        ...(cell.error? {button_error: cell.error}:{}),
        ...(cell.suffix? {button_suffix: cell.suffix}:{}),
        ...(cell.prefix? {button_prefix: cell.prefix}:{}),
        ...(cell.options? {button_option: cell.options}:{}),
        ...(cell.value? {button_value: cell.value}:{}),
        ...(cell.placeholder? {button_placeholder: cell.placeholder}:{}),
        ...(cell.input? {button_input: cell.input}:{}),

        ...(cell.headline? {button_headline_ios: cell.headline}:{}),
        ...(cell.subhead? {button_subhead_ios: cell.subhead}:{}),
        ...(cell.label? {button_label_ios: cell.label}:{}),
        ...(cell.sublabel? {button_sublabel_ios: cell.sublabel}:{}),
        ...(cell.body? {button_body_ios: cell.body}:{}),
        ...(cell.helper? {button_helper_ios: cell.helper}:{}),
        ...(cell.error? {button_error_ios: cell.error}:{}),
        ...(cell.suffix? {button_suffix_ios: cell.suffix}:{}),
        ...(cell.prefix? {button_prefix_ios: cell.prefix}:{}),
        ...(cell.options? {button_option_ios: cell.options}:{}),
        ...(cell.value? {button_value_ios: cell.value}:{}),
        ...(cell.placeholder? {button_placeholder_ios: cell.placeholder}:{}),
        ...(cell.input? {button_input_ios: cell.input}:{}),

        ...(cell.image? {button_img_url: cell.image}:{}),
        ...(cell.bgimage? {button_bgimage: cell.bgimage}:{}),
        ...(cell.image? {button_img_url_ios: cell.image}:{}),
        ...(cell.bgimage? {button_bgimage_ios: cell.bgimage}:{}),

        ...(cell.icon? {button_icon: cell.icon}:{}),
        ...(cell.trailing_icon? {button_trailing_icon: cell.trailing_icon}:{}),
        ...(cell.icon? {button_icon_ios: cell.icon}:{}),
        ...(cell.trailing_icon? {button_trailing_icon_ios: cell.trailing_icon}:{}),

        ...(cell.color_schema? {button_color_schema: cell.color_schema}:{}),
        ...(cell.color_schema? {button_color_schema_ios: cell.color_schema}:{}),

        ...(cell.text_align? {button_text_align: cell.text_align}:{}),
        ...(cell.text_align? {button_text_align_ios: cell.text_align}:{}),
        ...(cell.text_size? {button_fontsize: cell.text_size}:{}),
        ...(cell.text_size? {button_fontsize_ios: cell.text_size}:{}),

        ...(cell.next? {next: cell.next}:{}),
        ...(cell.newpage? {button_newpage: cell.newpage}:{}),
        ...(cell.login? {button_login: cell.login}:{}),
        ...(cell.access? {button_access: cell.access}:{}),
        ...(cell.caching? {button_db: cell.caching}:{}),
        ...(cell.query? {button_query: cell.query}:{}),
             }))
        }))
      }));
    }else return null

  }

aiAppTheme?(layout){
let modes: any

if (layout.android.navigation.position=="top"){
  modes = [{id:c4.ID, value:c4.TOP}];
}else {
  modes = [{id:c4.ID, value:c4.BOTTOM}];
}
if (layout.android.navigation.icon && layout.android.navigation.label){
  modes = [{id:c5.ID, value:c5.WIDE}];
}else {
  modes = [{id:c5.ID, value:c5.STANDARD}];
}
if (layout.android.drawer.enabled){
  modes = [{id:c8.ID, value:c8.SIDE}];
}else {
  modes = [{id:c8.ID, value:c8.NOSIDE}];
}
if (layout.android.material =='m3'){
  modes = [{id:c11.ID, value:c11.M3}];
}else {
  modes = [{id:c11.ID, value:c11.M2}];
}
//***IOS *****/
if (layout.ios.navigation.icon && layout.android.navigation.label){
  modes = [{id:c10.ID, value:c10.WIDE}];
}else {
  modes = [{id:c10.ID, value:c10.STANDARD}];
}
if (layout.ios.navigation.title=='large'){
  modes = [{id:c7.ID, value:c7.LARGE}];
}else {
  modes = [{id:c7.ID, value:c7.STANDARD}];
}
if (layout.ios.setting_screen.enabled){
  modes = [{id:c9.ID, value:c9.SIDE}];
}else {
  modes = [{id:c9.ID, value:c9.NOSIDE}];
}
if (layout.ios.setting_screen.position=='top'){
  modes = [{id:c6.ID, value:c6.TOP}];
}else {
  modes = [{id:c6.ID, value:c6.BOTTOM}];
}
if (layout.ios.material =='modern'){
  modes = [{id:c13.ID, value:c13.M3}];
}else {
  modes = [{id:c13.ID, value:c13.M2}];
}

this.changeMobileTheme(modes);
}

appToAiMapper(data){
  const aiConfig = {
    config: {

      app_info: this.reverseAppInfo(data.appConfig.app.system.app_info),
      colors: this.reverseColors(data.onlineChannelConfig.app.tabs),
      default: this.reverseDefault(data.onlineChannelConfig.app.tabs.default),
      theme: this.reverseAppTheme(data.onlineChannelConfig.app.tabs.mode), // Empty function placeholder
    },
    navigation: {
      components: this.reverseTabsMapper(data.onlineChannelConfig.app.tabs.tabs),
      menus:data.onlineChannelConfig.app.tabs.menus?this.reverseMenusMapper(data.onlineChannelConfig.app.tabs.menu):[],
    },
    drawer:{
      components: this.reverseTabsMapper(data.onlineAppConfig.app.tabs.tabs),
    },
    version: data.appConfig.app.version.value,
  };
  return aiConfig;
}



reverseTabsMapper(tabs: any[]): any[] {
  return tabs.map(tab => ({
    id: tab.id,
    module: this.getKeyByValue(AI2Tabs, tab.module),
    cat: this.getKeyByValue(AI2CAT, tab.cat),
    icon: tab.icon,
    title: tab.title,
    order: tab.tab_order,
    ...(tab.menu_group ? { menu_group: tab.menu_group } : {}),
    ...(tab.menu_id ? { menu_id: tab.menu_id } : {}),
    ...(tab.chat_label ? { chat_label: tab.chat_label } : {}),
    ...(tab.api_id ? { api_id: tab.api_id } : {}),
  }));
}

reverseMenusMapper(menus: any[]): any[] {
  return menus.map(menu => ({
    menu_id: menu.menu_id,
    cat: menu.cat,
    menu_name: menu.menu_name,
    menu_group: menu.menu_group,
    menu_version: menu.menu_version,
    menu_order: menu.menu_order,
    ...(menu.rows ? { rows: this.reverseRowsMapper(menu.rows) } : {}),
    ...(menu.api_id ? { api_id: menu.api_id } : {}),
  }));
}

reverseRowsMapper(rows: any[]): any[] {
  return rows.map(row => ({
    row_id: row.row_id,
    row_order: row.row_order,
    menu_id: row.menu_id,
    ...(row.cells ? { cells: this.reverseCellsMapper(row.buttons) } : {}),
    ...(row.row_version ? { row_version: row.row_version } : {}),
  }));
}

reverseCellsMapper(buttons: any[]): any[] {
  return buttons.map(button => ({
    cell_id: button.button_id,
    form: this.getKeyByValue(ai2ButtonForm, button.button_form),
    style: this.getKeyByValue(ai2ButtonStyle, button.button_style),
    cell_order: button.button_order,
    version: button.button_version,
    heading: button.button_heading,
    ...(button.button_headline ? { headline: button.button_headline } : {}),
    ...(button.button_subhead ? { subhead: button.button_subhead } : {}),
    ...(button.button_label ? { label: button.button_label } : {}),
    ...(button.button_body ? { body: button.button_body } : {}),
    ...(button.button_helper ? { helper: button.button_helper } : {}),
    ...(button.button_error ? { error: button.button_error } : {}),
    ...(button.button_suffix ? { suffix: button.button_suffix } : {}),
    ...(button.button_prefix ? { prefix: button.button_prefix } : {}),
    ...(button.button_placeholder ? { placeholder: button.button_placeholder } : {}),
    ...(button.button_input ? { input: button.button_input } : {}),
    ...(button.button_divider !== undefined ? { divider: button.button_divider } : {}),
    ...(button.button_options ? { options: button.button_options } : {}),
    ...(button.button_value ? { value: button.button_value } : {}),
    ...(button.button_image ? { image: button.button_image } : {}),
    ...(button.button_bgimage ? { bgimage: button.button_bgimage } : {}),
    ...(button.button_icon ? { icon: button.button_icon } : {}),
    ...(button.button_trailing_icon ? { trailing_icon: button.button_trailing_icon } : {}),
    ...(button.button_text_size ? { text_size: button.button_text_size } : {}),
    ...(button.button_text_align ? { text_align: button.button_text_align } : {}),
    ...(button.next ? { next: button.next } : {}),
  }));
}

reverseColorSchema(colorSchema: any): any {
  return {
    source: colorSchema.source,
  };
}

reverseAppInfo(appInfo: any): any {
  const newAppInfo = structuredClone(appInfo);
  newAppInfo.app_name = appInfo.channel_name;
  delete newAppInfo.channel_name;
  return newAppInfo;
}

reverseDefault(orgDefault: any): any {
  const newDefault = structuredClone(orgDefault);
  newDefault.id = "default"
  return newDefault;
}


reverseColors(colors: any): any {
  return {
    id:"colors",
    color_schema: this.reverseColorSchema(colors.color_schema),
    color_schema_ios: this.reverseColorSchema(colors.color_schema_ios),
  };
}
// Empty function for reverse theme mapping
reverseAppTheme(modes: any): any {
  // TODO: Implement theme mapping logic heref
  // 'theme':{ 'android':{'navigation':{'position':'string', 'icon':'boolean', 'label':'boolean'}, 'drawer':{'enabled':'boolean', }, 'material':'string'},
  // 'ios':{'navigation':{'icon':'boolean', 'label':'boolean', 'title':'string'}, 'setting_screen':{'enabled':'boolean', 'position':'string'}, 'material':'string'}},

  let theme: any;
   theme= {android : {navigation:{position:"", icon:true, label:true, title:null }, drawer:{enabled:true, position: null}, material: ""},
          ios:{navigation:{position:"", icon:true, label:true, title:"large" }, setting_screen:{enabled:true, position:"top"}, material:""}};

   theme.android.navigation.position = modes[3]==c4.TOP? "top" : "bottom";

  if (modes[4] ==c5.WIDE){
   theme.android.navigation.icon =true;
    theme.android.navigation.label =true;
  }else{
    // either one
    theme.android.navigation.icon =true;
    theme.android.navigation.label =false;
  }
  theme.android.drawer.enabled = modes[7] ==c8.SIDE? true:false;
  theme.android.material= modes[10] ==c11.M3? "m3":"m2";

   //***IOS *****/
   if (modes[9] ==c10.WIDE){
    theme.ios.navigation.position =true;
     theme.ios.navigation.label =true;
   }else{
     // either one
     theme.iso.navigation.icon =true;
     theme.ios.navigation.label =false;
   }
   theme.ios.navigation.title = modes[6] ==c7.LARGE? "large":"standard";
   theme.ios.setting_screen.enabled = modes[8] ==c9.SIDE? true:false;
   theme.ios.material= modes[12] ==c13.M3? "modern":"clasic";
   theme.ios.setting_screen.position= modes[13]==c6.TOP? "top":"bottom";

   return theme
}

// Utility function to get the key by value from an enum
getKeyByValue(enumObj: any, value: string): string | undefined {
  return Object.keys(enumObj).find(key => enumObj[key] === value);
}
}

export function validateObject(input: any, structure: Record<string, any>): string[] {
  const errors: string[] = [];

  function validate(obj: any, struct: any, path: string) {
    Object.entries(struct).forEach(([key, expectedType]) => {

      // ✅ Handle optional keys
      const isOptional = key.endsWith('?');
      const actualKey = isOptional ? key.slice(0, -1) : key;
      const actualValue = obj[actualKey];
      const currentPath = path ? `${path}.${actualKey}` : actualKey;

      // ✅ If the key does not exist in the object
      if (!(actualKey in obj)) {
        if (!isOptional) {
          errors.push(`Missing required key: "${currentPath}"`);
        }
        return; // Skip optional keys that are not present
      }

      // ✅ Handle optional expected type
      if (typeof expectedType === 'string' && expectedType.endsWith('?')) {
        expectedType = expectedType.slice(0, -1);
      }

      // ✅ Handle nested object validation
      if (typeof expectedType === 'object' && expectedType !== null && !Array.isArray(expectedType)) {
        if (typeof actualValue !== 'object' || actualValue === null || Array.isArray(actualValue)) {
          errors.push(`Invalid type for key: "${currentPath}". Expected "object", but got "${Array.isArray(actualValue) ? 'array' : typeof actualValue}".`);
        } else {
          validate(actualValue, expectedType, currentPath);
        }
      }
      // ✅ Handle array validation
      else if (Array.isArray(expectedType)) {
        if (!Array.isArray(actualValue)) {
          errors.push(`Invalid type for key: "${currentPath}". Expected "array", but got "${typeof actualValue}".`);
        } else {
          actualValue.forEach((item, index) => {
            const itemPath = `${currentPath}[${index}]`;
            if (typeof expectedType[0] === 'object') {
              validate(item, expectedType[0], itemPath);
            } else {
              const itemType = typeof item;
              if (itemType !== expectedType[0]) {
                errors.push(`Invalid type for key: "${itemPath}". Expected "${expectedType[0]}", but got "${itemType}".`);
              }
            }
          });
        }
      }
      // ✅ Handle primitive type validation (number, string, boolean, etc.)
      else {
        const actualType = Array.isArray(actualValue) ? 'array' : typeof actualValue;

        if (actualType !== expectedType) {
          errors.push(`Invalid type for key: "${currentPath}". Expected "${expectedType}", but got "${actualType}".`);
        }
      }
    });
  }

  validate(input, structure, '');
  return errors;





}






